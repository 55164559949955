import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Alert,
    Spinner,
} from "reactstrap";
import TableContainer from '../../components/Common/TableContainer';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
    fetchCountingReportDashboard_EXTEND,
    fetchCountingReportDashboard_REPAYMENT,
    fetchCountingReportDashboard_KONTRAK,
    fetchTableReportPawnshopPhone,
} from "../../helpers/backend_helper";

import jwt_decode from "jwt-decode";
import * as moment from "moment";

import {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNIKCustomer,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatFieldAction,
    FormatOnlyLoading,
    FormatRAM_Internal,
    FormatJatuhTempo,
    FormatConfirmed,
    FormatRejected,
    FormatStatusBarang,
    FormatPelunasan,
} from "./isMasterTableCol";

let isUUIDOperator = null;
let isValopKodeCabang = null;
let MasterDataTableKontrakToday = null;

const TXADashboard = props => {

    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);
            
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }

    }
    const [isTotalCountingPerpanjang, setisTotalCountingPerpanjang] = useState(null);

    async function FetchCountingPerpanjangToday() {
        try {
            setisTotalCountingPerpanjang(await fetchCountingReportDashboard_EXTEND({ isUIDAccount: isUUIDOperator }));
            //showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            console.log(Err);
        }
    }

    const [isTotalCountingPelunasan, setisTotalCountingPelunasan] = useState(null);

    async function FetchCountingPelunasanToday() {
        try {
            setisTotalCountingPelunasan(await fetchCountingReportDashboard_REPAYMENT({ isUIDAccount: isUUIDOperator }));
            //showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            console.log(Err);
        }
    }

    const [isTotalCountingNotaKontrak, setisTotalCountingNotaKontrak] = useState(null);

    async function FetchCountingNotaKontrakToday() {
        try {
            setisTotalCountingNotaKontrak(await fetchCountingReportDashboard_KONTRAK({ isUIDAccount: isUUIDOperator }));
            //showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            console.log(Err);
        }
    }

    useEffect(() => {
        isUUIDOperator = null;
        isValopKodeCabang = null;
        MasterDataTableKontrakToday = null;

        autoUpdateProfile();

        setInterval(async () => {
            autoUpdateProfile();
        }, 500);

        setTimeout(async () => {
            await FetchCountingPerpanjangToday();
            await FetchCountingPelunasanToday();
            await FetchCountingNotaKontrakToday();
            await FeedFunctionDataForTableKontrakToday("isTRXToday");
            //await FeedFunctionDataForTableKontrakToday("isTRXAll");
        }, 500);
    }, []);

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    let summaryReports0 = [
        {
            title: "Jumlah Nota Masuk - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "primary",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakTunai > 0 ? isTotalCountingNotaKontrak.TotalKontrakTunai + " Nota" : "-" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakTunai > 0 ? isTotalCountingNotaKontrak.TotalKontrakTunai + " Nota" : "-" : "NaN",
            value_color: "text-primary",
        },
        {
            title: "Jumlah Nota Masuk - Bank / E-Wallet",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "info",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakEWallet > 0 ? isTotalCountingNotaKontrak.TotalKontrakNonTunai + " Nota / " + isTotalCountingNotaKontrak.TotalKontrakEWallet + " Nota" : "- / -" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakEWallet > 0 ? isTotalCountingNotaKontrak.TotalKontrakNonTunai + " Nota / " + isTotalCountingNotaKontrak.TotalKontrakEWallet + " Nota" : "- / -" : "NaN",
            value_color: "text-info",
        },
        {
            title: "Jumlah Nota Masuk - Unit",
            iconClass: "fas fa-arrow-alt-circle-down",
            bgColor: "warning",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakEWallet > 0 ? (isTotalCountingNotaKontrak.TotalKontrakNonTunai + isTotalCountingNotaKontrak.TotalKontrakTunai + isTotalCountingNotaKontrak.TotalKontrakEWallet) + " Unit" : "-" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakTunai > 0 || isTotalCountingNotaKontrak.TotalKontrakEWallet > 0 ? (isTotalCountingNotaKontrak.TotalKontrakNonTunai + isTotalCountingNotaKontrak.TotalKontrakTunai + isTotalCountingNotaKontrak.TotalKontrakEWallet) + " Unit" : "-" : "NaN",
            value_color: "text-warning",
        },
        {
            title: "Akumulasi Nota Masuk - Tunai",
            iconClass: "mdi mdi-transfer-down",
            bgColor: "primary",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total !== null ? CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total) : "Rp. -" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total !== null ? CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total) : "Rp. -" : "NaN",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Nota Masuk - Bank / E-Wallet",
            iconClass: "mdi mdi-bank-transfer-out",
            bgColor: "info",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total !== null || isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total !== null ? CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total) + " / " + CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total) : "Rp. - / Rp. -" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total !== null || isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total !== null ? CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total) + " / " + CurrencyFormatter.format(isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total) : "Rp. - / Rp. -" : "NaN",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Nota Masuk - Pengeluaran Dana",
            iconClass: "fas fa-arrow-alt-circle-up",
            bgColor: "success",
            textColor: "text-white",
            description: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total !== null || isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total !== null ? CurrencyFormatter.format((isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total + isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total + isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total)) : "Rp. -" : "NaN",
            value: isTotalCountingNotaKontrak !== null ? isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total !== null || isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total !== null ? CurrencyFormatter.format((isTotalCountingNotaKontrak.TotalKontrakNonTunaiNOMINAL.Total + isTotalCountingNotaKontrak.TotalKontrakTunaiNOMINAL.Total + isTotalCountingNotaKontrak.TotalKontrakEWalletNOMINAL.Total)) : "Rp. -" : "NaN",
            value_color: "text-success",
        },
    ];

    let summaryReports1 = [
        {
            title: "Jumlah Nota Perpanjang - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "success",
            textColor: "text-white",
            description: isTotalCountingPerpanjang !== null ? isTotalCountingPerpanjang.TotalTunai > 0 ? isTotalCountingPerpanjang.TotalTunai + " Transaksi / " + CurrencyFormatter.format(isTotalCountingPerpanjang.TotalNominalTunai.Total) : "- / Rp. -" : "NaN",
            value: isTotalCountingPerpanjang !== null ? isTotalCountingPerpanjang.TotalTunai > 0 ? isTotalCountingPerpanjang.TotalTunai + " Transaksi / " + CurrencyFormatter.format(isTotalCountingPerpanjang.TotalNominalTunai.Total) : "- / Rp. -" : "NaN",
            value_color: "text-success",
        },
        {
            title: "Jumlah Nota Perpanjang - Transfer Bank",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "success",
            textColor: "text-white",
            description: isTotalCountingPerpanjang !== null ? isTotalCountingPerpanjang.TotalNonTunai > 0 ? isTotalCountingPerpanjang.TotalNonTunai + " Transaksi" : "-" : "NaN",
            value: isTotalCountingPerpanjang !== null ? isTotalCountingPerpanjang.TotalNonTunai > 0 ? isTotalCountingPerpanjang.TotalNonTunai + " Transaksi" : "-" : "NaN",
            value_color: "text-success",
        },
    ];

    let summaryReports2 = [
        {
            title: "Jumlah Nota Pelunasan - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "danger",
            textColor: "text-white",
            description: isTotalCountingPelunasan !== null ? isTotalCountingPelunasan.TotalTunai > 0 ? isTotalCountingPelunasan.TotalTunai + " Transaksi / " + CurrencyFormatter.format(isTotalCountingPelunasan.TotalNominalTunai.Total) : "- / Rp. -" : "NaN",
            value: isTotalCountingPelunasan !== null ? isTotalCountingPelunasan.TotalTunai > 0 ? isTotalCountingPelunasan.TotalTunai + " Transaksi / " + CurrencyFormatter.format(isTotalCountingPelunasan.TotalNominalTunai.Total) : "- / Rp. -" : "NaN",
            value_color: "text-danger",
        },
        {
            title: "Jumlah Nota Pelunasan - Transfer Bank",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "danger",
            textColor: "text-white",
            description: isTotalCountingPelunasan !== null ? isTotalCountingPelunasan.TotalNonTunai > 0 ? isTotalCountingPelunasan.TotalNonTunai + " Transaksi" : "-" : "NaN",
            value: isTotalCountingPelunasan !== null ? isTotalCountingPelunasan.TotalNonTunai > 0 ? isTotalCountingPelunasan.TotalNonTunai + " Transaksi" : "-" : "NaN",
            value_color: "text-danger",
        },
    ];

    const printOutContractFaktur = (isNomorKontrak) => {
        let printWindow = "";

        //apply base url for axios
        let API_URL = '';

        //Using Global Domain Server
        if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
            API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN;
        }
        else {
            //127.0.0.1
            if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                if (window.location.hostname == process.env.REACT_APP_IP_ROOT) {
                    API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
                else {
                    API_URL = "//" + process.env.REACT_APP_DOMAIN_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
            }
            //Local IP 192.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + process.env.REACT_APP_FE_PORT_LOCAL;
            }
            //Public IP X.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + process.env.REACT_APP_FE_PORT_INTERNET;
            }

        }

        printWindow = window.open("//" + API_URL + "/apps/pawnshop/print/handphone/" + isNomorKontrak, "popup", "width=" + 1024 + ",height=" + 640);

        //printWindow.document.write(printableContent.innerHTML);
        //printWindow.print();
        //console.log("Test");
    };

    const isColumnsDataKontrakToday = useMemo(
        () => [
            {
                Header: "#",
                accessor: "PGD_KontrakFailed",
                disableFilters: true,
                filterable: false,
                sortable: false,
            },
            {
                Header: "No. Kontrak",
                accessor: "PGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "Tgl. Terbit",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGDRincian_WaktuTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Perpanjang",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGD_TanggalTerakhirTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Ja.Po",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGDRincian_JTempoTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "isCustomer.NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nasabah",
                accessor: "isCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Jaminan",
                accessor: "PGDJaminan_Tipe",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Pelunasan",
                accessor: "PGD_NominalTebus",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            {
                Header: "Jt. Tempo",
                accessor: "PGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            "-"
                            :
                            <FormatJatuhTempo {...cellProps} />
                    ))
                    //return <FormatJatuhTempo {...cellProps} />;
                },
            },
            {
                Header: "St. Konfirmasi",
                accessor: "PGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatConfirmed {...cellProps} />;
                },
            },
            {
                Header: "St. Kontrak",
                accessor: "PGD_Lunas",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    /*return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            <Badge className="bg-danger font-size-11">Kontrak Gagal</Badge>
                            :
                            <FormatPelunasan {...cellProps} />
                    ))*/

                    return <FormatPelunasan {...cellProps} />;
                },
            },
            /*{
                Header: "St. Barang",
                accessor: "PGD_StatusBarang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatStatusBarang {...cellProps} />;
                },
            },*/
            {
                Header: "Cabang",
                accessor: "isCabang.CabangName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCabang {...cellProps} />;
                },
            },
            {
                Header: "Operator",
                accessor: "isOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                    <Link
                                        to={"/apps/pawnshop/details/handphone/" + cellProps.cell.row.allCells[1].value}
                                        className="btn btn-sm btn-info"
                                        onClick={() => {

                                        }}
                                    >
                                        <i className="mdi mdi-eye-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Kontrak
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-primary"
                                        onClick={async (evt) => {
                                            await printOutContractFaktur(cellProps.cell.row.allCells[1].value);
                                        }}
                                    >
                                        <i className="fas fa-file-download" id="invoicetooltip" />
                                        <UncontrolledTooltip placement="top" target="invoicetooltip">
                                            Lihat Invoice
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const [isDataTableKontrakToday, setisDataTableKontrakToday] = useState(null);
    const [isDataLoadingForTableKontrak, setisDataLoadingForTableKontrak] = useState(false);

    async function FeedFunctionDataForTableKontrakToday(WhatsFiltered) {

        setisDataTableKontrakToday(null);
        setisDataLoadingForTableKontrak(true);

        setTimeout(async () => {
            try {
                MasterDataTableKontrakToday = await fetchTableReportPawnshopPhone({ LimitData: 1000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTableKontrakToday[0] != undefined) {
                    setisDataTableKontrakToday(MasterDataTableKontrakToday);
                }
                else {
                    MasterDataTable = null;
                    setisDataTableKontrakToday(null);
                }
                //showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');
                setisDataLoadingForTableKontrak(false);
            }
            catch (Err) {
                setisDataLoadingForTableKontrak(false);
                //showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');
            }
        }, 500);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch]);
    
    //meta title
    document.title = "Halaman Utama - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Halaman Utama")}
                  />

                  <Row>
                      <Col xl={12}>
                          <CardTitle className="text-center text-uppercase">Laporan Transaksi Masuk Hari Ini</CardTitle>
                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                          <Alert className="alert-info" role="alert">
                              <h5 className="alert-heading text-primary pb-2"><i className="mdi mdi-information-outline me-2"></i> INFORMASI</h5>
                              <p>Laporan Harian dibawah ini, adalah perhitungan seluruh data transaksi yang di lakukan pada hari ini <b>{moment().tz('Asia/Jakarta').format("DD MMMM YYYY")}</b> termasuk transaksi yang belum di validasi atau biasa disebut transaksi pending, dan kontrak yang lunas tidak termasuk dalam perhitungan laporan.</p>
                              <p>~Terimakasih ^.^</p>
                          </Alert>

                          <Col xl="12">
                              <Row>
                                  {/* Reports Render */}
                                  {summaryReports0.map((report, key) => (
                                      <Col md="4" className="" key={"_col_" + key}>
                                          <Card className="mini-stats-wid">
                                              <CardBody>
                                                  <div className="d-flex">
                                                      <div className="flex-grow-1">
                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <p className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "text-black fw-medium placeholder-glow"
                                                                              : "text-black fw-medium"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                              : report.title
                                                                      }
                                                                  </p>
                                                                  :
                                                                  <p className="text-black fw-medium text-uppercase">
                                                                      {report.title}
                                                                  </p>
                                                          }

                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <span className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "mb-0 placeholder-glow"
                                                                              : "mb-0"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="mb-0 placeholder text-primary col-6">report.description</span>
                                                                              : report.description
                                                                      }
                                                                  </span>
                                                                  :
                                                                  <>
                                                                      <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                                  </>
                                                          }

                                                      </div>
                                                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                          <span className={
                                                              "avatar-title bg-" +
                                                              report.bgColor +
                                                              " " +
                                                              report.textColor
                                                          }
                                                          >
                                                              <i
                                                                  className={
                                                                      " " + report.iconClass + " font-size-20"
                                                                  }
                                                              ></i>
                                                          </span>
                                                      </div>

                                                  </div>
                                              </CardBody>
                                          </Card>
                                      </Col>
                                  ))}
                              </Row>
                          </Col>
                      </Col>

                      <Col xl={12}>
                          <Col xl="12">
                              <Row>
                                  {/* Reports Render */}
                                  {summaryReports1.map((report, key) => (
                                      <Col md="6" className="" key={"_col_" + key}>
                                          <Card className="mini-stats-wid">
                                              <CardBody>
                                                  <div className="d-flex">
                                                      <div className="flex-grow-1">
                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <p className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "text-black fw-medium placeholder-glow"
                                                                              : "text-black fw-medium"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                              : report.title
                                                                      }
                                                                  </p>
                                                                  :
                                                                  <p className="text-black fw-medium text-uppercase">
                                                                      {report.title}
                                                                  </p>
                                                          }

                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <span className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "mb-0 placeholder-glow"
                                                                              : "mb-0"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="mb-0 placeholder text-primary col-6">report.description</span>
                                                                              : report.description
                                                                      }
                                                                  </span>
                                                                  :
                                                                  <>
                                                                      <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                                  </>
                                                          }

                                                      </div>
                                                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                          <span className={
                                                              "avatar-title bg-" +
                                                              report.bgColor +
                                                              " " +
                                                              report.textColor
                                                          }
                                                          >
                                                              <i
                                                                  className={
                                                                      " " + report.iconClass + " font-size-20"
                                                                  }
                                                              ></i>
                                                          </span>
                                                      </div>

                                                  </div>
                                              </CardBody>
                                          </Card>
                                      </Col>
                                  ))}
                              </Row>
                          </Col>
                      </Col>

                      <Col xl={12}>
                          <Col xl="12">
                              <Row>
                                  {/* Reports Render */}
                                  {summaryReports2.map((report, key) => (
                                      <Col md="6" className="" key={"_col_" + key}>
                                          <Card className="mini-stats-wid">
                                              <CardBody>
                                                  <div className="d-flex">
                                                      <div className="flex-grow-1">
                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <p className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "text-black fw-medium placeholder-glow"
                                                                              : "text-black fw-medium"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                              : report.title
                                                                      }
                                                                  </p>
                                                                  :
                                                                  <p className="text-black fw-medium text-uppercase">
                                                                      {report.title}
                                                                  </p>
                                                          }

                                                          {
                                                              report.description == "NaN"
                                                                  ?
                                                                  <span className=
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? "mb-0 placeholder-glow"
                                                                              : "mb-0"
                                                                      }
                                                                  >
                                                                      {
                                                                          report.description == "NaN"
                                                                              ? <span className="mb-0 placeholder text-primary col-6">report.description</span>
                                                                              : report.description
                                                                      }
                                                                  </span>
                                                                  :
                                                                  <>
                                                                      <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                                  </>
                                                          }

                                                      </div>
                                                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                          <span className={
                                                              "avatar-title bg-" +
                                                              report.bgColor +
                                                              " " +
                                                              report.textColor
                                                          }
                                                          >
                                                              <i
                                                                  className={
                                                                      " " + report.iconClass + " font-size-20"
                                                                  }
                                                              ></i>
                                                          </span>
                                                      </div>

                                                  </div>
                                              </CardBody>
                                          </Card>
                                      </Col>
                                  ))}
                              </Row>
                          </Col>
                      </Col>

                      <Col xl={12}>
                          <Card>
                              <CardBody>
                                  <CardTitle className="text-center text-uppercase">Table Kontrak / Nota Baru Hari Ini</CardTitle>
                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                  {
                                      isDataLoadingForTableKontrak
                                          ?
                                          <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                              <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                                          </Col>
                                          :
                                          isDataTableKontrakToday != null && isDataTableKontrakToday.length > 0
                                              ?
                                              <Col lg={12} className={"mt-1"}>
                                                  <TableContainer
                                                      className={"table table-hover rounded"}
                                                      columns={isColumnsDataKontrakToday}
                                                      data={isDataTableKontrakToday || [{}]}
                                                      isGlobalFilter={true}
                                                      isAddOptions={false}
                                                      isPlaceholderData={"Cari data pegadaian. . ."}
                                                      customPageSize={5}
                                                      isSorted={"desc"}
                                                      isLoading={true}
                                                  />
                                              </Col>
                                              :
                                              <Col lg={12} className="mt-5 pb-5 text-center">
                                                  <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                              </Col>
                                  }
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>

          </Container>
          </div>
    </React.Fragment>
  );
};

TXADashboard.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(TXADashboard);
