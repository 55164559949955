import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer bg-black">
        <Container fluid={true}>
                  <Row>
                      <Col md={6}> Copyright © {new Date().getFullYear()} <a href="https://www.txa.co.id/" target="_blank" rel="noreferrer">{process.env.REACT_APP_COPYRIGHT}</a> - All right reserved.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                              App Version : {process.env.REACT_APP_VERSION} {process.env.REACT_APP_VERSION_DESCRIPTION}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
