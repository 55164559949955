import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
//i18n
import { withTranslation } from "react-i18next";
// users
import userProfilePict from "../../../assets/images/users/default.png";
import userProfilePictTroll from "../../../assets/images/users/default_troll.png";
import jwt_decode from "jwt-decode";

const UserProfile = props => {

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [card_id, setcard_id] = useState("");
    const [fullName, setfullName] = useState("");
    const [userName, setuserName] = useState("");
    const [uuid, setuuid] = useState("");
    const [role, setrole] = useState("");
    const [email, setemail] = useState("");
    const [last_login_at, setlast_login_at] = useState("");
    const [api_key, setapi_key] = useState("");
    const [avatar, setAvatar] = useState("");

    const eachUserData = async () => {

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setcard_id(DecodeUserData.card_id);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);
            setemail(DecodeUserData.email);
            setlast_login_at(DecodeUserData.last_login_at);
            setapi_key(DecodeUserData.api_key);

            setAvatar(DecodeUserData.avatar);
        }
        catch (Err) {
        }
    }

    useEffect(() => {
        eachUserData();

        setTimeout(() => {
            {
                eachUserData();
            }
        }, 3000); //Refresh token setiap 15s

    }, [props.success]);
  //meta title
    document.title = "User Profile - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + props.t("Pengguna")}
            breadcrumbItem={props.t("Profil Pengguna")}
                  />
                  <Row>
                      <Col lg={12 }>
                          <Card>
                              <CardBody>
                                  <Row>
                                      <Col md={2}>
                                          <div className="d-flex">
                                              <div className="ms-3">
                                                  <img
                                                      src={userName == "system" ? userProfilePictTroll : userProfilePict}
                                                      alt=""
                                                      className="avatar-lg rounded img-thumbnail"
                                                  />
                                              </div>
                                          </div>
                                      </Col>

                                      <Col md={5}>
                                          <div className="table-responsive">
                                              <table className="table table-wrap mb-0 table">
                                                  <tbody>
                                                      <tr>
                                                          <th scope="row" width="30%">Nama Depan</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {firstName}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">Nama Belakang</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {lastName}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">Nama Lengkap</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {fullName}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">Alamat Email</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {email}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">Username</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {userName}
                                                          </b></td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                      </Col>

                                      <Col md={5}>
                                          <div className="table-responsive">
                                              <table className="table table-wrap mb-0 table">
                                                  <tbody>
                                                      <tr>
                                                          <th scope="row" width="30%">Role</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {role}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">UUID</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {uuid}
                                                          </b></td>
                                                      </tr>
                                                      {/*<tr>
                                                          <th scope="row" width="30%">Card ID</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {card_id}
                                                          </b></td>
                                                      </tr>*/}
                                                      <tr>
                                                          <th scope="row" width="30%">API Key</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {api_key != null ? "***********************" : "-"}
                                                          </b></td>
                                                      </tr>
                                                      <tr>
                                                          <th scope="row" width="30%">Terakhir Login</th>
                                                          <td width="5%">:</td>
                                                          <td><b>
                                                              {last_login_at}
                                                          </b></td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                      </Col>

                                      <Col md={12}>
                                              <CardBody>
                                                  <Button
                                                      type="button"
                                                      color="info"
                                                      className="btn-sm btn-square"
                                                      style={{ float: 'right' }}
                                                  >
                                                      <Link
                                                          to={"/apps/account/settings"}
                                                          className="text-white text-center">
                                                      <i className="mdi mdi-account-cog" style={{ marginRight: '1px' }}></i> Pengaturan Akun
                                                      </Link>
                                                          </Button>
                                              </CardBody>
                                      </Col>
                                  </Row>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
          </Container>
          </div>
    </React.Fragment>
  );
};


export default withTranslation()(UserProfile);
