import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    Alert,
    InputGroup,
    FormFeedback,
    UncontrolledAlert,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
    fetchDataNoKontrak,
    fetchDetailPerpanjangNoKontrak,
    fetchFullDataBank,
    fetchDataBankXeWallet,
    fetchFullDataFakeBank,
    fetchDataBankSistem,
    fetchDetailDataBankSistem,
    createExtendContract,
    uploadBuktiPerpanjang,
    fetchTableReportPawnshopPhone,
    fetchTableReportPawnshopPhoneAKTIF,
} from "../../../../helpers/backend_helper";

import jwt_decode from "jwt-decode";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, useFormikContext } from "formik";

import moment from 'moment';

import TableContainer from '../../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';

import {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNIKCustomer,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatFieldAction,
    FormatOnlyLoading,
    FormatRAM_Internal,
    FormatJatuhTempo,
    FormatConfirmed,
    FormatRejected,
    FormatStatusBarang,
    FormatPelunasan,
    FormatNominalPerpanjang,
} from "./isMasterTableCol";

let isFullDataKontrak_API = null;
let isFullDataMasterBankAsal_API = null;
let isFullDataMasterBankTujuan_API = null;
let isDetailDataMasterBankTujuan_API = null;
let isFullDetailDataKontrak_API = null;
let UUIDAccountAPI = null;
let EXT_INPUTField_NamaRekPengirim = null;
let EXT_INPUTField_NoRekPengirim = null;
let EXT_INPUT_TipePembayaran = null;
let EXT_INPUT_NoKontrak = null;
let EXT_INPUT_BuktiPembayaran = null;
let EXT_TipePembayaranForUploadFile = undefined;
let ErrorStatusCreatePerpanjang = undefined;
let ErrorStatusUploadFoto = undefined;

let isUUIDOperator = null;
let isValopKodeCabang = null;
let MasterDataTableKontrak = null;

const PerpanjangTRXPawnshopHandphone = props => {
    const dispatch = useDispatch();

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "1500",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success') {
            toastr.success(isMessage, isTitle);
            setTimeout(async function () {
                return window.location.href = '/apps/pawnshop/details/handphone/' + EXT_INPUT_NoKontrak;
            }, 1500);
        }
        else if (isType === 'info') {
            toastr.info(isMessage, isTitle);
        }
        else if (isType === 'warning') {
            toastr.warning(isMessage, isTitle);
        }
        else {
            toastr.error(isMessage, isTitle);
            setTimeout(async function () {
                return location.reload();
            }, 1500)
        }
    }

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);
            
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            UUIDAccountAPI = DecodeUserData.uuid;

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
            console.log(Err)
        }
    }

    const [opUUID, setopUUID] = useState(null);
    const [opKodeCabang, setopKodeCabang] = useState(null);
    const [role, setrole] = useState(null);


    async function FeedFunctionDataNoKontrak() {
        setisLoadingCariNoKontrak(true);
        try {
            isFullDataKontrak_API = await fetchDataNoKontrak({ UUIDAccount: UUIDAccountAPI });

            setisFullDataKontrak(isFullDataKontrak_API);
            setisLoadingCariNoKontrak(false);
        }
        catch (Err) {
            //Nothing
        }
    }

    const [selectedCariNoKontrak, setselectedCariNoKontrak] = useState(null);
    const [isLoadingCariNoKontrak, setisLoadingCariNoKontrak] = useState(false);
    const [isFullDataKontrak, setisFullDataKontrak] = useState(null);
    const [isFullDetailDataNoKontrak, setisFullDetailDataNoKontrak] = useState(null);

    const [isEnableDisableNoKontrak, setisEnableDisableNoKontrak] = useState(false);
    const [isEnableDisableTipePembayaran, setisEnableDisableTipePembayaran] = useState(true);
    const [isEnableDisableCariDataBankAsal, setisEnableDisableCariDataBankAsal] = useState(true);
    const [isEnableDisableCariDataBankTujuan, setisEnableDisableCariDataBankTujuan] = useState(true);
    const [isEnableDisableInfoBankPengirim, setisEnableDisableInfoBankPengirim] = useState(true);
    const [EnableDisableBTNCreateExtend, setEnableDisableBTNCreateExtend] = useState(false);


    const optionCariNoKontrak = [
        {
            label: "Cari Nomor Kontrak Nasabah",
            options: isFullDataKontrak || []
        },
    ];
    async function handleFeedCariNoKontrak() {
        setisFullDataKontrak(null);

        await FeedFunctionDataNoKontrak();
    }

    async function handleCariNoKontrak(selectedCariNoKontrak) {
        setselectedCariNoKontrak(selectedCariNoKontrak);

        EXT_INPUT_NoKontrak = selectedCariNoKontrak.value;

        setisEnableDisableTipePembayaran(false);

        await FeedFunctionDetailPerpanjangNoKontrak(selectedCariNoKontrak.value);
    };

    async function FeedFunctionDetailPerpanjangNoKontrak(ContractNumber) {
        try {
            isFullDetailDataKontrak_API = await fetchDetailPerpanjangNoKontrak({ ContractNumber: ContractNumber });

            setisFullDetailDataNoKontrak(isFullDetailDataKontrak_API);

            setINPUTField_BarangJaminan(isFullDetailDataKontrak_API.PGDJaminan_Tipe);
            setINPUTField_NIKNasabah(isFullDetailDataKontrak_API.isCustomer.NIKPelanggan);
            setINPUTField_NamaNasabah(isFullDetailDataKontrak_API.isCustomer.NamaLengkap);
            setINPUTField_BiayaPerpanjang(isFullDetailDataKontrak_API.PGD_BiayaPerpanjang);
            setINPUTField_JaPoKeterlambatan(isFullDetailDataKontrak_API.PGD_JumlahHariKeterlambatan);
            
            EXT_INPUTField_NamaRekPengirim = isFullDetailDataKontrak_API.isCustomer.NamaLengkap;
            setINPUTField_UUIDPelanggan(isFullDetailDataKontrak_API.isCustomer.idPelanggan);

            setINPUTField_TglJAPO(moment(isFullDetailDataKontrak_API.PGDRincian_JTempoTRX).tz('Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss'));

        }
        catch (Err) {
            //Nothing
        }
    }

    const [isFullDataMasterBankAsal, setisFullDataMasterBankAsal] = useState([{}]);
    const [selectedCariDataBankAsal, setselectedCariDataBankAsal] = useState(null);
    const [isLoadingCariCariDataBankAsal, setisLoadingCariCariDataBankAsal] = useState(false);
    const [BankAsalSelected, setBankAsalSelected] = useState(null);

    async function FeedFunctionMasterDataBankAsal(isTipeBank) {
        setisLoadingCariCariDataBankAsal(true);
        if (isTipeBank === "Transfer Bank") {
            try {
                isFullDataMasterBankAsal_API = await fetchDataBankXeWallet();

                setisFullDataMasterBankAsal(isFullDataMasterBankAsal_API);
                setisLoadingCariCariDataBankAsal(false);
            }
            catch (Err) {
                //Nothing
            }
        }
        else {
            try {
                isFullDataMasterBankAsal_API = await fetchFullDataFakeBank();

                setisFullDataMasterBankAsal(isFullDataMasterBankAsal_API);
                setisLoadingCariCariDataBankAsal(false);
            }
            catch (Err) {
                //Nothing
            }
        }
    }

    const optionCariDataBankAsal = [
        {
            label: "Cari Data Bank",
            options: isFullDataMasterBankAsal || []
        },
    ];

    async function handleFeedCariDataBankAsal() {
        setisFullDataMasterBankAsal(null);

        await FeedFunctionMasterDataBankAsal(EXT_INPUT_TipePembayaran);
    }

    async function handleCariDataBankAsal(selectedCariDataBank) {
        setselectedCariDataBankAsal(selectedCariDataBank);
        setBankAsalSelected(selectedCariDataBank.label);

        setisEnableDisableInfoBankPengirim(false);
    };

    const [isFullDataMasterBankTujuan, setisFullDataMasterBankTujuan] = useState(null);
    const [isDetailDataMasterBankTujuan, setisDetailDataMasterBankTujuan] = useState(null);
    const [selectedCariDataBankTujuan, setselectedCariDataBankTujuan] = useState(null);
    const [BankTujuanSelected, setBankTujuanSelected] = useState(null);
    const [isLoadingCariCariDataBankTujuan, setisLoadingCariCariDataBankTujuan] = useState(false);
    const [isNamaRekTujuan, setisNamaRekTujuan] = useState(null);
    const [isNoRekTujuan, setisNoRekTujuan] = useState(null);

    async function FeedFunctionDetailDataBankTujuan(isIDBank) {
        try {
            isDetailDataMasterBankTujuan_API = await fetchDetailDataBankSistem({ ID: isIDBank });

            setisDetailDataMasterBankTujuan(isDetailDataMasterBankTujuan_API);
            setisNamaRekTujuan(isDetailDataMasterBankTujuan_API.AtasNama);
            setisNoRekTujuan(isDetailDataMasterBankTujuan_API.NomorRek);

            if (parseInt(isDetailDataMasterBankTujuan_API.SandiBank) === 9999) {
                //handleInputField_NoRekAsal("Tunai");
                EXT_INPUTField_NoRekPengirim = "Rek-Tunai";
            }
        }
        catch (Err) {
            //Nothing
        }
    }

    async function FeedFunctionMasterDataBankTujuan(isTipeBank) {
        setisLoadingCariCariDataBankTujuan(true);
        if (isTipeBank === "Transfer Bank") {
            try {
                isFullDataMasterBankTujuan_API = await fetchDataBankSistem({ Type: "Bank" });

                setisFullDataMasterBankTujuan(isFullDataMasterBankTujuan_API);
                setisLoadingCariCariDataBankTujuan(false);
            }
            catch (Err) {
                //Nothing
            }
        }
        else {
            try {
                isFullDataMasterBankTujuan_API = await fetchDataBankSistem({ Type: "Tunai" });

                setisFullDataMasterBankTujuan(isFullDataMasterBankTujuan_API);
                setisLoadingCariCariDataBankTujuan(false);
            }
            catch (Err) {
                //Nothing
            }
        }
    }

    const optionCariDataBankTujuan = [
        {
            label: "Cari Data Bank",
            options: isFullDataMasterBankTujuan || []
        },
    ];

    async function handleFeedCariDataBankTujuan() {
        setisFullDataMasterBankTujuan(null);

        await FeedFunctionMasterDataBankTujuan(EXT_INPUT_TipePembayaran);
    }

    async function handleCariDataBankTujuan(selectedCariDataTujuan) {
        setselectedCariDataBankTujuan(selectedCariDataTujuan);
        setBankTujuanSelected(selectedCariDataTujuan.label);
        await FeedFunctionDetailDataBankTujuan(selectedCariDataTujuan.value);
    };

    const [checkedTunai, setcheckedTunai] = useState(false);
    const [checkedNonTunai, setcheckedNonTunai] = useState(false);

    async function handleSelectTipePembayaran(INPUTValue) {
        EXT_INPUT_TipePembayaran = INPUTValue;

        setAllStepisPassed(false);
        
        isDetailDataMasterBankTujuan_API = null;

        EXT_INPUTField_NoRekPengirim = null;
        EXT_INPUT_BuktiPembayaran = null;

        setisFullDataMasterBankAsal(null);
        setisDetailDataMasterBankTujuan(null);

        setisDetailDataMasterBankTujuan(null);
        setisNamaRekTujuan(null);
        setisNoRekTujuan(null);
        setselectedCariDataBankTujuan(null);
        setBankTujuanSelected(null);
        setselectedCariDataBankAsal(null);
        setBankAsalSelected(null);
        
    }

    const [isBuktiPembayaran, setisBuktiPembayaran] = useState(null);
    const [isFileBuktiPembayaran, setisFileBuktiPembayaran] = useState(null);
    const [AllStepisPassed, setAllStepisPassed] = useState(false);

    function handleChangeFileBuktiPembayaran(e) {
        setisBuktiPembayaran(URL.createObjectURL(e.target.files[0]));

        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = "imgExtendPayment-" + EXT_INPUT_NoKontrak + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        EXT_INPUT_BuktiPembayaran = isFileRenameValue;

        //console.log(FileBuktiPembayaran);

        setisFileBuktiPembayaran(fileRenamed);
    }

    

    const [INPUTField_UUIDPelanggan, setINPUTField_UUIDPelanggan] = useState(null);
    const [INPUTField_TglPembayaran, setINPUTField_TglPembayaran] = useState(null);
    const [INPUTField_TglJAPO, setINPUTField_TglJAPO] = useState(null);
    const [INPUTField_BarangJaminan, setINPUTField_BarangJaminan] = useState(null);
    const [INPUTField_NIKNasabah, setINPUTField_NIKNasabah] = useState(null);
    const [INPUTField_NamaNasabah, setINPUTField_NamaNasabah] = useState(null);
    const [INPUTField_BiayaPerpanjang, setINPUTField_BiayaPerpanjang] = useState(null);
    const [INPUTField_JaPoKeterlambatan, setINPUTField_JaPoKeterlambatan] = useState(null);

    const Validation_Perpanjang = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Field_UUIDPelanggan: INPUTField_UUIDPelanggan || undefined,
            Field_OpUUID: opUUID || undefined,
            Field_NoKontrak: EXT_INPUT_NoKontrak || undefined,
            Field_TglPembayaran: moment.tz('Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss') || undefined,
            Field_TglJatuhTempo: INPUTField_TglJAPO || undefined,
            Field_BarangJaminan: INPUTField_BarangJaminan || undefined,
            Field_NIKNasabah: INPUTField_NIKNasabah || undefined,
            Field_NamaNasabah: INPUTField_NamaNasabah || undefined,
            Field_BiayaPerpanjang: INPUTField_BiayaPerpanjang || undefined,
            Field_JaPoKeterlambatan: INPUTField_JaPoKeterlambatan,
            Field_MetodePembayaran: EXT_INPUT_TipePembayaran || undefined,
            Field_NamaBankAsal: BankAsalSelected || undefined,
            Field_NamaRekAsal: EXT_INPUTField_NamaRekPengirim || undefined,
            Field_NoRekAsal: EXT_INPUTField_NoRekPengirim || undefined,
            Field_NominalPerpanjang: INPUTField_BiayaPerpanjang || undefined,
            Field_NamaBankTujuan: BankTujuanSelected || undefined,
            Field_NamaRekTujuan: isNamaRekTujuan || undefined,
            Field_NoRekTujuan: isNoRekTujuan || undefined,
            Field_BuktiPembayaran: EXT_INPUT_BuktiPembayaran || undefined,
            Field_FileBuktiPembayaran: isFileBuktiPembayaran || undefined,
        },
        validationSchema: Yup.object().shape({
            Field_NamaRekAsal: Yup.string().required("Nama Pemilik Rekening Pengirim Tidak Boleh Kosong!"),
            Field_NoRekAsal: Yup.string().required("Nomor Rekening Pengirim Tidak Boleh Kosong!"),
        }),
        onSubmit: (values) => {
            FeedFunctionExtendDataKontrak(values);
        }
    });
    const [trxPerpanjangSuccess, settrxPerpanjangSuccess] = useState(false);
    const [updateLoading, setupdateLoading] = useState(false);
    const [isLoadingTextSubmit, setisLoadingTextSubmit] = useState("Transaksi sedang diproses. . .");

    async function FeedFunctionExtendDataKontrak(JSONData) {
        let result = null;
        
        setupdateLoading(true);
        setEnableDisableBTNCreateExtend(true);

        settrxPerpanjangSuccess(false);

        try {
            result = await createExtendContract({ isFieldTRX: JSONData });

            setTimeout(async () => {
                {
                    setupdateLoading(false);

                    settrxPerpanjangSuccess(true);

                    showToastNotification('Success!', 'Transaksi Perpanjang untuk Nomor Kontrak #' + EXT_INPUT_NoKontrak + ' berhasil dibuat', 'success');
                }
            }, 1500);
        }
        catch (Err) {
            ErrorStatusCreatePerpanjang = true;

            settrxPerpanjangSuccess(false);

            setTimeout(async () => {
                {
                    showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah! silahkan ulangi transaksi.', 'danger');
                }
            }, 1500);
        }

        if (!ErrorStatusCreatePerpanjang && EXT_INPUT_BuktiPembayaran !== null) {
            try {
                await uploadBuktiPerpanjang({ "imgFile": isFileBuktiPembayaran });
            }
            catch (Err) {
                ErrorStatusUploadFoto = true;
            }
        }
    }

    async function handleInputField_NamaRekAsal(INPUTValue) {
        EXT_INPUTField_NamaRekPengirim = INPUTValue;
    }

    async function handleInputField_NoRekAsal(INPUTValue) {
        EXT_INPUTField_NoRekPengirim = INPUTValue;
    }

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    setInterval(async () => {
        {
            await autoRecheckUserInputValue();
        }
    }, 500);

    async function autoRecheckUserInputValue() {
        if (EXT_TipePembayaranForUploadFile) {
            if (EXT_INPUT_NoKontrak !== null && INPUTField_UUIDPelanggan !== null && opUUID !== null && EXT_INPUT_TipePembayaran !== null && BankAsalSelected !== null && EXT_INPUTField_NamaRekPengirim !== null && EXT_INPUTField_NoRekPengirim !== null && BankTujuanSelected !== null && isNamaRekTujuan !== null && isNoRekTujuan !== null && EXT_INPUT_BuktiPembayaran !== null && isFileBuktiPembayaran !== null && isDetailDataMasterBankTujuan_API !== null) {
                setAllStepisPassed(true);
            }
        }
        else {
            if (EXT_INPUT_NoKontrak !== null && INPUTField_UUIDPelanggan !== null && opUUID !== null && EXT_INPUT_TipePembayaran !== null && BankAsalSelected !== null && EXT_INPUTField_NamaRekPengirim !== null && EXT_INPUTField_NoRekPengirim !== null && BankTujuanSelected !== null && isNamaRekTujuan !== null && isNoRekTujuan !== null && isDetailDataMasterBankTujuan_API !== null) {
                setAllStepisPassed(true);
            }
        }
    }

    const [isPagePreloader, setisPagePreloader] = useState(false);

    useEffect(() => {
        isFullDataKontrak_API = [{}];
        isFullDataMasterBankAsal_API = [{}];
        isFullDataMasterBankTujuan_API = [{}];
        isDetailDataMasterBankTujuan_API = [{}];
        isFullDetailDataKontrak_API = [{}];
        UUIDAccountAPI = null;
        EXT_INPUTField_NamaRekPengirim = null;
        EXT_INPUTField_NoRekPengirim = null;
        EXT_INPUT_TipePembayaran = null;
        EXT_INPUT_NoKontrak = null;
        EXT_INPUT_BuktiPembayaran = null;
        EXT_TipePembayaranForUploadFile = undefined;
        ErrorStatusCreatePerpanjang = undefined;
        ErrorStatusUploadFoto = undefined;

        autoUpdateProfile();

        FeedFunctionDataNoKontrak();

        FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

        setisPagePreloader(true);

        setTimeout(async () => {
            {
                setisPagePreloader(false);
            }
        }, 500);

        setInterval(async () => {
            {
                await autoUpdateProfile();
                //await autoRecheckUserInputValue();
            }
        }, 500);
    }, []);

    const isColumnsData = useMemo(
        () => [
            {
                Header: "#",
                accessor: "PGD_KontrakFailed",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "No. Kontrak",
                accessor: "PGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "Tgl. Ja.Po",
                accessor: "PGDRincian_JTempoTRX",
                filterable: true,
                disableFilters: true,
                sortable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "isCustomer.NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nasabah",
                accessor: "isCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Jaminan",
                accessor: "PGDJaminan_Tipe",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "R A M",
                accessor: "PGDJaminan_RAM",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatRAM_Internal {...cellProps} />;
                },
            },
            {
                Header: "R O M",
                accessor: "PGDJaminan_Internal",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatRAM_Internal {...cellProps} />;
                },
            },
            {
                Header: "Biaya",
                accessor: "PGD_BiayaPerpanjang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalPerpanjang {...cellProps} />;
                },
            },
            {
                Header: "Jt. Tempo",
                accessor: "PGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            "-"
                            :
                            <FormatJatuhTempo {...cellProps} />
                    ))
                    //return <FormatJatuhTempo {...cellProps} />;
                },
            },
            {
                Header: "St. Konfirmasi",
                accessor: "PGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatConfirmed {...cellProps} />;
                },
            },
            {
                Header: "St. Kontrak",
                accessor: "PGD_Lunas",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {

                    return <FormatPelunasan {...cellProps} />;
                },
            },
            {
                Header: "Cabang",
                accessor: "isCabang.CabangName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCabang {...cellProps} />;
                },
            },
        ],
        []
    );

    async function FeedFunctionDataForTableValidasiTransaksi(WhatsFiltered) {
        try {
            MasterDataTableKontrak = await fetchTableReportPawnshopPhoneAKTIF({ LimitData: 1000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
            //console.log()
            if (MasterDataTableKontrak[0] != undefined) {
                setisDataValidsasiTransaksi(MasterDataTableKontrak);
            }
            else {
                MasterDataTableKontrak = null;
                setisDataValidsasiTransaksi(null);
            }

        }
        catch (Err) {

        }
    }

    const [isDataValidsasiTransaksi, setisDataValidsasiTransaksi] = useState(null);

    //meta title
    document.title = "Pegadaian Handphone | Perpanjang Kontrak " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Pegadaian Handphone - Perpanjang Kontrak")}
                  />

                  {
                      !isPagePreloader
                      ?
                       isDataValidsasiTransaksi != null && isDataValidsasiTransaksi.length > 0
                          ?
                          <Row>
                              <Card>
                                  <CardBody>
                                      <Row>
                                          <Col lg={12} className={"mt-1"}>
                                              <TableContainer
                                                  className={"table table-hover rounded"}
                                                  columns={isColumnsData}
                                                  data={isDataValidsasiTransaksi || [{}]}
                                                  isGlobalFilter={true}
                                                  isAddOptions={false}
                                                  isPlaceholderData={"Cari data kontrak. . ."}
                                                  customPageSize={5}
                                                  isSorted={"desc"}
                                              />
                                          </Col>
                                      </Row>
                                  </CardBody>
                              </Card>
                          </Row>
                          :
                              null
                          :
                          null
                  }

                  {
                      isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                              </Col>
                          </Row>
                          :
                          <Row>
                              <Card>
                                  <CardBody>
                                      <Row>
                                          <Col xl={12}>
                                              <div >
                                                  <Alert className="alert-info" role="alert">
                                                      <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                      <p>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</p>
                                                  </Alert>
                                              </div>
                                          </Col>
                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                          <Col xl={12}>
                                              <Form encType="multipart/form-data" onSubmit={(e) => {
                                                  e.preventDefault();
                                                  Validation_Perpanjang.handleSubmit();
                                                  return false;
                                              }}>
                                                  <Row>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_CariNoKontrak">
                                                                  <b><span className="text-danger">*</span></b> Cari No Kontrak
                                                              </Label>
                                                              <Select
                                                                  id="Field_CariNoKontrak"
                                                                  name="Field_CariNoKontrak"
                                                                  placeholder="Masukan Nomor Kontrak. . ."
                                                                  value={selectedCariNoKontrak}
                                                                  onMenuOpen={(e) => {
                                                                      handleFeedCariNoKontrak();
                                                                  }}
                                                                  onChange={(selectedCariNoKontrak) => {
                                                                      handleCariNoKontrak(selectedCariNoKontrak);
                                                                  }}
                                                                  options={optionCariNoKontrak}
                                                                  className="select2-selection"
                                                                  isLoading={isLoadingCariNoKontrak}
                                                                  isDisabled={isEnableDisableNoKontrak}
                                                              />
                                                          </div>
                                                      </Col>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_BarangJaminan">
                                                                  Barang Jaminan
                                                              </Label>
                                                              <Input
                                                                  name="Field_BarangJaminan"
                                                                  id="Field_BarangJaminan"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Barang Jaminan . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_BarangJaminan || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_BarangJaminan && Validation_Perpanjang.errors.Field_BarangJaminan ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_BarangJaminan && Validation_Perpanjang.errors.Field_BarangJaminan ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_BarangJaminan}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NIKNasabah">
                                                                  NIK / No. SIM Nasabah
                                                              </Label>
                                                              <Input
                                                                  name="Field_NIKNasabah"
                                                                  id="Field_NIKNasabah"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi NIK / Nomor SIM Nasabah . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NIKNasabah || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NIKNasabah && Validation_Perpanjang.errors.Field_NIKNasabah ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NIKNasabah && Validation_Perpanjang.errors.Field_NIKNasabah ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NIKNasabah}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                  </Row>
                                                  <Row>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NamaNasabah">
                                                                  Nama Nasabah
                                                              </Label>
                                                              <Input
                                                                  name="Field_NamaNasabah"
                                                                  id="Field_NamaNasabah"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Nama Nasabah . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NamaNasabah || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NamaNasabah && Validation_Perpanjang.errors.Field_NamaNasabah ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NamaNasabah && Validation_Perpanjang.errors.Field_NamaNasabah ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NamaNasabah}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_BiayaPerpanjang">
                                                                  Biaya Perpanjang
                                                              </Label>
                                                              <Input
                                                                  name="Field_BiayaPerpanjang"
                                                                  id="Field_BiayaPerpanjang"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Biaya Perpanjang . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={CurrencyFormatter.format(Validation_Perpanjang.values.Field_BiayaPerpanjang) || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_BiayaPerpanjang && Validation_Perpanjang.errors.Field_BiayaPerpanjang ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_BiayaPerpanjang && Validation_Perpanjang.errors.Field_BiayaPerpanjang ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_BiayaPerpanjang}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_JaPoKeterlambatan">
                                                                  Keterlambatan
                                                              </Label>
                                                              <Input
                                                                  name="Field_JaPoKeterlambatan"
                                                                  id="Field_JaPoKeterlambatan"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Hari Keterlambatan . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_JaPoKeterlambatan > 0 || Validation_Perpanjang.values.Field_JaPoKeterlambatan === 0 ? Validation_Perpanjang.values.Field_JaPoKeterlambatan + " Hari" : Validation_Perpanjang.values.Field_JaPoKeterlambatan === -1 ? "-" : Validation_Perpanjang.values.Field_JaPoKeterlambatan || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_JaPoKeterlambatan && Validation_Perpanjang.errors.Field_JaPoKeterlambatan ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_JaPoKeterlambatan && Validation_Perpanjang.errors.Field_JaPoKeterlambatan ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_JaPoKeterlambatan}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                  </Row>

                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} className="pt-2 pb-2" />

                                                  <Row>
                                                      <Col xl={12}>
                                                          {
                                                              trxPerpanjangSuccess && !updateLoading
                                                                  ?
                                                                  <UncontrolledAlert
                                                                      color="success"
                                                                      className="alert-dismissible fade show"
                                                                      role="alert"
                                                                  >
                                                                      <i className="mdi mdi-check-all me-2"></i>Transaksi Perpanjang untuk Nomor Kontrak <b>#{EXT_INPUT_NoKontrak}</b> atas nama Nasabah <b>{INPUTField_NamaNasabah}</b> berhasil dibuat, selanjutnya mohon menunggu untuk proses validasi Nomor Kontrak oleh Administrator. Beberapa saat lagi Anda akah dialihkan ke halaman Detail Kontrak.
                                                                  </UncontrolledAlert>
                                                                  :
                                                                  null
                                                          }
                                                      </Col>
                                                      <Col lg={12}>
                                                          <CardTitle><h5 className="text-center">FORMULIR PERPANJANG KONTRAK</h5></CardTitle>

                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} className="pt-2 pb-2" />
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label for="Field_TipePembayaran">
                                                                  <b><span className="text-danger">*</span></b> Pilih Tipe Pembayaran
                                                              </Label>
                                                              <Row>
                                                                  <Col sm="6">
                                                                      <div className="form-check form-checkbox form-check-success">
                                                                          <input
                                                                              type="checkbox"
                                                                              className="form-check-input"
                                                                              id="Field_TipePembayaran_TUNAI"
                                                                              name="Field_TipePembayaran_TUNAI"
                                                                              checked={checkedTunai}
                                                                              onChange={(evt) => {
                                                                                  if (evt.target.checked) {
                                                                                      handleSelectTipePembayaran("Tunai")
                                                                                      setisEnableDisableCariDataBankAsal(false);
                                                                                      setisEnableDisableCariDataBankTujuan(false);

                                                                                      EXT_TipePembayaranForUploadFile = false;
                                                                                  }
                                                                                  else {
                                                                                      handleSelectTipePembayaran(undefined)
                                                                                      setisEnableDisableCariDataBankAsal(true);
                                                                                      setisEnableDisableCariDataBankTujuan(true);

                                                                                      EXT_TipePembayaranForUploadFile = undefined;
                                                                                  }

                                                                                  //setAllStepisPassed(false);
                                                                                  setcheckedTunai(!checkedTunai);
                                                                                  setcheckedNonTunai(false);
                                                                              }}
                                                                              disabled={isEnableDisableTipePembayaran}
                                                                          />
                                                                          <label
                                                                              className="form-check-label"
                                                                              htmlFor="Field_TipePembayaran_TUNAI"
                                                                          >
                                                                              Tunai
                                                                          </label>
                                                                      </div>
                                                                  </Col>

                                                                  <Col sm="6">
                                                                      <div className="form-check form-checkbox form-check-info">
                                                                          <input
                                                                              type="checkbox"
                                                                              className="form-check-input"
                                                                              id="Field_TipePembayaran_TFBank"
                                                                              name="Field_TipePembayaran_TFBank"
                                                                              checked={checkedNonTunai}
                                                                              onChange={(evt) => {
                                                                                  if (evt.target.checked) {
                                                                                      handleSelectTipePembayaran("Transfer Bank")
                                                                                      setisEnableDisableCariDataBankAsal(false);
                                                                                      setisEnableDisableCariDataBankTujuan(false);

                                                                                      EXT_TipePembayaranForUploadFile = true;
                                                                                  }
                                                                                  else {
                                                                                      handleSelectTipePembayaran(undefined)
                                                                                      setisEnableDisableCariDataBankAsal(true);
                                                                                      setisEnableDisableCariDataBankTujuan(true);

                                                                                      EXT_TipePembayaranForUploadFile = undefined;
                                                                                  }

                                                                                  //setAllStepisPassed(false);
                                                                                  setcheckedTunai(false);
                                                                                  setcheckedNonTunai(!checkedNonTunai);
                                                                              }}
                                                                              disabled={isEnableDisableTipePembayaran}
                                                                          />
                                                                          <label
                                                                              className="form-check-label"
                                                                              htmlFor="Field_TipePembayaran_TFBank"
                                                                          >
                                                                              Non-Tunai
                                                                          </label>
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                          </div>
                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="" for="Field_PilihBankAsal">
                                                                  <b><span className="text-danger">*</span></b> Pilih Bank/eWallet Pengirim
                                                              </Label>
                                                              <Select
                                                                  id="Field_PilihBankAsal"
                                                                  name="Field_PilihBankAsal"
                                                                  placeholder="Cari Data Bank/eWallet Pengirim. . ."
                                                                  value={selectedCariDataBankAsal}
                                                                  onMenuOpen={(e) => {
                                                                      handleFeedCariDataBankAsal();
                                                                  }}
                                                                  onChange={(selectedCariDataBankAsal) => {
                                                                      handleCariDataBankAsal(selectedCariDataBankAsal);
                                                                  }}
                                                                  options={optionCariDataBankAsal}
                                                                  className="select2-selection"
                                                                  isLoading={isLoadingCariCariDataBankAsal}
                                                                  isDisabled={isEnableDisableCariDataBankAsal}
                                                              />
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="" for="Field_PilihBankTujuan">
                                                                  <b><span className="text-danger">*</span></b> Pilih Bank Pembayaran
                                                              </Label>
                                                              <Select
                                                                  id="Field_PilihBankTujuan"
                                                                  name="Field_PilihBankTujuan"
                                                                  placeholder="Cari Data Bank Pembayaran. . ."
                                                                  value={selectedCariDataBankTujuan}
                                                                  onMenuOpen={(e) => {
                                                                      handleFeedCariDataBankTujuan();
                                                                  }}
                                                                  onChange={(selectedCariDataBankTujuan) => {
                                                                      handleCariDataBankTujuan(selectedCariDataBankTujuan);
                                                                  }}
                                                                  options={optionCariDataBankTujuan}
                                                                  className="select2-selection"
                                                                  isLoading={isLoadingCariCariDataBankTujuan}
                                                                  isDisabled={isEnableDisableCariDataBankTujuan}
                                                              />
                                                          </div>
                                                      </Col>
                                                  </Row>

                                                  <Row>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NamaRekAsal">
                                                                  <b><span className="text-danger">*</span></b> Nama Pemilik Rek. Pengirim
                                                              </Label>
                                                              <Input
                                                                  name="Field_NamaRekAsal"
                                                                  id="Field_NamaRekAsal"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Masukkan Nama Pemilik Rekening Pengirim. . ."
                                                                  onChange={(evt) => {
                                                                      handleInputField_NamaRekAsal(evt.target.value);

                                                                      Validation_Perpanjang.handleChange(evt);
                                                                  }}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NamaRekAsal || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={isEnableDisableInfoBankPengirim}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NamaRekAsal && Validation_Perpanjang.errors.Field_NamaRekAsal ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NamaRekAsal && Validation_Perpanjang.errors.Field_NamaRekAsal ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NamaRekAsal}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NoRekAsal">
                                                                  <b><span className="text-danger">*</span></b> Nomor Rek. Pengirim
                                                              </Label>
                                                              <Input
                                                                  name="Field_NoRekAsal"
                                                                  id="Field_NoRekAsal"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Masukkan No. Rekening Pengirim. . ."
                                                                  onChange={(evt) => {
                                                                      handleInputField_NoRekAsal(evt.target.value);

                                                                      Validation_Perpanjang.handleChange(evt);
                                                                  }}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NoRekAsal || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={isEnableDisableInfoBankPengirim}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NoRekAsal && Validation_Perpanjang.errors.Field_NoRekAsal ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NoRekAsal && Validation_Perpanjang.errors.Field_NoRekAsal ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NoRekAsal}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NominalPerpanjang">
                                                                  <b><span className="text-danger">*</span></b> Nominal Biaya Perpanjang
                                                              </Label>
                                                              <Input
                                                                  name="Field_NominalPerpanjang"
                                                                  id="Field_NominalPerpanjang"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Nominal Perpanjangan . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={CurrencyFormatter.format(Validation_Perpanjang.values.Field_NominalPerpanjang) || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NominalPerpanjang && Validation_Perpanjang.errors.Field_NominalPerpanjang ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NominalPerpanjang && Validation_Perpanjang.errors.Field_NominalPerpanjang ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NominalPerpanjang}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                  </Row>

                                                  <Row>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NamaRekTujuan">
                                                                  <b><span className="text-danger">*</span></b> Nama Rekening Pembayaran
                                                              </Label>
                                                              <Input
                                                                  name="Field_NamaRekTujuan"
                                                                  id="Field_NamaRekTujuan"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Nama Rekening Pembayaran . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NamaRekTujuan || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NamaRekTujuan && Validation_Perpanjang.errors.Field_NamaRekTujuan ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NamaRekTujuan && Validation_Perpanjang.errors.Field_NamaRekTujuan ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NamaRekTujuan}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_NoRekTujuan">
                                                                  <b><span className="text-danger">*</span></b> Nomor Rekening Pembayaran
                                                              </Label>
                                                              <Input
                                                                  name="Field_NoRekTujuan"
                                                                  id="Field_NoRekTujuan"
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Informasi Nomor Rekening Pembayaran . . ."
                                                                  onChange={Validation_Perpanjang.handleChange}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  value={Validation_Perpanjang.values.Field_NoRekTujuan || ""}
                                                                  autoComplete={"off"}
                                                                  disabled={true}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_NoRekTujuan && Validation_Perpanjang.errors.Field_NoRekTujuan ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_NoRekTujuan && Validation_Perpanjang.errors.Field_NoRekTujuan ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_NoRekTujuan}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>

                                                      <Col lg="4">
                                                          <div className="mb-3">
                                                              <Label className="col-form-label" for="Field_BuktiPembayaran">
                                                                  {EXT_TipePembayaranForUploadFile ? <><b><span className="text-danger">*</span></b></> : null}  Upload Bukti Pembayaran
                                                              </Label>
                                                              <Input
                                                                  name="Field_BuktiPembayaran"
                                                                  id="Field_BuktiPembayaran"
                                                                  className="form-control"
                                                                  type="file"
                                                                  accept="image/*"
                                                                  onChange={(evt) => {

                                                                      handleChangeFileBuktiPembayaran(evt);

                                                                      Validation_Perpanjang.handleChange(evt);
                                                                  }}
                                                                  onBlur={Validation_Perpanjang.handleBlur}
                                                                  autoComplete={"off"}
                                                                  disabled={isEnableDisableInfoBankPengirim}
                                                                  invalid={
                                                                      Validation_Perpanjang.touched.Field_BuktiPembayaran && Validation_Perpanjang.errors.Field_BuktiPembayaran ? true : false
                                                                  }
                                                              />
                                                              {Validation_Perpanjang.touched.Field_BuktiPembayaran && Validation_Perpanjang.errors.Field_BuktiPembayaran ? (
                                                                  <FormFeedback type="invalid">{Validation_Perpanjang.errors.Field_BuktiPembayaran}</FormFeedback>
                                                              ) : null}
                                                          </div>
                                                      </Col>
                                                  </Row>

                                                  <Row>
                                                      <Col xl={12} className="pt-3 text-sm-end">
                                                          {/*<button className="btn btn-primary btn-block" type="submit">
                                                      <i className="bx bxs-analyse font-size-16 align-middle me-2"></i> Proses Transaksi
                                                  </button>*/}
                                                          {
                                                              !trxPerpanjangSuccess
                                                                  ?
                                                                  <button
                                                                      className={
                                                                          !AllStepisPassed ? "btn btn-primary btn-block align-right disabled" : "btn btn-primary btn-block align-right"
                                                                      }
                                                                      disabled={EnableDisableBTNCreateExtend}
                                                                      type="submit"
                                                                  >
                                                                      {
                                                                          updateLoading
                                                                              ?
                                                                              <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="light" /> {isLoadingTextSubmit}</>
                                                                              :
                                                                              <><i className="bx bxs-analyse font-size-16 align-middle me-2"></i> Proses Transaksi</>
                                                                      }
                                                                  </button>
                                                                  :
                                                                  null
                                                          }
                                                      </Col>
                                                  </Row>
                                              </Form>
                                          </Col>
                                      </Row>
                                  </CardBody>
                              </Card>
                          </Row>
                  }
                  
                  

          </Container>
          </div>
    </React.Fragment>
  );
};

PerpanjangTRXPawnshopHandphone.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PerpanjangTRXPawnshopHandphone);
