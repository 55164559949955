export default function authHeader() {
    const obj = JSON.parse(localStorage.getItem("authToken"))

  /*if (obj && obj.accessToken) {
    return { Authorization: obj.accessToken }
  } else {
      return {}
  }*/

    if (obj && obj) {
        return { obj }
    } else {
        return {  }
    }
}
