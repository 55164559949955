import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    Alert,
    InputGroup,
    FormFeedback,
    UncontrolledAlert,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import jwt_decode from "jwt-decode";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, useFormikContext } from "formik";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

import {
    postRegisteringNewCustomer,
    postUploadFotoKTPNasabah,
    cekNIK,
    cekNoHP,
} from "../../../helpers/backend_helper";


let INPUT_TipeTPengenal = "";
let INPUT_NamaLengkap = "";
let INPUT_NamaPanggilan = "";
let INPUT_NIK = "";
let INPUT_NomorHP = "";
let INPUT_Alamat = "";
let INPUT_FotoKTP = "";

let isStatusNIKAvailable = false;
let isStatusNoHPAvailable = false;

let isUUIDLoggedIN = null;

const CustomerRegisterNew = props => {
    const dispatch = useDispatch();

    const [isDataLoading, setisDataLoading] = useState(false);

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "2500",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success') {
            toastr.success(isMessage, isTitle);
        }
        else if (isType === 'info') {
            toastr.info(isMessage, isTitle);
        }
        else if (isType === 'warning') {
            toastr.warning(isMessage, isTitle);
        }
        else {
            toastr.error(isMessage, isTitle);
        }

        setTimeout(async function () {
            return location.reload();
        }, 3000)
    }

    const [isEDFullNamePelanggan, setisEDFullNamePelanggan] = useState(true);
    const [isEDNamePelanggan, setisEDNamePelanggan] = useState(true);
    const [isEDNIKPelanggan, setisEDNIKPelanggan] = useState(true);
    const [isEDPhoneNumberPelanggan, setisEDPhoneNumberPelanggan] = useState(true);
    const [isEDFotoKTPPelanggan, setisEDFotoKTPPelanggan] = useState(true);
    const [isEDAlamatPelanggan, setisEDAlamatPelanggan] = useState(true);
    const [EnableDisableBTNCreateCustomer, setEnableDisableBTNCreateCustomer] = useState(false);
    const [updateLoading, setupdateLoading] = useState(false);
    const [AllStepisPassed, setAllStepisPassed] = useState(false);
    const [fileFotoKTP, setfileFotoKTP] = useState(null);

    const onlyNumberRegEXP = "^[0-9]*$";

    const VLD_DataPelanggan = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            INPUTDPel_UUID_Operator: isUUIDLoggedIN || undefined,
            INPUTDPel_TipeTPengenal: INPUT_TipeTPengenal || undefined,
            INPUTDPel_NamaLengkap: INPUT_NamaLengkap || undefined,
            INPUTDPel_NamaPanggilan: INPUT_NamaPanggilan || undefined,
            INPUTDPel_NIK: INPUT_NIK || undefined,
            INPUTDPel_NoHP: INPUT_NomorHP || "62",
            INPUTDPel_Alamat: INPUT_Alamat || undefined,
            INPUTDPel_FotoKTP: INPUT_FotoKTP || undefined,
            INPUTDPel_FileIMGFotoKTP: fileFotoKTP || undefined,
        },
        validationSchema: Yup.object().shape({
            INPUTDPel_NamaLengkap: Yup.string().required("Nama Lengkap wajib diisi sesuai nama pada Tanda Pengenal dan tidak boleh kosong!")
                .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi!')
                .max(96, 'Maksimal karakter hanya boleh sampai 96 karakter!'),
            INPUTDPel_NamaPanggilan: Yup.string().required("Nama Panggilan wajib diisi dan tidak boleh kosong!")
                .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi!')
                .max(32, 'Maksimal karakter hanya boleh sampai 32 karakter!'),
            INPUTDPel_NIK: Yup.string().required("Nomor Induk Kependudukan atau Nomor SIM wajib diisi & tidak boleh kosong!")
                .matches("^[0-9]*$", 'Nomor Induk Kependudukan atau Nomor SIM hanya boleh menggunakan angka!')
                .min(8, 'Minimal NIK atau Nomor SIM adalah 8 digit!')
                .max(17, 'Maksimal NIK atau Nomor SIM adalah 8 digit!'),
            INPUTDPel_NoHP: Yup.string().min(10, 'Nonmor Telepon minimal adalah 10 digit angka!').max(15, 'Nonmor Telepon maksimal adalah 15 digit angka!').matches(onlyNumberRegEXP, 'Format No Telepon/WA tidak sesuai, pastikan hanya angka yang anda masukan, Cth: 81234567890').required("Nomor Telepon atau WhatsApp wajib diisi & tidak boleh kosong!"),
            INPUTDPel_Alamat: Yup.string().required("Alamat lengkap wajib diisi dan harus jelas sesuai pada KTP tidak boleh kosong!")
        }),
        onSubmit: (values) => {
            //console.log(values);
            FeedCustomerRegistration(values);
        }
    });

    async function FeedCustomerRegistration(isJSONValue)
    {
        setupdateLoading(true);
        setEnableDisableBTNCreateCustomer(true);

        try {
            await postRegisteringNewCustomer({ "isFieldRegister": isJSONValue });
            
            try {
                if (INPUT_FotoKTP !== "") {
                    await postUploadFotoKTPNasabah({ "imgFile": fileFotoKTP });
                }
            }
            catch (Err) {

            }
            
            setTimeout(async function () {
                setupdateLoading(false);

                showToastNotification('Success!', 'Registrasi Nasabah atas Nama ' + INPUT_NamaLengkap + ' berhasil diproses!', 'success');
            }, 2500);
        }
        catch (Err) {
            setTimeout(async () => {
                {
                    showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah! silahkan ulangi transaksi.', 'danger');
                }
            }, 1500);
        }
    }

    function handleChangeFotoKTP(e) {
        //setselectedFile(URL.createObjectURL(e.target.files[0]));

        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        //const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = "imgKTPNasabah-" + INPUT_NIK + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        INPUT_FotoKTP = isFileRenameValue;

        //console.log(e.target.files[0]);

        setfileFotoKTP(fileRenamed);
    }

    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDLoggedIN = DecodeUserData.uuid;
        }
        catch (Err) {
            //Nothing
        }
    }

    setInterval(async () => {
        if (INPUT_TipeTPengenal === 'KTP' || INPUT_TipeTPengenal === 'SIM') {
            if (INPUT_NamaLengkap !== '' && INPUT_NamaPanggilan !== '' && !isStatusNoHPAvailable && !isStatusNIKAvailable && INPUT_NIK !== '' && INPUT_NomorHP !== '' && INPUT_Alamat !== '') {
                setAllStepisPassed(true);
                //setEnableDisableBTNCreateCustomer(false);
            }
            else {
                setAllStepisPassed(false);
                //setEnableDisableBTNCreateCustomer(true);
            }
        }
    }, 200);

    useEffect(() => {
        INPUT_TipeTPengenal = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";

        isStatusNIKAvailable = false;
        isStatusNoHPAvailable = false;
        isUUIDLoggedIN = null;

        autoUpdateProfile();

        setisDataLoading(true);

        setTimeout(async () => {
            setisDataLoading(false);
        }, 500);

        setInterval(async () => {
            await autoUpdateProfile();
        }, 500);

    }, []);

    const optionTipeTPengenal = [
        {
            label: "Pilih Kategori Nasabah",
            options: [
                { label: "Kartu Tanda Pengenal (KTP)", value: "KTP" },
                { label: "Surat Izin Mengemudi (SIM)", value: "SIM" }
            ]
        },
    ];

    const [selectedTipeTPengenal, setselectedTipeTPengenal] = useState(null);

    function handleSelectTipeTandaPengenal(selectedTipeTPengenal) {
        setselectedTipeTPengenal(selectedTipeTPengenal);

        INPUT_TipeTPengenal = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";

        if (selectedTipeTPengenal.value === "KTP" || selectedTipeTPengenal.value === "SIM") {
            setisEDFullNamePelanggan(false);
            setisEDNamePelanggan(false);
            setisEDNIKPelanggan(false);
            setisEDPhoneNumberPelanggan(false);
            setisEDFotoKTPPelanggan(false);
            setisEDAlamatPelanggan(false);

            INPUT_TipeTPengenal = selectedTipeTPengenal.value;
        }
    }

    function handleFormDataPelanggan_NamaLengkap(InputValue) {
        INPUT_NamaLengkap = InputValue;
    }

    function handleFormDataPelanggan_NamaPanggilan(InputValue) {
        INPUT_NamaPanggilan = InputValue;
    }

    const [isNIKExists, setisNIKExists] = useState(null);
    async function handleFormDataPelanggan_NIK(InputValue) {
        INPUT_NIK = InputValue;

        if (InputValue.length > 8) {
            await CheckNIKisNotExists(InputValue);
        }
    }

    async function CheckNIKisNotExists(valNIKNya) {
        try {
            let isCheckFromAPI = await cekNIK(valNIKNya);

            if (isCheckFromAPI !== null) {
                setisNIKExists(isCheckFromAPI);

                isShowModalNIKExists();

                isStatusNIKAvailable = true;
            }
            else {
                setisNIKExists(null);

                isStatusNIKAvailable = false;
            }
        }
        catch (Err) {
            isStatusNIKAvailable = true;

            setisNIKExists("Error");
        }
    }

    const [isModalNIKExists, setisModalNIKExists] = useState(false);
    function isShowModalNIKExists() {
        setisModalNIKExists(!isModalNIKExists);
        removeBodyCss();
    }

    const [isNoHPExists, setisNoHPExists] = useState(null);

    async function handleFormDataPelanggan_NoHP(InputValue) {
        INPUT_NomorHP = InputValue;

        /*if (InputValue.length > 8) {
            await CheckNoHPisNotExists(InputValue);
        }*/
    }

    async function CheckNoHPisNotExists(valNoHP) {
        try {
            let isCheckFromAPI = await cekNoHP(valNoHP);

            if (isCheckFromAPI !== null) {
                setisNoHPExists(isCheckFromAPI);

                isShowModalHPExists();

                isStatusNoHPAvailable = true;
            }
            else {
                setisNoHPExists(null);

                isStatusNoHPAvailable = false;
            }
        }
        catch (Err) {
            isStatusNoHPAvailable = true;

            setisNoHPExists("Error");
        }
    }

    const [isModalHPExists, setisModalHPExists] = useState(false);
    function isShowModalHPExists() {
        setisModalHPExists(!isModalHPExists);
        removeBodyCss();
    }

    function handleFormDataPelanggan_Alamat(InputValue) {
        INPUT_Alamat = InputValue;
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    //meta title
    document.title = "Registrasi Nasabah Baru - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Registrasi Nasabah Baru")}
                  />

                  {/*IS MODAL FOR ERROR NIK EXISTS*/}
                  <Modal
                      isOpen={isModalNIKExists}
                      toggle={() => {
                          isShowModalNIKExists();
                      }}
                      backdrop={'static'}
                      id="modalNIKExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalNIKExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NASABAH SUDAH TERDAFTAR</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalNIKExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">Nomor Induk : <b>{INPUT_NIK}</b></p>
                          <p className="font-size-13 text-justify">Nomor Induk tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.NamaLengkap : "Unknown"}</b>, dengan jenis tanda pengenal <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.JenisTandaPengenal : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalNIKExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR ERROR HP EXISTS*/}
                  <Modal
                      isOpen={isModalHPExists}
                      toggle={() => {
                          isShowModalHPExists();
                      }}
                      backdrop={'static'}
                      id="modalHPExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalHPExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NOMOR TELEPON SUDAH DIGUNAKAN</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalHPExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">No. Telepon : <b>{INPUT_NomorHP}</b></p>
                          <p className="font-size-13 text-justify">Nomor Telepon / WhatsApp tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNoHPExists !== null && isNoHPExists !== "Error" ? isNoHPExists.NamaLengkap : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalHPExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {
                      isDataLoading
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                              </Col>
                          </Row>
                          :
                          <Row>
                              <Col lg={12}>
                                  <Card>
                                      <CardBody>
                                          <Form encType="multipart/form-data" onSubmit={(e) => {
                                              e.preventDefault();
                                              VLD_DataPelanggan.handleSubmit();
                                              return false;
                                          }}>
                                              <Row>
                                                  <Col xl={12}>
                                                      <div >
                                                          <Alert className="alert-info" role="alert">
                                                              <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                              <p>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</p>
                                                          </Alert>
                                                      </div>
                                                  </Col>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Row>

                                              <Row>
                                                  <Col lg='12' className="">
                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" htmlFor="Pelanggan_Kategori" for="Pelanggan_Kategori">
                                                                      <b><span className="text-danger">*</span></b>Tanda Pengenal
                                                                  </Label>
                                                                  <Select
                                                                      id="Pelanggan_Kategori"
                                                                      name="Pelanggan_Kategori"
                                                                      placeholder="Pilih Jenis Tanda Pengenal"
                                                                      value={selectedTipeTPengenal}
                                                                      onChange={(TipeTandaPengenalDipilih) => {
                                                                          handleSelectTipeTandaPengenal(TipeTandaPengenalDipilih);

                                                                          //setselectedTipeTPengenal(INPUT_TipeTPengenal);
                                                                      }}
                                                                      options={optionTipeTPengenal}
                                                                      className="select2-selection"
                                                                  />
                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NIK">
                                                                      <b><span className="text-danger">*</span></b>Nomor Induk Kependudukan / Nomor SIM
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NIK"
                                                                      id="INPUTDPel_NIK"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Nomor Induk Kependudukan / Nomor Kartu SIM . . ."
                                                                      maxLength={17}
                                                                      onChange={(evt) => {
                                                                          if (!isNaN(evt.target.value)) {
                                                                              handleFormDataPelanggan_NIK(evt.target.value);
                                                                          }
                                                                          else {
                                                                              handleFormDataPelanggan_NIK(evt.target.value.replace(/[^0-9]/g, ""));
                                                                          }

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={INPUT_NIK || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={isEDNIKPelanggan}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NIK}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NamaLengkap">
                                                                      <b><span className="text-danger">*</span></b>Nama Lengkap Nasabah
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NamaLengkap"
                                                                      id="INPUTDPel_NamaLengkap"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Nama Lengkap Sesuai di Tanda Pengenal . . ."
                                                                      maxLength={64}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NamaLengkap(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NamaLengkap || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={isEDFullNamePelanggan}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap ? true : false
                                                                      }
                                                                  />
                                                                  {
                                                                      VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap ? (
                                                                          <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap}</FormFeedback>
                                                                      ) : null
                                                                  }

                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NamaPanggilan">
                                                                      <b><span className="text-danger">*</span></b>Nama Panggilan
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NamaPanggilan"
                                                                      id="INPUTDPel_NamaPanggilan"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Masukan Nama Panggilan . . ."
                                                                      maxLength={24}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NamaPanggilan(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={INPUT_NamaPanggilan || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={isEDNamePelanggan}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NoHP">
                                                                      <b><span className="text-danger">*</span></b>Telepon / WhatsApp
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NoHP"
                                                                      id="INPUTDPel_NoHP"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Tanpa diawali 0 / 62 (Cth : 81234567890)"
                                                                      maxLength={15}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NoHP(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NoHP || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={isEDPhoneNumberPelanggan}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NoHP}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_FotoKTP">
                                                                      Upload Foto Tanda Pengenal
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_FotoKTP"
                                                                      id="INPUTDPel_FotoKTP"
                                                                      className="form-control"
                                                                      type="file"
                                                                      accept="image/*"
                                                                      onChange={(evt) => {

                                                                          handleChangeFotoKTP(evt);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      autoComplete={"off"}
                                                                      disabled={isEDFotoKTPPelanggan}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_FotoKTP && VLD_DataPelanggan.errors.INPUTDPel_FotoKTP ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_FotoKTP && VLD_DataPelanggan.errors.INPUTDPel_FotoKTP ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_FotoKTP}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>

                                                      </Row>

                                                      <Row>
                                                          <Col lg="12">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_Alamat">
                                                                      <b><span className="text-danger">*</span></b>Alamat Lengkap
                                                                  </Label>
                                                                  <textarea
                                                                      id="INPUTDPel_Alamat"
                                                                      name="INPUTDPel_Alamat"
                                                                      className="form-control"
                                                                      rows="3"
                                                                      placeholder="Masukan Alamat Lengkap sesuai Tanda Pengenal . . ."
                                                                      autoComplete="off"
                                                                      maxLength={255}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_Alamat || ""}
                                                                      disabled={isEDAlamatPelanggan}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_Alamat(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_Alamat && VLD_DataPelanggan.errors.INPUTDPel_Alamat ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_Alamat}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col xl={12} className="pt-3 text-sm-end">
                                                              {/*<button className="btn btn-primary btn-block" type="submit">
                                                        <i className="fas fa-user-plus font-size-16 align-middle me-2"></i> Registrasi
                                                      </button>*/}
                                                              <button
                                                                  className={
                                                                      !AllStepisPassed ? "btn btn-primary btn-block align-right disabled" : "btn btn-primary btn-block align-right"
                                                                  }
                                                                  disabled={EnableDisableBTNCreateCustomer}
                                                                  type="submit"
                                                              >
                                                                  {
                                                                      updateLoading
                                                                          ?
                                                                          <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0 me-2" color="light" /> Registrasi Sedang Diproses . . .</>
                                                                          :
                                                                          <><i className="fas fa-user-plus font-size-16 align-middle me-2"></i> Registrasi Nasabah</>
                                                                  }
                                                              </button>
                                                          </Col>
                                                      </Row>

                                                  </Col>
                                              </Row>
                                          </Form>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }

          </Container>
          </div>
    </React.Fragment>
  );
};

CustomerRegisterNew.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CustomerRegisterNew);
