import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom"

import jwt_decode from "jwt-decode";

// Authentication related pages
import SignIn from "../pages/TXAAuthentication/SignIn"
import SignOut from "../pages/TXAAuthentication/SignOut"

// Dashboard
import TXADashboard from "../pages/TXADashboard/index"
// UserProfile
import UserProfile from "../pages/TXAAccounts/Profile/index"
// UserSettings
import UserSettings from "../pages/TXAAccounts/Settings/index"

import Page404 from "../pages/HelperPages/Page404"

import TXAComingSoon from "../pages/HelperPages/TXAComingSoon"

// Transaksi > Pegadaian > Transaksi Baru
import NewTRXPawnshopHandphone from "../pages/TXATransaction/PawnshopHandphone/TransaksiBaru/index"
import PerpanjangTRXPawnshopHandphone from "../pages/TXATransaction/PawnshopHandphone/Perpanjang/index"
import PelunasanTRXPawnshopHandphone from "../pages/TXATransaction/PawnshopHandphone/Pelunasan/index"

import NewTRXPawnshopLaptop from "../pages/TXATransaction/PawnshopLaptop/index"
import NewTRXPawnshopMotorcycle from "../pages/TXATransaction/PawnshopMotorcycle/index"
import NewTRXPawnshopCar from "../pages/TXATransaction/PawnshopCar/index"

// Transaksi > Penjualan > Transaksi Baru
import NewTRXSelling from "../pages/TXATransaction/Selling/index"

// Transaksi > Pembelian > Transaksi Baru
import NewTRXPurchasing from "../pages/TXATransaction/Purchasing/index"

// Transaksi > Validasi Transaksi
import TRXValidationHandphoneContract from "../pages/TXATransaction/TRXValidation/Handphone/Validation/Contract/index"
import TRXValidationHandphoneExtend from "../pages/TXATransaction/TRXValidation/Handphone/Validation/Extend/index"
import TRXValidationHandphoneRepayment from "../pages/TXATransaction/TRXValidation/Handphone/Validation/Repayment/index"

//Belum
import ManagementReportPawnshop from "../pages/TXAManagementReport/ReportPawnshop/index"
import ManagementReportPawnshopDeleted from "../pages/TXAManagementReport/ReportPawnshop/Deleted/index"
import ManagementReportPawnshopExtended from "../pages/TXAManagementReport/ReportPawnshop/Extend/index"
import ManagementReportPawnshopRepayment from "../pages/TXAManagementReport/ReportPawnshop/Repayment/index"
import ManagementReportSelling from "../pages/TXAManagementReport/ReportSelling/index"
import ManagementReportPurchasing from "../pages/TXAManagementReport/ReportPurchasing/index"

//Belum
import ProductAccessories from "../pages/TXAManagementProduct/ProductAccessories/index"
import ProductAuction from "../pages/TXAManagementProduct/ProductAuction/index"
import ProductElectronic from "../pages/TXAManagementProduct/ProductElectronic/index"
import ProductVehicles from "../pages/TXAManagementProduct/ProductVehicles/index"

//Belum
import CustomerListData from "../pages/TXAManagementCustomer/CustomerListData/index"
import CustomerSettings from "../pages/TXAManagementCustomer/CustomerSettings/index"
import CustomerRegisterNew from "../pages/TXAManagementCustomer/CustomerRegister/index"

//Belum
import StatisticsPawnshop from "../pages/TXAStatistics/StatsPawnshop/index"
import StatisticsPurchasing from "../pages/TXAStatistics/StatsPurchasing/index"
import StatisticsSelling from "../pages/TXAStatistics/StatsSelling/index"

//Belum
import ManagementUserListData from "../pages/TXAUserManagement/UserListData/index"
import ManagementUserRegisterNew from "../pages/TXAUserManagement/UserRegister/index"

//Belum
import PawnshopHandphoneDetailTRX from "../pages/TXATransaction/PawnshopHandphone/DetailTransaction/index"
import PrintContract from "../pages/TXATransaction/PawnshopHandphone/DetailTransaction/PrintContract";
import ImageContract from "../pages/TXATransaction/PawnshopHandphone/DetailTransaction/ImageContract";
import FakePrintContract from "../pages/TXATransaction/PawnshopHandphone/DetailTransaction/FakePrintContract";
import WhatsAppGateway from "../pages/HelperPages/WhatsAppGateway";

const roleProtectedRoute = async () => {
    const [role, setrole] = useState(null);

    try {
        const UserData = localStorage.getItem("authToken");
        const DecodeUserData = jwt_decode(UserData);

        setrole(DecodeUserData.role);
    }
    catch (Err) {
        console.log(Err);
    }

    return role;
}

const authProtectedRoutes = [
    
    //Dashboard
    { path: "/apps/dashboard", component: <TXADashboard /> },
    { path: "/apps/wa-gateway", component: <WhatsAppGateway /> },

    //Users -> Profile
    { path: "/apps/account/profile", component: <UserProfile /> },
    //Users -> Settings
    { path: "/apps/account/settings", component: <UserSettings /> },
    
    //Authentication -> Sign Out
    { path: "/auth/sign-out", component: <SignOut /> },

    //Transaksi > Pegadaian > Transaksi Baru
    { path: "/apps/pawnshop/transaction/handphone/new-contract", component: <NewTRXPawnshopHandphone /> },
    { path: "/apps/pawnshop/transaction/handphone/new-extend", component: <PerpanjangTRXPawnshopHandphone/> },
    { path: "/apps/pawnshop/transaction/handphone/new-repayment", component: <PelunasanTRXPawnshopHandphone /> },
    { path: "/apps/pawnshop/details/handphone/:ContractNumber", component: <PawnshopHandphoneDetailTRX /> },

    { path: "/apps/pawnshop/laptop-transaction", component: <NewTRXPawnshopLaptop /> },
    { path: "/apps/pawnshop/motorcycle-transaction", component: <NewTRXPawnshopMotorcycle /> },
    { path: "/apps/pawnshop/car-transaction", component: <NewTRXPawnshopCar /> },

    //Transaksi > Pegadaian > Transaksi Baru
    { path: "/apps/selling/transaction/outlet/handphone", component: <NewTRXSelling /> },
    { path: "/apps/selling/transaction/outlet/accessories", component: <TXAComingSoon /> },
    { path: "/apps/selling/transaction/auction/handphone", component: <TXAComingSoon /> },

    //Transaksi > Pembelian > Transaksi Baru
    { path: "/apps/purchasing/new-transaction", component: <NewTRXPurchasing /> },

    //Transaksi > Validasi Transaksi
    { path: "/apps/pawnshop/validation/handphone/contract", component: <TRXValidationHandphoneContract /> }, //Sudah
    { path: "/apps/pawnshop/validation/handphone/extend", component: <TRXValidationHandphoneExtend /> }, //Sudah
    { path: "/apps/pawnshop/validation/handphone/repayment", component: <TRXValidationHandphoneRepayment /> }, //Sudah
    
    //Belum
    { path: "/apps/product-management/accessories", component: <ProductAccessories /> },
    { path: "/apps/product-management/auction", component: <ProductAuction /> },
    { path: "/apps/product-management/handphone", component: <ProductElectronic /> },
    { path: "/apps/product-management/vehicles", component: <ProductVehicles /> },

    //Belum
    { path: "/apps/customer-management/customer-list-data", component: <CustomerListData /> },
    { path: "/apps/customer-management/customer-settings/:UUIDCustomer", component: <CustomerSettings /> },
    { path: "/apps/customer-management/customer-register", component: <CustomerRegisterNew /> },

    //Belum
    { path: "/apps/pawnshop/report-management/handphone/report-data", component: <ManagementReportPawnshop /> }, //Sudah
    { path: "/apps/pawnshop/report-management/handphone/report-extend", component: <ManagementReportPawnshopExtended /> }, //Sudah
    { path: "/apps/pawnshop/report-management/handphone/report-repayment", component: <ManagementReportPawnshopRepayment /> }, //Sudah
    { path: "/apps/pawnshop/report-management/handphone/report-deleted", component: <ManagementReportPawnshopDeleted /> }, //Sudah
    { path: "/apps/report-management/selling-list-data", component: <ManagementReportSelling /> },
    { path: "/apps/report-management/purchasing-list-data", component: <ManagementReportPurchasing /> },

    //Belum
    { path: "/apps/pawnshop/statistics/handphone/analytics", component: <StatisticsPawnshop /> },
    { path: "/apps/selling/statistics/outlet/analytics", component: <StatisticsSelling /> },
    { path: "/apps/statistics/purchasing-stats", component: <StatisticsPurchasing /> },
    { path: "/apps/auction/statistics/outlet/analytics", component: <TXAComingSoon /> },

    //Belum
    { path: "/apps/users-management/user-list-data", component: <ManagementUserListData /> },
    { path: "/apps/users-management/user-register", component: <ManagementUserRegisterNew /> },

    //Belum
    { path: "/apps/system-configuration", component: <TXAComingSoon /> },

    { path: "/apps/selling/validation/outlet-transaction", component: <TXAComingSoon /> },
    { path: "/apps/selling/validation/auction-transaction", component: <TXAComingSoon /> },

    { path: "/apps/selling/report-management/store-product", component: <TXAComingSoon /> },
    { path: "/apps/selling/report-management/auction-product", component: <TXAComingSoon /> },

    { path: "/apps/selling/report-management/auction-product", component: <TXAComingSoon /> },
    { path: "/apps/selling/report-management/auction-product", component: <TXAComingSoon /> },

    { path: "/pps/product-management/outlet/handphone-data", component: <TXAComingSoon /> },
    { path: "/apps/product-management/outlet/handphone-data", component: <TXAComingSoon /> },
    { path: "/apps/product-management/accessories/acesories-data", component: <TXAComingSoon /> },
  

    //Belum
    { path: "/apps/pawnshop/others/handphone/estimating-data", component: <TXAComingSoon /> },
    { path: "/apps/pawnshop/others/handphone/physical-checks", component: <TXAComingSoon /> },

    //Detail Transaksi Pegadaian Handphone
    //{ path: "/apps/transaction-detail/handphone/:ContractNumber", component: <PawnshopHandphoneDetailTRX /> },

   {
    path: "/",
    exact: true,
    component: < Navigate to="/apps/dashboard" />,
   },
]

const publicRoutes = [
    //Authentication -> Sign In
    { path: "/auth/sign-in", component: <SignIn /> },

    { path: "*", component: <Page404 /> },
]

const customeRoutes = [
    //{ path: "/faktur-print/:ContractNumber", component: <PrintContract /> },
    { path: "/apps/pawnshop/print/handphone/:ContractNumber", component: <PrintContract /> },
    /*{ path: "/apps/pawnshop/print/handphone/:ContractNumber.jpeg", component: <ImageContract /> },*/
    /*{ path: "/apps/pawnshop/print-dummy/handphone/:ContractNumber", component: <FakePrintContract /> },*/
]

export { authProtectedRoutes, publicRoutes, customeRoutes }

