import React from 'react';
import PropTypes from 'prop-types';
import withRouter from './Common/withRouter';
import { isUserAuthenticated } from "helpers/backend_helper.js";
import { Navigate } from "react-router-dom"

const NonAuthLayout = (props) => {

    /*if (localStorage.getItem("authToken") && localStorage.getItem("authRToken")) {
        return window.location.href = '/apps/dashboard';
    }*/

    return (
        <React.Fragment>{props.children}</React.Fragment>
    );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
