import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import jwt_decode from "jwt-decode";

let roleVAR = null;

const SidebarContentUser = props => {
    const [uuid, setuuid] = useState(null);
    const [role, setrole] = useState(null);

    const eachUserData = async () => {

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);

            roleVAR = DecodeUserData.role;
        }
        catch (Err) {
            console.log(Err);
        }
    }

    const ref = useRef();
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        eachUserData();
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    useEffect(() => {
        roleVAR = null;

        setInterval(async () => {
            {
                await eachUserData();
            }
        }, 500);
    });

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (

        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Akses Cepat</li>
                        <li>
                            <Link to="/apps/dashboard">
                                <i className="fas fa-home" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Halaman Utama")}</span>
                            </Link>
                        </li>

                        <li className="menu-title">Transaksi</li>
                        <li>
                            <Link to="/apps/pawnshop/#" className="has-arrow">
                                <i className="fas fa-gem" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Pegadaian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/handphone-transaction/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/handphone-transaction/create-contract">{props.t("Transaksi Baru")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/handphone-transaction/extend-contract">{props.t("Transaksi Perpanjang")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/handphone-transaction/repayment-contract">{props.t("Transaksi Pelunasan")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                {/*<li>
                                    <Link to="/apps/pawnshop/laptop-transaction">{props.t("Pegadaian Laptop")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/pawnshop/motorcycle-transaction">{props.t("Pegadaian Motor")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/pawnshop/car-transaction">{props.t("Pegadaian Mobil")}</Link>
                                </li>*/}
                            </ul>
                        </li>
                        <li>
                            <Link to="/apps/selling/#" className="has-arrow">
                                <i className="fas fa-cash-register" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Penjualan")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/selling/new-transaction">{props.t("Buat Transaksi")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/apps/purchasing/#" className="has-arrow">
                                <i className="fas fa-store-alt" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Pembelian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/purchasing/new-transaction">{props.t("Buat Transaksi")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">Manajemen Produk</li>
                        <li>
                            <Link to="/apps/product-management/#" className="has-arrow">
                                <i className="fas fa-cube" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Daftar Produk")}</span>
                            </Link>
                            <ul className="sub-menu">
                                {/*<li>
                                    <Link to="/apps/product-management/vehicles">{props.t("Kendaraan")}</Link>
                                </li>*/}
                                <li>
                                    <Link to="/apps/product-management/electronic">{props.t("Elektronik")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/product-management/accessories">{props.t("Aksesoris")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/apps/product-management/auction" >
                                <i className="fas fa-recycle" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Produk Lelang")}</span>
                            </Link>
                        </li>

                        <li className="menu-title">Manajemen Nasabah</li>
                        <li>
                            <Link to="/apps/customer-management/#" className="has-arrow">
                                <i className="fas fa-address-card" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Nasabah")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/customer-management/customer-list-data">{props.t("Data Nasabah")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/customer-management/customer-register">{props.t("Registrasi Nasabah")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">Manajemen Laporan</li>
                        <li>
                            <Link to="/apps/report-management/pawnshop-list-data" >
                                <i className="fas fa-file-invoice-dollar" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Pegadaian")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/apps/report-management/selling-list-data" >
                                <i className="fas fa-file-export" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Penjualan")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/apps/report-management/purchasing-list-data" >
                                <i className="fas fa-file-download" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Pembelian")}</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );

    //console.log(role)
    /*if (roleVAR !== null && roleVAR !== "Administrator" || roleVAR !== null && roleVAR !== "Superuser") {
        
    }
    else {
        return (

            <React.Fragment>
                <SimpleBar className="h-100" ref={ref}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">Akses Cepat</li>
                            <li>
                                <Link to="/apps/dashboard">
                                    <i className="fas fa-home" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Halaman Utama")}</span>
                                </Link>
                            </li>

                            <li className="menu-title">Transaksi</li>
                            <li>
                                <Link to="/apps/pawnshop/#" className="has-arrow">
                                    <i className="fas fa-gem" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Pegadaian")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/apps/pawnshop/handphone-transaction/#" className="has-arrow">
                                            <span>{props.t("Pegadaian Handphone")}</span>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/apps/pawnshop/handphone-transaction/create-contract">{props.t("Transaksi Baru")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/apps/pawnshop/handphone-transaction/extend-contract">{props.t("Transaksi Perpanjang")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/apps/pawnshop/handphone-transaction/repayment-contract">{props.t("Transaksi Pelunasan")}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {*//*<li>
                                    <Link to="/apps/pawnshop/laptop-transaction">{props.t("Pegadaian Laptop")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/pawnshop/motorcycle-transaction">{props.t("Pegadaian Motor")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/pawnshop/car-transaction">{props.t("Pegadaian Mobil")}</Link>
                                </li>*//*}
                                </ul>
                            </li>
                            <li>
                                <Link to="/apps/selling/#" className="has-arrow">
                                    <i className="fas fa-cash-register" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Penjualan")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/apps/selling/new-transaction">{props.t("Buat Transaksi")}</Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/apps/purchasing/#" className="has-arrow">
                                    <i className="fas fa-store-alt" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Pembelian")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/apps/purchasing/new-transaction">{props.t("Buat Transaksi")}</Link>
                                    </li>
                                </ul>
                            </li>

                            {*//*{
                            role == "Superuser" || role == "Administrator"
                                ?
                                <li>
                                    <Link to="/apps/purchasing/#" className="has-arrow">
                                        <i className="fas fa-store-alt" style={{ fontSize: "16px" }}></i>
                                        <span>{props.t("Pembelian")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/purchasing/new-transaction">{props.t("Buat Transaksi")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                :
                                null
                        }*//*}



                            {*//*<li>
                            <Link to="/apps/transaction-validation" >
                                <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Validasi Pegadaian")}</span>
                            </Link>
                        </li>*//*}
                            <div>
                                <li className="menu-title">Validasi Transaksi</li>
                                <li>
                                    <Link to="/apps/transaction-validation/#" className="has-arrow">
                                        <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/transaction-validation/handphone-validation">{props.t("Validasi Data Kontrak")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </div>

                            {
                                role === "Superuser" || role === "Administrator"
                                    ?
                                    null
                                    :
                                    null
                            }

                            <li className="menu-title">Manajemen Produk</li>
                            <li>
                                <Link to="/apps/product-management/#" className="has-arrow">
                                    <i className="fas fa-cube" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Daftar Produk")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    {*//*<li>
                                    <Link to="/apps/product-management/vehicles">{props.t("Kendaraan")}</Link>
                                </li>*//*}
                                    <li>
                                        <Link to="/apps/product-management/electronic">{props.t("Elektronik")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/apps/product-management/accessories">{props.t("Aksesoris")}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/apps/product-management/auction" >
                                    <i className="fas fa-recycle" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Produk Lelang")}</span>
                                </Link>
                            </li>

                            <li className="menu-title">Manajemen Nasabah</li>
                            <li>
                                <Link to="/apps/customer-management/#" className="has-arrow">
                                    <i className="fas fa-address-card" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Nasabah")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/apps/customer-management/customer-list-data">{props.t("Data Nasabah")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/apps/customer-management/customer-register">{props.t("Registrasi Nasabah")}</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-title">Manajemen Laporan</li>
                            <li>
                                <Link to="/apps/report-management/pawnshop-list-data" >
                                    <i className="fas fa-file-invoice-dollar" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Data Pegadaian")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/apps/report-management/selling-list-data" >
                                    <i className="fas fa-file-export" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Data Penjualan")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/apps/report-management/purchasing-list-data" >
                                    <i className="fas fa-file-download" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Data Pembelian")}</span>
                                </Link>
                            </li>

                            <li className="menu-title">Statistik</li>
                            <li>
                                <Link to="/apps/statistics/pawnshop-stats" >
                                    <i className="fas fa-chart-line" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Statistik Pegadaian")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/apps/statistics/selling-stats" >
                                    <i className="fas fa-chart-bar" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Statistik Penjualan")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/apps/statistics/purchasing-stats" >
                                    <i className="fas fa-chart-area" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Statistik Pembelian")}</span>
                                </Link>
                            </li>

                            <li className="menu-title">Manajemen Pengguna</li>
                            <li>
                                <Link to="/apps/users-management/#" className="has-arrow">
                                    <i className="fas fa-users" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Pengguna")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/apps/users-management/user-list-data">{props.t("Data Pengguna")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/apps/users-management/user-register">{props.t("Tambah Data Baru")}</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-title">Pengaturan Sistem</li>
                            <li>
                                <Link to="/dsadsadsadas#" className="has-arrow">
                                    <i className="mdi mdi-cellphone-iphone"></i>
                                    <span>{props.t("Database Handphone")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/dsadsadsadas#">{props.t("Data Handphone")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/dsadsadsadas#">{props.t("Data Taksiran Handphone")}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/dsadsadsadas#" >
                                    <i className="fas fa-store-alt" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Pengaturan Cabang")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/apps/system-configuration" >
                                    <i className="fas fa-cogs" style={{ fontSize: "16px" }}></i>
                                    <span>{props.t("Pengaturan Sistem")}</span>
                                </Link>
                            </li>

                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        );
    }*/
    
};

SidebarContentUser.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContentUser));
