import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Spinner,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableContainer from '../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';

import {
    fetchTableDataCustomerFull,
} from "../../../helpers/backend_helper";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

import {
    FormatTipeASBarang,
    FormatTotalKontrak,
    FormatTotalKontrakLunas,
    FormatTotalKontrakFailETC,
    FormatUserBlocked,
} from "./isMasterTableCol";

let MasterDataTableDataCustomer = null;

const CustomerListData = props => {
    const dispatch = useDispatch();

    const [isPagePreloader, setisPagePreloader] = useState(false);

    useEffect(() => {

        FeedFunctionMasterDataTableDataCustomer("Full");

    }, [dispatch]);

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "3000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    async function FeedFunctionMasterDataTableDataCustomer(CardTypeValue) {

        setisDataTableListCustomer(null);
        setisPagePreloader(true);

        setTimeout(async () => {
            try {
                MasterDataTableDataCustomer = await fetchTableDataCustomerFull({ CardType: CardTypeValue });
                //console.log()
                if (MasterDataTableDataCustomer[0] != undefined) {
                    setisDataTableListCustomer(MasterDataTableDataCustomer);
                }
                else {
                    MasterDataTableKontrak = null;
                    setisDataTableListCustomer(null);
                }

                //showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisPagePreloader(false);
            }
            catch (Err) {
                setisPagePreloader(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    const [isDataTableListCustomer, setisDataTableListCustomer] = useState(null);

    const isColumnsData = useMemo(
        () => [
            {
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nama",
                accessor: "NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Total Kontrak",
                accessor: "TotalKontrak",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrak {...cellProps} />;
                },
            },
            {
                Header: "Kontrak Berjalan",
                accessor: "KontrakBerjalan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrak {...cellProps} />;
                },
            },
            {
                Header: "Kontrak Selesai",
                accessor: "KontrakSelesai",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrakLunas {...cellProps} />;
                },
            },
            {
                Header: "Kontrak Ditolak",
                accessor: "TotalKontrakReject",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrakFailETC {...cellProps} />;
                },
            },
            {
                Header: "Kontrak Gagal",
                accessor: "KontrakGagal",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrakFailETC {...cellProps} />;
                },
            },
            {
                Header: "Kontrak Ja.Po",
                accessor: "TotalJatuhTempo",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrakFailETC {...cellProps} />;
                },
            },
            {
                Header: "J. Keterlambatan",
                accessor: "JumlahKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTotalKontrakFailETC {...cellProps} />;
                },
            },
            {
                Header: "St. Blacklist",
                accessor: "Blacklist",
                disableFilters: true,
                filterable: true,
                sortable: true,
                Cell: cellProps => {
                    return <FormatUserBlocked {...cellProps} />;
                    //return <FormatJatuhTempo {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                accessor: "idPelanggan",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                {/*<li>
                                    <Link
                                        to={"#"}
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => {

                                        }}
                                    >
                                        <i className="mdi mdi-account-details" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Data Nasabah
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>*/}

                                <li>
                                    <Link
                                        to={"/apps/customer-management/customer-settings/" + cellProps.value}
                                        className="btn btn-sm btn-soft-primary"
                                        onClick={(evt) => {
                                            
                                        }}
                                    >
                                        <i className="mdi mdi-account-edit" id="confirmtooltip" />
                                        <UncontrolledTooltip placement="top" target="confirmtooltip">
                                            Perubahan Data Nasabah
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    //meta title
    document.title = "Daftar Data Nasabah - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Daftar Data Nasabah")}
                  />

                  {/*{
                      isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="info" />
                              </Col>
                          </Row>
                          :
                          null
                  }*/}

                  {
                      isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                              </Col>
                          </Row>
                          :
                          isDataTableListCustomer != null && isDataTableListCustomer.length > 0
                              ?
                              <Row>
                                  <Card>
                                      <CardBody>
                                          <Row>
                                              <Col lg={12} className={"mt-1"}>
                                                  <TableContainer
                                                      className={"table table-hover rounded"}
                                                      columns={isColumnsData}
                                                      data={isDataTableListCustomer || [{}]}
                                                      isGlobalFilter={true}
                                                      isAddOptions={false}
                                                      isPlaceholderData={"Cari data nasabah. . ."}
                                                      customPageSize={10}
                                                      isSorted={"desc"}
                                                  />
                                              </Col>
                                          </Row>
                                      </CardBody>
                                  </Card>
                              </Row>
                              :
                              <Row>
                                  <Card>
                                      <CardBody>
                                          <Row>
                                              <Col lg={12} className="mt-5 pb-5 text-center">
                                                  <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                              </Col>
                                          </Row>
                                      </CardBody>
                                  </Card>
                              </Row>
                  }
                  
          </Container>
          </div>
    </React.Fragment>
  );
};

CustomerListData.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CustomerListData);
