import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Alert,
    Modal,
    Input,
    Form,
    Collapse,
    Label,
    Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableContainer from '../../../../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select";
import jwt_decode from "jwt-decode";
import classnames from "classnames";
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
    fetchTableTRXValidationREPAYMENT,
    updateValidationContract,
    deleteDataContract,
    rejectDataContract,
    validationPelunasanContract,
    rejectionPelunasanContract,
} from "../../../../../../helpers/backend_helper";

import {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatKeterlambatan,
} from "./isMasterTableCol";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

let MasterDataTable = [{}];
let isUUIDOperator = null;

const TRXValidationHandphoneRepayment = props => {
    moment.tz.setDefault('Asia/Jakarta');

    const [isDataLoading, setisDataLoading] = useState(false);

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "5000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isShowInformasi, setisShowInformasi] = useState(false);

    const handleClickisShowInformasi = () => {
        setisShowInformasi(!isShowInformasi);
    };

    const dispatch = useDispatch();

    //Select Filter Date
    const optionFilterDateData = [
        {
            label: "Filter Transaksi",
            options: [
                { label: "Transaksi Hari Ini", value: "isTRXToday" },
                { label: "Semua Transaksi", value: "isTRXAll" }
            ]
        },
    ];

    const [selectedFilterDateData, setselectedFilterDateData] = useState(null);

    async function handleSelectedFilterDateData(selectedFilterDateData) {
        setselectedFilterDateData(selectedFilterDateData);

        MasterDataTable = [{}];

        setisDataValidsasiTransaksi(null);

        await FeedFunctionDataForTableValidasiTransaksi(selectedFilterDateData.value);
    }

    async function FeedFunctionDataForTableValidasiTransaksi(WhatsFiltered) {
        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);
        MasterDataTable = [{}];

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableTRXValidationREPAYMENT({ LimitData: 1000, MethodSort: 'DESC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                setisDataLoading(false);
            }
            catch (Err) {

            }
        }, 500);
    }

    const [isDataValidsasiTransaksi, setisDataValidsasiTransaksi] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            /*if (role != null && role != "Administrator" || role != null && role != "Superuser") {
                //if (role === "Operator") {
                window.location.href = '/apps/dashboard'
            }*/

            isUUIDOperator = DecodeUserData.uuid;
        }
        catch (Err) {
            //Nothing
        }
    }

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    useEffect(() => {
        autoUpdateProfile();

        FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
        
        setInterval(async () => {
            {
                /*MasterDataTable = [{}];

                await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");*/
                autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s


    }, [dispatch]);

    const [imgBuktiPembayaran, setimgBuktiPembayaran] = useState(null);
    const [isFileBuktiPayPembayaran, isSetFileBuktiPayPembayaran] = useState(false);
    const [isFileIMGBuktiPayPembayaran, isSetFileIMGBuktiPayPembayaran] = useState(null);

    const isColumnsData = useMemo(
        () => [
            /*{
                Header: "idPelanggan",
                accessor: "isCustomer.idPelanggan",
                filterable: false,
                disableFilters: true,
            },*/
            {
                Header: "No. Kontrak",
                accessor: "PLPGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "Nasabah",
                accessor: "isPelunasanCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCustomer {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Pelunasan",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PLPGD_TglPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Nominal Pelunasan",
                accessor: "PLPGD_NominalPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            /*{
                Header: "Bank Asal",
                accessor: "PLPGD_RekAsal_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Bank Pembayaran",
                accessor: "PLPGD_RekTujuan_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },*/
            {
                Header: "Bukti",
                accessor: "PLPGD_BuktiPembayaran",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return (
                        cellProps.value !== null
                            ?
                            <Link onClick={() => {
                                isSetFileIMGBuktiPayPembayaran(cellProps.value);
                                isSetFileBuktiPayPembayaran(true);
                                setimgBuktiPembayaran(cellProps.value);

                            }}>Lihat Bukti Foto</Link>
                            :
                            <span>Tidak Ada</span>
                    );
                },
            },
            {
                Header: "Operator",
                accessor: "isPelunasanOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Metode",
                accessor: "PLPGD_MetodePembayaran",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <FormatMetodeTransaksi {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                    <Link
                                        to={"/apps/pawnshop/details/handphone/" + cellProps.cell.row.allCells[0].value}
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => {

                                        }}
                                    >
                                        <i className="mdi mdi-eye-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Kontrak
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-success"
                                        onClick={(evt) => {
                                            if (cellProps.cell.row.allCells[0].value != '' || cellProps.cell.row.allCells[0].value != null) {
                                                setisNomorKontrak(cellProps.cell.row.allCells[0].value);
                                                setisIDTransSelected(cellProps.value);
                                                setisCommandSelectedValue("Confirm");

                                                //console.log(cellProps.cell.row.allCells[0].value)
                                                isShowModalConfirmKontrak();

                                                //setisUUIDUserContract(cellProps.cell.row.allCells[0].value);
                                            }
                                        }}
                                    >
                                        <i className="mdi mdi-check-outline" id="confirmtooltip" />
                                        <UncontrolledTooltip placement="top" target="confirmtooltip">
                                            Validasi Transaksi
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const [isIDTransSelected, setisIDTransSelected] = useState(null);
    const [isNomorKontrak, setisNomorKontrak] = useState(null);
    const [isCommandSelectedValue, setisCommandSelectedValue] = useState(null);
    const [isSudahCekFISIK, setisSudahCekFISIK] = useState(false);
    const [isBarangAda, setisBarangAda] = useState(null);
    const [isUUIDUserContract, setisUUIDUserContract] = useState(null);
    const [isModalConfirmKontrak, setisModalConfirmKontrak] = useState(false);

    function isShowModalConfirmKontrak() {
        setisBarangAda(null);
        setisSudahCekFISIK(false);
        setisModalConfirmKontrak(!isModalConfirmKontrak);
        removeBodyCss();
    }

    const [isModalDeleteKontrak, setisModalDeleteKontrak] = useState(false);

    function isShowModalDeleteKontrak() {
        setisModalDeleteKontrak(!isModalDeleteKontrak);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const formKontrakValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isFieldDataID: isIDTransSelected || undefined,
            isFieldCommand: isCommandSelectedValue || undefined,
            isFieldUIDValidator: opUUID || undefined,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
            //console.log(values);

            if (isCommandSelectedValue === "Confirm") {
                try {
                    await validationPelunasanContract(values);

                    showToastNotification('Success!', 'Data Transaksi Pelunasan dengan ID #' + isIDTransSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
                }
                catch (Err) {
                    showToastNotification('Oops!', 'Data Transaksi Pelunasan dengan ID #' + isIDTransSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                }
            }
            else { 
                try {
                    await rejectionPelunasanContract(values);

                    showToastNotification('Success!', 'Data Transaksi Pelunasan dengan ID #' + isIDTransSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil diperbaharui!', 'warning');
                }
                catch (Err) {
                    showToastNotification('Oops!', 'Data Transaksi Pelunasan dengan ID #' + isIDTransSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                }
            }

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
        },
    });

    async function PostUpdateValidasiDataKontrak(isFormValue) {
        try {
            await updateValidationContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    async function PostRejectDataKontrak(isFormValue) {
        try {
            await rejectDataContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di tolak!', 'success');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di tolak, ada sesuatu yang salah!', 'error');
        }
    }
    
    async function PostDeleteDataKontrak(isFormValue) {
        try {

            await deleteDataContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di dihapus!', 'info');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di hapus, ada sesuatu yang salah!', 'error');
        }
    }

    function getFotoBuktiPembayaran(isFileName) {
        if (isFileName !== null) {
            try {
                //apply base url for axios
                let API_URL = '';
                let API_IMAGE_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
                const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
                const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
                    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND + ":" + API_IMAGE_PORT_INTERNET;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
                    }

                }

                return "http://" + API_URL + "/img-assets/imgBuktiPelunasan/" + isFileName;

            }
            catch (Err) {
                //Nothing
                //console.log(Err);
            }
        }
    }

    //meta title
    document.title = "Validasi Transaksi Pelunasan Kontrak - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Validasi Transaksi Pelunasan Kontrak")}
                  />
                  {
                      role !== null & role === "Operator"
                          ?
                          window.location.href = '/apps/dashboard'
                          :
                          null
                  }

                  {isFileBuktiPayPembayaran ? (
                      <Lightbox
                          mainSrc={getFotoBuktiPembayaran(imgBuktiPembayaran !== null ? imgBuktiPembayaran : null)}
                          enableZoom={true}
                          imageCaption={"Foto Bukti Pembayaran"}
                          onCloseRequest={() => {
                              isSetFileBuktiPayPembayaran(false);
                          }}
                      />
                  )
                      : null}

                  {/*IS MODAL FOR CONFIRM DATA*/}
                      < Modal
                          isOpen={isModalConfirmKontrak}
                          toggle={() => {
                              isShowModalConfirmKontrak();
                          }}
                          backdrop={'static'}
                          id="modalConfirmKontrak"
                          className={'modal-dialog-centered'}
                      >
                          <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-success" id="modalConfirmKontrakLabel"><i className=" mdi mdi-information-outline me-2"></i>VALIDASI PELUNASAN KONTRAK #{isIDTransSelected}</h5>
                              <button type="button" className="btn-close"
                                  onClick={() => {
                                      setisModalConfirmKontrak(false);
                                  }} aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                              <p className="pb-1">Nomor Kontrak : <b>#{isNomorKontrak}</b></p>
                          <p className="font-size-13 text-justify">Proses validasi transaksi <b className="text-warning">TIDAK DAPAT DIBATALKAN</b>, pastikan Anda sudah melakukan pengecekan transaksi sebelum melanjutkan ke proses validasi.</p>

                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-soft-info fw-bold" onClick={() => {
                                  setisModalConfirmKontrak(false);
                              }}>Tutup</button>
                              <Form
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                      formKontrakValidation.handleSubmit();

                                      setisModalConfirmKontrak(false);

                                      setisCommandSelectedValue("Confirm");

                                      return false;
                                  }}
                              >
                                  <Input
                                      name="isFieldDataID"
                                      type="hidden"
                                      onChange={formKontrakValidation.handleChange}
                                      onBlur={formKontrakValidation.handleBlur}
                                      value={isIDTransSelected}
                                  />
                              <button type="submit" className="btn btn-soft-success fw-bold">Konfirmasi</button>
                              </Form>
                              <Form
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                      formKontrakValidation.handleSubmit();

                                      setisModalConfirmKontrak(false);

                                      setisCommandSelectedValue("Reject");

                                      return false;
                                  }}
                              >
                                  <Input
                                      name="isFieldDataID"
                                      type="hidden"
                                      onChange={formKontrakValidation.handleChange}
                                      onBlur={formKontrakValidation.handleBlur}
                                      value={isIDTransSelected}
                                  />
                              <button type="submit" className="btn btn-soft-danger fw-bold">Tolak</button>
                              </Form>
                          </div>
                      </Modal>

                  {
                      isDataLoading
                          ?
                          <Col lg={12} className={"mt-5 pb-5 text-center"}>
                              <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                          </Col>
                          :
                          <Row>
                              <Col lg="12">
                                  <Card>
                                      <CardBody>
                                          <Row>
                                              {/*ACCORDION INFORMASI*/}
                                              <Col xl={12} className={"pb-4"}>
                                                  <div
                                                      className="accordion"
                                                      id="showInformasiTab"
                                                  >
                                                      <div className="accordion-item ">
                                                          <h2 className={classnames(
                                                              "accordion-header",
                                                              /*{ "bg-light": !isShowInformasi },*/

                                                          )} id="showInformasiTabHeading">
                                                              <button
                                                                  className={classnames(
                                                                      "accordion-button",
                                                                      "fw-medium",
                                                                      { collapsed: !isShowInformasi },
                                                                      { "text-white": !isShowInformasi },
                                                                      { "text-white": isShowInformasi },
                                                                      /*{ "text-danger bg-light": isShowInformasi },*/
                                                                  )}
                                                                  type="button"
                                                                  onClick={handleClickisShowInformasi}
                                                                  style={{ cursor: "pointer", backgroundColor: '#101218' }}
                                                              >
                                                                  <i className="text-muted fw-bold mdi mdi-information-outline me-2"></i> {!isShowInformasi ? "TAMPILKAN PETUNJUK INFORMASI" : "SEMBUNYIKAN PETUNJUK INFORMASI"}
                                                              </button>
                                                          </h2>

                                                          <Collapse
                                                              isOpen={isShowInformasi}
                                                              className="accordion-collapse"
                                                          >
                                                              <div className="accordion-body">
                                                                  <div className="text-black">
                                                                      <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                                      <br />
                                                                      <p>#Penjelesan Fungsi Tombol <b>Aksi</b> : </p>
                                                                      <ul style={{ listStyle: 'none' }}>
                                                                          <li className="pb-3">
                                                                              <Link
                                                                                  to="#"
                                                                                  className="btn btn-sm btn-info font-size-14"
                                                                                  onClick={() => {
                                                                                      return false;
                                                                                  }}
                                                                              >
                                                                                  <i className="mdi mdi-eye-outline" id="detailtooltipINFO" />
                                                                                  <UncontrolledTooltip placement="top" target="detailtooltipINFO">
                                                                                      Detil Kontrak
                                                                                  </UncontrolledTooltip>
                                                                              </Link> Tombol Detil Kontrak
                                                                              <ul style={{ listStyle: 'inside' }}>
                                                                                  <li><b>#FUNGSI</b> : Berfungsi untuk melihat detil kontak atau melihat isi data kontrak secara keseluruhan.</li>
                                                                              </ul>
                                                                          </li>
                                                                          <li className="pb-3">
                                                                              <Link
                                                                                  to="#"
                                                                                  className="btn btn-sm btn-success font-size-14"
                                                                                  onClick={() => {
                                                                                      return false;
                                                                                  }}
                                                                              >
                                                                                  <i className="mdi mdi-check-outline" id="confirmtooltipINFO" />
                                                                                  <UncontrolledTooltip placement="top" target="confirmtooltipINFO">
                                                                                      Validasi Transaksi
                                                                                  </UncontrolledTooltip>
                                                                              </Link> Tombol Validasi Transaksi (<i>Quick</i>)
                                                                              <ul style={{ listStyle: 'inside' }}>
                                                                                  <li><b>#FUNGSI</b> : Berfungsi untuk melakukan validasi kontrak secara cepat tanpa melakukan pengecekan data terlebih dahulu, mohon hati-hati dalam menggunakan fitur ini.</li>
                                                                              </ul>
                                                                          </li>
                                                                      </ul>
                                                                      <p>~Terimakasih ^.^</p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                  </div>
                                              </Col>

                                              <Col lg={12}>
                                                  <Select
                                                      id="INPUTFilterDateTRX"
                                                      name="INPUTFilterDateTRX"
                                                      placeholder="Filter Waktu Transaksi"
                                                      value={selectedFilterDateData}
                                                      onChange={(selectedFilterDateData) => {
                                                          handleSelectedFilterDateData(selectedFilterDateData);
                                                      }}
                                                      options={optionFilterDateData}
                                                      className="select2-selection"
                                                      isDisabled={null}
                                                  />
                                              </Col>

                                              {
                                                  isDataValidsasiTransaksi !== null && isDataValidsasiTransaksi.length > 0
                                                      ?
                                                      <Col lg={12} className={"mt-4"}>
                                                          <TableContainer
                                                              className={"table table-hover rounded"}
                                                              columns={isColumnsData}
                                                              data={isDataValidsasiTransaksi || [{}]}
                                                              isGlobalFilter={true}
                                                              isAddOptions={false}
                                                              isPlaceholderData={"Cari data transaksi. . ."}
                                                              customPageSize={50}
                                                              isSorted={"asc"}
                                                          />
                                                      </Col>
                                                      :
                                                      <Col lg={12} className="mt-5 pb-5 text-center">
                                                          <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                                      </Col>
                                              }
                                          </Row>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }

                      
          </Container>
          </div>
    </React.Fragment>
  );
};

TRXValidationHandphoneRepayment.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(TRXValidationHandphoneRepayment);
