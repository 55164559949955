import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";
import jwt_decode from "jwt-decode";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {
    //Custom
    const [Dashboard, setDashboard] = useState(false);
    const [AnalisaData, setAnalisaData] = useState(false);
    const [DataManagement, setDataManagement] = useState(false);
    const [Pemantauan, setPemantauan] = useState(false);
    const [Pengembang, setPengembang] = useState(false);
    const [UserManagement, setUserManagement] = useState(false);
    const [Bantuan, setBantuan] = useState(false);
    const [ViolationEvaluation, setViolationEvaluation] = useState(false);
    const [EngineeringMode, setEngineeringMode] = useState(false);

    const [uuid, setuuid] = useState(null);
    const [role, setrole] = useState(null);

    const eachUserData = async () => {

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);
        }
        catch (Err) {
            console.log(Err);
        }
    }

    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");

        removeActivation(items);

        for (var i = 0; i < items.length; ++i) {
            if (window.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }

        setInterval(async () => {
            {
                await eachUserData();
            }
        }, 500);
    });

    const removeActivation = items => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }


    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={props.leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link arrow-none"
                                        onClick={e => {
                                            //e.preventDefault();
                                            setDashboard(!Dashboard);
                                        }}
                                        to="/apps/dashboard"
                                    >
                                        <i className="mdi mdi-view-dashboard me-2"></i>
                                        {props.t("Halaman Utama")}
                                    </Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none"
                                        onClick={e => {
                                            e.preventDefault();
                                            setBantuan(!Bantuan);
                                        }}
                                        style={({ cursor: "pointer" })}
                                        to="/#"
                                    >
                                        <i className="mdi mdi-book-open-page-variant-outline me-2"></i>
                                        {props.t("Main_Menu")}
                                        <div className="arrow-down"></div>
                                    </Link>
                                    <div
                                        className={classname("dropdown-menu", { show: Bantuan })}
                                    >
                                        <Link to="/#" className="dropdown-item">
                                            {props.t("Example")}
                                        </Link>
                                    </div>
                                </li>

                                {/*{
                                    role != null && role != "User" && role != "Operator" && role != "Administrator"
                                        ?
                                        <li className="nav-item dropdown">
                                            <Link
                                                className="nav-link dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    setEngineeringMode(!EngineeringMode);
                                                }}
                                                style={({ cursor: "pointer" })}
                                                to="/apps/engineer-mode"
                                            >
                                                <i className="mdi mdi-shield-lock me-2"></i>
                                                {props.t("Engineer Mode")}
                                            </Link>
                                        </li>
                                        :
                                        null
                                }*/}

                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
