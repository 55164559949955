import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Modal,
    Label,
    Form,
    FormFeedback,
    Input,
    Alert,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import jwt_decode from "jwt-decode";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    TXA_POST_QR_CODE_WHATSAPP,
    TXA_POST_WHATSAPP_SESSION_START,
    TXA_POST_WHATSAPP_SESSION_DELETE,
    TXA_POST_WHATSAPP_SESSION_LIST,
    TXA_POST_WHATSAPP_SESSION_DETAIL,
    TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT,
    TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE,
} from "../../helpers/backend_helper";
//import { readFile } from "fs/promises";

let isUUIDOperator = null;
let isValopKodeCabang = null;
let isBase64QRCode = null;
let isAuthDetailData = null;
let isListAuthWAGateway = null;
let isAuthStatusWAGateway = false;
let isBase64QRCodeRequested = false;
let isAuthStatusWAGateway_NEEDED_AUTH = false;
//let fs = require("fs");

const WhatsAppGateway = props => {

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "3000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isNamaWA, setisNamaWA] = useState("-");
    const [isNomorWA, setisNomorWA] = useState("-");
    const [isPerangkatWA, setisPerangkatWA] = useState("-");
    const [isIDPerangkatWA, setisIDPerangkatWA] = useState("-");

    const [isDataLoading, setisDataLoading] = useState(false);
    const [isQRDataLoading, setisQRDataLoading] = useState(false);
    //const [isListAuthWAGateway, setisListAuthWAGateway] = useState(null);

    const [isQRCodeWhatsApp, setisQRCodeWhatsApp] = useState(null);

    const [isWhatsAppConnectionStatus, setisWhatsAppConnectionStatus] = useState(null);

    const [isResultSendTextWA, setisResultSendTextWA] = useState(null);
    const [isLoadingSendMessage, setisLoadingSendMessage] = useState(false);

    const dispatch = useDispatch();

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }
    }

    async function FetchQRCodeWhatsAppAuth() {
        setisQRDataLoading(true);
        isBase64QRCodeRequested = true;
        try {
            isBase64QRCode = await TXA_POST_QR_CODE_WHATSAPP({ SessionIdentity: isValopKodeCabang, SessionScan: true });
            setisQRCodeWhatsApp(isBase64QRCode.qr);
        }
        catch (Err) {
            console.log(Err);
        }
        setisQRDataLoading(false);
    }

    async function FetchQRCodeWhatsAppAuth_START() {
        setisQRDataLoading(true);
        isBase64QRCodeRequested = true;
        try {
            isBase64QRCode = await TXA_POST_WHATSAPP_SESSION_START({ SessionIdentity: isValopKodeCabang, SessionScan: true });
            setisQRCodeWhatsApp(isBase64QRCode.qr);
        }
        catch (Err) {
            console.log(Err);
        }
        setisQRDataLoading(false);
    }
    
    async function SendTextMessageWA(isReceiver) {
        setisResultSendTextWA(null);
        setisLoadingSendMessage(true);
        try {
            let isResponseFromWA = await TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT({ SessionIdentity: isValopKodeCabang, ReceiverPhoneNumber: isReceiver, textMessages: "Command Test Function for Session => " + isValopKodeCabang});

            setisResultSendTextWA(JSON.stringify(isResponseFromWA));

            setisLoadingSendMessage(false);

            showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            setisLoadingSendMessage(false);
            console.log(Err);
        }
    }

    async function SendTextWithImageMessageWA(isReceiver, isMediaIMG) {
        setisResultSendTextWA(null);
        setisLoadingSendMessage(true);
        try {
            let isResponseFromWA = await TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE({ SessionIdentity: isValopKodeCabang, ReceiverPhoneNumber: isReceiver, textMessages: "Command Test Function for Session => " + isValopKodeCabang, isMediaFile: isMediaIMG });

            setisResultSendTextWA(JSON.stringify(isResponseFromWA));

            setisLoadingSendMessage(false);

            showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            setisLoadingSendMessage(false);
            console.log(Err);
        }
    }

    async function DeleteSessionASLogoutWhatsApp() {

        try {
            await TXA_POST_WHATSAPP_SESSION_DELETE({ SessionIdentity: isValopKodeCabang });
            setisQRCodeWhatsApp(null);
            setisWhatsAppConnectionStatus(null);
            isAuthStatusWAGateway_NEEDED_AUTH = true;
            isAuthStatusWAGateway = false;
            isBase64QRCodeRequested = false;

            setisNamaWA("-");
            setisNomorWA("-");
            setisPerangkatWA("-");
            setisIDPerangkatWA("-");
            
           // await FetchSessionListWhatsApp();
        }
        catch (Err) {
            //console.log(Err);
        }
    }

    async function FetchSessionDetailWhatsApp() {

        try {
            isAuthDetailData = await TXA_POST_WHATSAPP_SESSION_DETAIL({ SessionIdentity: isValopKodeCabang });

            //if (isAuthDetailData.data.authState.creds.me) {
            if (isAuthDetailData.data.authState !== undefined) {

                if (isAuthDetailData.data.authState.creds.me) {
                    //setisWhatsAppAuthStatus(true);
                    setisWhatsAppConnectionStatus(true);

                    if (isAuthDetailData.data.authState.creds.me.name) {
                        setisNamaWA(isAuthDetailData.data.authState.creds.me.name);
                    }

                    if (isAuthDetailData.data.authState.creds.me.id) {
                        setisNomorWA((isAuthDetailData.data.authState.creds.me.id).split(":")[0]);
                    }

                    if (isAuthDetailData.data.authState.creds.platform) {
                        setisPerangkatWA(isAuthDetailData.data.authState.creds.platform);
                    }

                    if (isAuthDetailData.data.authState.creds.phoneId) {
                        setisIDPerangkatWA(isAuthDetailData.data.authState.creds.phoneId);
                    }
                }
            }
        }
        catch (Err) {
            //console.log(Err);
        }
    }


    const [SessionWhatsAppList, setSessionWhatsAppList] = useState("-");

    async function FetchSessionListWhatsApp() {
        try {
            let isResultList = await TXA_POST_WHATSAPP_SESSION_LIST({ APIKey: true });

            isListAuthWAGateway = await isResultList.data;

            setSessionWhatsAppList(JSON.stringify(isResultList.data));
        }
        catch (Err) {
            console.log(Err);
        }

    }

    useEffect(() => {
        isUUIDOperator = null;
        isValopKodeCabang = null;
        isBase64QRCode = null;
        isListAuthWAGateway = null;
        isAuthDetailData = null;
        isAuthStatusWAGateway = false;
        isBase64QRCodeRequested = false;
        isAuthStatusWAGateway_NEEDED_AUTH = false;

        setisDataLoading(true);

        autoUpdateProfile();
        FetchSessionDetailWhatsApp();

        setInterval(async () => {
            if (window.location.pathname === "/apps/wa-gateway") {
                if (isBase64QRCodeRequested) {
                    await FetchSessionDetailWhatsApp();
                }

                await FetchSessionListWhatsApp();
            }
        }, 3000);

        setTimeout(async () => {
            setisDataLoading(false);
        }, 500);

    }, []);

    const formKirimPesanTeks = useFormik({
        enableReinitialize: true,

        initialValues: {
            isFieldPhoneNoReceiver: "62",
        },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
            //console.log(values);

            await SendTextMessageWA(values.isFieldPhoneNoReceiver);
        },
    });

    const [isFileMediaIMG, setisFileMediaIMG] = useState(null);

    async function handleChangeFileMediaIMG(e) {
        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = fileNameIMG + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        const ConvertToBase64 = await toBase64(fileRenamed);

        setisFileMediaIMG(ConvertToBase64);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        }
        catch (Err) {
            //Nothing
            console.log(Err);
        }
    });

    const formKirimPesanTeksIMG = useFormik({
        enableReinitialize: true,

        initialValues: {
            isFieldPhoneNoReceiver: "62",
            isFieldMediaIMG: isFileMediaIMG,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
            //console.log(values);

            await SendTextWithImageMessageWA(values.isFieldPhoneNoReceiver, values.isFieldMediaIMG);
        },
    });

    const [isModalKirimPesanTeks, setisModalKirimPesanTeks] = useState(false);
    const [isModalKirimPesanTeksIMG, setisModalKirimPesanTeksIMG] = useState(false);

    function isShowModalisModalKirimPesanTeks() {
        setisModalKirimPesanTeks(!isModalKirimPesanTeks);
        removeBodyCss();
    }

    function isShowModalisModalKirimPesanTeksIMG() {
        setisModalKirimPesanTeksIMG(!isModalKirimPesanTeksIMG);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    //meta title
    document.title = "WhatsApp Gateway " + process.env.REACT_APP_NAME;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
                        breadcrumbItem={props.t("WhatsApp Gateway")}
                    />

                    <Modal
                        isOpen={isModalKirimPesanTeks}
                        toggle={() => {
                            isShowModalisModalKirimPesanTeks();
                        }}
                        backdrop={'static'}
                        id="modalKirimPesanTeks"
                        className={'modal-dialog-centered modal-md d-print-none'}
                    >
                        <div className="modal-header bg-white bg-opacity-25 ">
                            <h5 className="modal-title text-info" id="modalKirimPesanTeksLabel"><i className=" mdi mdi-information-outline me-2"></i>FORMULIR KIRIM PESAN TEKS WA</h5>

                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setisModalKirimPesanTeks(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <Input
                                    name="isFieldPhoneNoReceiver"
                                    id="isFieldPhoneNoReceiver"
                                    className="form-control border-info"
                                    type="text"
                                    maxLength={17}
                                    placeholder="Nomor WhatsApp Penerima . . ."
                                    onChange={formKirimPesanTeks.handleChange}
                                    onBlur={formKirimPesanTeks.handleBlur}
                                    value={formKirimPesanTeks.values.isFieldPhoneNoReceiver || "62"}
                                    autoComplete={"off"}
                                    invalid={
                                        formKirimPesanTeks.touched.isFieldPhoneNoReceiver && formKirimPesanTeks.errors.isFieldPhoneNoReceiver ? true : false
                                    }
                                />
                                {formKirimPesanTeks.touched.isFieldPhoneNoReceiver && formKirimPesanTeks.errors.isFieldPhoneNoReceiver ? (
                                    <FormFeedback type="invalid">{formKirimPesanTeks.errors.isFieldPhoneNoReceiver}</FormFeedback>
                                ) : null}
                                <Label htmlFor="isFieldPhoneNoReceiver" for="isFieldPhoneNoReceiver">
                                    No. WhatsApp Penerima
                                </Label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-md btn-soft-info" onClick={() => {
                                setisModalKirimPesanTeks(false);
                            }}>Tutup</button>
                            <Form
                                onSubmit={(e) => {

                                    e.preventDefault();
                                    formKirimPesanTeks.handleSubmit();
                                    setisModalKirimPesanTeks(false);

                                    return false;
                                }}
                            >
                                <button type="submit" className="btn btn-md btn-soft-success" >Kirim</button>
                            </Form>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isModalKirimPesanTeksIMG}
                        toggle={() => {
                            isShowModalisModalKirimPesanTeksIMG();
                        }}
                        backdrop={'static'}
                        id="modalKirimPesanTeks"
                        className={'modal-dialog-centered modal-md d-print-none'}
                    >
                        <div className="modal-header bg-white bg-opacity-25 ">
                            <h5 className="modal-title text-info" id="modalKirimPesanTeksLabel"><i className=" mdi mdi-information-outline me-2"></i>FORMULIR KIRIM PESAN TEKS & GAMBAR WA</h5>

                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setisModalKirimPesanTeksIMG(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <Input
                                    name="isFieldPhoneNoReceiver"
                                    id="isFieldPhoneNoReceiver"
                                    className="form-control border-info"
                                    type="text"
                                    maxLength={17}
                                    placeholder="Nomor WhatsApp Penerima . . ."
                                    onChange={formKirimPesanTeksIMG.handleChange}
                                    onBlur={formKirimPesanTeksIMG.handleBlur}
                                    value={formKirimPesanTeksIMG.values.isFieldPhoneNoReceiver || "62"}
                                    autoComplete={"off"}
                                    invalid={
                                        formKirimPesanTeksIMG.touched.isFieldPhoneNoReceiver && formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver ? true : false
                                    }
                                />
                                {formKirimPesanTeksIMG.touched.isFieldPhoneNoReceiver && formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver ? (
                                    <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver}</FormFeedback>
                                ) : null}
                                <Label htmlFor="isFieldPhoneNoReceiver" for="isFieldPhoneNoReceiver">
                                    No. WhatsApp Penerima
                                </Label>
                            </div>

                            {/*<div className="form-floating mb-3">
                                <Input
                                    name="isFieldMediaIMG"
                                    id="isFieldMediaIMG"
                                    className="form-control border-info"
                                    type="text"
                                    placeholder="URL / Link Gambar . . ."
                                    onChange={formKirimPesanTeksIMG.handleChange}
                                    onBlur={formKirimPesanTeksIMG.handleBlur}
                                    autoComplete={"off"}
                                    invalid={
                                        formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? true : false
                                    }
                                />
                                {formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? (
                                    <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldMediaIMG}</FormFeedback>
                                ) : null}
                                <Label htmlFor="isFieldMediaIMG" for="isFieldMediaIMG">
                                    Link / URL File Media
                                </Label>
                            </div>*/}

                            <div className="mb-3">
                                <Label className="col-form-label" for="isFieldMediaIMG">
                                    Upload File Media
                                </Label>
                                <Input
                                    name="isFieldMediaIMG"
                                    id="isFieldMediaIMG"
                                    className="form-control"
                                    type="file"
                                    accept="image/*"
                                    onChange={(evt) => {
                                        handleChangeFileMediaIMG(evt);
                                    }}
                                    onBlur={formKirimPesanTeksIMG.handleBlur}
                                    autoComplete={"off"}
                                    invalid={
                                        formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? true : false
                                    }
                                />
                                {formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? (
                                    <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldMediaIMG}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-md btn-soft-info" onClick={() => {
                                setisModalKirimPesanTeksIMG(false);
                            }}>Tutup</button>
                            <Form
                                onSubmit={(e) => {

                                    e.preventDefault();
                                    formKirimPesanTeksIMG.handleSubmit();
                                    setisModalKirimPesanTeksIMG(false);

                                    return false;
                                }}
                            >
                                <button type="submit" className="btn btn-md btn-soft-success" >Kirim</button>
                            </Form>
                        </div>
                    </Modal>

                    {
                        isDataLoading
                            ?
                            <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                            </Col>
                            :
                            <Row>
                                <Col xl={12}>
                                    <div >
                                        <Alert className="alert-info" role="alert">
                                            <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> PERHATIAN!</h5>
                                            <p>Fitur <i className="fw-bold">WhatsApp Gateway</i> masih dalam tahap pengembangan atau tahap uji coba, mohon berhati - hati dalam menggunakannya.</p>
                                        </Alert>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <Card>
                                        <CardBody className="" style={{ height: "360px" }}>
                                            <CardTitle className="text-center">Status Server <span className="text-success">WhatsApp</span></CardTitle>
                                            <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                            {
                                                isQRDataLoading
                                                    ?
                                                    <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                                        <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                                                    </Col>
                                                    :
                                                    <>
                                                        <Col lg={12} className={"text-center"}>
                                                            {
                                                                isWhatsAppConnectionStatus !== null
                                                                    ?
                                                                    isWhatsAppConnectionStatus
                                                                        ?
                                                                        <>
                                                                            <Col lg={12} className={"text-center"}>
                                                                                <i className="fas fa-check-circle text-success" style={{ fontSize: "128px" }}></i>
                                                                            </Col>
                                                                            <Col lg={12} className={"text-center mt-2"}>
                                                                                <span className="text-success fw-bold" style={{ fontSize: "18px" }}>WhatsApp</span><span className="fw-bold" style={{ fontSize: "18px" }}> Connected!</span>
                                                                            </Col>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Col lg={12} className={"text-center"}>
                                                                                <i className="fas fa-times-circle text-danger" style={{ fontSize: "128px" }}></i>
                                                                            </Col>
                                                                            <Col lg={12} className={"text-center mt-2"}>
                                                                                <span className="text-success fw-bold" style={{ fontSize: "18px" }}>WhatsApp</span><span className="text-danger fw-bold" style={{ fontSize: "18px" }}> is not Connected!</span>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                    isBase64QRCodeRequested
                                                                        ?
                                                                        <img
                                                                            src={isQRCodeWhatsApp}
                                                                            alt=""
                                                                            width="180px"
                                                                            height="180px"
                                                                            id="expandedImg1"
                                                                            className="img-fluid mx-auto d-block rounded"
                                                                            onClick={() => {

                                                                            }}
                                                                            style={{ maxHeight: "180px" }}
                                                                        />
                                                                        :
                                                                        <>
                                                                            <Col lg={12} className={"text-center"}>
                                                                                <i className="fas fa-times-circle text-danger" style={{ fontSize: "128px" }}></i>
                                                                            </Col>
                                                                            <Col lg={12} className={"text-center mt-2"}>
                                                                                <span className="text-success fw-bold" style={{ fontSize: "18px" }}>WhatsApp</span><span className="text-danger fw-bold" style={{ fontSize: "18px" }}> is not Connected!</span>
                                                                            </Col>
                                                                        </>
                                                            }
                                                        </Col>
                                                    </>
                                            }
                                            <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                            <Col lg={12} className={"text-center mt-4"}>
                                                <div className="flex-wrap gap-2">
                                                    {
                                                        isWhatsAppConnectionStatus
                                                            ?
                                                            isLoadingSendMessage
                                                                ?
                                                                <Col lg={12} className={"text-center"}>
                                                                    <Spinner className="" style={{ height: "24px", width: "24px" }} color="primary" />
                                                                </Col>
                                                                :
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-soft-danger btn-label me-2 btn-sm fw-bold"
                                                                        onClick={async (evt) => {
                                                                            await DeleteSessionASLogoutWhatsApp();
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-sign-out-alt label-icon"></i> Sign Out
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-soft-success btn-label me-2 btn-sm fw-bold"
                                                                        onClick={async (evt) => {
                                                                            await isShowModalisModalKirimPesanTeks();
                                                                        }}
                                                                    >
                                                                        <i className="far fa-paper-plane label-icon"></i> Send Text
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-soft-info btn-label me-2 btn-sm fw-bold"
                                                                        onClick={async (evt) => {
                                                                            await isShowModalisModalKirimPesanTeksIMG();
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-image label-icon"></i> Send Image
                                                                    </button>
                                                                </>
                                                            :
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-soft-primary btn-label me-2 btn-sm fw-bold"
                                                                    onClick={async (evt) => {
                                                                        await FetchQRCodeWhatsAppAuth();
                                                                    }}
                                                                >
                                                                    <i className="fas fa-qrcode label-icon"></i> Request QR Code
                                                                </button>


                                                            </>
                                                    }
                                                </div>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl={7}>
                                    <Card>
                                        <CardBody className="" style={{ height: "360px" }}>
                                            <CardTitle className="text-center">Informasi Akun <span className="text-success">WhatsApp</span></CardTitle>
                                            <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                            <Col md={12}>
                                                <div className="table-responsive">
                                                    <table className="table table-wrap mb-0 table">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row" width="30%">Nama WhatsApp</th>
                                                                <td width="5%">:</td>
                                                                <td><b>
                                                                    {isNamaWA}
                                                                </b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" width="30%">Nomor WhatsApp</th>
                                                                <td width="5%">:</td>
                                                                <td><b>
                                                                    {isNomorWA}
                                                                </b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" width="30%">Perangkat</th>
                                                                <td width="5%">:</td>
                                                                <td><b>
                                                                    {isPerangkatWA}
                                                                </b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" width="30%">ID Perangkat</th>
                                                                <td width="5%">:</td>
                                                                <td><b>
                                                                    {isIDPerangkatWA}
                                                                </b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" width="30%">Sesi Aktif - Kode Cabang</th>
                                                                <td width="5%">:</td>
                                                                <td>{SessionWhatsAppList}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="text-center">Response Server Log</CardTitle>
                                            <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                            <div className="mb-3">
                                                <textarea
                                                    id="Log_Res_WA"
                                                    name="Log_Res_WA"
                                                    className="form-control border-info"
                                                    rows="8"
                                                    placeholder="Log Response from WhatsApp Server . . ."
                                                    autoComplete="off"
                                                    defaultValue={isResultSendTextWA || ""}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>
                        }

                </Container>
            </div>
        </React.Fragment>
    );
};

WhatsAppGateway.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(WhatsAppGateway);
