import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Table,
    Label,
    Input,
    Modal,
    Form,
    UncontrolledTooltip,
    Badge,
} from "reactstrap";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link, useParams } from "react-router-dom";

//Import Image
import logo from "../../../../assets/images/logo-dark.png";
import logoInvoices from "../../../../assets/images/logo-invoice.png";
import logoLight from "../../../../assets/images/logo-light.png";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
    fetchDetailContract,
    updateValidationContract,
    rejectDataContract,
    deleteDataContract,
    validationExtendContract,
    rejectionExtendContract,
    validationPelunasanContract,
    rejectionPelunasanContract,
    fetchDetailTRXPerpanjangKontrak,
    fetchDetailTRXPelunasanKontrak,
    setKontrakGadaiFAILED,
} from "../../../../helpers/backend_helper";
import moment from 'moment';

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

import {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNIKCustomer,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatFieldAction,
    FormatOnlyLoading,
    FormatRAM_Internal,
    FormatJatuhTempo,
    FormatConfirmed,
    FormatRejected,
} from "./isMasterTableCol";
import TableContainer from '../../../../components/Common/TableContainer';

let isMasterDataContract = null;
let isFileKTPPath = null;
let isFileKelengkapanPath = null;
//let isContractNumber = null;
let isRoleAccount = null;

const PawnshopHandphoneDetailTRX = props => {

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    const [isNomorKontrak, setisNomorKontrak] = useState(null);
    let [isSudahCekFISIK, setisSudahCekFISIK] = useState(false);
    let [isBarangAda, setisBarangAda] = useState(null);
    const [isModalConfirmKontrakGagal, setisModalConfirmKontrakGagal] = useState(false);
    const [isModalConfirmKontrak, setisModalConfirmKontrak] = useState(false);
    const [isModalConfirmPerpanjang, setisModalConfirmPerpanjang] = useState(false);
    const [isModalDetilPerpanjang, setisModalDetilPerpanjang] = useState(false);
    const [isModalConfirmPelunasan, setisModalConfirmPelunasan] = useState(false);
    const [isModalDetilPelunasan, setisModalDetilPelunasan] = useState(false);

    const handleGoBack = () => {
        window.history.back(); // Kembali ke halaman sebelumnya
    };

    const paramsID = useParams();

    const dispatch = useDispatch();

    const [isFullDataContract, setisFullDataContract] = useState(null);
    const [isContractNumber, setisContractNumber] = useState(null);


    async function FeedFunctionFullDataContract(isValNoKontrak) {

        isMasterDataContract = null;

        if (isValNoKontrak != null) {
            try {
                isMasterDataContract = await fetchDetailContract({ ContractNumber: isValNoKontrak });

                if (isMasterDataContract === null) {
                    return window.location.href = '/apps/dashboard';
                }
                else {
                    setisFullDataContract(isMasterDataContract);
                }

            }
            catch (Err) {
                return window.location.href = '/apps/dashboard';
            }
        }
    }

    useEffect(() => {
        isMasterDataContract = null;
        isFileKTPPath = null;
        isFileKelengkapanPath = null;
        isRoleAccount = null;

        FeedFunctionFullDataContract(paramsID.ContractNumber);

        setisNomorKontrak(paramsID.ContractNumber);

        autoUpdateProfile();

        setTimeout(async () => {
            {
                setisContractNumber(paramsID.ContractNumber);
            }
        }, 100); //Refresh data setiap 10s

        setInterval(async () => {
            {
                autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s

    }, [dispatch]);

    /*const queryGETParameters = new URLSearchParams(window.location.search)
    const type = queryGETParameters.get("type")*/

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    //Print the Invoice
    const printStruk = () => {
        window.print();
    };

    //Print the Invoice
    const printContract = () => {
        window.print();
    };

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const [photoIndex, setphotoIndex] = useState(0);
    const [isGalleryZoom, setisGalleryZoom] = useState(false);

    const [isFileBuktiPayPerpanjang, isSetFileBuktiPayPerpanjang] = useState(false);
    const [isFileIMGBuktiPayPerpanjang, isSetFileIMGBuktiPayPerpanjang] = useState(null);

    const [isFileBuktiPayPelunasan, isSetFileBuktiPayPelunasan] = useState(false);
    const [isFileIMGBuktiPayPelunasan, isSetFileIMGBuktiPayPelunasan] = useState(null);

    const [isFilePathCaptureKTP, isSetFilePathCaptureKTP] = useState(false);
    const [isFilePathCaptureKelengkapan, isSetFilePathCaptureKelengkapan] = useState(false);

    function getFotoKTPandKelengkapan(isFileName, isType) {
        if (isFileName !== null) {
            try {
                //apply base url for axios
                let API_URL = '';
                let API_IMAGE_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
                const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
                const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
                    }

                }

                if (isType === "KTP") {
                    return "//" + API_URL + "/img-assets/imgKTPNasabah/" + isFileName;
                }
                else {
                    return "//" + API_URL + "/img-assets/imgKelengkapan/" + isFileName;
                }

            }
            catch (Err) {
                //Nothing
                //console.log(Err);
            }
        }
    }

    function getFotoBuktiBayarPerpanjang(isFileName) {
        if (isFileName !== null) {
            try {
                //apply base url for axios
                let API_URL = '';
                let API_IMAGE_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
                const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
                const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
                    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
                    }

                }

                return "//" + API_URL + "/img-assets/imgBuktiPerpanjang/" + isFileName;

            }
            catch (Err) {
                //Nothing
                //console.log(Err);
            }
        }
    }

    function getFotoBuktiBayarPelunasan(isFileName) {
        if (isFileName !== null) {
            try {
                //apply base url for axios
                let API_URL = '';
                let API_IMAGE_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
                const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
                const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
                    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
                    }

                }

                return "//" + API_URL + "/img-assets/imgBuktiPelunasan/" + isFileName;

            }
            catch (Err) {
                //Nothing
                //console.log(Err);
            }
        }
    }

    function isShowModalConfirmKontrakGagal() {
        setisModalConfirmKontrakGagal(!isModalConfirmKontrakGagal);
        removeBodyCss();
    }

    function isShowModalConfirmKontrak() {
        setisBarangAda(null);
        setisSudahCekFISIK(false);
        setisCommandSelected(null);
        setisModalConfirmKontrak(!isModalConfirmKontrak);
        removeBodyCss();
    }

    //const [isIDDetilPerpanjangSelected, setisIDDetilPerpanjangSelected] = useState(null);

    const [isIDPelunasanSelected, setisIDPelunasanSelected] = useState(null);
    const [isCommandPelunasan, setisCommandPelunasan] = useState(null);
    function isShowModalConfirmPelunasan() {
        setisModalConfirmPelunasan(!isModalConfirmPelunasan);
        removeBodyCss();
    }

    function isShowModalDetilPelunasan() {
        setisModalDetilPelunasan(!isModalDetilPelunasan);
        removeBodyCss();
    }

    const [isIDPerpanjangSelected, setisIDPerpanjangSelected] = useState(null);
    const [isCommandPerpanjang, setisCommandPerpanjang] = useState(null);
    function isShowModalConfirmPerpanjang() {
        setisModalConfirmPerpanjang(!isModalConfirmPerpanjang);
        removeBodyCss();
    }
    function isShowModalDetilPerpanjang() {
        setisModalDetilPerpanjang(!isModalDetilPerpanjang);
        removeBodyCss();
    }

    const formPerpanjangVLD = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isFieldDataID: isIDPerpanjangSelected || undefined,
            isFieldCommand: isCommandPerpanjang || undefined,
            isFieldUIDValidator: opUUID || undefined,
        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: async (values) => {
            if (isCommandPerpanjang !== null) {
                if (isCommandPerpanjang === "Validation") {
                    try {
                        await validationExtendContract(values);

                        showToastNotification('Success!', 'Data Transaksi dengan ID #' + isIDPerpanjangSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
                    }
                    catch (Err) {
                        showToastNotification('Oops!', 'Data Transaksi dengan ID #' + isIDPerpanjangSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                    }
                }
                else {
                    try {
                        await rejectionExtendContract(values);

                        showToastNotification('Success!', 'Data Transaksi Perpanjang dengan ID #' + isIDPerpanjangSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil diperbaharui!', 'warning');
                    }
                    catch (Err) {
                        showToastNotification('Oops!', 'Data Transaksi Perpanjang dengan ID #' + isIDPerpanjangSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                    }
                }
            }

            FeedFunctionFullDataContract(paramsID.ContractNumber);

            setisNomorKontrak(paramsID.ContractNumber);

            setTimeout(async () => {
                {
                    setisContractNumber(paramsID.ContractNumber);
                }
            }, 100); //Refresh data setiap 10s
        },
    });

    const formKontrakGagalVLD = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isFieldDataNoKontrak: isNomorKontrak || undefined,
            isFieldUIDValidator: opUUID || undefined,
        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: async (values) => {
            //console.log(values); //setKontrakGadaiFAILED
            try {
                await setKontrakGadaiFAILED(values);

                showToastNotification('INFO!', 'Nomor Kontrak #' + values.isFieldDataNoKontrak + ' dinyatakan GAGAL!', 'info');
            }
            catch (Err) {
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');
            }

            FeedFunctionFullDataContract(paramsID.ContractNumber);

            setisNomorKontrak(paramsID.ContractNumber);

            setTimeout(async () => {
                {
                    setisContractNumber(paramsID.ContractNumber);
                }
            }, 100); //Refresh data setiap 10s
        },
    });

    const formPelunasanVLD = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isFieldDataID: isIDPelunasanSelected || undefined,
            isFieldCommand: isCommandPelunasan || undefined,
            isFieldUIDValidator: opUUID || undefined,
        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: async (values) => {
            if (isCommandPelunasan !== null) {
                if (isCommandPelunasan === "Validation") {
                    try {
                        await validationPelunasanContract(values);

                        showToastNotification('Success!', 'Data Transaksi Pelunasan dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
                    }
                    catch (Err) {
                        showToastNotification('Oops!', 'Data Transaksi Pelunasan dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                    }
                }
                else {
                    try {
                        await rejectionPelunasanContract(values);

                        showToastNotification('Success!', 'Data Transaksi dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil diperbaharui!', 'warning');
                    }
                    catch (Err) {
                        showToastNotification('Oops!', 'Data Transaksi dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                    }
                }
            }

            FeedFunctionFullDataContract(paramsID.ContractNumber);

            setisNomorKontrak(paramsID.ContractNumber);

            setTimeout(async () => {
                {
                    setisContractNumber(paramsID.ContractNumber);
                }
            }, 100); //Refresh data setiap 10s
        },
    });

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "5000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isCommandSelected, setisCommandSelected] = useState(null);
    const [isUUIDUserContract, setisUUIDUserContract] = useState(null);

    const formKontrakValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isValUUIDUserContract: isUUIDUserContract || undefined,
            isValNoKontrak: isNomorKontrak || undefined,
            isCommandSelected: isCommandSelected || undefined,
            isUIDValidator: opUUID || undefined,
            isValSudahCekFisik: isSudahCekFISIK || undefined,
            isValBarangAda: isBarangAda || "Tidak Ada",
            isTanggalUpdateTX: moment.tz('Asia/Jakarta').format("YYYY-MM-DD HH:mm:ss") || undefined,
        },
        validationSchema: Yup.object({
            isValNoKontrak: Yup.string(),
        }),
        onSubmit: async (values) => {
            if (isFullDataContract != null && !isFullDataContract.PGD_isConfirmed && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed) {
                if (isCommandSelected === "Confirm") {
                    await PostUpdateValidasiDataKontrak(values);
                }
                else if (isCommandSelected === "Reject") {
                    await PostRejectDataKontrak(values);
                } else {
                    await PostDeleteDataKontrak(values);
                }

                await FeedFunctionFullDataContract(values.isValNoKontrak);
            }
        },
    }); 

    const [resultDetailTRXPerpanjang, setresultDetailTRXPerpanjang] = useState(null);
    const [resultDetailTRXPelunasan, setresultDetailTRXPelunasan] = useState(null);

    async function FeedDetailTRXPerpanjang(idTransaksi) {
        try {
            let isResultnya = await fetchDetailTRXPerpanjangKontrak({ isIDTrans: idTransaksi });

            setresultDetailTRXPerpanjang(isResultnya);
            //showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            //showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    async function FeedDetailTRXPelunasan(idTransaksi) {
        try {
            let isResultnya = await fetchDetailTRXPelunasanKontrak({ isIDTrans: idTransaksi });

            setresultDetailTRXPelunasan(isResultnya);
            //showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            //showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    async function PostUpdateValidasiDataKontrak(isFormValue) {
        try {
            await updateValidationContract(isFormValue);

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    async function PostRejectDataKontrak(isFormValue) {
        try {
            await rejectDataContract(isFormValue);

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di tolak!', 'info');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di tolak, ada sesuatu yang salah!', 'error');
        }
    }

    async function PostDeleteDataKontrak(isFormValue) {
        try {
            await deleteDataContract(isFormValue);

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di dihapus!', 'info');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di tolak, ada sesuatu yang salah!', 'error');
        }
    }

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isRoleAccount = DecodeUserData.role;

            //isUUIDOperator = DecodeUserData.uuid;
        }
        catch (Err) {
            //Nothing
        }
    }
    
    let summaryReportKontrak = [
        {
            title: "Status Kontrak",
            iconClass: "fas fa-file-signature",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? "Kontrak Dihapus" : isFullDataContract.PGD_KontrakFailed ? "Kontrak Gagal" : isFullDataContract.PGD_isRejected ? "Kontrak Ditolak" : !isFullDataContract.PGD_isConfirmed && !isFullDataContract.PGD_Lunas ? "Menunggu Validasi" : isFullDataContract.PGD_Lunas ? "Lunas" : !isFullDataContract.PGD_Lunas && isFullDataContract.PGD_JatuhTempo ? "Jatuh Tempo" : "Sedang Berjalan" : null,
            value_color: isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? "Kontrak Dihapus" : isFullDataContract.PGD_KontrakFailed ? "text-black" : isFullDataContract.PGD_isRejected ? "text-danger" : !isFullDataContract.PGD_isConfirmed && !isFullDataContract.PGD_Lunas ? "text-info" : isFullDataContract.PGD_Lunas ? "text-success" : !isFullDataContract.PGD_Lunas && isFullDataContract.PGD_JatuhTempo ? "text-warning" : "text-primary" : null,
        },
        {
            title: "Total Perpanjangan",
            iconClass: "fas fa-file-signature",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? isFullDataContract.PGD_TotalPerpanjang : "-",
            value_color: "text-success",
        },
        {
            title: "Total / Hari - Keterlambatan",
            iconClass: "fas fa-file-signature",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? isFullDataContract.PGD_JumlahHariKeterlambatan === -1 ? isFullDataContract.PGD_JumlahKeterlambatan + " / " + "-" : isFullDataContract.PGD_JumlahKeterlambatan + " / " + isFullDataContract.PGD_JumlahHariKeterlambatan + " Hari" : "- / -",
            value_color: "text-info",
        },
        {
            title: "Biaya Perpanjangan",
            iconClass: "fas fa-file-signature",
            bgColor: "danger",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_BiayaPerpanjang) : "Rp. -",
            value_color: "text-danger",
        },
        {
            title: "Total Kontrak Nasabah",
            iconClass: "fas fa-file-signature",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? isFullDataContract.isCustomer.TotalKontrak : "-",
            value_color: "text-primary",
        },
        {
            title: "Tgl. Terakhir Perpanjangan",
            iconClass: "fas fa-file-signature",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? moment(isFullDataContract.PGD_TanggalTerakhirPerpanjang).tz('Asia/Jakarta').format("DD MMM YYYY, HH:mm:ss") : "-",
            value_color: "text-success",
        },
        {
            title: "Nominal Tebus",
            iconClass: "fas fa-file-signature",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_NominalTebus) : "Rp. -",
            value_color: "text-info",
        },
        {
            title: "Biaya Jasa Titip",
            iconClass: "fas fa-file-signature",
            bgColor: "danger",
            textColor: "text-white",
            description: "-",
            value: isFullDataContract != null ? CurrencyFormatter.format((isFullDataContract.PGDRincian_Nominal) * (isFullDataContract.PGD_PercentBiaya /100)) : "Rp. -",
            value_color: "text-danger",
        },
    ];

    const isColumnsData = useMemo(
        () => [
            {
                Header: "Tgl. Jt. Tempo",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PPGD_JTempoTRX",
                disableFilters: true,
                filterable: true,
                sortable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Perpanjang",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PPGD_TglPembayaran",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Termin",
                accessor: "PPGD_MetodePembayaran",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatMetodeTransaksi {...cellProps} />;
                },
            },
            {
                Header: "Bank Nasabah",
                accessor: "PPGD_RekAsal_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Bank Pembayaran",
                accessor: "PPGD_RekTujuan_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nominal",
                accessor: "PPGD_NominalPerpanjang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            {
                Header: "Operator",
                accessor: "isExtendOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Keterlambatan",
                accessor: "PPGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatJatuhTempo {...cellProps} />;
                },
            },
            {
                Header: "St. Validasi",
                accessor: "PPGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatConfirmed {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                isSorted: 'asc',
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                {
                                    cellProps.cell.row.allCells[8].value == null && isRoleAccount !== 'Operator'
                                        ?
                                        <li>
                                            <Link
                                                to="#"
                                                className={cellProps.cell.row.allCells[8].value == null ? "btn btn-sm btn-success" : "btn btn-sm btn-success disabled"}
                                                onClick={(evt) => {
                                                    setisIDPerpanjangSelected(cellProps.cell.row.allCells[9].value);
                                                    setisCommandPerpanjang("Validation");
                                                    isShowModalConfirmPerpanjang();
                                                }}
                                            >
                                                <i className="mdi mdi-check-outline" id="confirmtooltip" />
                                                <UncontrolledTooltip placement="top" target="confirmtooltip">
                                                    Validasi Transaksi
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                        :
                                    null
                                }

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-info"
                                        onClick={async (evt)  => {

                                            await FeedDetailTRXPerpanjang(cellProps.cell.row.allCells[9].value);
                                            
                                            setisIDPerpanjangSelected(cellProps.cell.row.allCells[9].value);
                                            isShowModalDetilPerpanjang();
                                        }}
                                    >
                                        <i className="mdi mdi-file-document-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Transaksi
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const isColumnsDataPelunasan = useMemo(
        () => [
            {
                Header: "Tgl. Pelunasan",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PLPGD_TglPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Termin",
                accessor: "PLPGD_MetodePembayaran",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatMetodeTransaksi {...cellProps} />;
                },
            },
            {
                Header: "Bank Nasabah",
                accessor: "PLPGD_RekAsal_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Bank Pembayaran",
                accessor: "PLPGD_RekTujuan_Bank",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nominal Tebus",
                accessor: "PLPGD_NominalPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            {
                Header: "Operator",
                accessor: "isPelunasanOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            /*{
                Header: "Keterlambatan",
                accessor: "PPGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatJatuhTempo {...cellProps} />;
                },
            },*/
            {
                Header: "St. Validasi",
                accessor: "PLPGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatConfirmed {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                isSorted: 'asc',
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                {
                                    cellProps.cell.row.allCells[6].value == null && isRoleAccount !== 'Operator'
                                        ?
                                    <><li>
                                    <Link
                                        to="#"
                                        className={cellProps.cell.row.allCells[6].value == null ? "btn btn-sm btn-success" : "btn btn-sm btn-success disabled"}
                                        onClick={(evt) => {
                                            setisIDPelunasanSelected(cellProps.cell.row.allCells[7].value);
                                            setisCommandPelunasan("Validation");
                                            isShowModalConfirmPelunasan();
                                        }}
                                    >
                                        <i className="mdi mdi-check-outline" id="confirmtooltip" />
                                        <UncontrolledTooltip placement="top" target="confirmtooltip">
                                            Validasi Transaksi
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                                </>
                                        :
                                null

                                }

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-info"
                                        onClick={async (evt) => {
                                            await FeedDetailTRXPelunasan(cellProps.cell.row.allCells[7].value);

                                            setisIDPelunasanSelected(cellProps.cell.row.allCells[7].value);
                                            isShowModalDetilPelunasan();
                                        }}
                                    >
                                        <i className="mdi mdi-file-document-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Transaksi
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const printOutContractFaktur = () => {
        let printWindow = "";

        //apply base url for axios
        let API_URL = '';

        //Using Global Domain Server
        if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
            API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN;
        }
        else {
            //127.0.0.1
            if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                if (window.location.hostname == process.env.REACT_APP_IP_ROOT) {
                    API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
                else {
                    API_URL = "//" + process.env.REACT_APP_DOMAIN_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
            }
            //Local IP 192.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + process.env.REACT_APP_FE_PORT_LOCAL;
            }
            //Public IP X.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + process.env.REACT_APP_FE_PORT_INTERNET;
            }

        }

        printWindow = window.open("//" + API_URL + "/apps/pawnshop/print/handphone/" + isNomorKontrak, "popup", "width=" + 1024 + ",height=" + 640);

        //printWindow.document.write(printableContent.innerHTML);
        //printWindow.print();
        //console.log("Test");
    };

    const printOutContractFakturFAKE = () => {
        let printWindow = "";

        //apply base url for axios
        let API_URL = '';

        //Using Global Domain Server
        if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
            API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN;
        }
        else {
            //127.0.0.1
            if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                if (window.location.hostname == process.env.REACT_APP_IP_ROOT) {
                    API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
                else {
                    API_URL = "//" + process.env.REACT_APP_DOMAIN_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
            }
            //Local IP 192.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + process.env.REACT_APP_FE_PORT_LOCAL;
            }
            //Public IP X.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + process.env.REACT_APP_FE_PORT_INTERNET;
            }

        }

        printWindow = window.open("//" + API_URL + "/apps/pawnshop/print-dummy/handphone/" + isNomorKontrak, "popup", "width=" + 1024 + ",height=" + 640);

        //printWindow.document.write(printableContent.innerHTML);
        //printWindow.print();
        //console.log("Test");
    };

    //meta title
    document.title = "Detil Informasi Kontrak #" + paramsID.ContractNumber + " - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
                      title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Detil Informasi Kontrak #" + paramsID.ContractNumber )}
                  />

                  {/*IS MODAL FOR VALIDASI KEGAGALAN KONTRAK*/}
                  {
                      isFullDataContract != null ? role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                          ?
                          <Modal
                              isOpen={isModalConfirmKontrakGagal}
                              toggle={() => {
                                  isShowModalConfirmKontrakGagal();
                              }}
                              backdrop={'static'}
                              id="modalConfirmKontrakGagal"
                              className={'modal-dialog-centered d-print-none'}
                          >
                              <div className="modal-header bg-white bg-opacity-25 ">
                                  <h5 className="modal-title text-danger" id="modalConfirmKontrakGagalLabel"><i className=" mdi mdi-information-outline me-2"></i>NYATAKAN SEBAGAI KONTRAK GAGAL</h5>

                                  <button type="button" className="btn-close"
                                      onClick={() => {
                                          setisModalConfirmKontrakGagal(false);
                                      }} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <p className="font-size-13 text-justify">Proses ini <b className="fw-bold text-danger">TIDAK DAPAT DIBATALKAN</b>, pastikan Anda sudah yakin bahwa kontrak <b>#{isNomorKontrak}</b> dinyatakan sebagai kontrak yang gagal, dan jaminan akan dijadikan barang lelang.</p>
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-info" onClick={() => {
                                      setisModalConfirmKontrakGagal(false);
                                  }}>Tutup</button>
                                  <Form
                                      onSubmit={(e) => {
                                          e.preventDefault();
                                          formKontrakGagalVLD.handleSubmit();
                                          setisModalConfirmKontrakGagal(false);
                                          return false;
                                      }}
                                  >
                                      <button type="submit" className="btn btn-danger" >Konfirmasi</button>
                                  </Form>
                              </div>
                          </Modal>
                          :
                          null
                          :
                          null
                  }

                  {/*IS MODAL FOR VALIDASI PERPANJANG KONTRAK*/}
                  {
                      isFullDataContract != null ? role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                          ?
                          <Modal
                              isOpen={isModalConfirmPerpanjang}
                              toggle={() => {
                                  isShowModalConfirmPerpanjang();
                              }}
                              backdrop={'static'}
                              id="modalConfirmPerpanjang"
                              className={'modal-dialog-centered d-print-none'}
                          >
                              <div className="modal-header bg-white bg-opacity-25 ">
                                  {
                                      isCommandPerpanjang === "Validation"
                                          ?
                                          <h5 className="modal-title text-success" id="modalConfirmPerpanjangLabel"><i className=" mdi mdi-information-outline me-2"></i>VALIDASI PERPANJANGAN KONTRAK #{isIDPerpanjangSelected}</h5>
                                          :
                                          <h5 className="modal-title text-danger" id="modalConfirmPerpanjangLabel"><i className=" mdi mdi-alert-outline me-2"></i>TOLAK PERPANJANGAN KONTRAK #{isIDPerpanjangSelected}</h5>
                                  }

                                  <button type="button" className="btn-close"
                                      onClick={() => {
                                          setisModalConfirmPerpanjang(false);
                                      }} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <p className="font-size-13 text-justify">Proses validasi transaksi <b className="text-warning">TIDAK DAPAT DIBATALKAN</b>, pastikan Anda sudah melakukan pengecekan transaksi sebelum melanjutkan ke proses validasi.</p>
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-info" onClick={() => {
                                      setisModalConfirmPerpanjang(false);
                                  }}>Tutup</button>
                                  <Form
                                      onSubmit={(e) => {
                                          e.preventDefault();
                                          formPerpanjangVLD.handleSubmit();
                                          setisModalConfirmPerpanjang(false);
                                          return false;
                                      }}
                                  >
                                      <button type="submit" className="btn btn-success" >Konfirmasi</button>
                                  </Form>

                                  <Form
                                      onSubmit={(e) => {
                                          setisCommandPerpanjang("Reject");

                                          e.preventDefault();
                                          formPerpanjangVLD.handleSubmit();
                                          setisModalConfirmPerpanjang(false);
                                          return false;
                                      }}
                                  >
                                      <button type="submit" className="btn btn-danger">Tolak</button>
                                  </Form>
                              </div>
                          </Modal>
                          :
                          null
                          :
                          null
                  }
                  

                  {/*IS MODAL FOR DETIL PERPANJANG KONTRAK*/}
                  <Modal
                      isOpen={isModalDetilPerpanjang}
                      toggle={() => {
                          isShowModalDetilPerpanjang();
                      }}
                      backdrop={'static'}
                      id="modalDetilPerpanjang"
                      className={'modal-dialog-centered modal-xl d-print-none'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalDetilPerpanjangLabel"><i className=" mdi mdi-information-outline me-2"></i>DETIL TRANSAKSI PERPANJANG #{isIDPerpanjangSelected}</h5>

                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalDetilPerpanjang(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <Row>
                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Kontrak</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <Badge className="bg-info font-size-12"><span># {resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_NoKontrak : "NaN"} </span></Badge>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Tgl. Jt. Tempo</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? moment(resultDetailTRXPerpanjang.PPGD_JTempoTRX).tz('Asia/Jakarta').format('D MMMM YYYY, HH:mm:ss') : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Metode Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {
                                              resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_MetodePembayaran === "Transfer Bank" ? <Badge className="badge-soft-success font-size-11"><span>Transfer Bank</span></Badge> : resultDetailTRXPerpanjang.PPGD_MetodePembayaran === "E-Wallet" ? <Badge className="badge-soft-primary font-size-11"><span>E-Wallet</span></Badge> : <Badge className="badge-soft-danger font-size-11"><span>Tunai</span></Badge> : "NaN"
                                          }
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Tgl. Perpanjang</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? moment(resultDetailTRXPerpanjang.PPGD_TglPembayaran).tz('Asia/Jakarta').format('D MMMM YYYY, HH:mm:ss') : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekAsal_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekTujuan_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekAsal_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekTujuan_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekAsal_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_RekTujuan_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Nominal Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span className="text-primary font-size-13">{resultDetailTRXPerpanjang !== null ? CurrencyFormatter.format(resultDetailTRXPerpanjang.PPGD_NominalPerpanjang) : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Keterlambatan</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_JumlahHariKeterlambatan > 0 || resultDetailTRXPerpanjang.PPGD_JumlahHariKeterlambatan === 0 ? <Badge className="bg-danger font-size-12"><span>{resultDetailTRXPerpanjang.PPGD_JumlahHariKeterlambatan + " Hari"}</span></Badge> : resultDetailTRXPerpanjang.PPGD_JumlahHariKeterlambatan === -1 ? <Badge className="badge-soft-success font-size-12"><span>-</span></Badge> : "NaN" : "NaN"}
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Status Validasi</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_isConfirmed === null ? <Badge className="badge-soft-info font-size-11"><span>Belum Validasi</span></Badge> : resultDetailTRXPerpanjang.PPGD_isConfirmed ? <Badge className="bg-success font-size-11"><span>Diterima</span></Badge> : <Badge className="bg-danger font-size-11"><span>Tidak Valid</span></Badge> : "NaN"}
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Operator</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.isExtendOperator.fullName !== null ? resultDetailTRXPerpanjang.isExtendOperator.fullName : "NaN" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Validasi Oleh</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.isExtendOwner !== null ? resultDetailTRXPerpanjang.isExtendOwner.fullName : "-" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bukti Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      {
                                          resultDetailTRXPerpanjang !== null
                                              ?
                                              resultDetailTRXPerpanjang.PPGD_BuktiPembayaran !== null
                                                  ?
                                                  <><Col sm={6}>
                                                      <Link onClick={() => {
                                                          isSetFileIMGBuktiPayPerpanjang(resultDetailTRXPerpanjang.PPGD_BuktiPembayaran);
                                                          isSetFileBuktiPayPerpanjang(true);

                                                      }}>Lihat Bukti Foto</Link>
                                                  </Col>
                                                  </>
                                                  :
                                                  <>
                                                  <Col sm={6}>
                                                     <span>Tidak Ada</span>
                                                  </Col>
                                                  </>
                                              :
                                              null
                                      }
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              {isFileBuktiPayPerpanjang ? (
                                  <Lightbox
                                      mainSrc={getFotoBuktiBayarPerpanjang(resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_BuktiPembayaran : null)}
                                      enableZoom={true}
                                      imageCaption={"Foto Bukti Pembayaran Perpanjang Kontrak"}
                                      onCloseRequest={() => {
                                          isSetFileBuktiPayPerpanjang(false);
                                      }}
                                  />
                              )
                                  : null}
                          </Row>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-info" onClick={() => {
                              setisModalDetilPerpanjang(false);
                          }}>Tutup</button>
                          {resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_isConfirmed === null && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPerpanjang("Validation");

                                      e.preventDefault();
                                      formPerpanjangVLD.handleSubmit();
                                      setisModalDetilPerpanjang(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-success" >Konfirmasi</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }

                          {resultDetailTRXPerpanjang !== null ? resultDetailTRXPerpanjang.PPGD_isConfirmed === null && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPerpanjang("Reject");

                                      e.preventDefault();
                                      formPerpanjangVLD.handleSubmit();
                                      setisModalDetilPerpanjang(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-danger" >Tolak</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }
                      </div>
                  </Modal>

                  {/*IS MODAL FOR VALIDASI PELUNASAN KONTRAK*/}
                  {
                      isFullDataContract != null ? role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                          ?
                          <Modal
                              isOpen={isModalConfirmPelunasan}
                              toggle={() => {
                                  isShowModalConfirmPelunasan();
                              }}
                              backdrop={'static'}
                              id="modalConfirmPelunasan"
                              className={'modal-dialog-centered d-print-none'}
                          >
                              <div className="modal-header bg-white bg-opacity-25 ">
                                  {
                                      isCommandPelunasan === "Validation"
                                          ?
                                          <h5 className="modal-title text-success" id="modalConfirmPelunasanLabel"><i className=" mdi mdi-information-outline me-2"></i>VALIDASI PELUNASAN KONTRAK #{isIDPelunasanSelected}</h5>
                                          :
                                          <h5 className="modal-title text-danger" id="modalConfirmPelunasanLabel"><i className=" mdi mdi-alert-outline me-2"></i> TOLAK PELUNASAN KONTRAK #{isIDPelunasanSelected}</h5>
                                  }

                                  <button type="button" className="btn-close"
                                      onClick={() => {
                                          setisModalConfirmPelunasan(false);
                                      }} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <p className="font-size-13 text-justify">Proses validasi transaksi <b className="text-warning">TIDAK DAPAT DIBATALKAN</b>, pastikan Anda sudah melakukan pengecekan transaksi sebelum melanjutkan ke proses validasi.</p>
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-info" onClick={() => {
                                      setisModalConfirmPelunasan(false);
                                  }}>Tutup</button>
                                  <Form
                                      onSubmit={(e) => {
                                          setisCommandPelunasan("Validation");

                                          e.preventDefault();
                                          formPelunasanVLD.handleSubmit();
                                          setisModalConfirmPelunasan(false);
                                          return false;
                                      }}
                                  >
                                      <button type="submit" className="btn btn-success" >Konfirmasi</button>
                                  </Form>

                                  <Form
                                      onSubmit={(e) => {
                                          setisCommandPelunasan("Reject");

                                          e.preventDefault();
                                          formPelunasanVLD.handleSubmit();
                                          setisModalConfirmPelunasan(false);
                                          return false;
                                      }}
                                  >
                                      <button type="submit" className="btn btn-danger" >Tolak</button>
                                  </Form>
                              </div>
                          </Modal>
                          :
                          null
                          :
                          null
                  }
                  
                  {/*IS MODAL FOR DETIL PELUNASAN KONTRAK*/}
                  <Modal
                      isOpen={isModalDetilPelunasan}
                      toggle={() => {
                          isShowModalDetilPelunasan();
                      }}
                      backdrop={'static'}
                      id="modalDetilPelunasan"
                      className={'modal-dialog-centered modal-xl d-print-none'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalDetilPelunasanLabel"><i className=" mdi mdi-information-outline me-2"></i>DETIL TRANSAKSI PELUNASAN #{isIDPelunasanSelected}</h5>

                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalDetilPelunasan(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <Row>
                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Kontrak</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <Badge className="bg-info font-size-12"><span># {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_NoKontrak : "NaN"} </span></Badge>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Metode Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {
                                              resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_MetodePembayaran === "Transfer Bank" ? <Badge className="badge-soft-success font-size-11"><span>Transfer Bank</span></Badge> : resultDetailTRXPelunasan.PLPGD_MetodePembayaran === "E-Wallet" ? <Badge className="badge-soft-primary font-size-11"><span>E-Wallet</span></Badge> : <Badge className="badge-soft-danger font-size-11"><span>Tunai</span></Badge> : "NaN"
                                          }
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Tgl. Pelunasan</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? moment(resultDetailTRXPelunasan.PLPGD_TglPelunasan).tz('Asia/Jakarta').format('D MMMM YYYY, HH:mm:ss') : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Status Validasi</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null ? <Badge className="badge-soft-info font-size-11"><span>Belum Validasi</span></Badge> : resultDetailTRXPelunasan.PLPGD_isConfirmed ? <Badge className="bg-success font-size-11"><span>Diterima</span></Badge> : <Badge className="bg-danger font-size-11"><span>Tidak Valid</span></Badge> : "NaN"}
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Nominal Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span className="text-primary font-size-13">{resultDetailTRXPelunasan !== null ? CurrencyFormatter.format(resultDetailTRXPelunasan.PLPGD_NominalPelunasan) : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Operator</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.isPelunasanOperator.fullName !== null ? resultDetailTRXPelunasan.isPelunasanOperator.fullName : "NaN" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Validasi Oleh</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.isPelunasanOwner !== null ? resultDetailTRXPelunasan.isPelunasanOwner.fullName : "-" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bukti Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      {
                                          resultDetailTRXPelunasan !== null
                                              ?
                                              resultDetailTRXPelunasan.PLPGD_BuktiPembayaran !== null
                                                  ?
                                                  <><Col sm={6}>
                                                      <Link onClick={() => {
                                                          isSetFileIMGBuktiPayPelunasan(resultDetailTRXPelunasan.PLPGD_BuktiPembayaran);
                                                          isSetFileBuktiPayPelunasan(true);

                                                      }}>Lihat Bukti Foto</Link>
                                                  </Col>
                                                  </>
                                                  :
                                                  <>
                                                      <Col sm={6}>
                                                          <span>Tidak Ada</span>
                                                      </Col>
                                                  </>
                                              :
                                              null
                                      }
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              {isFileBuktiPayPelunasan ? (
                                  <Lightbox
                                      mainSrc={getFotoBuktiBayarPelunasan(resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_BuktiPembayaran : null)}
                                      enableZoom={true}
                                      imageCaption={"Foto Bukti Pembayaran Pelunasan Kontrak"}
                                      onCloseRequest={() => {
                                          isSetFileBuktiPayPelunasan(false);
                                      }}
                                  />
                              )
                                  : null}
                          </Row>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-info" onClick={() => {
                              setisModalDetilPelunasan(false);
                          }}>Tutup</button>
                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPelunasan("Validation");

                                      e.preventDefault();
                                      formPelunasanVLD.handleSubmit();
                                      setisModalDetilPelunasan(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-success" >Konfirmasi</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }

                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPelunasan("Reject");

                                      e.preventDefault();
                                      formPelunasanVLD.handleSubmit();
                                      setisModalDetilPelunasan(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-danger" >Tolak</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }
                      </div>
                  </Modal>

                  {/*IS MODAL FOR CONFIRM DATA*/}
                  {
                      isFullDataContract != null ? !isFullDataContract.PGD_isConfirmed && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas ?
                          <Modal
                              isOpen={isModalConfirmKontrak}
                              toggle={() => {
                                  isShowModalConfirmKontrak();
                              }}
                              backdrop={'static'}
                              id="modalConfirmKontrak"
                              className={'modal-dialog-centered d-print-none'}
                          >
                              <div className="modal-header bg-white bg-opacity-25 ">
                                  <h5 className="modal-title text-success" id="modalConfirmKontrakLabel"><i className=" mdi mdi-information-outline me-2"></i>VALIDASI DATA KONTRAK</h5>
                                  <button type="button" className="btn-close"
                                      onClick={() => {
                                          setisModalConfirmKontrak(false);
                                      }} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <p className="pb-1">Nomor Kontrak : <b>#{isNomorKontrak}</b></p>
                                  <p className="font-size-13 text-justify">Proses validasi data kontrak ini <b className="text-warning">TIDAK DAPAT DIBATALKAN</b>, apakah anda yakin ingin memvalidasi data kontrak ini, dan pastikan Anda sudah melakukan pengecekan data dan fisik barang jaminan.</p>

                                  <p>Harap Ceklist pertanyaan <b className="text-danger">Apakah Barang Ada ?</b> jika barang ada atau tersedia.</p>
                                  <br />

                                  <Col sm={12}>
                                      <div className="form-check form-checkbox form-check-info text-right">

                                          <Row >
                                              <Col sm={6}>
                                                  <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id="isValSudahCekFisik"
                                                      name="isValSudahCekFisik"
                                                      onChange={(evt) => {
                                                          setisSudahCekFISIK(evt.target.checked)
                                                      }}
                                                  />
                                                  <Label
                                                      className="form-check-label text-info"
                                                      htmlFor="isValSudahCekFisik"
                                                  >
                                                      Sudah Cek Fisik ?
                                                  </Label>
                                              </Col>

                                              <Col sm={6}>
                                                  <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id="isValBarangAda"
                                                      name="isValBarangAda"
                                                      onChange={(evt) => {
                                                          if (evt.target.checked) {
                                                              setisBarangAda("Ada");
                                                          }
                                                      }}
                                                  />
                                                  <Label
                                                      className="form-check-label text-danger"
                                                      htmlFor="isValBarangAda"
                                                  >
                                                      Apakah Barang <b>Ada</b> ?
                                                  </Label>
                                              </Col>
                                          </Row>

                                      </div>
                                  </Col>

                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-info" onClick={() => {
                                      setisModalConfirmKontrak(false);
                                  }}>Tutup</button>
                                  <Form
                                      onSubmit={(e) => {
                                          e.preventDefault();
                                          formKontrakValidation.handleSubmit();

                                          setisModalConfirmKontrak(false);
                                          setisSudahCekFISIK(false);
                                          setisBarangAda(null);

                                          setisCommandSelected("Confirm");

                                          return false;
                                      }}
                                  >
                                      <Input
                                          name="isValNoKontrak"
                                          type="hidden"
                                          onChange={formKontrakValidation.handleChange}
                                          onBlur={formKontrakValidation.handleBlur}
                                          value={formKontrakValidation.values.isValNoKontrak || ""}
                                      />
                                      <button type="submit" className="btn btn-success" disabled={!isSudahCekFISIK ? true : false}>Konfirmasi</button>
                                  </Form>

                                  <Form
                                      onSubmit={(e) => {
                                          e.preventDefault();
                                          formKontrakValidation.handleSubmit();

                                          setisModalConfirmKontrak(false);
                                          setisSudahCekFISIK(false);
                                          setisBarangAda(null);

                                          setisCommandSelected("Reject");

                                          return false;
                                      }}
                                  >
                                      <Input
                                          name="isValNoKontrak"
                                          type="hidden"
                                          onChange={formKontrakValidation.handleChange}
                                          onBlur={formKontrakValidation.handleBlur}
                                          value={formKontrakValidation.values.isValNoKontrak || ""}
                                      />
                                      <button type="submit" className="btn btn-danger">Tolak</button>
                                  </Form>

                                  <Form
                                      onSubmit={(e) => {
                                          e.preventDefault();
                                          formKontrakValidation.handleSubmit();

                                          setisModalConfirmKontrak(false);
                                          setisSudahCekFISIK(false);
                                          setisBarangAda(null);

                                          setisCommandSelected("Delete");

                                          return false;
                                      }}
                                  >
                                      <Input
                                          name="isValNoKontrak"
                                          type="hidden"
                                          onChange={formKontrakValidation.handleChange}
                                          onBlur={formKontrakValidation.handleBlur}
                                          value={formKontrakValidation.values.isValNoKontrak || ""}
                                      />
                                      <button type="submit" className="btn btn-danger">Hapus Kontrak</button>
                                  </Form>
                              </div>
                          </Modal>
                          :
                          null
                          :
                          null
                  }
                  

                  <Row>
                      <Col lg={12} className="p-2 d-print-none">
                          <Card>
                              <CardBody>
                              <Col sm={2}>
                                      <Button
                                          className="btn btn-info"
                                          onClick={handleGoBack}
                                      >
                                        <i className={"fas fa-arrow-left me-2"}></i> Kembali
                                      </Button>
                                  </Col>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>


                  <Row>
                      <Col lg="12" className="p-2 d-print-none">
                          <Row>
                              {/* Reports Render */}
                              {summaryReportKontrak.map((report, key) => (
                                  <Col md="3" key={"_col_" + key}>
                                      <Card className="mini-stats-wid">
                                          <CardBody>
                                              <div className="d-flex">
                                                  <div className="flex-grow-1">
                                                      {
                                                          report.description == "NaN"
                                                              ?
                                                              <p className=
                                                                  {
                                                                      report.description == "NaN"
                                                                          ? "text-black fw-medium placeholder-glow"
                                                                          : "text-black fw-medium"
                                                                  }
                                                              >
                                                                  {
                                                                      report.description == "NaN"
                                                                          ? <span className="text-black fw-medium placeholder col-8">report.title</span>
                                                                          : report.title
                                                                  }
                                                              </p>
                                                              :
                                                              <p className="text-black fw-medium">
                                                                  {report.title}
                                                              </p>
                                                      }

                                                      {
                                                          report.description == "NaN"
                                                              ?
                                                              <span className=
                                                                  {
                                                                      report.description == "NaN"
                                                                          ? "mb-0 placeholder-glow"
                                                                          : "mb-0"
                                                                  }
                                                              >
                                                                  {
                                                                      report.description == "NaN"
                                                                          ? <span className="mb-0 placeholder col-6">report.description</span>
                                                                          : report.description
                                                                  }
                                                              </span>
                                                              :
                                                              <>
                                                                  <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                              </>
                                                      }

                                                  </div>
                                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                      <span className={
                                                          "avatar-title bg-" +
                                                          report.bgColor +
                                                          " " +
                                                          report.textColor
                                                      }
                                                      >
                                                          <i
                                                              className={
                                                                  " " + report.iconClass + " font-size-20"
                                                              }
                                                          ></i>
                                                      </span>
                                                  </div>

                                              </div>
                                          </CardBody>
                                      </Card>
                                  </Col>
                              ))}
                          </Row>
                      </Col>

                      <Col lg={12} className="p-2">
                          <Card style={{ boxShadow: 'none' }}>
                              <CardBody className="">
                                  <div className="invoice-title">
                                      <h4 className="float-end fw-bold font-size-16">
                                          <strong>#{isContractNumber || "NaN"}</strong>
                                      </h4>
                                      <div className="mb-4">
                                          <img src={logoInvoices} alt="logo-dark" className="logo-dark-element" height="24" />
                                          <img src={logoLight} alt="logo-light" className="logo-light-element" height="24" />
                                      </div>
                                  </div>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />

                                  <Row>
                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Nama Nasabah</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isCustomer.NamaLengkap : "Please wait..."}</span>
                                                  <span className="d-print-none"> ({isFullDataContract != null ? isFullDataContract.isCustomer.NamaPanggilan : "Please wait..."})</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Tgl. Transaksi</span> <span className="fw-bold d-print-none"> Terakhir</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span className="fw-bold">{isFullDataContract != null ? moment(isFullDataContract.PGD_TanggalTerakhirTRX || isFullDataContract.PGDRincian_WaktuTRX).tz('Asia/Jakarta').format('D MMMM YYYY') : "Please wait..."}</span>
                                                  <span className="fw-bold d-print-none">{isFullDataContract != null ? moment(isFullDataContract.PGD_TanggalTerakhirTRX || isFullDataContract.PGDRincian_WaktuTRX).tz('Asia/Jakarta').format(', HH:mm:ss') : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">NIK / SIM</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isCustomer.NIKPelanggan : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Tgl. Jt. Tempo</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span className="fw-bold">{isFullDataContract != null ? isFullDataContract.PGD_Lunas || isFullDataContract.PGD_KontrakFailed ? "-" : moment(isFullDataContract.PGDRincian_JTempoTRX).tz('Asia/Jakarta').format('D MMMM YYYY') : "Please wait..."}</span>
                                                  <span className="fw-bold d-print-none">{isFullDataContract != null ? isFullDataContract.PGD_Lunas || isFullDataContract.PGD_KontrakFailed ? null : moment(isFullDataContract.PGDRincian_JTempoTRX).tz('Asia/Jakarta').format(', HH:mm:ss') : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">No. Telepon</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isCustomer.NoHP : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Operator</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isOperator.fullName : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Termin</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.PGDRincian_Bank : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <span className="fw-bold">Cabang</span>
                                              </Col>
                                              <Col sm={1}>
                                                  <span className="fw-bold">:</span>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isCabang.CabangName : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>

                                      {/*<Col sm={6} className={"pb-2"}>
                                          <Row>
                                              <Col sm={5}>
                                                  <strong>Alamat</strong>
                                              </Col>
                                              <Col sm={1}>
                                                  <strong>:</strong>
                                              </Col>
                                              <Col sm={6}>
                                                  <span>{isFullDataContract != null ? isFullDataContract.isCustomer.Alamat : "Please wait..."}</span>
                                              </Col>
                                          </Row>
                                      </Col>*/}
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />

                                  <Row>
                                      <Col md={12}>
                                          <div className="py-2 mt-3">
                                              <h4 className="fw-bold"><u>INFORMASI PERANGKAT</u></h4>
                                          </div>
                                          <div className="table-responsive">
                                              <Table className="table-nowrap">
                                                  <thead>
                                                      <tr>
                                                          <th>Nama Perangkat</th>
                                                          {/*<th className="d-print-none">Jenis</th>*/}
                                                          <th>R A M</th>
                                                          <th>R O M</th>
                                                          <th>IMEI.1</th>
                                                          <th>IMEI.2</th>
                                                          <th className="d-print-none">Jen. Sandi</th>
                                                          <th className="d-print-none">Sandi</th>
                                                          {/*<th className="text-end">Nominal</th>*/}
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_Tipe : "Please wait..."}</td>
                                                          {/*<td className="d-print-none">{isFullDataContract != null ? isFullDataContract.PGDJaminan_Jenis : "Please wait..."}</td>*/}
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_RAM + " GB" : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_Internal + " GB" : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_IMEI1 : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_IMEI2 === null ? "-" : isFullDataContract.PGDJaminan_IMEI2 : "Please wait..."}</td>
                                                          <td className="d-print-none">{isFullDataContract != null ? isFullDataContract.PGDJaminan_JenisKatasandi : "Please wait..."}</td>
                                                          <td className="d-print-none">{isFullDataContract != null ? isFullDataContract.PGDJaminan_Katasandi : "Please wait..."}</td>
                                                          {/*<td className="text-end">{isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal) : "Please wait..."}</td>*/}
                                                      </tr>
                                                      {/*<tr>
                                                          <td colSpan="5" className="text-end">
                                                              Sub Total
                                                          </td>
                                                          <td className="text-end">
                                                              {CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal)}
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td colSpan="5" className="border-0 text-end">
                                                              <strong>Total</strong>
                                                          </td>
                                                          <td className="border-0 text-end">
                                                              <h4 className="m-0">
                                                                  {CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal)}
                                                              </h4>
                                                          </td>
                                                      </tr>*/}
                                                  </tbody>
                                              </Table>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col md={12}>
                                          <div className="py-2 mt-3">
                                              <h4 className=" fw-bold"><u>INFORMASI PENERIMA DANA</u></h4>
                                          </div>
                                          <div className="table-responsive">
                                              <Table className="table-nowrap">
                                                  <thead>
                                                      <tr>
                                                          <th>Nama Bank / eWallet</th>
                                                          <th>Nama Penerima</th>
                                                          <th>No. Rekening</th>
                                                          <th>Metode</th>
                                                          <th>Nom. Diterima</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDRincian_Bank : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDRincian_NamaRek : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDRincian_NoRek : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDRincian_Metode : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal.toFixed(0)) : "Please wait..."}</td>
                                                      </tr>
                                                  </tbody>
                                              </Table>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col md={12}>
                                          <div className="py-2 mt-3">
                                              <h4 className=" fw-bold"><u>DOKUMEN PENDUKUNG</u></h4>
                                          </div>
                                          <div className="table-responsive text-center">
                                              <Table className="table-nowrap">
                                                  <thead>
                                                      <tr>
                                                          <th>Foto Tanda Pengenal</th>
                                                          <th>Foto Kelengkapan</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>
                                                              <Col lg={12}>
                                                                  {isFilePathCaptureKTP ? (
                                                                      <Lightbox
                                                                          mainSrc={getFotoKTPandKelengkapan(isFullDataContract != null ? isFullDataContract.isCustomer.FotoKTP : null, "KTP")}
                                                                          enableZoom={true}
                                                                          imageCaption={
                                                                              isFullDataContract != null ? "Foto Tanda Pengenal (" + isFullDataContract.isCustomer.JenisTandaPengenal + ")" : "Foto Tanda Pengenal" 
                                                                          }
                                                                          onCloseRequest={() => {
                                                                              isSetFilePathCaptureKTP(false);
                                                                          }}
                                                                      />
                                                                  )
                                                                      : null}
                                                                  <div>
                                                                      <img
                                                                          src={getFotoKTPandKelengkapan(isFullDataContract != null ? isFullDataContract.isCustomer.FotoKTP : null, "KTP")}
                                                                          alt=""
                                                                          id="expandedImg1"
                                                                          className="img-fluid mx-auto d-block rounded"
                                                                          onClick={() => {
                                                                              isSetFilePathCaptureKTP(true);
                                                                          }}
                                                                          style={{ maxHeight: "180px" }}
                                                                      />
                                                                  </div>
                                                                  <small className="d-print-none"><b className="text-info">*</b> Klik untuk memperbesar gambar.</small>
                                                              </Col>
                                                          </td>
                                                          <td>
                                                              <Col lg={12}>
                                                                  {isFilePathCaptureKelengkapan ? (
                                                                      <Lightbox
                                                                          mainSrc={getFotoKTPandKelengkapan(isFullDataContract != null ? isFullDataContract.PGD_FotoKelengkapan : null, "Kelengkapan")}
                                                                          enableZoom={true}
                                                                          imageCaption={
                                                                              "Foto Dokumentasi Kelengkapan"
                                                                          }
                                                                          onCloseRequest={() => {
                                                                              isSetFilePathCaptureKelengkapan(false);
                                                                          }}
                                                                      />
                                                                  )
                                                                      : null}
                                                                  <div>
                                                                      <img
                                                                          src={getFotoKTPandKelengkapan(isFullDataContract != null ? isFullDataContract.PGD_FotoKelengkapan : null, "Kelengkapan")}
                                                                          alt=""
                                                                          id="expandedImg2"
                                                                          className="img-fluid mx-auto d-block rounded"
                                                                          onClick={() => {
                                                                              isSetFilePathCaptureKelengkapan(true);
                                                                          }}
                                                                          style={{ maxHeight: "180px" }}
                                                                      />
                                                                  </div>
                                                                  <small className="d-print-none"><b className="text-info">*</b> Klik untuk memperbesar gambar.</small>
                                                              </Col>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </Table>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row className="pt-2">
                                      <Col md={12}>
                                          <div className="py-2 mt-3">
                                              <h4 className=" fw-bold"><u>INFORMASI LAINNYA</u></h4>
                                          </div>
                                          <Row>
                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Informasi Fisik Perangkat</span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KondisiFisik == "Mulus" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Mulus
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KondisiFisik == "Tidak Mulus" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Tidak Mulus
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Informasi Fungsi Perangkat</span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KondisiFungsi == "Normal" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Normal
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KondisiFungsi == "Tidak Normal" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Tidak Normal
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Indikasi Barang Pernah Service</span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_IndikasiPernahServices == "Belum Pernah Services" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Blm. Pernah Services
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_IndikasiPernahServices == "Pernah Services" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Sdh. Pernah Services
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Status IMEI Perangkat</span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_StatusBlockIMEI == "Terdaftar" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Terdaftar
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_StatusBlockIMEI == "Tidak Terdaftar" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Tidak Terdaftar
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Informasi <i>Case</i> Eksternal</span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCasing == "Ada" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Ada
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="6">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCasing == "Tidak Ada" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Tidak Ada
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="INPUTJBar_InfoCharger">
                                                          <span className="fw-bold">Informasi <i>Charger</i></span>
                                                      </Label>
                                                      <Row>
                                                          <Col sm="4">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCharger == "Original" ? "fas fa-check-square form-check-input text-success" : "form-check-input text-success" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-success"
                                                                  >
                                                                      Original
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="4">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCharger == "OEM" ? "fas fa-check-square form-check-input text-info" : "form-check-input text-info" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-info"
                                                                  >
                                                                      OEM
                                                                  </label>
                                                              </div>
                                                          </Col>

                                                          <Col sm="4">
                                                              <div className="form-check form-checkbox">
                                                                  <i className={isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCharger == "Tidak Ada" ? "fas fa-check-square form-check-input text-danger" : "form-check-input text-danger" : null}></i>
                                                                  <label
                                                                      className="form-check-label text-danger"
                                                                  >
                                                                      Tidak Ada
                                                                  </label>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Catan Barang Jaminan</span>
                                                      </Label>
                                                      <p className='text-justify'>{isFullDataContract != null ? isFullDataContract.PGDJaminan_Catatan : "Please wait..."}</p>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              <Col sm="6">
                                                  <div className="mb-3">
                                                      <Label for="">
                                                          <span className="fw-bold">Catan Transaksi</span>
                                                      </Label>
                                                      <p className='text-justify'>{isFullDataContract != null ? isFullDataContract.PGDRincian_Catatan : "Please wait..."}</p>
                                                  </div>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                          </Row>
                                      </Col>
                                  </Row>
                                  

                                  <Row>
                                      <Col md={12}>
                                          <div className="py-2 mt-3">
                                              <h4 className="fw-bold"><u>RINCIAN</u></h4>
                                          </div>
                                          <div className="table-responsive">
                                              <Table className="table-nowrap">
                                                  <thead>
                                                      <tr>
                                                          <th>Nama Perangkat</th>
                                                          {/*<th className="d-print-none">Jenis</th>*/}
                                                          <th>RAM</th>
                                                          <th>Internal</th>
                                                          <th>IMEI1</th>
                                                          <th className="text-end">Taksiran Awal</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_Tipe : "Please wait..."}</td>
                                                          {/*<td className="d-print-none">{isFullDataContract != null ? isFullDataContract.PGDJaminan_Jenis : "Please wait..."}</td>*/}
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_RAM + " GB" : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_Internal + " GB" : "Please wait..."}</td>
                                                          <td>{isFullDataContract != null ? isFullDataContract.PGDJaminan_IMEI1 : "Please wait..."}</td>
                                                          <td className="text-end">{isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_HargaTaksiranAwal.toFixed(0)) : "Please wait..."}</td>
                                                      </tr>
                                                      {/*<tr>
                                                          <td colSpan="4" className="text-end">
                                                              <strong>Sub Total</strong>
                                                          </td>
                                                          <td className="text-end">
                                                              {isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_HargaTaksiranAwal.toFixed(0)) : "Please wait..."}
                                                          </td>
                                                      </tr>*/}
                                                      <tr>
                                                          <td colSpan="4" className="text-end">
                                                              <span className="fw-bold">Nilai Penurunan ({isFullDataContract != null ? isFullDataContract.PGD_TotalPersentase.toFixed(0) : "Please wait..."}%)</span>
                                                          </td>
                                                          <td className="text-end"><span className="fw-bold m-0 text-danger">
                                                              {isFullDataContract != null ? "- " + CurrencyFormatter.format((isFullDataContract.PGD_HargaTaksiranAwal.toFixed(0)) * (isFullDataContract.PGD_TotalPersentase.toFixed(0) / 100)) : "Please wait..."}</span>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td colSpan="4" className="border-0 text-end">
                                                              <span className="fw-bold">Taksiran Maksimal (Akhir)</span>
                                                          </td>
                                                          <td className="border-0 text-end">
                                                              <h4 className="m-0 text-primary">
                                                                  {isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_HargaTaksiranAkhir.toFixed(0)) : "Please wait..."}
                                                              </h4>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td colSpan="4" className="border-0 text-end">
                                                              <span className="fw-bold">Nominal Diterima Nasabah</span>
                                                          </td>
                                                          <td className="border-0 text-end">
                                                              <h4 className="m-0 text-success">
                                                                  {isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal.toFixed(0)) : "Please wait..."}
                                                              </h4>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </Table>
                                          </div>
                                      </Col>
                                  </Row>

                                  <hr className="mt-3" style={{ borderTop: "1px solid #DDE6F4" }} />

                                  <Row>
                                      <Col sm="6" className="mt-3">
                                          <address>
                                              <span className="fw-bold">Catatan :</span>
                                              <ul className="font-size-11" style={{ listStyle: 'outside' }}>
                                                  <li>
                                                      Tempo 31 Hari, tidak terima alasan apapun mengenai keterlambatan pembayaran.
                                                  </li>
                                                  <li>
                                                      Gadai itu ada aturannya, jangan pura-pura lupa, tidak tau, tidak baca , dll.
                                                  </li>
                                                  <li>
                                                      Harap dipahami sistemnya, dan lakukan kewajiban bayar tepat waktu.
                                                  </li>
                                                  <li>
                                                      Jangan salahkan siapapun jika ada konsekuensi lelang / jual jaminan akibat kelalaian pembayaran yang tidak sesuai aturan.
                                                  </li>
                                                  <li>
                                                      Menandatangani nota gadai, berarti Anda menyetujui segala aturan yang ada.
                                                  </li>
                                              </ul>
                                          </address>
                                      </Col>
                                      <Col sm="3" className="mt-3 text-sm-center">
                                          <address>
                                              <span className="fw-bold">Nasabah,</span>
                                              <br />
                                              <br />
                                              <br />
                                              <br />
                                              <br />

                                              <span className="font-size-12">( {isFullDataContract != null ? isFullDataContract.isCustomer.NamaLengkap : "Please wait..."} )</span>
                                          </address>
                                      </Col>
                                      <Col sm="3" className="mt-3 text-sm-center">
                                          <address>
                                              <span className="fw-bold">Operator,</span>
                                              <br />
                                              <br />
                                              <br />
                                              <br />
                                              <br />

                                              <span className="font-size-12">( {isFullDataContract != null ? isFullDataContract.isOperator.fullName : "Please wait..."} )</span>
                                          </address>
                                      </Col>
                                  </Row>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>

                  <Row className="p-2">
                      <Card className="d-print-none">
                          <CardBody>
                              <Row>
                                  {
                                      isFullDataContract != null ? isFullDataContract.isExtendContract.length > 0
                                          ?

                                          <Col lg={12} className={"mt-1"}>
                                              <CardTitle className="pb-1 text-center">CATATAN TRANSAKSI PERPANJANG KONTRAK</CardTitle>
                                              <hr className="pb-1" style={{ borderTop: "1px solid #DDE6F4" }} />
                                              <TableContainer
                                                  className={"table table-hover rounded"}
                                                  columns={isColumnsData}
                                                  data={isFullDataContract != null ? isFullDataContract.isExtendContract : [{}]}
                                                  isGlobalFilter={true}
                                                  isAddOptions={false}
                                                  isPlaceholderData={"Cari data. . ."}
                                                  customPageSize={5}
                                                  isSorted={"desc"}
                                              />
                                          </Col>

                                          :
                                          <Col lg={12} className="text-center">
                                              <CardTitle className="pb-1">CATATAN TRANSAKSI PERPANJANG KONTRAK</CardTitle>
                                              <hr className="pb-1" style={{ borderTop: "1px solid #DDE6F4" }} />
                                              <span>------ Belum ada catatan transaksi perpanjang kontrak ------</span>
                                          </Col>
                                          :
                                          null
                                  }
                              </Row>
                          </CardBody>
                      </Card>
                  </Row>

                  <Row className="p-2">
                      <Card className="d-print-none">
                          <CardBody>
                              <Row>
                                  {
                                      isFullDataContract != null ? isFullDataContract.isPelunasanContract.length > 0
                                          ?

                                          <Col lg={12} className={"mt-1"}>
                                              <CardTitle className="pb-1 text-center">CATATAN TRANSAKSI PELUNASAN KONTRAK</CardTitle>
                                              <hr className="pb-1" style={{ borderTop: "1px solid #DDE6F4" }} />
                                              <TableContainer
                                                  className={"table table-hover rounded"}
                                                  columns={isColumnsDataPelunasan}
                                                  data={isFullDataContract != null ? isFullDataContract.isPelunasanContract : [{}]}
                                                  isGlobalFilter={true}
                                                  isAddOptions={false}
                                                  isPlaceholderData={"Cari data. . ."}
                                                  customPageSize={5}
                                                  isSorted={"desc"}
                                              />
                                          </Col>

                                          :
                                          <Col lg={12} className="text-center">
                                              <CardTitle className="pb-1">CATATAN TRANSAKSI PELUNASAN KONTRAK</CardTitle>
                                              <hr className="pb-1" style={{ borderTop: "1px solid #DDE6F4" }} />
                                              <span>------ Belum ada catatan transaksi perpanjang kontrak ------</span>
                                          </Col>
                                          :
                                          null
                                  }
                              </Row>
                          </CardBody>
                      </Card>
                  </Row>

                  <Row className={"pb-4"}>
                      <Col lg={12}>
                          {
                              isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? null : 
                                  <div className="d-print-none mt-5" id="printInvoice">
                                      <div className="float-end">
                                          <Link
                                              to="#"
                                              onClick={printInvoice}
                                              className="btn btn-success me-2"
                                          >
                                              <i className="fa fa-print me-2" /> Cetak Dokumen
                                          </Link>
                                      </div>
                                  </div>
                                  :
                                  null
                          }

                          {
                              isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? null :
                                  <div className="d-print-none mt-5" id="printKontrak">
                                      <div className="float-end">
                                          <Link
                                              to="#"
                                              onClick={printOutContractFaktur}
                                              className="btn btn-primary me-2"
                                          >
                                              <i className="fas fa-file-invoice me-2" /> Lihat Invoice
                                          </Link>
                                      </div>
                                  </div>
                                  :
                                  null
                          }

                          {
                              isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? null : isFullDataContract.PGD_isConfirmed && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas
                                  ?
                                  <div className="d-print-none mt-5" id="kontrakFailed">
                                      <div className="float-end">
                                          <Link
                                              to="#"
                                              onClick={(evt) => {
                                                  isShowModalConfirmKontrakGagal();
                                              }}
                                              className="btn btn-danger me-2"
                                          >
                                              <i className="far fa-times-circle me-2" /> Kontrak Gagal
                                          </Link>
                                      </div>
                                  </div>
                                  :
                                  null
                                  :
                                  null
                          }
                          

                          {
                              isFullDataContract != null ? isFullDataContract.PGD_DeleteStatus ? null : !isFullDataContract.PGD_isConfirmed && role !== "Operator" && !isFullDataContract.PGD_isRejected && !isFullDataContract.PGD_KontrakFailed && !isFullDataContract.PGD_Lunas ? 
                                  <div className="d-print-none mt-5" id="validasiKontrak">
                                      <div className="float-end">
                                          <Link
                                              to="#"
                                              onClick={(evt) => {
                                                  isShowModalConfirmKontrak();
                                              }}
                                              className="btn btn-warning me-2"
                                          >
                                              <i className="fas fa-check-circle me-2" /> Validasi Kontrak
                                          </Link>
                                      </div>
                                  </div>
                                  :
                                  null
                                  :
                                  null
                          }
                          
                      </Col>
                  </Row>
          </Container>
          </div>
    </React.Fragment>
  );
};

PawnshopHandphoneDetailTRX.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PawnshopHandphoneDetailTRX);
