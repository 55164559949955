import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    Alert,
    InputGroup,
    FormFeedback,
    UncontrolledAlert,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import Webcam from "react-webcam";
import Select from "react-select";
import { Link, useParams } from "react-router-dom"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import jwt_decode from "jwt-decode";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, useFormikContext } from "formik";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

import {
    postUpdateDataCustomer,
    postUploadFotoKTPNasabah,
    cekNIK,
    cekNoHP,
    postDataPelangganByUID,
} from "../../../helpers/backend_helper";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

let INPUT_TipeTPengenal = "";
let INPUT_NamaLengkap = "";
let INPUT_NamaPanggilan = "";
let INPUT_NIK = "";
let INPUT_NomorHP = "";
let INPUT_Alamat = "";
let INPUT_BLReason = "";
let INPUT_FotoKTP = "";

let isStatusNIKAvailable = false;
let isStatusNoHPAvailable = false;
let INPUT_BlacklistStatus = null;

let isUUIDLoggedIN = null;
let isResultDetailCustomer = null;
let isFullDataPelangganUID_RESULT = null;

const CustomerSettings = props => {
    const dispatch = useDispatch();

    const [isPagePreloader, setisPagePreloader] = useState(false);

    const handleGoBack = () => {
        window.history.back(); // Kembali ke halaman sebelumnya
    };

    const paramsUUIDCustomer = useParams();

    const webcamRefCaptureKTP = useRef(null);

    const captureFotoKTPFromCAM = useCallback(async () => {
        const imageSrc = webcamRefCaptureKTP.current.getScreenshot({ width: 1280, height: 720 });

        //const blob = await fetch(imageSrc).then((res) => res);

        const isFileRenameValue = "imgKTPNasabah-" + INPUT_NIK + "_" + Date.now() + ".jpg";
        let isResultFileKTP = dataURLtoFile(imageSrc, isFileRenameValue);

        setfileFotoKTPDisplay(imageSrc);

        INPUT_FotoKTP = isFileRenameValue;

        setfileFotoKTP(isResultFileKTP);

    }, [webcamRefCaptureKTP]);

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "2500",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success') {
            toastr.success(isMessage, isTitle);
        }
        else if (isType === 'info') {
            toastr.info(isMessage, isTitle);
        }
        else if (isType === 'warning') {
            toastr.warning(isMessage, isTitle);
        }
        else {
            toastr.error(isMessage, isTitle);
        }

        setTimeout(async function () {
            return location.reload();
        }, 700)
    }

    const [isEDFullNamePelanggan, setisEDFullNamePelanggan] = useState(true);
    const [isEDNamePelanggan, setisEDNamePelanggan] = useState(true);
    const [isEDNIKPelanggan, setisEDNIKPelanggan] = useState(true);
    const [isEDPhoneNumberPelanggan, setisEDPhoneNumberPelanggan] = useState(true);
    const [isEDFotoKTPPelanggan, setisEDFotoKTPPelanggan] = useState(true);
    const [isEDAlamatPelanggan, setisEDAlamatPelanggan] = useState(true);
    const [EnableDisableBTNCreateCustomer, setEnableDisableBTNCreateCustomer] = useState(false);
    const [updateLoading, setupdateLoading] = useState(false);
    const [AllStepisPassed, setAllStepisPassed] = useState(false);
    const [fileFotoKTP, setfileFotoKTP] = useState(null);
    const [fileFotoKTPDisplay, setfileFotoKTPDisplay] = useState(null);
    const [isFilePathCaptureKTP, isSetFilePathCaptureKTP] = useState(false);

    const [isStateINPUT_TipeTPengenal, setisStateINPUT_TipeTPengenal] = useState(null);
    const [isFullDataPelangganUID, setisFullDataPelangganUID] = useState(null);

    const onlyNumberRegEXP = "^[0-9]*$";

    const VLD_DataPelanggan = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            INPUTDPel_UUID_Operator: isUUIDLoggedIN || undefined,
            INPUTDPel_TipeTPengenal: isStateINPUT_TipeTPengenal || undefined,
            INPUTDPel_UUIDCustomer: paramsUUIDCustomer.UUIDCustomer || undefined,
            INPUTDPel_NamaLengkap: INPUT_NamaLengkap || undefined,
            INPUTDPel_NamaPanggilan: INPUT_NamaPanggilan || undefined,
            INPUTDPel_NIK: INPUT_NIK || undefined,
            INPUTDPel_NoHP: INPUT_NomorHP || "62",
            INPUTDPel_Alamat: INPUT_Alamat || undefined,
            INPUTDPel_BLStatus: INPUT_BlacklistStatus,
            INPUTDPel_BLReason: INPUT_BLReason || null,
            INPUTDPel_FotoKTP: INPUT_FotoKTP || undefined,
            INPUTDPel_FileIMGFotoKTP: fileFotoKTP || undefined,
        },
        validationSchema: Yup.object().shape({
            /*INPUTDPel_NamaLengkap: Yup.string().required("Nama Lengkap wajib diisi sesuai nama pada Tanda Pengenal dan tidak boleh kosong!")
                .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi!')
                .max(96, 'Maksimal karakter hanya boleh sampai 96 karakter!'),*/
            INPUTDPel_NamaPanggilan: Yup.string().required("Nama Panggilan wajib diisi dan tidak boleh kosong!")
                .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi!')
                .max(32, 'Maksimal karakter hanya boleh sampai 32 karakter!'),
            /*INPUTDPel_NIK: Yup.string().required("Nomor Induk Kependudukan atau Nomor SIM wajib diisi & tidak boleh kosong!")
                .matches("^[0-9]*$", 'Nomor Induk Kependudukan atau Nomor SIM hanya boleh menggunakan angka!')
                .min(8, 'Minimal NIK atau Nomor SIM adalah 8 digit!')
                .max(17, 'Maksimal NIK atau Nomor SIM adalah 8 digit!'),*/
            INPUTDPel_NoHP: Yup.string().min(10, 'Nonmor Telepon minimal adalah 10 digit angka!').max(15, 'Nonmor Telepon maksimal adalah 15 digit angka!').matches(onlyNumberRegEXP, 'Format No Telepon/WA tidak sesuai, pastikan hanya angka yang anda masukan, Cth: 81234567890').required("Nomor Telepon atau WhatsApp wajib diisi & tidak boleh kosong!"),
            INPUTDPel_Alamat: Yup.string().required("Alamat lengkap wajib diisi dan harus jelas sesuai pada KTP tidak boleh kosong!")
        }),
        onSubmit: (values) => {
            //console.log(values);
            FeedCustomerUpdater(values);
        }
    });

    async function FeedCustomerUpdater(isJSONValue)
    {
        setupdateLoading(true);
        setEnableDisableBTNCreateCustomer(true);

        try {
            await postUpdateDataCustomer({ isFieldData: isJSONValue });

            if (fileFotoKTP !== null) {
                await postUploadFotoKTPNasabah({ "imgFile": fileFotoKTP });
            }

            setTimeout(async function () {
                setupdateLoading(false);

                showToastNotification('Success!', 'Perubahan Data Nasabah atas Nama ' + INPUT_NamaLengkap + ' berhasil dilakukan!', 'success');
            }, 1500);

            await FetchDetailDataCustomer(paramsUUIDCustomer.UUIDCustomer);
        }
        catch (Err) {
            setTimeout(async () => {
                {
                    showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah! silahkan ulangi transaksi.', 'danger');
                }
            }, 1500);
        }
    }

    const [BlacklistNO, setBlacklistNO] = useState(false);
    const [BlacklistYES, setBlacklistYES] = useState(false);

    async function FetchDetailDataCustomer(UUIDCustomerValue)
    {
        setisPagePreloader(true);

        setTimeout(async () => {
            {
                try {
                    isResultDetailCustomer = await postDataPelangganByUID({ CustUID: UUIDCustomerValue });

                    setisFullDataPelangganUID(isResultDetailCustomer);

                    setisPagePreloader(false);
                }
                catch (Err) {
                    showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');
                }

                //console.log(isResultDetailCustomer[0].JenisTandaPengenal)

                if (isResultDetailCustomer.length > 0) {
                    INPUT_TipeTPengenal = isResultDetailCustomer[0].JenisTandaPengenal;
                    setisStateINPUT_TipeTPengenal(isResultDetailCustomer[0].JenisTandaPengenal);

                    INPUT_NamaLengkap = isResultDetailCustomer[0].NamaLengkap;
                    INPUT_NamaPanggilan = isResultDetailCustomer[0].NamaPanggilan;
                    INPUT_NIK = isResultDetailCustomer[0].NIKPelanggan;
                    INPUT_NomorHP = isResultDetailCustomer[0].NoHP;
                    INPUT_Alamat = isResultDetailCustomer[0].Alamat;
                    INPUT_BLReason = isResultDetailCustomer[0].Blacklist_Reason;
                    INPUT_FotoKTP = isResultDetailCustomer[0].FotoKTP;

                    /*if (isResultDetailCustomer[0].Blacklist) {
                        setBlacklistNO(false)
                        setBlacklistYES(!BlacklistYES)
                    }
                    else {
                        setBlacklistNO(!BlacklistNO)
                        setBlacklistYES(false)
                    }*/
                    handleFormBlacklistStatus(isResultDetailCustomer[0].Blacklist);


                    try {
                        //apply base url for axios
                        let API_URL = '';

                        const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                        const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                        const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                        //Using Global Domain Server
                        if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                            /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
                            API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
                            //API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND + ":" + API_IMAGE_PORT_INTERNET;
                        }
                        else {
                            //127.0.0.1
                            if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                                API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                            }
                            //Local IP 192.X.X.X
                            else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                                API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                            }
                            //Public IP X.X.X.X
                            else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                                API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                            }

                        }

                        //console.log(window.location.hostname);
                        setfileFotoKTPDisplay("//" + API_URL + "/img-assets/imgKTPNasabah/" + INPUT_FotoKTP);
                    }
                    catch (Err) {

                    }
                }
            }
        }, 500);
    }

    function handleChangeFotoKTP(e) {
        setfileFotoKTPDisplay(URL.createObjectURL(e.target.files[0]));

        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        //const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = "imgKTPNasabah-" + INPUT_NIK + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        INPUT_FotoKTP = isFileRenameValue;

        //console.log(e.target.files[0]);

        setfileFotoKTP(fileRenamed);
    }

    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDLoggedIN = DecodeUserData.uuid;
        }
        catch (Err) {
            //Nothing
        }
    }

    /*setInterval(async () => {
        if (INPUT_TipeTPengenal === 'KTP' || INPUT_TipeTPengenal === 'SIM') {
            if (INPUT_NamaLengkap !== '' && INPUT_NamaPanggilan !== '' && !isStatusNoHPAvailable && !isStatusNIKAvailable && INPUT_NIK !== '' && INPUT_NomorHP !== '' && INPUT_Alamat !== '') {
                setAllStepisPassed(true);
                //setEnableDisableBTNCreateCustomer(false);
            }
            else {
                setAllStepisPassed(false);
                //setEnableDisableBTNCreateCustomer(true);
            }
        }
    }, 200);*/

    useEffect(() => {
        INPUT_TipeTPengenal = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";

        isStatusNIKAvailable = false;
        isStatusNoHPAvailable = false;
        isUUIDLoggedIN = null;
        isResultDetailCustomer = null;

        autoUpdateProfile();

        FetchDetailDataCustomer(paramsUUIDCustomer.UUIDCustomer);

        setInterval(async () => {
            await autoUpdateProfile();
        }, 500);

    }, []);

    /*const optionTipeTPengenal = [
        {
            label: "Pilih Kategori Nasabah",
            options: [
                { label: "Kartu Tanda Pengenal (KTP)", value: "KTP" },
                { label: "Surat Izin Mengemudi (SIM)", value: "SIM" }
            ]
        },
    ];

    const [selectedTipeTPengenal, setselectedTipeTPengenal] = useState(null);

    function handleSelectTipeTandaPengenal(selectedTipeTPengenal) {
        setselectedTipeTPengenal(selectedTipeTPengenal);

        INPUT_TipeTPengenal = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";

        if (selectedTipeTPengenal.value === "KTP" || selectedTipeTPengenal.value === "SIM") {
            setisEDFullNamePelanggan(false);
            setisEDNamePelanggan(false);
            setisEDNIKPelanggan(false);
            setisEDPhoneNumberPelanggan(false);
            setisEDFotoKTPPelanggan(false);
            setisEDAlamatPelanggan(false);

            INPUT_TipeTPengenal = selectedTipeTPengenal.value;
        }
    }*/

    function handleFormDataPelanggan_NamaLengkap(InputValue) {
        INPUT_NamaLengkap = InputValue.toUpperCase();
    }

    function handleFormDataPelanggan_NamaPanggilan(InputValue) {
        INPUT_NamaPanggilan = InputValue.toUpperCase();
    }

    const [isNIKExists, setisNIKExists] = useState(null);
    async function handleFormDataPelanggan_NIK(InputValue) {
        INPUT_NIK = InputValue;

        if (InputValue.length > 8) {
            await CheckNIKisNotExists(InputValue);
        }
    }

    async function CheckNIKisNotExists(valNIKNya) {
        try {
            let isCheckFromAPI = await cekNIK(valNIKNya);

            if (isCheckFromAPI !== null) {
                setisNIKExists(isCheckFromAPI);

                isShowModalNIKExists();

                isStatusNIKAvailable = true;
            }
            else {
                setisNIKExists(null);

                isStatusNIKAvailable = false;
            }
        }
        catch (Err) {
            isStatusNIKAvailable = true;

            setisNIKExists("Error");
        }
    }

    const [isModalNIKExists, setisModalNIKExists] = useState(false);
    function isShowModalNIKExists() {
        setisModalNIKExists(!isModalNIKExists);
        removeBodyCss();
    }

    const [isNoHPExists, setisNoHPExists] = useState(null);

    async function handleFormDataPelanggan_NoHP(InputValue) {
        INPUT_NomorHP = InputValue;

        /*if (InputValue.length > 8) {
            await CheckNoHPisNotExists(InputValue);
        }*/
    }

    async function handleFormBlacklistStatus(InputValue) {
        INPUT_BlacklistStatus = InputValue;

        if (INPUT_BlacklistStatus) {
            setBlacklistYES(true);
            setBlacklistNO(false);
        }
        else if (!INPUT_BlacklistStatus) {
            setBlacklistNO(true);
            setBlacklistYES(false);
        }
        else {
            setBlacklistNO(false);
            setBlacklistYES(false);
        }
    }

    async function CheckNoHPisNotExists(valNoHP) {
        try {
            let isCheckFromAPI = await cekNoHP(valNoHP);

            if (isCheckFromAPI !== null) {
                setisNoHPExists(isCheckFromAPI);

                isShowModalHPExists();

                isStatusNoHPAvailable = true;
            }
            else {
                setisNoHPExists(null);

                isStatusNoHPAvailable = false;
            }
        }
        catch (Err) {
            isStatusNoHPAvailable = true;

            setisNoHPExists("Error");
        }
    }

    const [isModalHPExists, setisModalHPExists] = useState(false);
    function isShowModalHPExists() {
        setisModalHPExists(!isModalHPExists);
        removeBodyCss();
    }

    function handleFormDataPelanggan_Alamat(InputValue) {
        INPUT_Alamat = InputValue.toUpperCase();
    }

    function handleFormDataPelanggan_BLReason(InputValue) {
        INPUT_BLReason = InputValue.toUpperCase();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const [isModalFotoKTPWebCam, setisModalFotoKTPWebCam] = useState(false);
    function isShowModalFotoKTPWebCam() {
        setisModalFotoKTPWebCam(!isModalFotoKTPWebCam);
        removeBodyCss();
    }

    let summaryReports = [
        {
            title: "Total Kontrak",
            iconClass: "fas fa-file-signature",
            bgColor: "primary",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].TotalKontrak : "-",
            value_color: "text-success",
        },
        {
            title: "Kontrak Selesai",
            iconClass: "fas fa-file-signature",
            bgColor: "success",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakSelesai : "-",
            value_color: "text-success",
        },
        {
            title: "Kontrak Berjalan / Jatuh Tempo",
            iconClass: "fas fa-file-signature",
            bgColor: "info",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakBerjalan + " / " + isFullDataPelangganUID[0].TotalJatuhTempo : "- / -",
            value_color: "text-info",
        },
        {
            title: "Kontrak Pending",
            iconClass: "fas fa-file-signature",
            bgColor: "warning",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: "Soon",
            value_color: "text-warning",
        },
        {
            title: "Kontrak Gagal / Ditolak",
            iconClass: "fas fa-file-signature",
            bgColor: "danger",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakGagal + " / " + isFullDataPelangganUID[0].TotalKontrakReject : "- / -",
            value_color: "text-danger",
        },
        {
            title: "Jumlah Keterlambatan",
            iconClass: "fas fa-file-signature",
            bgColor: "info",
            textColor: "text-white",
            description: isFullDataPelangganUID != null ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].JumlahKeterlambatan : "-",
            value_color: "text-info",
        },
    ];

    //meta title
    document.title = "Perubahan Data Nasabah - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Perubahan Data Nasabah")}
                  />

                  {
                      !isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className="p-2 d-print-none">
                                  <Card>
                                      <CardBody>
                                          <Col sm={2}>
                                              <Button
                                                  className="btn btn-info"
                                                  onClick={handleGoBack}
                                              >
                                                  <i className={"fas fa-arrow-left me-2"}></i> Kembali
                                              </Button>
                                          </Col>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                          :
                          null
                  }
                  
                  {/*IS MODAL FOR Take Foto KTP*/}
                  <Modal
                      isOpen={isModalFotoKTPWebCam}
                      toggle={() => {
                          isShowModalFotoKTPWebCam();
                      }}
                      backdrop={'static'}
                      id="modalFotoKTPWebCam"
                      className={'modal-lg modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalFotoKTPWebCamLabel"><i className="fas fa-camera me-2"></i>AMBIL FOTO TANDA PENGENAL</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalFotoKTPWebCam(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <Webcam
                              className="img-fluid mx-auto d-block rounded"
                              forceScreenshotSourceSize={false}
                              mirrored={true}
                              screenshotFormat={'image/jpeg'}
                              screenshotQuality={1}
                              width={640}
                              height={480}
                              ref={webcamRefCaptureKTP}
                              facingmode="user"
                          />
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-info" onClick={() => {
                              captureFotoKTPFromCAM();

                              setisModalFotoKTPWebCam(false);
                          }}><i className="mdi mdi-camera-enhance me-2"></i> Ambil Foto</button>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR ERROR NIK EXISTS*/}
                  <Modal
                      isOpen={isModalNIKExists}
                      toggle={() => {
                          isShowModalNIKExists();
                      }}
                      backdrop={'static'}
                      id="modalNIKExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalNIKExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NASABAH SUDAH TERDAFTAR</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalNIKExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">Nomor Induk : <b>{INPUT_NIK}</b></p>
                          <p className="font-size-13 text-justify">Nomor Induk tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.NamaLengkap : "Unknown"}</b>, dengan jenis tanda pengenal <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.JenisTandaPengenal : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalNIKExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR ERROR HP EXISTS*/}
                  <Modal
                      isOpen={isModalHPExists}
                      toggle={() => {
                          isShowModalHPExists();
                      }}
                      backdrop={'static'}
                      id="modalHPExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalHPExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NOMOR TELEPON SUDAH DIGUNAKAN</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalHPExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">No. Telepon : <b>{INPUT_NomorHP}</b></p>
                          <p className="font-size-13 text-justify">Nomor Telepon / WhatsApp tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNoHPExists !== null && isNoHPExists !== "Error" ? isNoHPExists.NamaLengkap : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalHPExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {
                      isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                              </Col>
                          </Row>
                          :
                          <Row>
                              <Col xl="12">
                                  <Row>
                                      {/* Reports Render */}
                                      {summaryReports.map((report, key) => (
                                          <Col md="4" key={"_col_" + key}>
                                              <Card className="mini-stats-wid">
                                                  <CardBody>
                                                      <div className="d-flex">
                                                          <div className="flex-grow-1">
                                                              {
                                                                  report.description == "NaN"
                                                                      ?
                                                                      <p className=
                                                                          {
                                                                              report.description == "NaN"
                                                                                  ? "text-black fw-medium placeholder-glow"
                                                                                  : "text-black fw-medium"
                                                                          }
                                                                      >
                                                                          {
                                                                              report.description == "NaN"
                                                                                  ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                                  : report.title
                                                                          }
                                                                      </p>
                                                                      :
                                                                      <p className="text-black fw-medium">
                                                                          {report.title}
                                                                      </p>
                                                              }

                                                              {
                                                                  report.description == "NaN"
                                                                      ?
                                                                      <span className=
                                                                          {
                                                                              report.description == "NaN"
                                                                                  ? "mb-0 placeholder-glow"
                                                                                  : "mb-0"
                                                                          }
                                                                      >
                                                                          {
                                                                              report.description == "NaN"
                                                                                  ? <span className="mb-0 text-primary placeholder col-6">report.description</span>
                                                                                  : report.description
                                                                          }
                                                                      </span>
                                                                      :
                                                                      <>
                                                                          <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                                      </>
                                                              }

                                                          </div>
                                                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                              <span className={
                                                                  "avatar-title bg-" +
                                                                  report.bgColor +
                                                                  " " +
                                                                  report.textColor
                                                              }
                                                              >
                                                                  <i
                                                                      className={
                                                                          " " + report.iconClass + " font-size-20"
                                                                      }
                                                                  ></i>
                                                              </span>
                                                          </div>

                                                      </div>
                                                  </CardBody>
                                              </Card>
                                          </Col>
                                      ))}
                                  </Row>
                              </Col>

                              <Col lg={12}>
                                  <Card>
                                      <CardBody>
                                          <Form encType="multipart/form-data" onSubmit={(e) => {
                                              e.preventDefault();
                                              VLD_DataPelanggan.handleSubmit();
                                              return false;
                                          }}>
                                              <Row>
                                                  <Col xl={10}>
                                                      <div >
                                                          <Alert className="alert-info" role="alert">
                                                              <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                              <p>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</p>
                                                          </Alert>
                                                      </div>
                                                  </Col>

                                                  <Col xl={2}>
                                                      <img
                                                          src={fileFotoKTPDisplay}
                                                          alt=""
                                                          width="128px"
                                                          height="86px"
                                                          id="expandedImg1"
                                                          className="mx-auto d-block rounded"
                                                          onClick={() => {
                                                              isSetFilePathCaptureKTP(true);
                                                          }}
                                                          style={{ maxHeight: "180px" }}
                                                      />

                                                      {isFilePathCaptureKTP ? (
                                                          <Lightbox
                                                              mainSrc={fileFotoKTPDisplay}
                                                              enableZoom={true}
                                                              imageCaption={
                                                                  "Foto Tanda Pengenal"
                                                              }
                                                              onCloseRequest={() => {
                                                                  isSetFilePathCaptureKTP(false);
                                                              }}
                                                          />
                                                      )
                                                          : null}
                                                  </Col>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Row>

                                              <Row>
                                                  <Col lg='12' className="">
                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_TipeTPengenal">
                                                                      <b><span className="text-danger">*</span></b>Jenis Tanda Pengenal
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_TipeTPengenal"
                                                                      id="INPUTDPel_TipeTPengenal"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Jenis Tanda Pengenal"
                                                                      maxLength={12}
                                                                      onChange={VLD_DataPelanggan.handleChange}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_TipeTPengenal || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={true}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_TipeTPengenal && VLD_DataPelanggan.errors.INPUTDPel_TipeTPengenal ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_TipeTPengenal && VLD_DataPelanggan.errors.INPUTDPel_TipeTPengenal ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_TipeTPengenal}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NIK">
                                                                      <b><span className="text-danger">*</span></b>Nomor Induk Kependudukan / Nomor SIM
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NIK"
                                                                      id="INPUTDPel_NIK"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Nomor Induk Kependudukan / Nomor Kartu SIM . . ."
                                                                      maxLength={17}
                                                                      onChange={(evt) => {
                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NIK || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={true}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NIK}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NamaLengkap">
                                                                      <b><span className="text-danger">*</span></b>Nama Lengkap Nasabah
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NamaLengkap"
                                                                      id="INPUTDPel_NamaLengkap"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Nama Lengkap Sesuai di Tanda Pengenal . . ."
                                                                      maxLength={64}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NamaLengkap(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NamaLengkap || ""}
                                                                      autoComplete={"off"}
                                                                      //disabled={isEDFullNamePelanggan}
                                                                      disabled={false}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap ? true : false
                                                                      }
                                                                  />
                                                                  {
                                                                      VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap ? (
                                                                          <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap}</FormFeedback>
                                                                      ) : null
                                                                  }

                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NamaPanggilan">
                                                                      <b><span className="text-danger">*</span></b>Nama Panggilan
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NamaPanggilan"
                                                                      id="INPUTDPel_NamaPanggilan"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Masukan Nama Panggilan . . ."
                                                                      maxLength={24}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NamaPanggilan(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={INPUT_NamaPanggilan || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={false}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col lg="6">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_NoHP">
                                                                      <b><span className="text-danger">*</span></b>Telepon / WhatsApp
                                                                  </Label>
                                                                  <Input
                                                                      name="INPUTDPel_NoHP"
                                                                      id="INPUTDPel_NoHP"
                                                                      className="form-control"
                                                                      type="text"
                                                                      placeholder="Tanpa diawali 0 / 62 (Cth : 81234567890)"
                                                                      maxLength={15}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_NoHP(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NoHP || ""}
                                                                      autoComplete={"off"}
                                                                      disabled={false}
                                                                      invalid={
                                                                          VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP ? true : false
                                                                      }
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NoHP}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                          <Col lg="6">
                                                              <Label className="col-form-label" for="INPUTDPel_FotoKTP">
                                                                  Foto Tanda Pengenal (Gunakan Salah Satu)
                                                              </Label>
                                                              <Row>
                                                                  <Col lg="8">
                                                                      <div className="mb-3">
                                                                          <Input
                                                                              name="INPUTDPel_FotoKTP"
                                                                              id="INPUTDPel_FotoKTP"
                                                                              className="form-control"
                                                                              type="file"
                                                                              accept="image/*"
                                                                              onChange={(evt) => {

                                                                                  handleChangeFotoKTP(evt);

                                                                                  VLD_DataPelanggan.handleChange(evt);
                                                                              }}
                                                                              onBlur={VLD_DataPelanggan.handleBlur}
                                                                              autoComplete={"off"}
                                                                              disabled={false}
                                                                              invalid={
                                                                                  VLD_DataPelanggan.touched.INPUTDPel_FotoKTP && VLD_DataPelanggan.errors.INPUTDPel_FotoKTP ? true : false
                                                                              }
                                                                          />
                                                                          {VLD_DataPelanggan.touched.INPUTDPel_FotoKTP && VLD_DataPelanggan.errors.INPUTDPel_FotoKTP ? (
                                                                              <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_FotoKTP}</FormFeedback>
                                                                          ) : null}
                                                                      </div>
                                                                  </Col>

                                                                  <Col lg="4">
                                                                      <div className="mb-3">
                                                                          <div className="text-center">
                                                                              <Button
                                                                                  className="btn btn-md btn-block btn-soft-primary"
                                                                                  to="#"
                                                                                  disabled={false}
                                                                                  onClick={() => {
                                                                                      isShowModalFotoKTPWebCam();
                                                                                  }}
                                                                              >
                                                                                  <i className="fas fa-camera me-2"></i>Ambil Foto via WebCam
                                                                              </Button>
                                                                          </div>
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                          </Col>

                                                      </Row>

                                                      <Row>
                                                          <Col lg="4">
                                                              <div className="mb-3">
                                                                  <Label for="INPUTDPel_Blacklist">
                                                                      Status Daftar Hitam
                                                                  </Label>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="form-check form-checkbox form-check-success">
                                                                              <Input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  id="INPUTDPel_BlacklistNO"
                                                                                  name="INPUTDPel_BlacklistNO"
                                                                                  checked={BlacklistNO || undefined}
                                                                                  onChange={(evt) => {
                                                                                      if (evt.target.checked) {
                                                                                          handleFormBlacklistStatus(false)
                                                                                      }
                                                                                      else {
                                                                                          handleFormBlacklistStatus(null)
                                                                                      }

                                                                                      setBlacklistNO(!BlacklistNO)
                                                                                      setBlacklistYES(false)
                                                                                  }}
                                                                              />
                                                                              <Label
                                                                                  className="form-check-label text-success"
                                                                                  htmlFor="INPUTDPel_BlacklistNO"
                                                                              >
                                                                                  Tidak Dalam Daftar
                                                                              </Label>
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="form-check form-checkbox form-check-danger">
                                                                              <Input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  id="INPUTDPel_BlacklistYES"
                                                                                  name="INPUTDPel_BlacklistYES"
                                                                                  checked={BlacklistYES || undefined}
                                                                                  onChange={(evt) => {
                                                                                      if (evt.target.checked) {
                                                                                          handleFormBlacklistStatus(true)
                                                                                      }
                                                                                      else {
                                                                                          handleFormBlacklistStatus(null)
                                                                                      }

                                                                                      setBlacklistNO(false)
                                                                                      setBlacklistYES(!BlacklistYES)
                                                                                  }}
                                                                              />
                                                                              <Label
                                                                                  className="form-check-label text-danger"
                                                                                  htmlFor="INPUTDPel_BlacklistYES"
                                                                              >
                                                                                  Dalam Daftar Hitam
                                                                              </Label>
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                              </div>
                                                              <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                          </Col>
                                                          <Col lg="4">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_BLReason">
                                                                      Penjelasan aftar Hitam Nasabah
                                                                  </Label>
                                                                  <textarea
                                                                      id="INPUTDPel_BLReason"
                                                                      name="INPUTDPel_BLReason"
                                                                      className="form-control"
                                                                      rows="2"
                                                                      placeholder="Masukkan Penjelasan Untuk Daftar Hitam Nasabah . . ."
                                                                      autoComplete="off"
                                                                      maxLength={255}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_BLReason || ""}
                                                                      disabled={false}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_BLReason(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_BLReason && VLD_DataPelanggan.errors.INPUTDPel_BLReason ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_BLReason}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                          <Col lg="4">
                                                              <div className="mb-3">
                                                                  <Label className="col-form-label" for="INPUTDPel_Alamat">
                                                                      <b><span className="text-danger">*</span></b>Alamat Lengkap
                                                                  </Label>
                                                                  <textarea
                                                                      id="INPUTDPel_Alamat"
                                                                      name="INPUTDPel_Alamat"
                                                                      className="form-control"
                                                                      rows="2"
                                                                      placeholder="Masukan Alamat Lengkap sesuai Tanda Pengenal . . ."
                                                                      autoComplete="off"
                                                                      maxLength={255}
                                                                      value={VLD_DataPelanggan.values.INPUTDPel_Alamat || ""}
                                                                      disabled={false}
                                                                      onChange={(evt) => {
                                                                          handleFormDataPelanggan_Alamat(evt.target.value);

                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                      }}
                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                  />
                                                                  {VLD_DataPelanggan.touched.INPUTDPel_Alamat && VLD_DataPelanggan.errors.INPUTDPel_Alamat ? (
                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_Alamat}</FormFeedback>
                                                                  ) : null}
                                                              </div>
                                                          </Col>
                                                      </Row>

                                                      <Row>
                                                          <Col xl={12} className="pt-3 text-sm-end">
                                                              <button
                                                                  className="btn btn-primary btn-block align-right"
                                                                  type="submit"
                                                                  disabled={EnableDisableBTNCreateCustomer}
                                                              >
                                                                  {
                                                                      updateLoading
                                                                          ?
                                                                          <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0 me-2" color="light" /> Proses Perubahan Data . . .</>
                                                                          :
                                                                          <><i className="fas fa-user-edit font-size-16 align-middle me-2"></i> Update Data Nasabah</>
                                                                  }
                                                              </button>
                                                          </Col>
                                                      </Row>

                                                  </Col>
                                              </Row>
                                          </Form>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }
                  

          </Container>
          </div>
    </React.Fragment>
  );
};

CustomerSettings.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CustomerSettings);
