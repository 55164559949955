import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Alert,
    Modal,
    Input,
    Collapse,
    Label,
    Badge,
    Button,
    Spinner,
    InputGroup,
    Form,
    FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableContainer from '../../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select";
import jwt_decode from "jwt-decode";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Indonesian } from "flatpickr/dist/l10n/id.js"
import {
    fetchTableReportPawnshopPhoneREPAYMENT,
    fetchDetailTRXPelunasanKontrak,
    validationPelunasanContract,
    rejectionPelunasanContract,
    fetchCountingReportDataGadaiPhoneEXTEND,
    fetchTableReportPawnshopPhoneREPAYMENT_MANUAL,
    fetchCountingReportDataGadaiPhoneREPAYMENT,
    fetchCountingReportDataGadaiPhoneREPAYMENT_MANUAL,
} from "../../../../helpers/backend_helper";

import {
    FormatNoKontrak,
    FormatTipeASBarang,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatTerhapusKontrak,
    FormatDateTime,
    FormatConfirmed,
    FormatBarangJaminan,
    FormatMetodeTransaksi,
    FormatKeterlambatan,
    FormatNominalGadai,
} from "./isMasterTableCol";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

let MasterDataTable = [{}];
let isUUIDOperator = null;
let isValopKodeCabang = null;
let MasterSummaryReport = null;

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ManagementReportPawnshopRepayment = props => {
    moment.tz.setDefault('Asia/Jakarta');

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "3000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isShowInformasi, setisShowInformasi] = useState(false);

    const [FilterManualTglFromVAL, setFilterManualTglFromVAL] = useState(null);
    const [FilterManualTglToVAL, setFilterManualTglToVAL] = useState(null);

    const VLDFilterManual = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            FilterManualTglFrom: FilterManualTglFromVAL|| undefined,
            FilterManualTglTo: FilterManualTglToVAL || undefined,
            isUIDAccount: isUUIDOperator || undefined,
            KodeCabang: isValopKodeCabang || undefined,
            LimitData: 1000,
        },
        validationSchema: Yup.object({
            FilterManualTglFrom: Yup.date().required("Tanggal DARI wajib diisi tidak boleh kosong!"),
            FilterManualTglTo: Yup.date().required("Tanggal SAMPAI wajib diisi tidak boleh kosong!"),
        }),
        onSubmit: async (values) => {
            await FeedFunctionDataForTableMANUALFilter(values);

            await FeedFunctionDataForCOUNTINGMANUALFilter(values);
            //console.log(values);
        }
    });

    const handleClickisShowInformasi = () => {
        setisShowInformasi(!isShowInformasi);
    };

    const dispatch = useDispatch();
    
    const [isDataLoading, setisDataLoading] = useState(false);

    async function FeedFunctionDataForTableMANUALFilter(dsajkldhsajdhjksahjd) {
        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);
        MasterDataTable = [{}];

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneREPAYMENT_MANUAL(dsajkldhsajdhjksahjd);
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    async function FeedFunctionDataForCOUNTINGMANUALFilter(dsajkldhsajdhjksahjd) {
        setresultCountingTRXPelunasan(null);

        setTimeout(async () => {
            try {
                let ARDIMETAL = await fetchCountingReportDataGadaiPhoneREPAYMENT_MANUAL(dsajkldhsajdhjksahjd);
                //console.log()
                setresultCountingTRXPelunasan(ARDIMETAL);

                //showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');
            }
            catch (Err) {
                //showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    async function FeedFunctionDataForTableValidasiTransaksi(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);
        MasterDataTable = [{}];

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneREPAYMENT({ LimitData: 1000, FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                //showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                //showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    const [isDataValidsasiTransaksi, setisDataValidsasiTransaksi] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }
    }

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    useEffect(() => {
        autoUpdateProfile();

        FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
        FeedCountingTRXPelunasan('isTRXAll');
        /*setInterval(async () => {
            {
                await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
            }
        }, 10000); //Refresh data setiap 10s*/

        setInterval(async () => {
            {
                await autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s


    }, [dispatch]);


    const [isNomorKontrak, setisNomorKontrak] = useState(null);

    async function FeedDetailTRXPelunasan(idTransaksi) {
        setresultDetailTRXPelunasan(null);
        setisNomorKontrak(null);

        try {
            let isResultnya = await fetchDetailTRXPelunasanKontrak({ isIDTrans: idTransaksi });

            setresultDetailTRXPelunasan(isResultnya);

            setisNomorKontrak(isResultnya.PPGD_NoKontrak);
            //showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            //showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    /*async function FeedCountingTRXPelunasan(WhatsFilter) {
        setresultCountingTRXPelunasan(null);

        try {
            let isResultnya = await fetchCountingReportDataGadaiPhoneEXTEND({ isUIDAccount: isUUIDOperator, FilterDate: WhatsFilter });

            setresultCountingTRXPelunasan(isResultnya);
            //showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            //showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }*/

    async function FeedCountingTRXPelunasan(WhatsFilter) {
        setresultCountingTRXPelunasan(null);

        try {
            let isResultnya = await fetchCountingReportDataGadaiPhoneREPAYMENT({ isUIDAccount: isUUIDOperator, FilterDate: WhatsFilter });

            setresultCountingTRXPelunasan(isResultnya);
            //showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            //showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    const isColumnsData = useMemo(
        () => [
            {
                Header: "No. Kontrak",
                accessor: "PLPGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            /*{
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "isCustomer.NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },*/
            {
                Header: "Tgl. Pelunasan",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PLPGD_TglPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            /*{
                Header: "Tgl. Ja.Po",
                accessor: "PPGD_JTempoTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },*/
            {
                Header: "Nasabah",
                accessor: "isPelunasanCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Jaminan",
                accessor: "isPelunasanDataContract.PGDJaminan_Tipe",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatBarangJaminan {...cellProps} />;
                },
            },
            {
                Header: "Nominal",
                accessor: "PLPGD_NominalPelunasan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            {
                Header: "Metode",
                accessor: "PLPGD_MetodePembayaran",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatMetodeTransaksi {...cellProps} />;
                },
            },
            /*{
                Header: "Keterlambatan",
                accessor: "PPGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatKeterlambatan {...cellProps} />;
                },
            },*/
            {
                Header: "Status",
                accessor: "PLPGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <Badge className="bg-success font-size-11"><span>Diterima</span></Badge>
                            :
                            !cellProps.value && cellProps.value !== null
                                ?
                                <Badge className="bg-danger font-size-11"><span>Tidak Valid</span></Badge>
                                :
                                cellProps.value === null
                                    ?
                                    <Badge className="badge-soft-info font-size-11"><span>Belum Validasi</span></Badge>
                                    :
                                    "-"
                    );
                },
            },
            /*{
                Header: "Cabang",
                accessor: "isExtendOperator.user_cabang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCabang {...cellProps} />;
                },
            },*/
            {
                Header: "Operator",
                accessor: "isPelunasanOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Validator",
                accessor: "isPelunasanOwner.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                    <Link
                                        className="btn btn-sm btn-soft-primary"
                                        onClick={async (event) => {

                                            await FeedDetailTRXPelunasan(cellProps.cell.row.allCells[9].value);

                                            setisIDPelunasanSelected(cellProps.cell.row.allCells[9].value);
                                            isShowModalDetilPelunasan();
                                        }}
                                    >
                                        <i className="mdi mdi-eye-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Transaksi
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const [isIDPelunasanSelected, setisIDPelunasanSelected] = useState(null);
    const [isCommandPelunasan, setisCommandPelunasan] = useState(null);
    const [isModalDetilPelunasan, setisModalDetilPelunasan] = useState(false);
    const [resultCountingTRXPelunasan, setresultCountingTRXPelunasan] = useState(null);
    const [resultDetailTRXPelunasan, setresultDetailTRXPelunasan] = useState(null);
    const [isFileBuktiPayPelunasan, isSetFileBuktiPayPelunasan] = useState(false);
    const [isFileIMGBuktiPayPelunasan, isSetFileIMGBuktiPayPelunasan] = useState(null);

    function isShowModalDetilPelunasan() {
        setisModalDetilPelunasan(!isModalDetilPelunasan);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function getFotoBuktiBayarPelunasan(isFileName) {
        if (isFileName !== null) {
            try {
                //apply base url for axios
                let API_URL = '';
                let API_IMAGE_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
                const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
                const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
                    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND + ":" + API_IMAGE_PORT_INTERNET;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
                    }

                }

                return "//" + API_URL + "/img-assets/imgBuktiPelunasan/" + isFileName;

            }
            catch (Err) {
                //Nothing
                //console.log(Err);
            }
        }
    }

    const formPelunasanVLD = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isFieldDataID: isIDPelunasanSelected || undefined,
            isFieldCommand: isCommandPelunasan || undefined,
            isFieldUIDValidator: opUUID || undefined,
        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: async (values) => {
            if (isCommandPelunasan === "Validation") {
                try {
                    await validationPelunasanContract(values);

                    showToastNotification('Success!', 'Data Transaksi Pelunasan dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
                }
                catch (Err) {
                    showToastNotification('Oops!', 'Data Transaksi Pelunasan dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                }
            }
            else {
                try {
                    await rejectionPelunasanContract(values);

                    showToastNotification('Success!', 'Data Transaksi dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' berhasil diperbaharui!', 'warning');
                }
                catch (Err) {
                    showToastNotification('Oops!', 'Data Transaksi dengan ID #' + isIDPelunasanSelected + ' untuk kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
                }
            }

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            await FeedCountingTRXPerpanjang('isTRXAll');
        },
    });

    let summaryReports1 = [
        {
            title: "Total Data Pelunasan",
            iconClass: "fas fa-database",
            bgColor: "primary",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalPelunasan : "-",
            value_color: "text-primary",
        },
        {
            title: "Nilai Kontrak Sudah Lunas",
            iconClass: "far fa-money-bill-alt",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNilaiKontrak.Total || 0) : "Rp. -",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Keuntungan Pelunasan",
            iconClass: "bx bxs-rocket",
            bgColor: "success",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format((resultCountingTRXPelunasan.TotalNominalValid.Total - resultCountingTRXPelunasan.TotalNilaiKontrak.Total) || 0) : "Rp. -",
            value_color: "text-success",
        },
    ];

    /*let summaryReports = [
        {
            title: "Total Transaksi Pending",
            iconClass: "bx bxs-hourglass-top",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalPending || 0 : "-",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Transaksi Pending",
            iconClass: "bx bxs-hourglass-top",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalPending.Total || 0) : "-",
            value_color: "text-info",
        },
        {
            title: "Transaksi Tidak Valid",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalInvalid || 0 : "- / -",
            value_color: "text-danger",
        },
        {
            title: "Akumulasi Transaksi Tidak Valid",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalInvalid.Total || 0) : "- / -",
            value_color: "text-danger",
        },
        {
            title: "Transaksi Valid",
            iconClass: "far fa-check-circle",
            bgColor: "success",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalValid || 0 : "- / -",
            value_color: "text-success",
        },
        {
            title: "Akumulasi Transaksi Valid",
            iconClass: "far fa-check-circle",
            bgColor: "success",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalValid.Total || 0) : "- / -",
            value_color: "text-success",
        },
        {
            title: "Transaksi Tunai",
            iconClass: "fas fa-money-check-alt",
            bgColor: "primary",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalTunai || 0 : "- / -",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Transaksi Tunai",
            iconClass: "fas fa-money-check-alt",
            bgColor: "primary",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalTunai.Total || 0) : "- / -",
            value_color: "text-primary",
        },
        {
            title: "Transaksi Transfer Bank",
            iconClass: "bx bxs-bank",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalNonTunai || 0 : "- / -",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Transaksi Transfer Bank",
            iconClass: "bx bxs-bank",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalNonTunai.Total || 0) : "- / -",
            value_color: "text-info",
        },
    ];*/

    let summaryReports2 = [
        {
            title: "Total Transaksi Pending",
            iconClass: "bx bxs-hourglass-top",
            bgColor: "warning",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalPending || 0 : "-",
            value_color: "text-warning",
        },
        {
            title: "Akumulasi Transaksi Pending",
            iconClass: "bx bxs-hourglass-top",
            bgColor: "warning",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalPending.Total || 0) : "-",
            value_color: "text-warning",
        },
    ];

    let summaryReports3 = [
        {
            title: "Transaksi Tidak Valid",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalInvalid || 0 : "- / -",
            value_color: "text-danger",
        },
        {
            title: "Transaksi Valid",
            iconClass: "far fa-check-circle",
            bgColor: "success",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalValid || 0 : "- / -",
            value_color: "text-success",
        },
        {
            title: "Transaksi Tunai",
            iconClass: "fas fa-money-check-alt",
            bgColor: "primary",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalTunai || 0 : "- / -",
            value_color: "text-primary",
        },
        {
            title: "Transaksi Transfer Bank",
            iconClass: "bx bxs-bank",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? resultCountingTRXPelunasan.TotalNonTunai || 0 : "- / -",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Transaksi Tidak Valid",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalInvalid.Total || 0) : "- / -",
            value_color: "text-danger",
        },
        {
            title: "Akumulasi Transaksi Valid",
            iconClass: "far fa-check-circle",
            bgColor: "success",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalValid.Total || 0) : "- / -",
            value_color: "text-success",
        },
        {
            title: "Akumulasi Transaksi Tunai",
            iconClass: "fas fa-money-check-alt",
            bgColor: "primary",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalTunai.Total || 0) : "- / -",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Transaksi Transfer Bank",
            iconClass: "bx bxs-bank",
            bgColor: "info",
            textColor: "text-white",
            description: resultCountingTRXPelunasan !== null ? "-" : "NaN",
            value: resultCountingTRXPelunasan !== null ? CurrencyFormatter.format(resultCountingTRXPelunasan.TotalNominalNonTunai.Total || 0) : "- / -",
            value_color: "text-info",
        },
    ];

    //meta title
    document.title = "Laporan Data Pelunasan Gadai - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Laporan Data Pelunasan Gadai")}
                  />

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports1.map((report, key) => (
                              <Col md="4" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-info fw-medium placeholder-glow"
                                                                      : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports2.map((report, key) => (
                              <Col md="6" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-info fw-medium placeholder-glow"
                                                                      : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports3.map((report, key) => (
                              <Col md="3" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                  ? "text-info fw-medium placeholder-glow"
                                                                  : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                  ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  {/*IS MODAL FOR DETIL PELUNASAN KONTRAK*/}
                  <Modal
                      isOpen={isModalDetilPelunasan}
                      toggle={() => {
                          isShowModalDetilPelunasan();
                      }}
                      backdrop={'static'}
                      id="modalDetilPelunasan"
                      className={'modal-dialog-centered modal-xl d-print-none'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalDetilPelunasanLabel"><i className=" mdi mdi-information-outline me-2"></i>DETIL TRANSAKSI PELUNASAN #{isIDPelunasanSelected}</h5>

                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalDetilPelunasan(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <Row>
                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Kontrak</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <Badge className="bg-info font-size-12"><span># {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_NoKontrak : "NaN"} </span></Badge>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Metode Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {
                                              resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_MetodePembayaran === "Transfer Bank" ? <Badge className="badge-soft-success font-size-11"><span>Transfer Bank</span></Badge> : resultDetailTRXPelunasan.PLPGD_MetodePembayaran === "E-Wallet" ? <Badge className="badge-soft-primary font-size-11"><span>E-Wallet</span></Badge> : <Badge className="badge-soft-danger font-size-11"><span>Tunai</span></Badge> : "NaN"
                                          }
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Tgl. Pelunasan</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? moment(resultDetailTRXPelunasan.PLPGD_TglPelunasan).tz('Asia/Jakarta').format('D MMMM YYYY, HH:mm:ss') : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Status Validasi</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null ? <Badge className="badge-soft-info font-size-11"><span>Belum Validasi</span></Badge> : resultDetailTRXPelunasan.PLPGD_isConfirmed ? <Badge className="bg-success font-size-11"><span>Diterima</span></Badge> : <Badge className="bg-danger font-size-11"><span>Tidak Valid</span></Badge> : "NaN"}
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bank Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_Bank : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Rek. A/N Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_Nama : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Nasabah</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekAsal_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">No. Rek. Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_RekTujuan_NoRek : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Nominal Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span className="text-primary font-size-13">{resultDetailTRXPelunasan !== null ? CurrencyFormatter.format(resultDetailTRXPelunasan.PLPGD_NominalPelunasan) : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Operator</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.isPelunasanOperator.fullName !== null ? resultDetailTRXPelunasan.isPelunasanOperator.fullName : "NaN" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Validasi Oleh</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      <Col sm={6}>
                                          <span>{resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.isPelunasanOwner !== null ? resultDetailTRXPelunasan.isPelunasanOwner.fullName : "-" : "NaN"}</span>
                                      </Col>
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              <Col sm={6} className={"pb-1"}>
                                  <Row>
                                      <Col sm={5}>
                                          <span className="fw-bold">Bukti Pembayaran</span>
                                      </Col>
                                      <Col sm={1}>
                                          <span className="fw-bold">:</span>
                                      </Col>
                                      {
                                          resultDetailTRXPelunasan !== null
                                              ?
                                              resultDetailTRXPelunasan.PLPGD_BuktiPembayaran !== null
                                                  ?
                                                  <><Col sm={6}>
                                                      <Link onClick={() => {
                                                          isSetFileIMGBuktiPayPelunasan(resultDetailTRXPelunasan.PLPGD_BuktiPembayaran);
                                                          isSetFileBuktiPayPelunasan(true);

                                                      }}>Lihat Bukti Foto</Link>
                                                  </Col>
                                                  </>
                                                  :
                                                  <>
                                                      <Col sm={6}>
                                                          <span>Tidak Ada</span>
                                                      </Col>
                                                  </>
                                              :
                                              null
                                      }
                                  </Row>
                                  <hr className="" style={{ borderTop: "1px solid #DDE6F4" }} />
                              </Col>

                              {isFileBuktiPayPelunasan ? (
                                  <Lightbox
                                      mainSrc={getFotoBuktiBayarPelunasan(resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_BuktiPembayaran : null)}
                                      enableZoom={true}
                                      imageCaption={"Foto Bukti Pembayaran Pelunasan Kontrak"}
                                      onCloseRequest={() => {
                                          isSetFileBuktiPayPelunasan(false);
                                      }}
                                  />
                              )
                                  : null}
                          </Row>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-info" onClick={() => {
                              setisModalDetilPelunasan(false);
                          }}>Tutup</button>
                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null && role !== "Operator"
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPelunasan("Validation");

                                      e.preventDefault();
                                      formPelunasanVLD.handleSubmit();
                                      setisModalDetilPelunasan(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-success" >Konfirmasi</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }

                          {resultDetailTRXPelunasan !== null ? resultDetailTRXPelunasan.PLPGD_isConfirmed === null && role !== "Operator"
                              ?
                              <Form
                                  onSubmit={(e) => {
                                      setisCommandPelunasan("Reject");

                                      e.preventDefault();
                                      formPelunasanVLD.handleSubmit();
                                      setisModalDetilPelunasan(false);
                                      return false;
                                  }}
                              >
                                  <button type="submit" className="btn btn-danger" >Tolak</button>
                              </Form>
                              :
                              null
                              :
                              null
                          }
                      </div>
                  </Modal>

                  {
                      isDataLoading
                          ?
                          <Col lg={12} className={"mt-5 pb-5 text-center"}>
                              <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                          </Col>
                          :
                          <Row>
                              <Col lg="12">
                                  <Card>
                                      <CardBody>
                                          <Row>
                                              {/*ACCORDION INFORMASI*/}
                                              <Col xl={12} className={"pb-4"}>
                                                  <div
                                                      className="accordion"
                                                      id="showInformasiTab"
                                                  >
                                                      <div className="accordion-item ">
                                                          <h2 className={classnames(
                                                              "accordion-header",
                                                              /*{ "bg-light": !isShowInformasi },*/

                                                          )} id="showInformasiTabHeading">
                                                              <button
                                                                  className={classnames(
                                                                      "accordion-button",
                                                                      "fw-medium",
                                                                      { collapsed: !isShowInformasi },
                                                                      { "text-white": !isShowInformasi },
                                                                      { "text-white": isShowInformasi },
                                                                      /*{ "text-danger bg-light": isShowInformasi },*/
                                                                  )}
                                                                  type="button"
                                                                  onClick={handleClickisShowInformasi}
                                                                  style={{ cursor: "pointer", backgroundColor: '#101218' }}
                                                              >
                                                                  <i className="text-muted fw-bold mdi mdi-information-outline me-2"></i> {!isShowInformasi ? "TAMPILKAN PETUNJUK INFORMASI" : "SEMBUNYIKAN PETUNJUK INFORMASI"}
                                                              </button>
                                                          </h2>

                                                          <Collapse
                                                              isOpen={isShowInformasi}
                                                              className="accordion-collapse"
                                                          >
                                                              <div className="accordion-body">
                                                                  <div className="text-black">
                                                                      <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                                      <br />
                                                                      <p>#Penjelesan Fungsi Tombol <b>Aksi</b> : </p>
                                                                      <ul style={{ listStyle: 'none' }}>
                                                                          <li className="pb-3">
                                                                              <Link
                                                                                  to="#"
                                                                                  className="btn btn-sm btn-soft-primary font-size-14"
                                                                                  onClick={() => {
                                                                                      return false;
                                                                                  }}
                                                                              >
                                                                                  <i className="mdi mdi-eye-outline" id="detailtooltipINFO" />
                                                                                  <UncontrolledTooltip placement="top" target="detailtooltipINFO">
                                                                                      Detil Transaksi
                                                                                  </UncontrolledTooltip>
                                                                              </Link> Tombol Detil Transaksi
                                                                              <ul style={{ listStyle: 'inside' }}>
                                                                                  <li><b>#FUNGSI</b> : Berfungsi untuk melihat detil kontak atau melihat isi data kontrak secara keseluruhan.</li>
                                                                              </ul>
                                                                          </li>
                                                                      </ul>
                                                                      <p>~Terimakasih ^.^</p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                  </div>
                                                  {/*<div >
                                              <Alert className="alert-info" role="alert">
                                                  <h5 className="alert-heading text-primary pb-2"><i className="mdi mdi-information-outline me-2"></i> INFORMASI</h5>
                                                  <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                  <p>~Terimakasih ^.^</p>
                                              </Alert>
                                          </div>*/}
                                              </Col>

                                              <Col lg={12} className="">
                                                  <div className="d-flex flex-wrap gap-2 text-center me-2">
                                                      <button
                                                          type="button"
                                                          className="btn btn-soft-danger  btn-label me-2 btn-sm fw-bold"
                                                          onClick={async (evt) => {

                                                          }}
                                                      >
                                                          <i className="far fa-times-circle label-icon"></i>Transaksi Tidak Valid
                                                      </button>
                                                      <button
                                                          type="button"
                                                          className="btn btn-soft-success  btn-label me-2 btn-sm fw-bold"
                                                          onClick={async (evt) => {

                                                          }}
                                                      >
                                                          <i className="bx bx-badge-check label-icon"></i>Transaksi Valid
                                                      </button>
                                                      <button
                                                          type="button"
                                                          className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                          onClick={async (evt) => {
                                                              setisDataLoading(true);
                                                              await FeedFunctionDataForTableValidasiTransaksi("isTRXToday");

                                                              await FeedCountingTRXPelunasan("isTRXToday");
                                                          }}
                                                      >
                                                          <i className="bx bx-calendar label-icon"></i>Transaksi Hari Ini
                                                      </button>
                                                      <button
                                                          type="button"
                                                          className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                          onClick={async (evt) => {
                                                              setisDataLoading(true);

                                                              setFilterManualTglFromVAL(null);
                                                              setFilterManualTglToVAL(null);

                                                              await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

                                                              await FeedCountingTRXPelunasan('isTRXAll');
                                                          }}
                                                      >
                                                          <i className="bx bx-sync label-icon"></i>Perbaharui Data
                                                      </button>
                                                  </div>

                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                              </Col>

                                              {/*Filter Manual*/}
                                              <Col lg={12} className="">
                                                  <Row>
                                                      <Col md={3}>
                                                          <div className="mb-3">
                                                              <h4 className="card-title col-form-label">Filter Data Berdasarkan Tanggal ~ </h4>
                                                          </div>
                                                      </Col>
                                                      <Col md={9}>
                                                          <Form onSubmit={(e) => {
                                                              e.preventDefault();
                                                              VLDFilterManual.handleSubmit();
                                                              return false;
                                                          }}>
                                                              <Row>
                                                                  <Col sm={5}>
                                                                      <div className="mb-3">
                                                                      <Flatpickr
                                                                          name="FilterManualTglFrom"
                                                                          id="FilterManualTglFrom"
                                                                          className={`form-control ${VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? "is-invalid" : ""
                                                                              }`}
                                                                          placeholder="Pilih Tanggal Dari"
                                                                          options={{
                                                                              altInput: true,
                                                                              altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                                              dateFormat: "Y-m-d",
                                                                              locale: Indonesian,
                                                                              disableMobile: "true",
                                                                              defaultDate: null,
                                                                              time_24hr: true,
                                                                              maxDate: 'today',
                                                                              onChange: (selectedDates, dateStr, instance) => setFilterManualTglFromVAL(dateStr + " 00:00:00" /*+ moment().format(" 00:00:00")*/),
                                                                          }}
                                                                          value={VLDFilterManual.values.FilterManualTglFrom || ""}
                                                                      />
                                                                      {
                                                                          VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? (
                                                                              <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglFrom}</FormFeedback>
                                                                          ) : null
                                                                          }
                                                                      </div>
                                                                  </Col>
                                                                  <Col sm={5}>
                                                                      <div className="mb-3">
                                                                      <Flatpickr
                                                                          name="FilterManualTglTo"
                                                                          id="FilterManualTglTo"
                                                                          className={`form-control ${VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? "is-invalid" : ""
                                                                              }`}
                                                                          placeholder="Pilih Tanggal Sampai"
                                                                          options={{
                                                                              altInput: true,
                                                                              altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                                              dateFormat: "Y-m-d",
                                                                              locale: Indonesian,
                                                                              time_24hr: true,
                                                                              disableMobile: "true",
                                                                              defaultDate: null,
                                                                              minDate: FilterManualTglFromVAL,
                                                                              maxDate: 'today',
                                                                              onChange: (selectedDates, dateStr, instance) => setFilterManualTglToVAL(dateStr + " 23:59:59" /*+ moment().format(" HH:mm:ss")*/),
                                                                          }}
                                                                          value={VLDFilterManual.values.FilterManualTglTo || ""}
                                                                      />
                                                                      {
                                                                          VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? (
                                                                              <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglTo}</FormFeedback>
                                                                          ) : null
                                                                          }
                                                                      </div>
                                                                  </Col>
                                                                  <Col sm={2}>
                                                                      <Button color="btn btn-square btn-primary w-md me-2" disabled={false} type="submit"
                                                                          style={({ width: "100%", cursor: "pointer", fontSize: 12 })}
                                                                      >
                                                                          <i className="mdi mdi-filter" style={{ marginRight: '5px' }}></i> Filter Data
                                                                      </Button>
                                                                  </Col>
                                                              </Row>
                                                          </Form>
                                                      </Col>
                                                  </Row>
                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />


                                              </Col>
                                              {
                                                  isDataValidsasiTransaksi != null && isDataValidsasiTransaksi.length > 0
                                                      ?
                                                      <Col lg={12} className={"mt-1"}>
                                                          <TableContainer
                                                              className={"table table-hover rounded"}
                                                              columns={isColumnsData}
                                                              data={isDataValidsasiTransaksi || [{}]}
                                                              isGlobalFilter={true}
                                                              isAddOptions={false}
                                                              isPlaceholderData={"Cari data pelunasan. . ."}
                                                              customPageSize={10}
                                                              isSorted={"desc"}
                                                              isLoading={true}
                                                          />
                                                      </Col>
                                                      :
                                                      <Col lg={12} className="mt-5 pb-5 text-center">
                                                          <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                                      </Col>
                                              }
                                          </Row>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }
                  
          </Container>
          </div>
    </React.Fragment>
  );
};

ManagementReportPawnshopRepayment.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ManagementReportPawnshopRepayment);
