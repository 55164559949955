import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import authHeader  from "./jwt-token-access/auth-token-header";
import { fetchRefreshToken } from "../helpers/backend_helper";

//apply base url for axios
export let API_URL = "";
const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

export let API_IMAGE_URL = "";
const API_IMAGE_PORT_ROOT = process.env.REACT_APP_IMAGE_PORT_ROOT;
const API_IMAGE_PORT_LOCAL = process.env.REACT_APP_IMAGE_PORT_LOCAL;
const API_IMAGE_PORT_INTERNET = process.env.REACT_APP_IMAGE_PORT_INTERNET;

//Using Global Domain Server
if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
    /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND + ":" + API_IMAGE_PORT_INTERNET;
}
else {
    //127.0.0.1
    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_IMAGE_PORT_ROOT;
    }
    //Local IP 192.X.X.X
    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_IMAGE_PORT_LOCAL;
    }
    //Public IP X.X.X.X
    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
        API_IMAGE_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_IMAGE_PORT_INTERNET;
    }

}

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
    axiosApi.defaults.headers["Refresh_token"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["refreshToken"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Content-Type"] = "application/json";

  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
    axiosApi.defaults.headers["Refresh_token"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["refreshToken"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Content-Type"] = "application/json";

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
    axiosApi.defaults.headers["Refresh_token"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["refreshToken"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Content-Type"] = "application/json";

  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
    axiosApi.defaults.headers["Refresh_token"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["refreshToken"] = JSON.parse(localStorage.getItem("authRToken"));
    axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Content-Type"] = "application/json";

  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function postFormData(url, data, config = {}) {
    axiosApi.defaults.headers["Refresh_token"] = JSON.parse(localStorage.getItem("authRToken"));
    //axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Authorization"] = "Bearer " + authHeader().obj;
    axiosApi.defaults.headers["Content-Type"] = "multipart/form-data";
    
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then((response) => response.data);
}