import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import {
    Button,
} from "reactstrap";
import Moment from 'moment';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const FormatNoKontrak = (cell) => {
    return (
        (
            cell.value
                ?
                <Link
                    to="#"
                    className="text-body fw-bold text-center"><Badge className="badge-soft-primary font-size-11">{"#" + cell.value}</Badge>
                </Link>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
};

const FormatDateTime = (cell) => {
    let DTime = cell.value;
    //
    let TakeDate = "";
    let TakeTime = "";

    let DTimeFormat = "";

    let isDateNoFormat = "";

    try {
        isDateNoFormat = moment(cell.value);

        /*if (isDateNoFormat.tz('Asia/Jakarta').format("DDMMMMYYYY") === moment.tz('Asia/Jakarta').format("DDMMMMYYYY")) {
            DTimeFormat = <Badge className="badge-soft-primary font-size-11">Hari Ini</Badge>;
        }
        else {
            DTimeFormat = isDateNoFormat.tz('Asia/Jakarta').format("DD MMMM YYYY, HH:mm:ss");
        }*/

        //DTimeFormat = isDateNoFormat.tz('Asia/Jakarta').format("DD MMMM YYYY, HH:mm:ss");
        DTimeFormat = isDateNoFormat.tz('Asia/Jakarta').format("DD MMM YYYY");
    }
    catch (Err) {
        DTimeFormat = "-";
    }

    return (
        (
            cell.value
                ?
                DTimeFormat
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
};

const FormatTipeASBarang = (cell) => {
    return (
        (
            cell.value
                ?
                cell.value
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatOnlyLoading = (cell) => {
    return (
        (
            cell.value
                ?
                <strong>{cell.value}</strong>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatRAM_Internal = (cell) => {
    return (
        (
            cell.value
                ?
                <strong>{cell.value} GB</strong>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatNIKCustomer = (cell) => {
    return cell.value ? <Badge className="badge-soft-info font-size-12">{cell.value}</Badge> : '-';
}

const FormatNamaCustomer = (cell) => {
    return (
        (
            cell.value
                ?
                cell.value
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
});

const FormatNominalGadai = (cell) => {
    return (
        (
            cell.value
                ?
                cell.row.allCells[0].value
                ?
                    <span className="text-danger font-size-13 fw-bold">{CurrencyFormatter.format(cell.value)}</span>
                    :
                    cell.row.allCells[8].value 
                    ?
                    <span className="text-success font-size-13 fw-bold">{CurrencyFormatter.format(cell.value)}</span>
                        :
                        !cell.row.allCells[7].value && cell.row.allCells[7].value !== null
                            ?
                            <span className="text-warning font-size-13 fw-bold">{CurrencyFormatter.format(cell.value)}</span>
                            :
                    <span className="text-primary font-size-13 fw-bold">{CurrencyFormatter.format(cell.value)}</span>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatNominalPerpanjang = (cell) => {
    return (
        (
            cell.value
                ?
                <span className="text-primary font-size-13">{CurrencyFormatter.format(cell.value)}</span>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatNamaCabang = (cell) => {
    let isResult = cell.value;

    return (
        (
            cell.value
                ?
                isResult === "Pusat" ? <Badge className="badge-soft-primary font-size-12">Pusat</Badge> : <Badge className="badge-soft-success font-size-12">{isResult}</Badge>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatNamaOperator = (cell) => {
    return (
        (
            cell.value
                ?
                cell.value
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatMetodeTransaksi = (cell) => {
    switch (cell.value) {
        case "Transfer Bank":
            return <Badge className="badge-soft-success font-size-11">Transfer Bank</Badge>
        case "E-Wallet":
            return <Badge className="badge-soft-primary font-size-11">E-Wallet</Badge>
        case "Tunai":
            return <Badge className="badge-soft-danger font-size-11">Tunai</Badge>
        default:
            return cell.value
                ?
                cell.value
                :
                cell.value == null
                    ?
                    "-"
                :
                <span className="placeholder-glow">
                    <Link
                        to="#"
                        className="text-body fw-bold text-center placeholder col-8">NaN
                    </Link>
                </span>;
    }; 
}

const FormatFieldAction = (cell) => {
    return cell.value ? cell.value : '';
}

const FormatJatuhTempo = (cell) => {
    return (
        (
            cell.value > 0 || cell.value === 0
                ?
                <Badge className="bg-danger font-size-11">{cell.value + " Hari"}</Badge>
                :
                cell.value !== null
                    ?
                    cell.value === -1 && !cell.row.allCells[0].value && !cell.row.allCells[11].value
                        ?
                        !cell.row.allCells[10].value
                            ?
                            "-"
                            :
                            <Badge className="bg-success font-size-11">Tidak</Badge>
                        :
                        "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
            /*cell.value
                ?
                cell.value > 0 || cell.value === 0 ? <Badge className="bg-danger font-size-11">{cell.value + " Hari"}</Badge>
                :
                    "-"
                :
                cell.value === -1
                    ?
                    <Badge className="bg-success font-size-11">Tidak</Badge>
                :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>*/
        )
    );
}
const FormatPelunasan = (cell) => {
    //console.log(cell.row.allCells[1].value)
    return (
        (
            cell.value !== null
                ?
                cell.value
                    ?
                    <Badge className="bg-success font-size-11">LUNAS</Badge>
                    :
                    cell.row.allCells[0].value
                        ?
                        <Badge className="bg-danger font-size-11">GAGAL</Badge>
                        :
                        cell.row.allCells[1].value !== undefined
                            ?
                            !cell.row.allCells[10].value && cell.row.allCells[10].value !== null
                                ?
                                <Badge className="bg-danger font-size-11">DITOLAK</Badge>
                                :
                                !cell.row.allCells[10].value
                                    ?
                                    <Badge className="bg-warning font-size-11">PENDING</Badge>
                                    :
                            <Badge className="bg-info font-size-11">BERJALAN</Badge>
                            :
                    "-"
                :
                <span className="placeholder-glow">
                    <Link
                        to="#"
                        className="text-body fw-bold text-center placeholder col-8">NaN
                    </Link>
                </span>
        )
    );
}

const FormatStatusBarang = (cell) => {
    return (
        (
            cell.value || !cell.value
                ?
                cell.value ? <Badge className="bg-success font-size-11">{cell.value}</Badge> : cell.value == null ? "-" : <Badge className="bg-danger font-size-11">{cell.value}</Badge>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatConfirmed = (cell) => {
    return (
        (
            cell.value || !cell.value
                ?
                cell.value ? <Badge className="bg-info font-size-11">Approved</Badge> : cell.value == null ? "-" : <Badge className="bg-danger font-size-11">Rejected</Badge>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

const FormatRejected = (cell) => {
    return (
        (
            cell.value || !cell.value
                ?
                cell.value ? <Badge className="bg-danger font-size-12">YA</Badge> : cell.value == null ? "-" : <Badge className="bg-success  font-size-12">TIDAK</Badge>
                :
                cell.value == null
                    ?
                    "-"
                    :
                    <span className="placeholder-glow">
                        <Link
                            to="#"
                            className="text-body fw-bold text-center placeholder col-8">NaN
                        </Link>
                    </span>
        )
    );
}

export {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNIKCustomer,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatFieldAction,
    FormatOnlyLoading,
    FormatRAM_Internal,
    FormatJatuhTempo,
    FormatConfirmed,
    FormatRejected,
    FormatStatusBarang,
    FormatPelunasan,
    FormatNominalPerpanjang,
};