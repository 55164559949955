export const TXA_GET_AUTH_STATUS = "/auth/status";

//Authentication
export const TXA_POST_AUTH_SIGNIN = "/auth/sign-in";
export const TXA_DELETE_AUTH_SIGNOUT = "/auth/sign-out";

//Authentication Update Refresh_Token
export const TXA_GET_REFRESH_TOKEN = "/auth/token";

//Dashboard -> User Profile
export const TXA_SET_PROFILE_DATA = "/profile";

export const TXA_SET_UPDATE_PASSWORD = "/updatePassword";

export const TXA_POST_QR_CODE_WHATSAPP = "/api/v1/wagw/session/get-qr";
export const TXA_POST_WHATSAPP_SESSION_START = "/api/v1/wagw/session/start";
export const TXA_POST_WHATSAPP_SESSION_DELETE = "/api/v1/wagw/session/delete";
export const TXA_POST_WHATSAPP_SESSION_LIST = "/api/v1/wagw/session/list";
export const TXA_POST_WHATSAPP_SESSION_DETAIL = "/api/v1/wagw/session/detail";
export const TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT = "/api/v1/wagw/msg/send-text";
export const TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE = "/api/v1/wagw/msg/send-text-img";

export const TXA_GET_USER_MANAGEMENT = "/api/v3/fetchUserManagement";
export const TXA_GET_USER_DETAIL = "/api/v3/fetchUserDetail";
export const TXA_PUT_USER_DATA = "/api/v3/updateUserData";
export const TXA_PUT_USER_PASSWORD = "/api/v3/updateUserPass";
export const TXA_USER_REGISTRATION = "/api/v3/userRegistration";
export const TXA_USER_REMOVER = "/api/v3/userRemover";

export const TXA_POST_REGISTRASI_PELANGGAN_BARU = "/api/v3/postRegisteringNewCustomer";
export const TXA_POST_PERUBAHAN_DATA_PELANGGAN= "/api/v3/postUpdateDataCustomer";
export const TXA_FETCH_DATA_PELANGGAN_LAMA = "/api/v3/fetchPelanggan";
export const TXA_FETCH_DATA_PELANGGAN_TABLE_FULL = "/api/v3/fetchTableDataCustomerFull";
export const TXA_FETCH_DATA_PELANGGAN_BY_UID = "/api/v3/fetchPelangganByUID";
export const TXA_FETCH_DATA_PELANGGAN_BANK = "/api/v3/fetchPelangganBank";
export const TXA_FETCH_DATA_PELANGGAN_WITH_BANK = "/api/v3/fetchPelangganWithBank";
export const TXA_FETCH_DATA_PELANGGAN_LIST_BANK = "/api/v3/fetchPelangganListBank";

export const TXA_FETCH_FULL_MERK_HP = "/api/v3/fetchMerkHP";
export const TXA_FETCH_FULL_TIPE_HP_POST = "/api/v3/fetchTipeHP";
export const TXA_FETCH_FULL_TIPE_HP_SPEC = "/api/v3/fetchFullSpecTipeHP/";
export const TXA_FETCH_TAKSIRAN_TIPE_HP = "/api/v3/fetchNilaiTaksirHP";

export const TXA_FETCH_FULL_DATA_BANK = "/api/v3/fetchDataBank";
export const TXA_FETCH_FULL_DATA_BANK_X_EWALLET = "/api/v3/fetchDataBankXeWallet";
export const TXA_FETCH_FULL_DATA_EWALLET = "/api/v3/fetchDataEWallet";
export const TXA_FETCH_FULL_DATA_FAKE_BANK = "/api/v3/fetchDataFakeBank";
export const TXA_FETCH_FULL_DATA_BANK_SISTEM= "/api/v3/fetchDataBankSistem";
export const TXA_FETCH_DETAIL_DATA_BANK_SISTEM = "/api/v3/fetchDetailDataBankSistem";

export const TXA_SEND_TRANSAKSI_GADAI_BARANG_NEW = "/api/v3/newTRXGadaiBarang";
export const TXA_SEND_TRANSAKSI_GADAI_KONTRAK_FAILED = "/api/v3/setKontrakGadaiFAILED";
export const TXA_SEND_TRANSAKSI_GADAI_UPLOAD_FOTO_KTP = "/api/v3/uploadFotoKTP";
export const TXA_SEND_TRANSAKSI_GADAI_UPLOAD_FOTO_KELENGKAPAN = "/api/v3/uploadFotoKelengkapan";

export const TXA_GET_FOTO_KTP_NASABAH = "/img-assets/imgKTPNasabah/";
export const TXA_GET_FOTO_KELENGKAPAN_GADAI = "/img-assets/imgKelengkapan/";

export const TXA_STATISTIK_fetchNominalCashflowStatistik = "/api/v3/fetchNominalCashflowStatistik";
export const TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER = "/api/v3/fetchNominalCashflowStatistik_FILTER";

export const TXA_FETCH_COUNTING_DATA_PERPANJANG_DASHBOARD = "/api/v3/fetchCountingReportDashboard_EXTEND";
export const TXA_FETCH_COUNTING_DATA_PELUNASAN_DASHBOARD = "/api/v3/fetchCountingReportDashboard_REPAYMENT";
export const TXA_FETCH_COUNTING_DATA_KONTRAK_DASHBOARD = "/api/v3/fetchCountingReportDashboard_KONTRAK";

export const TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI = "/api/v3/fetchTableTRXValidation";
export const TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_EXTEND = "/api/v3/fetchTableTRXValidationEXTEND";
export const TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_EXTEND_MANUAL = "/api/v3/fetchCountingReportDataGadaiPhoneEXTEND-MANUAL";
export const TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_REPAYMENT = "/api/v3/fetchTableTRXValidationREPAYMENT";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP = "/api/v3/fetchTableReportPawnshopPhone";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_BY_BRAND = "/api/v3/fetchTableReportPawnshopPhoneByBrandHP";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_DELETED = "/api/v3/fetchTableReportPawnshopPhoneDeleted";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP = "/api/v3/fetchCountingReportDataGadaiPhone";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_REPAYMENT = "/api/v3/fetchCountingReportDataGadaiPhoneREPAYMENT";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_REPAYMENT_MANUAL = "/api/v3/fetchCountingReportDataGadaiPhoneREPAYMENT-MANUAL";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_EXTEND = "/api/v3/fetchCountingReportDataGadaiPhoneEXTEND";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_EXTEND_MANUAL = "/api/v3/fetchTableReportPawnshopPhoneEXTEND-MANUAL";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REPAYMENT = "/api/v3/fetchTableReportPawnshopPhoneREPAYMENT";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REPAYMENT_MANUAL = "/api/v3/fetchTableReportPawnshopPhoneREPAYMENT-MANUAL";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_EXTEND = "/api/v3/fetchTableReportPawnshopPhoneEXTEND";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_MANUAL = "/api/v3/fetchTableReportPawnshopPhoneMANUAL";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_AKTIF = "/api/v3/fetchTableReportPawnshopPhoneAKTIF";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_BY_BRAND = "/api/v3/fetchCountingReportDataGadaiPhoneByBrandHP";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_FAILED = "/api/v3/fetchCountingReportDataGadaiPhoneFAILED";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REJECT = "/api/v3/fetchCountingReportDataGadaiPhoneREJECT";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_LUNAS = "/api/v3/fetchCountingReportDataGadaiPhoneLUNAS";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_PENDING = "/api/v3/fetchCountingReportDataGadaiPhonePENDING";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_RUNNING = "/api/v3/fetchCountingReportDataGadaiPhoneRUNNING";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_LEW_JAPO = "/api/v3/fetchCountingReportDataGadaiPhoneLewJAPO";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TODAY = "/api/v3/fetchCountingReportDataGadaiPhoneTODAY";
export const TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_JAPO_TODAY = "/api/v3/fetchCountingReportDataGadaiPhoneJaPoTODAY";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_JAPO = "/api/v3/fetchTableReportPawnshopPhoneJAPO";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_PENDING = "/api/v3/fetchTableReportPawnshopPhonePENDING";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_FAILED = "/api/v3/fetchTableReportPawnshopPhoneFAILED";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_LUNAS = "/api/v3/fetchTableReportPawnshopPhoneLUNAS";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_REJECT = "/api/v3/fetchTableReportPawnshopPhoneREJECT";
export const TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_RUNNING = "/api/v3/fetchTableReportPawnshopPhoneRUNNING";
export const TXA_FETCH_UPDATE_VALIDASI_KONTRAK = "/api/v3/updateValidationContract";
export const TXA_FETCH_REJECT_DATA_KONTRAK = "/api/v3/rejectDataContract";
export const TXA_FETCH_DELETE_DATA_KONTRAK = "/api/v3/deleteDataContract";
export const TXA_FETCH_DETAIL_DATA_KONTRAK = "/api/v3/fetchDetailContract";

export const TXA_FETCH_CEK_NIK_EXISTS = "/api/v3/cekNIK/";
export const TXA_FETCH_CEK_NOHP_EXISTS = "/api/v3/cekNoHP/";
export const TXA_FETCH_INFORMASI_CABANG = "/api/v3/fetchDataCabang";

export const TXA_FETCH_DATA_NO_KONTRAK = "/api/v3/fetchDataNoKontrak";
/*export const TXA_FETCH_DATA_NO_KONTRAK_AKTIF = "/api/v3/fetchDataNoKontrakAKTIF";*/
export const TXA_FETCH_DETAIL_PERPANJANG_NO_KONTRAK = "/api/v3/fetchDetailPerpanjangNoKontrak";
export const TXA_FETCH_DETAIL_TRX_PERPANJANG_NO_KONTRAK = "/api/v3/fetchDetailTRXPerpanjangKontrak";
export const TXA_FETCH_DETAIL_PELUNASAN_NO_KONTRAK = "/api/v3/fetchDetailPelunasanNoKontrak";
export const TXA_FETCH_DETAIL_TRX_PELUNASAN_NO_KONTRAK = "/api/v3/fetchDetailTRXPelunasanKontrak";
export const TXA_POST_EXTEND_DATA_KONTRAK = "/api/v3/createExtendContract";
export const TXA_POST_VALIDATION_EXTEND_DATA_KONTRAK = "/api/v3/validationExtendContract";
export const TXA_POST_VALIDATION_PELUNASAN_DATA_KONTRAK = "/api/v3/validationPelunasanContract";
export const TXA_POST_VALIDATION_REJECT_EXTEND_DATA_KONTRAK = "/api/v3/rejectionExtendContract";
export const TXA_POST_VALIDATION_REJECT_PELUNASAN_DATA_KONTRAK = "/api/v3/rejectionPelunasanContract";
export const TXA_SEND_TRANSAKSI_UPLOAD_BUKTI_PERPANJANG = "/api/v3/uploadBuktiPerpanjang";
export const TXA_GET_FOTO_BUKTI_PERPANJANG = "/img-assets/imgBuktiPerpanjang/";

export const TXA_POST_PELUNASAN_DATA_KONTRAK = "/api/v3/createPelunasanContract";
export const TXA_SEND_TRANSAKSI_UPLOAD_BUKTI_PELUNASAN = "/api/v3/uploadBuktiPelunasan";
export const TXA_GET_FOTO_BUKTI_PELUNASAN = "/img-assets/imgBuktiPelunasan/";