import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

import jwt_decode from "jwt-decode";
import SidebarContentUser from "./SidebarContentUser";

let roleVAR = null;

const Sidebar = props => {

    const [uuid, setuuid] = useState(null);
    const [role, setrole] = useState(null);

    const eachUserData = async () => {

        //roleVAR = null;

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);

            if (DecodeUserData.role !== null) {
                roleVAR = DecodeUserData.role;
            }
        }
        catch (Err) {
            console.log(Err);
        }
    }

    useEffect(() => {
        roleVAR = null;

        setInterval(async () => {
            {
                await eachUserData();
            }
        }, 500);
    });

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link>
        </div>
              <div data-simplebar className="h-100">
                  {/*{
                      roleVAR === "Administrator" || roleVAR === "Superuser"
                          ?
                          props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />
                          :
                          props.type !== "condensed" ? <SidebarContentUser /> : <SidebarContentUser />
                  }*/}
                  {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
