import axios from "axios";
import { API_URL, del, get, post, put, postFormData } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const AToken = localStorage.getItem("authToken");
    const RToken = localStorage.getItem("authRToken");

    if (AToken != null && RToken != null) {
        return JSON.parse(AToken);
    }

    return null;
};

//is user is logged in
const isUserAuthenticated = async () => {
    return getLoggedInUser() !== null;
};

const getStoreToken = () => {
    const user = localStorage.getItem("authToken");
    if (user) return JSON.parse(user);
    return null;
};

const getStoreRToken = () => {
    const user = localStorage.getItem("authRToken");
    if (user) return JSON.parse(user);
    return null;
};


const fetchGetAuthStatus = data => {
        get(url.TXA_GET_AUTH_STATUS, data)
        .then(response => {
            //console.log(response.msg);
            if (response.result) {
                return response.msg;
            }

            throw response.msg;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }
            throw message;
        });
};

const postAuthSignIn = data => 
    post(url.TXA_POST_AUTH_SIGNIN, data)
        .then(response => {
            if (response.accessToken != null) return response;
            throw response;
        })
        .catch(err => {
            let message = "Sorry! something went wrong, please try again.";
            
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }
            throw message;
        });

const deleteAuthSignOut = data =>
    del(url.TXA_DELETE_AUTH_SIGNOUT, data)
        .then(response => {
            if (response == "OK") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message = "Sorry! something went wrong, please try again.";

            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }
            throw message;
        });

const fetchGetProfileData = data =>
    get(url.TXA_SET_PROFILE_DATA, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return JSON.parse(JSON.stringify(response.data));
            throw JSON.parse(JSON.stringify(response.data));
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const updateProfileData = data =>
    put(url.TXA_SET_PROFILE_DATA, data)
        .then(response => {
            if (response.message == "success")
                return JSON.parse(JSON.stringify(response));
            throw JSON.parse(JSON.stringify(response));
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchRefreshToken = async data =>
        post(url.TXA_GET_REFRESH_TOKEN, data)
        .then(response => {
            if (response != null)
                return response;
            throw response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        localStorage.removeItem("authToken");
                        localStorage.removeItem("authRToken");

                        window.location.href = '/auth/sign-in';

                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });
  
const putUpdatePassword = data =>
    put(url.TXA_SET_UPDATE_PASSWORD, data)
        .then(response => {

            if (response.message == "success") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const updateUserDataByUUID = data =>
    put(url.TXA_PUT_USER_DATA, data)
        .then(response => {

            if (response.message == "success") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const updateUserPassByUUID = data =>
    put(url.TXA_PUT_USER_PASSWORD, data)
        .then(response => {

            if (response.message == "success") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });
        
const registerNewUser = data =>
    post(url.TXA_USER_REGISTRATION, data)
        .then(response => {

            if (response.message == "success") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message;
            console.log(err.response);
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        message = err.response.data.message;
                        break;
                    case 403:
                        message = err.response.data.message;
                        break;
                    case 401:
                        message = err.response.data.message;
                        break;
                    case 404:
                        message = err.response.data.message;
                        break;
                    case 500:
                        message = err.response.data.message;
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const userRemover = data =>
    post(url.TXA_USER_REMOVER, data)
        .then(response => {

            if (response.message == "success") {
                return response;
            }

            throw response;
        })
        .catch(err => {
            let message;

            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        message = err.response.data.message;
                        break;
                    case 403:
                        message = err.response.data.message;
                        break;
                    case 401:
                        message = err.response.data.message;
                        break;
                    case 404:
                        message = err.response.data.message;
                        break;
                    case 500:
                        message = err.response.data.message;
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postRegisteringNewCustomer = data =>
    post(url.TXA_POST_REGISTRASI_PELANGGAN_BARU, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postUpdateDataCustomer = data =>
    post(url.TXA_POST_PERUBAHAN_DATA_PELANGGAN, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataPelangganLama = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_LAMA, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableDataCustomerFull = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_TABLE_FULL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postDataPelangganByUID = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_BY_UID, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullMerkHP = data =>
    get(url.TXA_FETCH_FULL_MERK_HP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullTipeHPByCode = data =>
    post(url.TXA_FETCH_FULL_TIPE_HP_POST, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullSpecTipeHP = data =>
    get(url.TXA_FETCH_FULL_TIPE_HP_SPEC + data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchNilaiTaksirHP = data =>
    post(url.TXA_FETCH_TAKSIRAN_TIPE_HP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullDataBank = data =>
    get(url.TXA_FETCH_FULL_DATA_BANK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataBankXeWallet = data =>
    get(url.TXA_FETCH_FULL_DATA_BANK_X_EWALLET, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullDataeWallet = data =>
    get(url.TXA_FETCH_FULL_DATA_EWALLET, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFullDataFakeBank = data =>
    get(url.TXA_FETCH_FULL_DATA_FAKE_BANK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataBankSistem = data =>
    post(url.TXA_FETCH_FULL_DATA_BANK_SISTEM, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDetailDataBankSistem = data =>
            post(url.TXA_FETCH_DETAIL_DATA_BANK_SISTEM, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataCustWithBank = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_WITH_BANK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataCustListBank = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_LIST_BANK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchPelangganBankSelect = data =>
    post(url.TXA_FETCH_DATA_PELANGGAN_BANK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postNewTransaksiGadaiBarang = data =>
    post(url.TXA_SEND_TRANSAKSI_GADAI_BARANG_NEW, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const setKontrakGadaiFAILED = data =>
    post(url.TXA_SEND_TRANSAKSI_GADAI_KONTRAK_FAILED, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postUploadFotoKelengkapan = data =>
    postFormData(url.TXA_SEND_TRANSAKSI_GADAI_UPLOAD_FOTO_KELENGKAPAN, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const postUploadFotoKTPNasabah = data =>
    postFormData(url.TXA_SEND_TRANSAKSI_GADAI_UPLOAD_FOTO_KTP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFotoKTPNasabah = data =>
    get(url.TXA_GET_FOTO_KTP_NASABAH + data)
        .then(response => {
            return url.TXA_GET_FOTO_KTP_NASABAH + response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchimgBuktiPerpanjang = data =>
    get(url.TXA_GET_FOTO_BUKTI_PERPANJANG + data)
        .then(response => {
            return url.TXA_GET_FOTO_BUKTI_PERPANJANG + response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });
const imgBuktiPelunasan = data =>
    get(url.TXA_GET_FOTO_BUKTI_PELUNASAN + data)
        .then(response => {
            return url.TXA_GET_FOTO_BUKTI_PERPANJANG + response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchFotoKelengkapan = data =>
    get(url.TXA_GET_FOTO_KELENGKAPAN_GADAI + data)
        .then(response => {
            return url.TXA_GET_FOTO_KTP_NASABAH + response;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const uploadBuktiPerpanjang = data =>
    postFormData(url.TXA_SEND_TRANSAKSI_UPLOAD_BUKTI_PERPANJANG, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const uploadBuktiPelunasan = data =>
    postFormData(url.TXA_SEND_TRANSAKSI_UPLOAD_BUKTI_PELUNASAN, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });
const fetchTableTRXValidationGET = data =>
    post(url.TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableTRXValidationEXTEND = data =>
    post(url.TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_EXTEND, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableTRXValidationEXTEND_MANUAL = data =>
    post(url.TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_EXTEND_MANUAL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableTRXValidationREPAYMENT = data =>
    post(url.TXA_FETCH_TABLEDATA_VALIDASI_TRANSAKSI_REPAYMENT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhone = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneByBrandHP = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_BY_BRAND, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneDeleted = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_DELETED, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhone = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneREPAYMENT = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_REPAYMENT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneREPAYMENT_MANUAL = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_REPAYMENT_MANUAL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneEXTEND = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_EXTEND, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneEXTEND_MANUAL = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TRANSAKSI_EXTEND_MANUAL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneMANUAL = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_MANUAL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneEXTEND = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_EXTEND, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneREPAYMENT = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REPAYMENT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneREPAYMENT_MANUAL = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REPAYMENT_MANUAL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneAKTIF = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_AKTIF, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneByBrandHP = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_BY_BRAND, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneFAILED = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_FAILED, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneLUNAS = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_LUNAS, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhonePENDING = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_PENDING, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneRUNNING = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_RUNNING, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneLewJAPO = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_LEW_JAPO, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneTODAY = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_TODAY, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneJaPoTODAY = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_JAPO_TODAY, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDataGadaiPhoneREJECT = data =>
    post(url.TXA_FETCH_COUNTING_DATA_REPORT_GADAI_HP_REJECT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneJAPO = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_JAPO, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhonePENDING = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_PENDING, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneFAILED = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_FAILED, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneLUNAS = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_LUNAS, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneREJECT = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_REJECT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchTableReportPawnshopPhoneRUNNING = data =>
    post(url.TXA_FETCH_TABLEDATA_REPORT_GADAI_HP_RUNNING, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const updateValidationContract = data =>
    post(url.TXA_FETCH_UPDATE_VALIDASI_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const rejectDataContract = data =>
    post(url.TXA_FETCH_REJECT_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const deleteDataContract = data =>
    post(url.TXA_FETCH_DELETE_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDetailContract = data =>
            post(url.TXA_FETCH_DETAIL_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const cekNIK = data =>
    get(url.TXA_FETCH_CEK_NIK_EXISTS + data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const cekNoHP = data =>
    get(url.TXA_FETCH_CEK_NOHP_EXISTS + data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataCabang = data =>
    post(url.TXA_FETCH_INFORMASI_CABANG, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDataNoKontrak = data =>
    post(url.TXA_FETCH_DATA_NO_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

/*const fetchDataNoKontrakAKTIF = data =>
    post(url.TXA_FETCH_DATA_NO_KONTRAK_AKTIF, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });*/

const fetchDetailPerpanjangNoKontrak = data =>
    post(url.TXA_FETCH_DETAIL_PERPANJANG_NO_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDetailTRXPerpanjangKontrak = data =>
    post(url.TXA_FETCH_DETAIL_TRX_PERPANJANG_NO_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDetailPelunasanNoKontrak = data =>
    post(url.TXA_FETCH_DETAIL_PELUNASAN_NO_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchDetailTRXPelunasanKontrak = data =>
    post(url.TXA_FETCH_DETAIL_TRX_PELUNASAN_NO_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const createExtendContract = data =>
    post(url.TXA_POST_EXTEND_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const createPelunasanContract = data =>
    post(url.TXA_POST_PELUNASAN_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const validationExtendContract = data =>
    post(url.TXA_POST_VALIDATION_EXTEND_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const validationPelunasanContract = data =>
    post(url.TXA_POST_VALIDATION_PELUNASAN_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const rejectionExtendContract = data =>
    post(url.TXA_POST_VALIDATION_REJECT_EXTEND_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const rejectionPelunasanContract = data =>
    post(url.TXA_POST_VALIDATION_REJECT_PELUNASAN_DATA_KONTRAK, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SESSION_START = data =>
    post(url.TXA_POST_WHATSAPP_SESSION_START, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SESSION_DELETE = data =>
    put(url.TXA_POST_WHATSAPP_SESSION_DELETE, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SESSION_LIST = data =>
    post(url.TXA_POST_WHATSAPP_SESSION_LIST, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_QR_CODE_WHATSAPP = data =>
    post(url.TXA_POST_QR_CODE_WHATSAPP, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SESSION_DETAIL = data =>
    post(url.TXA_POST_WHATSAPP_SESSION_DETAIL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT = data =>
    post(url.TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE = data =>
    post(url.TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299 || response.data.status >= 200 || response.data.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDashboard_EXTEND = data =>
    post(url.TXA_FETCH_COUNTING_DATA_PERPANJANG_DASHBOARD, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDashboard_REPAYMENT = data =>
    post(url.TXA_FETCH_COUNTING_DATA_PELUNASAN_DASHBOARD, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_STATISTIK_fetchNominalCashflowStatistik = data =>
    post(url.TXA_STATISTIK_fetchNominalCashflowStatistik, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER = data =>
    post(url.TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

const fetchCountingReportDashboard_KONTRAK = data =>
    post(url.TXA_FETCH_COUNTING_DATA_KONTRAK_DASHBOARD, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 403:
                        message = "Forbidden! Acees denied.";
                        break;
                    case 401:
                        message = "Your credential is invalid!";
                        break;
                    case 404:
                        message = "Sorry! your credentials was not found!";
                        break;
                    case 500:
                        message = "Sorry! internal server error.";
                        break;
                    default:
                        message = "Sorry! something went wrong, please try again.";
                        break;
                }
            }

            throw message;
        });

export {
    TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER,
    TXA_STATISTIK_fetchNominalCashflowStatistik,
    fetchCountingReportDashboard_EXTEND,
    fetchCountingReportDashboard_REPAYMENT,
    fetchCountingReportDashboard_KONTRAK,
    TXA_POST_WHATSAPP_SESSION_DETAIL,
    TXA_POST_QR_CODE_WHATSAPP,
    TXA_POST_WHATSAPP_SESSION_DELETE,
    TXA_POST_WHATSAPP_SESSION_START,
    TXA_POST_WHATSAPP_SESSION_LIST,
    TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT,
    TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE,
    getLoggedInUser,
    isUserAuthenticated,
    getStoreToken,
    getStoreRToken,
    fetchGetAuthStatus,
    postAuthSignIn,
    deleteAuthSignOut,
    fetchGetProfileData,
    updateProfileData,
    fetchRefreshToken,
    putUpdatePassword,
    updateUserDataByUUID,
    updateUserPassByUUID,
    registerNewUser,
    postUpdateDataCustomer,
    userRemover,
    fetchDataPelangganLama,
    postDataPelangganByUID,
    fetchFullMerkHP,
    fetchFullTipeHPByCode,
    fetchFullSpecTipeHP,
    fetchNilaiTaksirHP,
    fetchFullDataBank,
    fetchFullDataeWallet,
    fetchFullDataFakeBank,
    fetchDataBankSistem,
    fetchDetailDataBankSistem,
    fetchDataCustWithBank,
    fetchDataCustListBank,
    fetchPelangganBankSelect,
    postNewTransaksiGadaiBarang,
    postUploadFotoKelengkapan,
    postUploadFotoKTPNasabah,
    fetchFotoKTPNasabah,
    fetchFotoKelengkapan,
    fetchTableTRXValidationGET,
    fetchTableTRXValidationEXTEND,
    fetchTableTRXValidationREPAYMENT,
    updateValidationContract,
    rejectDataContract,
    deleteDataContract,
    fetchDetailContract,
    cekNIK,
    cekNoHP,
    fetchDataCabang,
    fetchTableReportPawnshopPhone,
    fetchTableReportPawnshopPhoneMANUAL,
    fetchTableReportPawnshopPhoneAKTIF,
    fetchTableReportPawnshopPhoneDeleted,
    fetchDataNoKontrak,
    /*fetchDataNoKontrakAKTIF,*/
    fetchDetailPerpanjangNoKontrak,
    fetchDetailTRXPerpanjangKontrak,
    createExtendContract,
    validationExtendContract,
    rejectionExtendContract,
    uploadBuktiPerpanjang,
    fetchimgBuktiPerpanjang,
    createPelunasanContract,
    uploadBuktiPelunasan,
    imgBuktiPelunasan,
    fetchTableReportPawnshopPhoneEXTEND,
    fetchDetailPelunasanNoKontrak,
    fetchDetailTRXPelunasanKontrak,
    validationPelunasanContract,
    rejectionPelunasanContract,
    postRegisteringNewCustomer,
    setKontrakGadaiFAILED,
    fetchTableReportPawnshopPhoneJAPO,
    fetchTableReportPawnshopPhonePENDING,
    fetchTableReportPawnshopPhoneFAILED,
    fetchTableReportPawnshopPhoneLUNAS,
    fetchTableReportPawnshopPhoneREJECT,
    fetchTableReportPawnshopPhoneRUNNING,
    fetchTableDataCustomerFull,
    fetchCountingReportDataGadaiPhone,
    fetchCountingReportDataGadaiPhoneEXTEND,
    fetchCountingReportDataGadaiPhoneByBrandHP,
    fetchCountingReportDataGadaiPhoneFAILED,
    fetchCountingReportDataGadaiPhoneREJECT,
    fetchCountingReportDataGadaiPhoneLUNAS,
    fetchCountingReportDataGadaiPhonePENDING,
    fetchCountingReportDataGadaiPhoneRUNNING,
    fetchCountingReportDataGadaiPhoneLewJAPO,
    fetchCountingReportDataGadaiPhoneTODAY,
    fetchCountingReportDataGadaiPhoneJaPoTODAY,
    fetchTableReportPawnshopPhoneByBrandHP,
    fetchTableReportPawnshopPhoneEXTEND_MANUAL,
    fetchTableTRXValidationEXTEND_MANUAL,
    fetchCountingReportDataGadaiPhoneREPAYMENT,
    fetchDataBankXeWallet,
    fetchCountingReportDataGadaiPhoneREPAYMENT_MANUAL,
    fetchTableReportPawnshopPhoneREPAYMENT,
    fetchTableReportPawnshopPhoneREPAYMENT_MANUAL,
};
