import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

// import images
import sketch_login from "assets/images/crypto/features-img/img-2.png";
import logo from "assets/images/logo.svg";

import * as moment from "moment";
import 'moment-timezone';
import Moment from "moment";

const Login = props => {

    useEffect(() => {
        if (localStorage.getItem("authToken") && localStorage.getItem("authRToken")) {
            return window.location.href = '/apps/dashboard';
        }
    }, []);

    //meta title
    document.title = "Sign In - " + process.env.REACT_APP_NAME;

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        email: '',
        password: '',
        showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Harap masukan email yang valid atau email yang terdaftar!"),
      password: Yup.string().required("Harap masukan password yang benar dan tidak boleh kosong!"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

    const { error, loading, authSuccess } = useSelector(state => ({
        error: state.Login.error,
        loading: state.Login.loading,
        authSuccess: state.Login.authSuccess,
    }));
    //console.log(loading);

    /*if (status)
    {
        console.log(status);
    }*/
    const handleClickShowPassword = () => {
        validation.setFieldValue('showPassword', !validation.values.showPassword);
    };

  return (
    <React.Fragment>
       <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
               <Card className="overflow-hidden">
                <div className="bg-dark bg-soft">
                  <Row>
                    <Col xs={8}>
                                          <div className="text-dark p-4">
                                              <h6 className="text-primary"><b>{process.env.REACT_APP_NAME} - {process.env.REACT_APP_TXA_SITE}</b></h6>
                                              <span>Perlu akses untuk dapat menggunakan Dashboard Web UI.</span>
                                              {/*{moment.tz(Moment().format('YYYY-MM-DD HH:mm:ss'), "Asia/Jakarta").utc().format() }*/}
                                              {/*moment.tz(Moment().format('YYYY-MM-DD HH:mm:ss'), "Asia/Jakarta").format('YYYY-MM-DD')
                                                  + " " +
                                                  moment.tz(Moment().format('YYYY-MM-DD HH:mm:ss'), "Asia/Jakarta").format('HH:mm:ss')
                                                  + " " +
                                                  moment.tz(Moment().format('YYYY-MM-DD HH:mm:ss'), "Asia/Jakarta").format("Z")*/ }

                                              {/*{Moment.tz(Moment().format('YYYY-MM-DD HH:mm:ss'), "Asia/Jakarta").format('YYYY-MM-DD HH:mm:ss') }*/}
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                       <img src={sketch_login} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                                      >
                                          {loading ? <Alert color="info"><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="info" /> Authenticating, please wait. . .</Alert> : authSuccess ? <Alert color = "success">< i className="fas fa-unlock-alt"></i> Authenticated! Please wait, you're redirecting to dashboard pages. . .</Alert> :  error ? <Alert color="danger">{error}</Alert>: null }

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Cth : account@txa.io"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                          autoComplete={"on"}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <Label className="form-label">Kata Sandi</Label>
                      <div className="mb-3 input-group">
                                              <Input
                                                  name="password"
                                                  value={validation.values.password}
                                                  type={validation.values.showPassword ? 'text' : 'password'}
                                                  placeholder="********************"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  invalid={validation.touched.password && validation.errors.password}
                                                  autoComplete="off"
                                              />
                                              <div
                                                  onClick={() => {
                                                      handleClickShowPassword();
                                                  }}
                                                  className="btn btn-light"
                                              >
                                                  {validation.values.showPassword ? (
                                                      <span>
                                                          <i className="bx bxs-show" />
                                                      </span>
                                                  ) : (
                                                      <span>
                                                          <i className="bx bx-hide" />
                                                      </span>
                                                  )}
                                              </div>
                                              {validation.touched.password && validation.errors.password && (
                                                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                              )}
                            </div>

                      {/*<div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>*/}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block" disabled={ loading }
                          type="submit"
                                              >
                                                  {
                                                      loading
                                                          ?
                                                          <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="info" /> Authenticating. . .</>
                                                          :
                                                          <><i className="fas fa-sign-in-alt"></i> Masuk</>
                                                  }
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
                          </Card>
                          <div className="mt-5 text-center">
                              {/*<p>
                                  Don&#39;t have an account ?{" "}
                                  <Link to="/register" className="fw-medium text-primary">
                                      {" "}
                                      Signup now{" "}
                                  </Link>{" "}
                              </p>*/}
                              <p>
                                  {/*Copyright &copy; {new Date().getFullYear()} {process.env.REACT_APP_COPYRIGHT} | All right reserved.*/}
                                  {/*{process.env.REACT_APP_APP_NAME} {process.env.REACT_APP_TXA_SITE} | Version {process.env.REACT_APP_VERSION} {process.env.REACT_APP_VERSION_DESCRIPTION}*/}
                                  Version {process.env.REACT_APP_VERSION} {process.env.REACT_APP_VERSION_DESCRIPTION} - {process.env.REACT_APP_TXA_SITE}
                              </p>
                          </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
    authSuccess: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
};
