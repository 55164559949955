import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Alert,
    Modal,
    Input,
    Collapse,
    Label,
    Badge,
    Button,
    Spinner,
    InputGroup,
    Form,
    FormFeedback,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Indonesian } from "flatpickr/dist/l10n/id.js"
import * as Yup from "yup";
import { useFormik, Formik, Field } from "formik";
const DateRangeSchema = Yup.object().shape({
    dateFromFilter: Yup.date().required("Dari tanggal harus diisi!"),
    dateToFilter: Yup.date().required("Sampai tanggal harus diisi!"),
});
let dariTanggal = '';
let keTanggal = '';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableContainer from '../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select";
import jwt_decode from "jwt-decode";
import classnames from "classnames";

import {
    fetchFullMerkHP,
    updateValidationContract,
    deleteDataContract,
    rejectDataContract,
    fetchTableReportPawnshopPhone,
    fetchTableReportPawnshopPhoneByBrandHP,
    fetchTableReportPawnshopPhoneJAPO,
    fetchTableReportPawnshopPhonePENDING,
    fetchTableReportPawnshopPhoneFAILED,
    fetchTableReportPawnshopPhoneLUNAS,
    fetchTableReportPawnshopPhoneREJECT,
    fetchTableReportPawnshopPhoneRUNNING,
    fetchCountingReportDataGadaiPhone,
    fetchTableReportPawnshopPhoneMANUAL,
    fetchCountingReportDataGadaiPhoneByBrandHP,
    fetchCountingReportDataGadaiPhoneFAILED,
    fetchCountingReportDataGadaiPhoneREJECT,
    fetchCountingReportDataGadaiPhoneLUNAS,
    fetchCountingReportDataGadaiPhonePENDING,
    fetchCountingReportDataGadaiPhoneRUNNING,
    fetchCountingReportDataGadaiPhoneLewJAPO,
    fetchCountingReportDataGadaiPhoneTODAY,
    fetchCountingReportDataGadaiPhoneJaPoTODAY,
    TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE,
    fetchDetailContract,
} from "../../../helpers/backend_helper";

import {
    FormatNoKontrak,
    FormatDateTime,
    FormatTipeASBarang,
    FormatNIKCustomer,
    FormatNamaCustomer,
    FormatNominalGadai,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatMetodeTransaksi,
    FormatFieldAction,
    FormatOnlyLoading,
    FormatRAM_Internal,
    FormatJatuhTempo,
    FormatConfirmed,
    FormatRejected,
    FormatStatusBarang,
    FormatPelunasan,
} from "./isMasterTableCol";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

let isMasterDataContract = null;

let MasterDataTable = [{}];
let isUUIDOperator = null;
let isValopKodeCabang = null;
let MasterSummaryReport = null;
let isFullMerkHPRESULT = [{}];

const ManagementReportPawnshop = props => {
    moment.tz.setDefault('Asia/Jakarta');

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "3000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isShowInformasi, setisShowInformasi] = useState(false);

    const handleClickisShowInformasi = () => {
        setisShowInformasi(!isShowInformasi);
    };

    const dispatch = useDispatch();

    //Select Filter Date
    const optionFilterDateData = [
        {
            label: "Filter Transaksi",
            options: [
                { label: "Transaksi Hari Ini", value: "isTRXToday" },
                { label: "Semua Transaksi", value: "isTRXAll" }
            ]
        },
    ];

    const [selectedFilterDateData, setselectedFilterDateData] = useState(null);

    async function handleSelectedFilterDateData(selectedFilterDateData) {
        setselectedFilterDateData(selectedFilterDateData);

        MasterDataTable = [{}];

        setisDataValidsasiTransaksi(null);

        await FeedFunctionDataForTableValidasiTransaksi(selectedFilterDateData.value);
    }

    const [isDataLoading, setisDataLoading] = useState(false);

    async function FeedFunctionDataForTableValidasiTransaksi(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhone({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    async function FeedFunctionDataForTableValidasiTransaksiJAPO(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneJAPO({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');


                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
        
    }

    async function FeedFunctionDataForTableValidasiTransaksiPENDING(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhonePENDING({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
        
    }

    async function FeedFunctionDataForTableValidasiTransaksiFAILED(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneFAILED({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
        
    }

    async function FeedFunctionDataForTableValidasiTransaksiLUNAS(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneLUNAS({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
        
    }

    async function FeedFunctionDataForTableValidasiTransaksiREJECT(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneREJECT({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
        
    }

    async function FeedFunctionDataForTableValidasiTransaksiRUNNING(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneRUNNING({ LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }
                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');
                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');
            }
        }, 500);
        
    }

    const [isDataValidsasiTransaksi, setisDataValidsasiTransaksi] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }
    }

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    useEffect(() => {
        autoUpdateProfile();

        FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

        postFetchsummaryReportDataGadaiPhone("isTRXAll");

        FeedFunctionMerkHP();

        setInterval(async () => {
            {
                /*MasterDataTable = [{}];

                await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");*/
                autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s


    }, [dispatch]);

    const printOutContractFaktur = (isNomorKontrak) => {
        let printWindow = "";

        //apply base url for axios
        let API_URL = '';

        //Using Global Domain Server
        if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
            API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN;
        }
        else {
            //127.0.0.1
            if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                if (window.location.hostname == process.env.REACT_APP_IP_ROOT) {
                    API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
                else {
                    API_URL = "//" + process.env.REACT_APP_DOMAIN_ROOT + ":" + process.env.REACT_APP_FE_PORT_ROOT;
                }
            }
            //Local IP 192.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + process.env.REACT_APP_FE_PORT_LOCAL;
            }
            //Public IP X.X.X.X
            else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + process.env.REACT_APP_FE_PORT_INTERNET;
            }

        }

        printWindow = window.open("//" + API_URL + "/apps/pawnshop/print/handphone/" + isNomorKontrak, "popup", "width=" + 1024 + ",height=" + 640);

        //printWindow.document.write(printableContent.innerHTML);
        //printWindow.print();
        //console.log("Test");
    };

    const isColumnsData = useMemo(
        () => [
            {
                Header: "#",
                accessor: "PGD_KontrakFailed",
                disableFilters: true,
                filterable: false,
                sortable: false,
            },
            {
                Header: "No. Kontrak",
                accessor: "PGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "Tgl. Terbit",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGDRincian_WaktuTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Perpanjang",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGD_TanggalTerakhirTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "Tgl. Ja.Po",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "PGDRincian_JTempoTRX",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatDateTime {...cellProps} />;
                },
            },
            {
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "isCustomer.NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nasabah",
                accessor: "isCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Jaminan",
                accessor: "PGDJaminan_Tipe",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Pelunasan",
                accessor: "PGD_NominalTebus",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNominalGadai {...cellProps} />;
                },
            },
            {
                Header: "Jt. Tempo",
                accessor: "PGD_JumlahHariKeterlambatan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            "-"
                            :
                            <FormatJatuhTempo {...cellProps} />
                    ))
                    //return <FormatJatuhTempo {...cellProps} />;
                },
            },
            {
                Header: "St. Konfirmasi",
                accessor: "PGD_isConfirmed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatConfirmed {...cellProps} />;
                },
            },
            {
                Header: "St. Kontrak",
                accessor: "PGD_Lunas",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    /*return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            <Badge className="bg-danger font-size-11">Kontrak Gagal</Badge>
                            :
                            <FormatPelunasan {...cellProps} />
                    ))*/

                    return <FormatPelunasan {...cellProps} />;
                },
            },
            /*{
                Header: "St. Barang",
                accessor: "PGD_StatusBarang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatStatusBarang {...cellProps} />;
                },
            },*/
            {
                Header: "Cabang",
                accessor: "isCabang.CabangName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCabang {...cellProps} />;
                },
            },
            {
                Header: "Operator",
                accessor: "isOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                    <Link
                                        to={"/apps/pawnshop/details/handphone/" + cellProps.cell.row.allCells[1].value}
                                        className="btn btn-sm btn-info"
                                        onClick={() => {

                                        }}
                                    >
                                        <i className="mdi mdi-eye-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Kontrak
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-primary"
                                        onClick={async (evt) => {
                                            await printOutContractFaktur(cellProps.cell.row.allCells[1].value);
                                        }}
                                    >
                                        <i className="fas fa-file-download" id="invoicetooltip" />
                                        <UncontrolledTooltip placement="top" target="invoicetooltip">
                                            Lihat Invoice
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-success"
                                        onClick={async (evt) => {
                                            setisNomorKontrakWhatsApp(cellProps.cell.row.allCells[1].value);

                                            await FeedFunctionFullDataContract(cellProps.cell.row.allCells[1].value);
                                            await isShowModalisModalKirimPesanTeksIMG();
                                        }}
                                    >
                                        <i className="mdi mdi-whatsapp" id="confirmtooltip" />
                                        <UncontrolledTooltip placement="top" target="confirmtooltip">
                                            Kirim Notifikasi WhatsApp
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );

    const [isNomorKontrak, setisNomorKontrak] = useState(null);
    const [isCommandSelectedValue, setisCommandSelectedValue] = useState(null);
    const [isSudahCekFISIK, setisSudahCekFISIK] = useState(false);
    const [isBarangAda, setisBarangAda] = useState(null);
    const [isUUIDUserContract, setisUUIDUserContract] = useState(null);
    const [isModalConfirmKontrak, setisModalConfirmKontrak] = useState(false);

    function isShowModalConfirmKontrak() {
        setisBarangAda(null);
        setisSudahCekFISIK(false);
        setisModalConfirmKontrak(!isModalConfirmKontrak);
        removeBodyCss();
    }

    const [isModalDeleteKontrak, setisModalDeleteKontrak] = useState(false);

    function isShowModalDeleteKontrak() {
        setisModalDeleteKontrak(!isModalDeleteKontrak);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const formKontrakValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            //isValUUIDUserContract: isUUIDUserContract || undefined,
            isValNoKontrak: isNomorKontrak || undefined,
            isCommandSelected: isCommandSelectedValue || undefined,
            isUIDValidator: opUUID || undefined,
            isValSudahCekFisik: isSudahCekFISIK || undefined,
            isValBarangAda: isBarangAda || "Tidak Ada",
            isTanggalUpdateTX: moment.tz('Asia/Jakarta').format("YYYY-MM-DD HH:mm:ss") || undefined,
        },
        validationSchema: Yup.object({
            isValNoKontrak: Yup.string(),
        }),
        onSubmit: async (values) => {
            //console.log(values);

           /* if (isCommandSelectedValue === "Confirm") {
                //console.log("=> Quick Konfirmasi Data Kontrak!");

                await PostUpdateValidasiDataKontrak(values);
            }
            else if (isCommandSelectedValue === "Reject") {
                //console.log("=> Quick Reject Data Kontrak!");

                await PostRejectDataKontrak(values);
            }
            else {
                //console.log("=> Quick Hapus Data Kontrak!");

                await PostDeleteDataKontrak(values);
            }*/
        },
    });

    /*async function PostUpdateValidasiDataKontrak(isFormValue) {
        try {
            await updateValidationContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di validasi!', 'success');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di validasi, ada sesuatu yang salah!', 'error');
        }
    }

    async function PostRejectDataKontrak(isFormValue) {
        try {
            await rejectDataContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di tolak!', 'success');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di tolak, ada sesuatu yang salah!', 'error');
        }
    }
    
    async function PostDeleteDataKontrak(isFormValue) {
        try {

            await deleteDataContract(isFormValue);

            MasterDataTable = [{}];

            await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

            showToastNotification('Success!', 'Nomor kontrak #' + isNomorKontrak + ' berhasil di dihapus!', 'info');
        }
        catch (Err) {
            showToastNotification('Oops!', 'Nomor kontrak #' + isNomorKontrak + ' gagal di hapus, ada sesuatu yang salah!', 'error');
        }
    }*/

    const [summaryReportDataGadai, setsummaryReportDataGadai] = useState(null);

    //
    async function postFetchsummaryReportDataGadaiPhone(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhone({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneByBrandHP(WhatsFiltered, MerkHPnya) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneByBrandHP({ isBrandCode: MerkHPnya, FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneFAILED(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneFAILED({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneREJECT(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneREJECT({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneLUNAS(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneLUNAS({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhonePENDING(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhonePENDING({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneRUNNING(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneRUNNING({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneLewJAPO(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneLewJAPO({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneTODAY(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneTODAY({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    async function postFetchsummaryReportDataGadaiPhoneJaPoTODAY(WhatsFiltered) {

        setsummaryReportDataGadai(null);

        try {
            MasterSummaryReport = await fetchCountingReportDataGadaiPhoneJaPoTODAY({ FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });

            if (MasterSummaryReport != null) {
                setsummaryReportDataGadai(MasterSummaryReport);
            }
            else {
                setsummaryReportDataGadai(null);
            }
        }
        catch (Err) {

        }
    }

    const [filterDataName, setfilterDataName] = useState("All in");

    let summaryReports0 = [
        {
            title: "Jumlah Kontrak Masuk - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "2",
            value_color: "text-primary",
        },
        {
            title: "Jumlah Kontrak Masuk - Transfer Bank",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "4",
            value_color: "text-info",
        },
        {
            title: "Jumlah Kontrak Masuk - Unit",
            iconClass: "fas fa-arrow-alt-circle-down",
            bgColor: "warning",
            textColor: "text-white",
            description: "-",
            value: "5",
            value_color: "text-warning",
        },
        {
            title: "Akumulasi Kontrak Masuk - Tunai",
            iconClass: "mdi mdi-transfer-down",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "Rp. 200.000",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Kontrak Masuk - Transfer Bank",
            iconClass: "mdi mdi-bank-transfer-out",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "Rp. 300.000",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Kontrak Masuk - Pengeluaran Dana",
            iconClass: "fas fa-arrow-alt-circle-up",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: "Rp. 500.000",
            value_color: "text-success",
        },

        {
            title: "Jumlah Kontrak Perpanjang - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "2",
            value_color: "text-primary",
        },
        {
            title: "Jumlah Kontrak Perpanjang - Transfer Bank",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "4",
            value_color: "text-info",
        },
        {
            title: "Jumlah Kontrak Perpanjang - Unit",
            iconClass: "fas fa-arrow-alt-circle-down",
            bgColor: "warning",
            textColor: "text-white",
            description: "-",
            value: "5",
            value_color: "text-warning",
        },
        {
            title: "Akumulasi Kontrak Perpanjang - Tunai",
            iconClass: "mdi mdi-transfer-down",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "Rp. 200.000",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Kontrak Perpanjang - Transfer Bank",
            iconClass: "mdi mdi-bank-transfer-out",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "Rp. 300.000",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Kontrak Perpanjang - Pengeluaran Dana",
            iconClass: "fas fa-arrow-alt-circle-up",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: "Rp. 500.000",
            value_color: "text-success",
        },

        {
            title: "Jumlah Kontrak Pelunasan - Tunai",
            iconClass: "mdi mdi-cash-multiple",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "2",
            value_color: "text-primary",
        },
        {
            title: "Jumlah Kontrak Pelunasan - Transfer Bank",
            iconClass: "mdi mdi-bank-outline",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "4",
            value_color: "text-info",
        },
        {
            title: "Jumlah Kontrak Pelunasan - Unit",
            iconClass: "fas fa-arrow-alt-circle-down",
            bgColor: "warning",
            textColor: "text-white",
            description: "-",
            value: "5",
            value_color: "text-warning",
        },
        {
            title: "Akumulasi Kontrak Pelunasan - Tunai",
            iconClass: "mdi mdi-transfer-down",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: "Rp. 200.000",
            value_color: "text-primary",
        },
        {
            title: "Akumulasi Kontrak Pelunasan - Transfer Bank",
            iconClass: "mdi mdi-bank-transfer-out",
            bgColor: "info",
            textColor: "text-white",
            description: "-",
            value: "Rp. 300.000",
            value_color: "text-info",
        },
        {
            title: "Akumulasi Kontrak Pelunasan - Pengeluaran Dana",
            iconClass: "fas fa-arrow-alt-circle-up",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: "Rp. 500.000",
            value_color: "text-success",
        },
    ];

    let summaryReports1 = [
        {
            title: "Total Kontrak Jatuh Tempo - " + filterDataName,
            iconClass: "far fa-clock",
            bgColor: "danger",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakJAPO + " Kontrak / Nota" : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakJAPO + " Kontrak / Nota" : "-",
            value_color: "text-danger",
        },
        {
            title: "Total Kontrak Pending - " + filterDataName,
            iconClass: "bx bxs-hourglass-top",
            bgColor: "warning",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakPENDING > 0 ? summaryReportDataGadai.TotalKontrakPENDING + " Kontrak / Nota" : "-" : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakPENDING > 0 ? summaryReportDataGadai.TotalKontrakPENDING + " Kontrak / Nota" : "-" : "-",
            value_color: "text-warning",
        },
    ];

    let summaryReports2 = [
        {
            title: "Total Kontrak Aktif - " + filterDataName,
            iconClass: "far fa-check-circle",
            bgColor: "primary",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrak + " Kontrak / Nota": "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrak + " Kontrak / Nota" : "-",
            value_color: "text-primary",
        },
        {
            title: "Total Stok Unit - " + filterDataName,
            iconClass: "fas fa-boxes",
            bgColor: "primary",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? (summaryReportDataGadai.TotalKontrakRUNNING + summaryReportDataGadai.TotalKontrakJAPO) + " Unit" : "NaN",
            value: summaryReportDataGadai !== null ? (summaryReportDataGadai.TotalKontrakRUNNING + summaryReportDataGadai.TotalKontrakJAPO) + " Unit" : "-",
            value_color: "text-primary",
        },
        {
            title: "Total Kontrak Berjalan - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "info",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakRUNNING + " Kontrak / Nota" : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakRUNNING + " Kontrak / Nota" : "-",
            value_color: "text-info",
        },
        {
            title: "Total Kontrak Lunas - " + filterDataName,
            iconClass: "fas fa-money-check-alt",
            bgColor: "success",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakLUNAS + " Kontrak / Nota" : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakLUNAS + " Kontrak / Nota" : "-",
            value_color: "text-success",
        },
        {
            title: "Total Kontrak Gagal - " + filterDataName,
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakFAILED > 0 ? summaryReportDataGadai.TotalKontrakFAILED + " Kontrak / Nota" : "-" : "-",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakFAILED > 0 ? summaryReportDataGadai.TotalKontrakFAILED + " Kontrak / Nota" : "-" : "-",
            value_color: "text-danger",
        },
        {
            title: "Total Kontrak Ditolak - " + filterDataName,
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakREJECT > 0 ? summaryReportDataGadai.TotalKontrakREJECT + " Kontrak" : "-" : "-",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakREJECT > 0 ? summaryReportDataGadai.TotalKontrakREJECT + " Kontrak" : "-" : "-",
            value_color: "text-danger",
        },
    ];

    /*let summaryReports = [
        {
            title: "Total Kontrak Aktif / Total Unit - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "primary",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrak + " / " + (summaryReportDataGadai.TotalKontrakRUNNING + summaryReportDataGadai.TotalKontrakJAPO) + " Unit" : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrak + " / " + (summaryReportDataGadai.TotalKontrakRUNNING + summaryReportDataGadai.TotalKontrakJAPO) + " Unit" : "-",
            value_color: "text-primary",
        },
        {
            title: "Total Kontrak Lunas - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "success",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakLUNAS : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakLUNAS : "-",
            value_color: "text-success",
        },
        {
            title: "Total Kontrak Ja.Po - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "danger",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakJAPO : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakJAPO : "-",
            value_color: "text-danger",
        },
        {
            title: "Total Kontrak Pending - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "warning",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakPENDING : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakPENDING : "-",
            value_color: "text-warning",
        },
        {
            title: "Total Kontrak Berjalan - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "info",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakRUNNING : "NaN",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakRUNNING : "-",
            value_color: "text-info",
        },
        {
            title: "Total Kontrak Gagal / Ditolak - " + filterDataName,
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "danger",
            textColor: "text-white",
            description: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakFAILED + " / " + summaryReportDataGadai.TotalKontrakREJECT : "- / -",
            value: summaryReportDataGadai !== null ? summaryReportDataGadai.TotalKontrakFAILED + " / " + summaryReportDataGadai.TotalKontrakREJECT : "- / -",
            value_color: "text-danger",
        },
    ];*/

    const [selectedFilterMerkHP, setselectedFilterMerkHP] = useState(null);
    const [selectedFilterMerkHPManual, setselectedFilterMerkHPManual] = useState(null);
    const [isFullMerkHP, setisFullMerkHP] = useState(null);
    const [isLoadingCariMerkHP, setisLoadingCariMerkHP] = useState(false);

    const optionFilterMerkHP = [
        {
            label: "Filter Kontrak Aktif Berdasarkan Merk",
            options: isFullMerkHP || []
        },
    ];

    async function handleSelectedFilterMerkHP(selectedFilterMerkHP) {
        setselectedFilterMerkHP(selectedFilterMerkHP);

        await FeedFunctionDataForTableByMerkHP("isTRXAll", selectedFilterMerkHP.value);
        await postFetchsummaryReportDataGadaiPhoneByBrandHP("isTRXAll", selectedFilterMerkHP.value);
    }

    async function handleSelectedFilterMerkHPManual(selectedFilterMerkHP) {
        setselectedFilterMerkHPManual(selectedFilterMerkHP);

        //await FeedFunctionDataForTableByMerkHP("isTRXAll", selectedFilterMerkHP.value);
        //await postFetchsummaryReportDataGadaiPhoneByBrandHP("isTRXAll", selectedFilterMerkHP.value);
    }

    async function FeedFunctionDataForTableByMerkHP(WhatsFiltered, MerkHPnya) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneByBrandHP({ isBrandCode: MerkHPnya, LimitData: 100000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    async function handleFeedCariMerkHP() {

        setisFullMerkHP(null);

        await FeedFunctionMerkHP();
    }

    async function FeedFunctionMerkHP() {

        setisLoadingCariMerkHP(true);

        try {
            isFullMerkHPRESULT = await fetchFullMerkHP();

            setisFullMerkHP(isFullMerkHPRESULT);

            setisLoadingCariMerkHP(false);
        }
        catch (Err) {

        }
    }

    const [FilterManualTglFromVAL, setFilterManualTglFromVAL] = useState(null);
    const [FilterManualTglToVAL, setFilterManualTglToVAL] = useState(null);
    const [FilterMerkHPManualVAL, setFilterMerkHPManualVAL] = useState(null);

    const VLDFilterManual = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            FilterManualTglFrom: FilterManualTglFromVAL ||undefined,
            FilterManualTglTo: FilterManualTglToVAL || undefined,
            FilterMerkHPManual: FilterMerkHPManualVAL || undefined,
            KodeCabang: isValopKodeCabang || undefined,
            LimitData: 100000,
        },
        validationSchema: Yup.object({
            FilterManualTglFrom: Yup.date().required("Tanggal DARI wajib diisi tidak boleh kosong!"),
            FilterManualTglTo: Yup.date().required("Tanggal SAMPAI wajib diisi tidak boleh kosong!"),
        }),
        onSubmit: async (values) => {
            await FeedFunctionDataForTableMANUALFilter(values);
            //console.log(values);
        }
    });

    async function FeedFunctionDataForTableMANUALFilter(JSONData) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneMANUAL(JSONData);
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    async function FeedFunctionFullDataContract(isValNoKontrak) {

        isMasterDataContract = null;

        if (isValNoKontrak != null) {
            try {
                isMasterDataContract = await fetchDetailContract({ ContractNumber: isValNoKontrak });

                if (isMasterDataContract === null) {
                    setisModalKirimPesanTeksIMG(false);
                }
                else {
                    setisNomorHPWhatsApp(isMasterDataContract.isCustomer.NoHP);
                    setisBarangJaminanWhatsApp(isMasterDataContract.PGDJaminan_Tipe);
                    setisNamaNasabahWhatsApp(isMasterDataContract.isCustomer.NamaLengkap);
                    setisTanggalGadaiPerpanjangWhatsApp(moment(isMasterDataContract.PGD_TanggalTerakhirPerpanjang).tz('Asia/Jakarta').format("D MMMM YYYY") || moment(isMasterDataContract.PGDRincian_WaktuTRX).tz('Asia/Jakarta').format("D MMMM YYYY"));
                    setisTanggalJatuhTempoWhatsApp(moment(isMasterDataContract.PGDRincian_JTempoTRX).tz('Asia/Jakarta').format("D MMMM YYYY"));
                }

            }
            catch (Err) {
                setisModalKirimPesanTeksIMG(false);
            }
        }
    }

    const [isModalKirimPesanTeksIMG, setisModalKirimPesanTeksIMG] = useState(false);
    const [isFileMediaIMG, setisFileMediaIMG] = useState(null);
    const [isNomorKontrakWhatsApp, setisNomorKontrakWhatsApp] = useState(null);
    const [isNomorHPWhatsApp, setisNomorHPWhatsApp] = useState(null);
    const [isBarangJaminanWhatsApp, setisBarangJaminanWhatsApp] = useState(null);
    const [isNamaNasabahWhatsApp, setisNamaNasabahWhatsApp] = useState(null);
    const [isTanggalGadaiPerpanjangWhatsApp, setisTanggalGadaiPerpanjangWhatsApp] = useState(null);
    const [isTanggalJatuhTempoWhatsApp, setisTanggalJatuhTempoWhatsApp] = useState(null);

    function isShowModalisModalKirimPesanTeksIMG() {
        setisModalKirimPesanTeksIMG(!isModalKirimPesanTeksIMG);
        removeBodyCss();
    }

    let isiPesanWhatsApp = `*Pelanggan Yth,*

Dimohon untuk segera melakukan pembayaran baik perpanjangan gadai atau tebus gadai.
Untuk perpanjangan cukup bayar biaya jasa titip 1 bulan.
Jika tidak ada pembayaran, jaminan akan dijual atau dilelang tanpa konfirmasi lagi.

Terimakasih ~

#Abaikan pesan ini jika Anda sudah melakukan perpanjang atau tebus gadai.

==============================================
==============================================

*Pelanggan Yth,*

No Kontrak :  *#`+ isNomorKontrakWhatsApp +`*
Nama : `+ isNamaNasabahWhatsApp +`
Barang Jaminan : `+ isBarangJaminanWhatsApp +`
Tanggal Gadai/Perpanjang : *`+ isTanggalGadaiPerpanjangWhatsApp +`*
Tanggal Jatuh Tempo : *`+ isTanggalJatuhTempoWhatsApp +`*

Dimohon untuk segera melakukan pembayaran baik perpanjangan gadai atau tebus gadai. Toleransi waktu maksimal 10 hari dari tanggal jatuh tempo nota Anda.
Untuk perpanjangan cukup bayar biaya jasa titip 1 (satu) bulan. Sedangkan jika tebus sudah lewat tanggal jatuh tempo, dikenakan biaya jasa titip 2 (dua) bulan.
Jika tidak ada pembayaran, jaminan akan dijual atau dilelang tanpa konfirmasi.

Terimakasih ~

#Abaikan pesan ini jika Anda sudah melakukan perpanjang atau tebus gadai.`;

    const formKirimPesanTeksIMG = useFormik({
        enableReinitialize: true,

        initialValues: {
            isFieldBarangJaminan: isBarangJaminanWhatsApp || undefined,
            isFieldNamaNasabah: isNamaNasabahWhatsApp || undefined,
            isFieldPhoneNoReceiver: isNomorHPWhatsApp || "62",
            isFieldMediaIMG: isFileMediaIMG,
            isFieldIsiPesanWA: isiPesanWhatsApp,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
            //console.log(values);

            await SendTextWithImageMessageWA(values.isFieldPhoneNoReceiver, values.isFieldMediaIMG, values.isFieldIsiPesanWA);
        },
    });

    async function SendTextWithImageMessageWA(isReceiver, isMediaIMG, isIsiPesannya) {
        //setisResultSendTextWA(null);
        //setisLoadingSendMessage(true);
        try {
            await TXA_POST_WHATSAPP_SEND_MESSAGES_TEXT_IMAGE({ SessionIdentity: isValopKodeCabang, ReceiverPhoneNumber: isReceiver, textMessages: isIsiPesannya, isMediaFile: isMediaIMG });

            //setisResultSendTextWA(JSON.stringify(isResponseFromWA));

            //setisLoadingSendMessage(false);

            showToastNotification('Success!', 'Pesan WhatsApp berhasil dikirim ke nomor ' + isReceiver, 'success');
        }
        catch (Err) {
            //setisLoadingSendMessage(false);
            console.log(Err);
        }
    }


    async function handleChangeFileMediaIMG(e) {
        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = fileNameIMG + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        const ConvertToBase64 = await toBase64(fileRenamed);

        setisFileMediaIMG(ConvertToBase64);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        }
        catch (Err) {
            //Nothing
            console.log(Err);
        }
    });

    //meta title
    document.title = "Laporan Data Pegadaian Handphone - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Laporan Data Pegadaian Handphone")}
                  />
                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports1.map((report, key) => (
                              <Col md="6" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-black fw-medium placeholder-glow"
                                                                      : "text-black fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder text-primary col-6">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports2.map((report, key) => (
                              <Col md="4" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-black fw-medium placeholder-glow"
                                                                      : "text-black fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-primary fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder text-primary col-6">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  {/*IS MODAL FOR NOTIFIKASI WHATSAPP*/}
                  <Modal
                      isOpen={isModalKirimPesanTeksIMG}
                      toggle={() => {
                          isShowModalisModalKirimPesanTeksIMG();
                      }}
                      backdrop={'static'}
                      id="modalKirimPesanTeks"
                      className={'modal-dialog-centered modal-xl d-print-none'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalKirimPesanTeksLabel"><i className=" mdi mdi-information-outline me-2"></i>FORMULIR NOTIFIKASI WHATSAPP - <span className="text-danger">#{isNomorKontrakWhatsApp}</span></h5>

                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalKirimPesanTeksIMG(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <Col xl={12}>
                              <div >
                                  <Alert className="alert-info" role="alert">
                                      <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> PERHATIAN!</h5>
                                      <p>Fitur <i className="fw-bold">WhatsApp Gateway</i> masih dalam tahap pengembangan atau tahap uji coba, mohon berhati - hati dalam menggunakannya.</p>
                                      <p>Sebelum melakukan pengiriman notifikasi WhatsApp mohon pastikan bahwa File/Foto/Gambar Kontrak/Nota sudah Anda unduh terlebih dahulu.</p>
                                  </Alert>
                              </div>
                          </Col>
                          <div className="form-floating mb-3">
                              <Input
                                  name="isFieldBarangJaminan"
                                  id="isFieldBarangJaminan"
                                  className="form-control border-info"
                                  type="text"
                                  maxLength={17}
                                  placeholder="Barang Jaminan . . ."
                                  onChange={formKirimPesanTeksIMG.handleChange}
                                  onBlur={formKirimPesanTeksIMG.handleBlur}
                                  value={formKirimPesanTeksIMG.values.isFieldBarangJaminan || ""}
                                  autoComplete={"off"}
                                  invalid={
                                      formKirimPesanTeksIMG.touched.isFieldBarangJaminan && formKirimPesanTeksIMG.errors.isFieldBarangJaminan ? true : false
                                  }
                              />
                              {formKirimPesanTeksIMG.touched.isFieldBarangJaminan && formKirimPesanTeksIMG.errors.isFieldBarangJaminan ? (
                                  <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldBarangJaminan}</FormFeedback>
                              ) : null}
                              <Label htmlFor="isFieldBarangJaminan" for="isFieldBarangJaminan">
                                  Barang Jaminan
                              </Label>
                          </div>
                          <div className="form-floating mb-3">
                              <Input
                                  name="isFieldNamaNasabah"
                                  id="isFieldNamaNasabah"
                                  className="form-control border-info"
                                  type="text"
                                  maxLength={17}
                                  placeholder="Nama Nasabah . . ."
                                  onChange={formKirimPesanTeksIMG.handleChange}
                                  onBlur={formKirimPesanTeksIMG.handleBlur}
                                  value={formKirimPesanTeksIMG.values.isFieldNamaNasabah || ""}
                                  autoComplete={"off"}
                                  invalid={
                                      formKirimPesanTeksIMG.touched.isFieldNamaNasabah && formKirimPesanTeksIMG.errors.isFieldNamaNasabah ? true : false
                                  }
                              />
                              {formKirimPesanTeksIMG.touched.isFieldNamaNasabah && formKirimPesanTeksIMG.errors.isFieldNamaNasabah ? (
                                  <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldNamaNasabah}</FormFeedback>
                              ) : null}
                              <Label htmlFor="isFieldNamaNasabah" for="isFieldNamaNasabah">
                                  Nama Nasabah
                              </Label>
                          </div>

                          <div className="form-floating mb-3">
                              <Input
                                  name="isFieldPhoneNoReceiver"
                                  id="isFieldPhoneNoReceiver"
                                  className="form-control border-info"
                                  type="text"
                                  maxLength={17}
                                  placeholder="Nomor WhatsApp Penerima . . ."
                                  onChange={formKirimPesanTeksIMG.handleChange}
                                  onBlur={formKirimPesanTeksIMG.handleBlur}
                                  value={formKirimPesanTeksIMG.values.isFieldPhoneNoReceiver || "62"}
                                  autoComplete={"off"}
                                  invalid={
                                      formKirimPesanTeksIMG.touched.isFieldPhoneNoReceiver && formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver ? true : false
                                  }
                              />
                              {formKirimPesanTeksIMG.touched.isFieldPhoneNoReceiver && formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver ? (
                                  <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldPhoneNoReceiver}</FormFeedback>
                              ) : null}
                              <Label htmlFor="isFieldPhoneNoReceiver" for="isFieldPhoneNoReceiver">
                                  No. WhatsApp Penerima
                              </Label>
                          </div>

                          <div className="mb-3">
                              <Row>
                                  <Col md={4}>
                                      <Label className="col-form-label" for="isFieldMediaIMG">
                                          <b><span className="text-danger">*</span></b> Upload File Kontrak / Nota
                                      </Label>
                                  </Col>
                                  <Col md={8}>
                                      <Input
                                          name="isFieldMediaIMG"
                                          id="isFieldMediaIMG"
                                          className="form-control"
                                          type="file"
                                          accept="image/*"
                                          onChange={(evt) => {
                                              handleChangeFileMediaIMG(evt);
                                          }}
                                          onBlur={formKirimPesanTeksIMG.handleBlur}
                                          autoComplete={"off"}
                                          invalid={
                                              formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? true : false
                                          }
                                      />
                                      {formKirimPesanTeksIMG.touched.isFieldMediaIMG && formKirimPesanTeksIMG.errors.isFieldMediaIMG ? (
                                          <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldMediaIMG}</FormFeedback>
                                      ) : null}
                                  </Col>
                              </Row>
                          </div>

                          <div className="mb-3">
                              <Row>
                                  <Col md={4}>
                                      <Label className="col-form-label" for="isFieldIsiPesanWA">
                                          <b><span className="text-danger">*</span></b> Isi Pesan WhatsApp
                                      </Label>
                                  </Col>
                                  <Col md={8}>
                                      <textarea
                                          id="isFieldIsiPesanWA"
                                          name="isFieldIsiPesanWA"
                                          className="form-control"
                                          rows="8"
                                          placeholder="Masukkan isi pesan WhatsApp yang akan dikirim . . ."
                                          autoComplete="off"
                                          maxLength={255}
                                          value={formKirimPesanTeksIMG.values.isFieldIsiPesanWA || ""}
                                          onChange={(evt) => {
                                              //handleFormDataPelanggan_Alamat(evt.target.value);

                                              formKirimPesanTeksIMG.handleChange(evt);
                                          }}
                                          onBlur={formKirimPesanTeksIMG.handleBlur}
                                      />
                                      {formKirimPesanTeksIMG.touched.isFieldIsiPesanWA && formKirimPesanTeksIMG.errors.isFieldIsiPesanWA ? (
                                          <FormFeedback type="invalid">{formKirimPesanTeksIMG.errors.isFieldIsiPesanWA}</FormFeedback>
                                      ) : null}
                                  </Col>
                              </Row>
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-md btn-soft-info" onClick={() => {
                              setisModalKirimPesanTeksIMG(false);
                          }}>Tutup</button>
                          <Form
                              onSubmit={(e) => {

                                  e.preventDefault();
                                  formKirimPesanTeksIMG.handleSubmit();
                                  setisModalKirimPesanTeksIMG(false);

                                  return false;
                              }}
                          >
                              <button type="submit" className="btn btn-md btn-soft-success" >Kirim</button>
                          </Form>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR CONFIRM DATA*/}
                  <Modal
                      isOpen={isModalConfirmKontrak}
                      toggle={() => {
                          isShowModalConfirmKontrak();
                      }}
                      backdrop={'static'}
                      id="modalConfirmKontrak"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-success" id="modalConfirmKontrakLabel"><i className=" mdi mdi-information-outline me-2"></i>VALIDASI DATA KONTRAK</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalConfirmKontrak(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">Nomor Kontrak : <b>#{isNomorKontrak}</b></p>
                          <p className="font-size-13 text-justify">Proses validasi data kontrak ini <b className="text-warning">TIDAK DAPAT DIBATALKAN</b>, apakah anda yakin ingin memvalidasi data kontrak ini tanpa melihat detil ?</p>
                          <p>Harap Ceklist pertanyaan <b className="text-danger">Apakah Barang Ada ?</b> jika barang ada atau tersedia.</p>
                          <br />

                          <Col sm={12}>
                              <div className="form-check form-checkbox form-check-info text-right">
                                  <Row>
                                      <Col sm={6}>
                                          <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="isValSudahCekFisik"
                                              name="isValSudahCekFisik"
                                              checked={isSudahCekFISIK}
                                              onChange={() => {
                                                  setisSudahCekFISIK(!isSudahCekFISIK)
                                              }}
                                          />
                                          <Label
                                              className="form-check-label text-info"
                                              htmlFor="isValSudahCekFisik"
                                          >
                                              Sudah Cek Fisik ?
                                          </Label>
                                      </Col>

                                      <Col sm={6}>
                                          <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="isValBarangAda"
                                              name="isValBarangAda"
                                              onChange={(evt) => {
                                                  if (evt.target.checked) {
                                                      setisBarangAda("Ada");
                                                  }
                                              }}
                                          />
                                          <Label
                                              className="form-check-label text-danger"
                                              htmlFor="isValBarangAda"
                                          >
                                              Apakah Barang <b>Ada</b> ?
                                          </Label>
                                      </Col>
                                  </Row>
                              </div>
                          </Col>

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-success" onClick={() => {
                              setisModalConfirmKontrak(false);
                          }}>Batal</button>
                          <Form
                              onSubmit={(e) => {
                                  e.preventDefault();
                                  formKontrakValidation.handleSubmit();

                                  setisModalConfirmKontrak(false);
                                  setisSudahCekFISIK(false);
                                  setisBarangAda(null);

                                  return false;
                              }}
                          >
                              <Input
                                  name="isValNoKontrak"
                                  type="hidden"
                                  onChange={formKontrakValidation.handleChange}
                                  onBlur={formKontrakValidation.handleBlur}
                                  value={formKontrakValidation.values.isValNoKontrak || ""}
                              />
                              <button type="submit" className="btn btn-info" disabled={!isSudahCekFISIK ? true : false }>Konfirmasi</button>
                          </Form>
                          <Form
                              onSubmit={(e) => {
                                  e.preventDefault();
                                  formKontrakValidation.handleSubmit();

                                  setisModalConfirmKontrak(false);
                                  setisSudahCekFISIK(false);
                                  setisBarangAda(null);

                                  setisCommandSelectedValue("Reject");

                                  return false;
                              }}
                          >
                              <Input
                                  name="isValNoKontrak"
                                  type="hidden"
                                  onChange={formKontrakValidation.handleChange}
                                  onBlur={formKontrakValidation.handleBlur}
                                  value={formKontrakValidation.values.isValNoKontrak || ""}
                              />
                              <button type="submit" className="btn btn-danger">Tolak</button>
                          </Form>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR DELETE DATA*/}
                  <Modal
                      isOpen={isModalDeleteKontrak}
                      toggle={() => {
                          isShowModalDeleteKontrak();
                      }}
                      backdrop={'static'}
                      id="modalDelKontrak"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header">
                          <h5 className="modal-title text-danger" id="modalDelKontrakLabel"><i className=" mdi mdi-alert-outline me-2"></i>HAPUS DATA KONTRAK</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalDeleteKontrak(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">Nomor Kontrak : <b>#{isNomorKontrak}</b></p>
                          <p className="font-size-13 text-justify">Proses penghapusan data kontrak ini <b className="text-danger">TIDAK DAPAT DIBATALKAN</b>, apakah anda yakin ingin menghapus kontrak ini ?</p>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-success" onClick={() => {
                              setisModalDeleteKontrak(false);
                          }}>Batal</button>
                          <Form
                              onSubmit={(e) => {
                                  e.preventDefault();
                                  formKontrakValidation.handleSubmit();

                                  setisModalDeleteKontrak(false);

                                  return false;
                              }}
                          >
                              <Input
                                  name="isValNoKontrak"
                                  type="hidden"
                                  onChange={formKontrakValidation.handleChange}
                                  onBlur={formKontrakValidation.handleBlur}
                                  value={formKontrakValidation.values.isValNoKontrak || ""}
                              />
                              <button type="submit" className="btn btn-danger">Hapus</button>
                          </Form>
                      </div>
                  </Modal>

                  <Row>
                      <Col lg="12">
                          <Card>
                              <CardBody>
                                  <Row>
                                      {/*ACCORDION INFORMASI*/}
                                      <Col xl={9} className={"pb-4"}>
                                          <div
                                              className="accordion"
                                              id="showInformasiTab"
                                          >
                                              <div className="accordion-item ">
                                                  <h2 className={classnames(
                                                      "accordion-header",
                                                      /*{ "bg-light": !isShowInformasi },*/
                                                      
                                                  )} id="showInformasiTabHeading">
                                                      <button
                                                          className={classnames(
                                                              "accordion-button",
                                                              "fw-medium",
                                                              { collapsed: !isShowInformasi },
                                                              { "text-white": !isShowInformasi },
                                                              { "text-white": isShowInformasi },
                                                              /*{ "text-danger bg-light": isShowInformasi },*/
                                                          )}
                                                          type="button"
                                                          onClick={handleClickisShowInformasi}
                                                          style={{ cursor: "pointer", backgroundColor: '#101218' }}
                                                      >
                                                          <i className="text-muted fw-bold mdi mdi-information-outline me-2"></i> {!isShowInformasi ? "TAMPILKAN PETUNJUK INFORMASI" : "SEMBUNYIKAN PETUNJUK INFORMASI"}
                                                      </button>
                                                  </h2>

                                                  <Collapse
                                                      isOpen={isShowInformasi}
                                                      className="accordion-collapse"
                                                  >
                                                      <div className="accordion-body">
                                                          <div className="text-black">
                                                              <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                              <br />
                                                              <p>#Penjelesan Fungsi Tombol <b>Aksi</b> : </p>
                                                              <ul style={{ listStyle: 'none' }}>
                                                                  <li className="pb-3">
                                                                      <Link
                                                                          to="#"
                                                                          className="btn btn-sm btn-info font-size-14"
                                                                          onClick={() => {
                                                                              return false;
                                                                          }}
                                                                      >
                                                                          <i className="mdi mdi-eye-outline" id="detailtooltipINFO" />
                                                                          <UncontrolledTooltip placement="top" target="detailtooltipINFO">
                                                                              Detil Kontrak
                                                                          </UncontrolledTooltip>
                                                                      </Link> Tombol Detil Kontrak
                                                                      <ul style={{ listStyle: 'inside' }}>
                                                                          <li><b>#FUNGSI</b> : Berfungsi untuk melihat detil kontak atau melihat isi data kontrak secara keseluruhan.</li>
                                                                      </ul>
                                                                  </li>
                                                                  <li className="pb-3">
                                                                      <Link
                                                                          to="#"
                                                                          className="btn btn-sm btn-success font-size-14"
                                                                          onClick={() => {
                                                                              return false;
                                                                          }}
                                                                      >
                                                                          <i className="mdi mdi-whatsapp" id="confirmtooltipINFO" />
                                                                          <UncontrolledTooltip placement="top" target="confirmtooltipINFO">
                                                                              Kirim Peringatan
                                                                          </UncontrolledTooltip>
                                                                      </Link> Kirim Peringatan (<i>Quick</i>)
                                                                      <ul style={{ listStyle: 'inside' }}>
                                                                          <li><b>#FUNGSI</b> : Berfungsi untuk mengirimkan pesan <i>WhatsApp</i>, <b>HARAP DIPERHATIKAN</b> tombol ini hanya digunakan untuk pengingat khusus kepada pemilik kontrak yang dalam status Jatuh Tempo, mohon untuk tidak menggunakan fitur ini ke kontrak yang tidak Jatuh Tempo.</li>
                                                                      </ul>
                                                                  </li>
                                                              </ul>
                                                              <p>~Terimakasih ^.^</p>
                                                          </div>
                                                      </div>
                                                  </Collapse>
                                              </div>
                                          </div>
                                          {/*<div >
                                              <Alert className="alert-info" role="alert">
                                                  <h5 className="alert-heading text-primary pb-2"><i className="mdi mdi-information-outline me-2"></i> INFORMASI</h5>
                                                  <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                  <p>~Terimakasih ^.^</p>
                                              </Alert>
                                          </div>*/}
                                      </Col>
                                      <Col sm={3} className="mb-3">
                                          <Select
                                              id="INPUTFilterMerk"
                                              name="INPUTFilterMerk"
                                              placeholder="Filter Kontrak Aktif Berdasarkan Merk"
                                              value={selectedFilterMerkHP}
                                              onMenuOpen={(e) => {
                                                  handleFeedCariMerkHP();
                                              }}
                                              onChange={(selectedFilterMerkHP) => {
                                                  handleSelectedFilterMerkHP(selectedFilterMerkHP);
                                              }}
                                              options={optionFilterMerkHP}
                                              className="select2-selection"
                                              isLoading={isLoadingCariMerkHP}
                                          />
                                      </Col>

                                      <Col lg={12} className="">
                                          {/*<Col sm={3}>
                                              <Select
                                                  id="INPUTFilterDateTRX"
                                                  name="INPUTFilterDateTRX"
                                                  placeholder="Filter Waktu Transaksi"
                                                  value={selectedFilterDateData}
                                                  onChange={(selectedFilterDateData) => {
                                                      handleSelectedFilterDateData(selectedFilterDateData);
                                                  }}
                                                  options={optionFilterDateData}
                                                  className="select2-selection"
                                                  isDisabled={null}
                                              />
                                          </Col>*/}
                                          <div className="d-flex flex-wrap gap-2 text-center me-2">
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-success  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("Hari Ini");
                                                      await FeedFunctionDataForTableValidasiTransaksiJAPO("isTRXToday");
                                                      await postFetchsummaryReportDataGadaiPhoneJaPoTODAY("isTRXToday");

                                                  }}
                                              >
                                                  <i className="far fa-clock label-icon"></i>Jatuh Tempo - Hari Ini
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-success  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("Hari Ini");
                                                      await FeedFunctionDataForTableValidasiTransaksi("isTRXToday");
                                                      await postFetchsummaryReportDataGadaiPhoneTODAY("isTRXToday");

                                                  }}
                                              >
                                                  <i className="bx bx-calendar label-icon"></i>Kontrak Baru - Hari Ini
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiJAPO("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhoneLewJAPO("isTRXAll");

                                                  }}
                                              >
                                                  <i className="far fa-clock label-icon"></i>Lewat Jatuh Tempo
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiRUNNING("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhoneRUNNING("isTRXAll");

                                                  }}
                                              >
                                                  <i className="far fa-check-circle label-icon"></i>Berjalan - All in
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiPENDING("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhonePENDING("isTRXAll");

                                                  }}
                                              >
                                                  <i className="bx bxs-hourglass-top label-icon"></i>Pending - All in
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-warning  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiLUNAS("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhoneLUNAS("isTRXAll");

                                                  }}
                                              >
                                                  <i className="bx bx-badge-check label-icon"></i>Lunas - All in
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-danger  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiFAILED("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhoneFAILED("isTRXAll");

                                                  }}
                                              >
                                                  <i className="far fa-times-circle label-icon"></i>Gagal - All in
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-danger  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksiREJECT("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhoneREJECT("isTRXAll");

                                                  }}
                                              >
                                                  <i className="far fa-times-circle label-icon"></i>Ditolak - All in
                                              </button>
                                              <button
                                                  type="button"
                                                  className="btn btn-soft-primary  btn-label me-2 btn-sm fw-bold"
                                                  onClick={async (evt) => {
                                                      setisDataLoading(true);
                                                      setfilterDataName("All in");
                                                      await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
                                                      await postFetchsummaryReportDataGadaiPhone("isTRXAll");

                                                  }}
                                              >
                                                  <i className="bx bx-detail label-icon"></i>Tampilkan Semua
                                              </button>
                                          </div>

                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                      </Col>


                                      {/*Filter Manual*/}
                                      <Col lg={12} className="">
                                          <Row>
                                              <Col md={3}>
                                                  <div className="mb-3">
                                                      <h4 className="card-title col-form-label">Filter Data Kontrak <span className="text-primary">All in</span> - Manual ~ </h4>
                                                  </div>
                                              </Col>
                                                  <Col md={9}>
                                                      <Form onSubmit={(e) => {
                                                          e.preventDefault();
                                                          VLDFilterManual.handleSubmit();
                                                          return false;
                                                      }}>
                                                      <Row>
                                                          <Col sm={3}>
                                                              <div className="mb-3">
                                                              <Flatpickr
                                                                  name="FilterManualTglFrom"
                                                                  id="FilterManualTglFrom"
                                                                  className={`form-control ${VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? "is-invalid" : ""
                                                                      }`}
                                                                  placeholder="Pilih Tanggal Dari"
                                                                  options={{
                                                                      altInput: true,
                                                                      altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                                      dateFormat: "Y-m-d",
                                                                      locale: Indonesian,
                                                                      time_24hr: true,
                                                                      disableMobile: "true",
                                                                      defaultDate: null,
                                                                      maxDate: 'today',
                                                                      onChange: (selectedDates, dateStr, instance) => setFilterManualTglFromVAL(dateStr /*+ moment().format(" 00:00:00")*/),
                                                                  }}
                                                                  value={VLDFilterManual.values.FilterManualTglFrom || ""}
                                                              />
                                                              {
                                                                  VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? (
                                                                      <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglFrom}</FormFeedback>
                                                                  ) : null
                                                                  }
                                                              </div>
                                                      </Col>
                                                          <Col sm={3}>
                                                              <div className="mb-3">
                                                              <Flatpickr
                                                                  name="FilterManualTglTo"
                                                                  id="FilterManualTglTo"
                                                                  className={`form-control ${VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? "is-invalid" : ""
                                                                      }`}
                                                                  placeholder="Pilih Tanggal Sampai"
                                                                  options={{
                                                                      altInput: true,
                                                                      altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                                      dateFormat: "Y-m-d",
                                                                      locale: Indonesian,
                                                                      time_24hr: true,
                                                                      disableMobile: "true",
                                                                      defaultDate: null,
                                                                      minDate: FilterManualTglFromVAL,
                                                                      maxDate: 'today',
                                                                      onChange: (selectedDates, dateStr, instance) => setFilterManualTglToVAL(dateStr /*+ moment().format(" HH:mm:ss")*/),
                                                                  }}
                                                                  value={VLDFilterManual.values.FilterManualTglTo || ""}
                                                              />
                                                              {
                                                                  VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? (
                                                                      <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglTo}</FormFeedback>
                                                                  ) : null
                                                                  }
                                                              </div>
                                                      </Col>
                                                          <Col sm={3}>
                                                              <div className="mb-3">
                                                          <Select
                                                              id="FilterMerkHPManual"
                                                              name="FilterMerkHPManual"
                                                              placeholder="Pilih Merk (Opsional)"
                                                              value={selectedFilterMerkHPManual}
                                                              onMenuOpen={(e) => {
                                                                  handleFeedCariMerkHP();
                                                              }}
                                                              onChange={(selectedFilterMerkHPManual) => {
                                                                  handleSelectedFilterMerkHPManual(selectedFilterMerkHPManual);

                                                                  setFilterMerkHPManualVAL(selectedFilterMerkHPManual.value);
                                                              }}
                                                              options={optionFilterMerkHP}
                                                              className="select2-selection"
                                                              isLoading={isLoadingCariMerkHP}
                                                                  />
                                                              </div>
                                                      </Col>
                                                      <Col sm={3}>
                                                          <Row>
                                                              <Col xs={6} className="">
                                                                      <Button color="btn btn-square btn-soft-primary w-md me-2" disabled={false} type="submit"
                                                                      style={({ width: "100%", cursor: "pointer", fontSize: 12 })}
                                                                  >
                                                                      <i className="mdi mdi-filter" style={{ marginRight: '5px' }}></i> Filter
                                                                  </Button>
                                                              </Col>
                                                              <Col xs={6}>
                                                                  <Button
                                                                      onClick={async (event) => {
                                                                          await handleSelectedFilterMerkHPManual(null);

                                                                              setFilterManualTglFromVAL(null);
                                                                              setFilterManualTglToVAL(null);
                                                                              setFilterMerkHPManualVAL(null);

                                                                          setisDataLoading(true);
                                                                          setfilterDataName("All in");
                                                                          await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
                                                                          await postFetchsummaryReportDataGadaiPhone("isTRXAll");
                                                                      }} type="button"
                                                                          color="btn btn-square btn-soft-primary w-md me-2" disabled={false}
                                                                      style={({ width: "100%", cursor: "pointer", fontSize: 12 })}
                                                                  >
                                                                      <i className="mdi mdi-reload" style={{ marginRight: '5px' }}></i> Reset
                                                                  </Button>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      </Row>
                                                  </Form>
                                                  </Col>
                                          </Row>
                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                          
                                      </Col>
                                      {
                                          isDataLoading
                                              ?
                                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                                              </Col>
                                              :
                                              isDataValidsasiTransaksi != null && isDataValidsasiTransaksi.length > 0
                                              ?
                                              <Col lg={12} className={"mt-1"}>
                                                  <TableContainer
                                                      className={"table table-hover rounded"}
                                                      columns={isColumnsData}
                                                      data={isDataValidsasiTransaksi || [{}]}
                                                      isGlobalFilter={true}
                                                      isAddOptions={false}
                                                      isPlaceholderData={"Cari data pegadaian. . ."}
                                                      customPageSize={50}
                                                      isSorted={"desc"}
                                                      isLoading={true}
                                                  />
                                                  </Col>
                                                  :
                                                  <Col lg={12} className="mt-5 pb-5 text-center">
                                                      <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                                  </Col>
                                      }
                                  </Row>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
          </Container>
          </div>
    </React.Fragment>
  );
};

ManagementReportPawnshop.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ManagementReportPawnshop);
