import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    Alert,
    InputGroup,
    FormFeedback,
    UncontrolledAlert,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";

import Webcam from "react-webcam";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Select from "react-select";
import { Link } from "react-router-dom"
import classnames from "classnames"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Indonesian } from "flatpickr/dist/l10n/id.js"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from 'moment';
import Dropzone from "react-dropzone";

//Import Image
import PatternLockIMG from "../../../../assets/images/Pattern-Lock.png";

import {
    fetchDataPelangganLama,
    postDataPelangganByUID,
    fetchFullMerkHP,
    fetchFullTipeHPByCode,
    fetchFullSpecTipeHP,
    fetchNilaiTaksirHP,
    fetchFullDataBank,
    fetchFullDataeWallet,
    fetchFullDataFakeBank,
    fetchDataCustWithBank,
    fetchDataCustListBank,
    fetchPelangganBankSelect,
    postNewTransaksiGadaiBarang,
    postUploadFotoKelengkapan,
    postUploadFotoKTPNasabah,
    cekNIK,
    cekNoHP,
    fetchDataCabang,
} from "../../../../helpers/backend_helper";
/*import {
    API_URL,
} from "../../../helpers/api_helper";*/

import jwt_decode from "jwt-decode";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, useFormikContext } from "formik";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

let isDataCabangRESULT = null ;
let isFullMerkHPRESULT = [{}] ;
let isFullTipeHPRESULT = [{}] ;
let isFullDataPelangganRESULT = [{}] ;
let isFullDataMasterBankRESULT = [{}] ;
let selectedFullDataBank_RESULT = [{}] ;
let isResultTransaksiGadai = [{}] ;
let isResultFullPhoneSpec = [{}] ;
let isResultFullNilaiTaksiranHP = null;
let isFullDataPelangganUID_RESULT = null;

let RES_TipeTPengenal = "";
let RES_TipePelanggan = "";
let RES_JenisKatasandi = "";
let RES_RincianMETODE = "";
//let isLoadingCariPelanggan = false;
//let isLoadingCariMerkHP = false;
//let isLoadingCariDataBank = false;

let JSONInputFull = {};
let INPUT_OP_UUID = "";
let INPUT_OP_KODE_CABANG = "";

//Form Data Pelanggan - Transaksi Gadai
let INPUT_TipeTPengenal = "";
let INPUT_KategoriPelanggan = "";
let INPUT_idPelanggan = "";
let INPUT_NamaLengkap = "";
let INPUT_NamaPanggilan = "";
let INPUT_NIK = "";
let INPUT_NomorHP = "";
let INPUT_Alamat = "";
let INPUT_FotoKTP = "";
let INPUT_LevelPelanggan = "";

//Form Barang Jaminan - Transaksi Gadai
let INPUT_JenisBarang = "SmartPhone";
let INPUT_KondisiFisik = "";
let INPUT_FungsiPerangkat = "";
let INPUT_IndikasiServices = "";
let INPUT_StatusIMEI = "";
let INPUT_Charger = "";
let INPUT_InfoCasing = "";
let INPUT_MerkHP = "";
let INPUT_TipeHP = "";
let INPUT_TipeHPCODE = null;
let INPUT_TipeHPLainnya = "";
let INPUT_RAM = "";
let INPUT_MemInternal = "";
let INPUT_IMEI1 = "";
let INPUT_IMEI2 = "00000000";
let INPUT_JenisKatasandiHP = "";
let INPUT_KatasandiHP = "";
let INPUT_FotoKelengkapan = "";
let INPUT_CatatanBarang = "";

//Form Rincian Transaksi
let INPUT_TipeRekening = "";
let INPUT_TipeRekeningNAMA = "";
let INPUT_MetodePencairan = "";
let INPUT_MetodePencairanVALUE = "";
let INPUT_NominalGadai = "";
let INPUT_NominalGadaiFormat = "";
let INPUT_HargaTaksiran = 0;
let INPUT_HargaTaksiran_Manual = 0;
let INPUT_HargaTaksiran_ManualFORMAT = 0;
let INPUT_TotalPersentaseTaksiran = 0;
let INPUT_NamaBank = "";
let INPUT_SandiBank = "";
let INPUT_TipeBank = "";
let INPUT_NamaRek = "";
let INPUT_NoRek = "";
let INPUT_FromTanggal = "";
let INPUT_ToTanggal = "";
let INPUT_CatatanTransaksi = "";
let INPUT_PercentJasaTitip = 0;
let INPUT_BiayaPerpanjang = 0;
let INPUT_BiayaPerpanjangFormat = 0;
let INPUT_NominalTebus = 0;
let INPUT_NominalTebusFormat = 0;
let INPUT_RekeningExists = false;

let isStatusNIKAvailable = false;
let isStatusNoHPAvailable = false;

const NewTRXPawnshopHandphone = props => {

    const [isPagePreloader, setisPagePreloader] = useState(false);

    const [isINPUT_TipePelanggan, isSetINPUT_TipePerlanggan] = useState("");
    const [isINPUT_TipeTandaPengenal, isSetINPUT_TipeTandaPengenal] = useState("");
    const [isLoadingCariPelanggan, setisLoadingCariPelanggan] = useState(false);
    const [isLoadingCariMerkHP, setisLoadingCariMerkHP] = useState(false);
    const [isLoadingCariTipeHP, setisLoadingCariTipeHP] = useState(false);
    const [isLoadingCariDataBank, setisLoadingCariDataBank] = useState(false);

    const webcamRefCaptureKTP = useRef(null);

    const captureFotoKTPFromCAM = useCallback(async () => {
        const imageSrc = webcamRefCaptureKTP.current.getScreenshot({ width: 1280, height: 720 });

        //const blob = await fetch(imageSrc).then((res) => res);

        const isFileRenameValue = "imgKTPNasabah-" + INPUT_NIK + "_" + Date.now() + ".jpg";
        let isResultFileKTP = dataURLtoFile(imageSrc, isFileRenameValue);

        setselectedFile(imageSrc);

        INPUT_FotoKTP = isFileRenameValue;

        setfileFotoKTP(isResultFileKTP);

    }, [webcamRefCaptureKTP]);

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const webcamRefCaptureKelengkapan = useRef(null);

    const captureFotoKelengkapanFromCAM = useCallback(() => {
        const imageSrc = webcamRefCaptureKelengkapan.current.getScreenshot({ width: 1280, height: 720 });

        //const blob = await fetch(imageSrc).then((res) => res);

        const isFileRenameValue = "imgTRX-" + INPUT_NIK + "_" + Date.now() + ".jpg";
        let isResultFileKelengkapan = dataURLtoFile(imageSrc, isFileRenameValue);

        setselectedFileKelengkapan(imageSrc);

        INPUT_FotoKelengkapan = isFileRenameValue;

        setfileFotoKelengkapan(isResultFileKelengkapan);

    }, [webcamRefCaptureKelengkapan]);

    const [FisikMulus, setFisikMulus] = useState(false);
    const [FisikTidakMulus, setFisikTidakMulus] = useState(false);
    const [FungsiNormal, setFungsiNormal] = useState(false);
    const [FungsiTidakNormal, setFungsiTidakNormal] = useState(false);
    const [IndikasiTidakPernahServices, setIndikasiTidakPernahServices] = useState(false);
    const [IndikasiPernahServices, setIndikasiPernahServices] = useState(false);
    const [IMEITerdaftar, setIMEITerdaftar] = useState(false);
    const [IMEITidakTerdaftar, setIMEITidakTerdaftar] = useState(false);

    const [modal_center, setmodal_center] = useState(false);
    const [ModalPengisianKatasandi, setModalPengisianKatasandi] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function ToggleModalPengisianKatasandi() {
        setModalPengisianKatasandi(!ModalPengisianKatasandi);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    moment.tz.setDefault('Asia/Jakarta');

    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }
    
    const [isEnableDisableCariTipeHandphone, setisEnableDisableCariTipeHandphone] = useState(true);
    const [isEnableDisablePilihFisikPerangkat, setisEnableDisablePilihFisikPerangkat] = useState(true);
    const [isEnableDisablePilihFungsiPerangkat, setisEnableDisablePilihFungsiPerangkat] = useState(true);
    const [isEnableDisablePilihIndikasiServicesPerangkat, setisEnableDisablePilihIndikasiServicesPerangkat] = useState(true);
    const [isEnableDisablePilihInformasiCasingPerangkat, setisEnableDisablePilihInformasiCasingPerangkat] = useState(true);
    const [isEnableDisablePilihStatusIMEIPerangkat, setisEnableDisablePilihStatusIMEIPerangkat] = useState(true);
    const [isEnableDisablePilihInformasiChargerPerangkat, setisEnableDisablePilihInformasiChargerPerangkat] = useState(true);
    const [isEnableDisableInputRAMPerangkat, setisEnableDisableInputRAMPerangkat] = useState(true);
    const [isEnableDisableInputMemInternalPerangkat, setisEnableDisableInputMemInternalPerangkat] = useState(true);
    const [isEnableDisableInputIMEI1Perangkat, setisEnableDisableInputIMEI1Perangkat] = useState(true);
    const [isEnableDisableInputIMEI2Perangkat, setisEnableDisableInputIMEI2Perangkat] = useState(true);
    const [isEnableDisablePilihJenisKatasandiPerangkat, setisEnableDisablePilihJenisKatasandiPerangkat] = useState(true);
    const [isEnableDisableInputCatatanPerangkat, setisEnableDisableInputCatatanPerangkat] = useState(true);
    const [isEnableDisableInputNilaiTaksirMaksimal, setisEnableDisableInputNilaiTaksirMaksimal] = useState(true);
    
    const [isEnableDisableCariPelanggan, setisEnableDisableCariPelanggan] = useState(true);
    const [isEnableDisableKategoriPelanggan, setisEnableDisableKategoriPelanggan] = useState(true);
    const [isEDFullNamePelanggan, setisEDFullNamePelanggan] = useState(true);
    const [isEDNamePelanggan, setisEDNamePelanggan] = useState(true);
    const [isEDNIKPelanggan, setisEDNIKPelanggan] = useState(true);
    const [isEDPhoneNumberPelanggan, setisEDPhoneNumberPelanggan] = useState(true);
    const [isEDFotoKTPPelanggan, setisEDFotoKTPPelanggan] = useState(true);
    const [isEDAlamatPelanggan, setisEDAlamatPelanggan] = useState(true);
    const [ShowUploadKTP, setShowUploadKTP] = useState(true);

    const [isFullDataPelanggan, setisFullDataPelanggan] = useState(null);
    const [isFullDataPelangganUID, setisFullDataPelangganUID] = useState(null);

    const [isFullMerkHP, setisFullMerkHP] = useState(null);
    const [isFullTipeHPByBrand, setisFullTipeHPByBrand] = useState(null);
    const [isFullTipeHPSpec, setisFullTipeHPSpec] = useState(null);
    const [isFullNilaiTaksiranHP, setisFullNilaiTaksiranHP] = useState(null);

    const [isNominalFisikHP, setisNominalFisikHP] = useState(0);
    const [isNominalFungsiHP, setisNominalFungsiHP] = useState(0);
    const [isNominalHPPernahServices, setisNominalHPPernahServices] = useState(0);
    const [isNominalIMEIStatusHP, setisNominalIMEIStatusHP] = useState(0);
    const [isNominalHPCharger, setisNominalHPCharger] = useState(0);
    const [isNominalHPChargerNothing, setisNominalHPChargerNothing] = useState(0);
    const [isNominalHPChargerOEM, setisNominalHPChargerOEM] = useState(0);

    async function FeedFunctionNilaiTaksiranHP(IDorCode) {

        isResultFullNilaiTaksiranHP = null;

        try {
            isResultFullNilaiTaksiranHP = await fetchNilaiTaksirHP({ IDorCode: IDorCode });

            setisFullNilaiTaksiranHP(isResultFullNilaiTaksiranHP);

            if (isResultFullNilaiTaksiranHP != null) {
                handleFormJaminan_NilaiTaksirManual(isResultFullNilaiTaksiranHP.NilaiTaksirMax);

                setisEnableDisableInputNilaiTaksirMaksimal(true);

                setisTaksiranBukanAngka(false);
            }
            else {
                setisEnableDisableInputNilaiTaksirMaksimal(false);
            }
        }
        catch (Err) {
            console.log(Err);
            setisEnableDisableInputNilaiTaksirMaksimal(true);
        }
    }

    function autoChangedValueHargaTaksiran() {

        INPUT_HargaTaksiran = 0;
        INPUT_TotalPersentaseTaksiran = 0;

        let CountingPercentageTaksir = 0;

        try {
            if (isResultFullNilaiTaksiranHP != null) {

                INPUT_HargaTaksiran = isResultFullNilaiTaksiranHP.NilaiTaksirMax;

                if (INPUT_IndikasiServices === "Pernah Services") {
                    CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_ServiceORNot;
                }

                if (INPUT_KondisiFisik === "Tidak Mulus") {
                    CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_Fisik;
                }

                if (INPUT_FungsiPerangkat === "Tidak Normal") {
                    CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_Fungsi;
                }

                if (INPUT_StatusIMEI === "Tidak Terdaftar") {
                    CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_IMEIBlockORNot;
                }

                if (INPUT_Charger === "OEM" || INPUT_Charger === "Tidak Ada") {
                    if (INPUT_Charger === "OEM") {
                        CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_ChargerOEM;
                    }
                    else {
                        CountingPercentageTaksir = CountingPercentageTaksir + isResultFullNilaiTaksiranHP.Percentage_ChargerNothing;
                    }
                }

                if (CountingPercentageTaksir > 99) {
                    CountingPercentageTaksir = isResultFullNilaiTaksiranHP.Percentage_ServiceORNot;
                }

                INPUT_TotalPersentaseTaksiran = Number((CountingPercentageTaksir / 100).toFixed(2));

                INPUT_HargaTaksiran = (INPUT_HargaTaksiran - (INPUT_HargaTaksiran * INPUT_TotalPersentaseTaksiran)).toFixed(0);

                //handleFormJaminan_NilaiTaksirManual(INPUT_HargaTaksiran);
            }
            else {
                INPUT_HargaTaksiran = INPUT_HargaTaksiran_Manual;

                if (INPUT_IndikasiServices === "Pernah Services") {
                    CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_INDIKASI_SERVICED);
                }

                if (INPUT_KondisiFisik === "Tidak Mulus") {
                    CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_FISIK);
                }

                if (INPUT_FungsiPerangkat === "Tidak Normal") {
                    CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_FUNGSI);
                }

                if (INPUT_StatusIMEI === "Tidak Terdaftar") {
                    CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_IMEI_BLOCKED);
                }

                if (INPUT_Charger === "OEM" || INPUT_Charger === "Tidak Ada") {
                    if (INPUT_Charger === "OEM") {
                        CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_CHARGER_OEM);
                    }
                    else {
                        CountingPercentageTaksir = CountingPercentageTaksir + Number(process.env.REACT_APP_DEFAULT_PERCENT_NO_CHARGER);
                    }
                }

                if (CountingPercentageTaksir > 99) {
                    CountingPercentageTaksir = Number(process.env.REACT_APP_DEFAULT_PERCENT_INDIKASI_SERVICED);
                }
                
                INPUT_TotalPersentaseTaksiran = Number((CountingPercentageTaksir / 100).toFixed(2));

                INPUT_HargaTaksiran = (INPUT_HargaTaksiran - (INPUT_HargaTaksiran * INPUT_TotalPersentaseTaksiran)).toFixed(0);

                //handleFormJaminan_NilaiTaksirManual(INPUT_HargaTaksiran);
            }
        }
        catch (Err) {
            //Nothing
            console.log(Err);
        }

        setisHargaTaksiran(INPUT_HargaTaksiran);
    }

    async function FeedFunctionSpecHandphone(IDorCode) {
        try {
            isResultFullPhoneSpec = await fetchFullSpecTipeHP(IDorCode);

            setisFullTipeHPSpec(isResultFullPhoneSpec);

            setisSpecChipsetSelected(isResultFullPhoneSpec.Chipset);
            setisSpecRAMSelected(isResultFullPhoneSpec.SpecRAM);
            setisSpecMemInternalSelected(isResultFullPhoneSpec.SpecInternalMemory);

            INPUT_TipeHP = isResultFullPhoneSpec.NameDevices;
            INPUT_TipeHPCODE = isResultFullPhoneSpec.dbID;

            INPUT_RAM = isResultFullPhoneSpec.SpecRAM;
            INPUT_MemInternal = isResultFullPhoneSpec.SpecInternalMemory;
        }
        catch (Err) {

        }
    }

    async function FeedFunctionMerkHP() {

        setisLoadingCariMerkHP(true);

        try {
            isFullMerkHPRESULT = await fetchFullMerkHP();

            setisFullMerkHP(isFullMerkHPRESULT);

            setisLoadingCariMerkHP(false);
        }
        catch (Err) {

        }
    }

    //Select Cari Pelanggan
    let optionMerkHandphone = [
        {
            label: "Cari Merk Handphone",
            options: isFullMerkHP || []
        },
    ];

    let [selectedMerkHandphone, setselectedMerkHandphone] = useState(null);

    async function handleFeedCariMerkHP() {

        setisFullMerkHP(null);

        await FeedFunctionMerkHP();
    }

    async function handleCariMerkHP(selectedMerkHandphone) {
        setselectedMerkHandphone(selectedMerkHandphone);
        
        setselectedTipeHandphone(null);

        setisFullTipeHPSpec(null);

        setisSpecChipsetSelected(null);
        setisSpecRAMSelected(null);
        setisSpecMemInternalSelected(null);

        setisEnableDisableInputNilaiTaksirMaksimal(true);

        INPUT_TipeHP = '';

        INPUT_RAM = '';
        INPUT_MemInternal = '';
        
        INPUT_HargaTaksiran_Manual = 0;
        INPUT_HargaTaksiran_ManualFORMAT = '';

        INPUT_NominalGadai = 0;
        INPUT_NominalGadaiFormat = '';
        INPUT_NominalTebus = 0;
        INPUT_NominalTebusFormat = '';

        setisEnableDisablePilihFisikPerangkat(true);
        setisEnableDisablePilihFungsiPerangkat(true);
        setisEnableDisablePilihIndikasiServicesPerangkat(true);
        setisEnableDisablePilihInformasiCasingPerangkat(true);
        setisEnableDisablePilihStatusIMEIPerangkat(true);
        setisEnableDisablePilihInformasiChargerPerangkat(true);
        setisEnableDisableInputRAMPerangkat(true);
        setisEnableDisableInputMemInternalPerangkat(true);
        setisEnableDisableInputIMEI1Perangkat(true);
        setisEnableDisableInputIMEI2Perangkat(true);
        setisEnableDisablePilihJenisKatasandiPerangkat(true);
        setisEnableDisableInputCatatanPerangkat(true);

        await FeedFunctionMerkHP();

        await FeedFunctionTipeHPByBrand();
    }

    async function FeedFunctionTipeHPByBrand() {

        setisLoadingCariTipeHP(true);

        try {
            isFullTipeHPRESULT = await fetchFullTipeHPByCode({ BrandCode: INPUT_MerkHP });

            setisFullTipeHPByBrand(isFullTipeHPRESULT);

            setisLoadingCariTipeHP(false);
        }
        catch (Err) {

        }
    }

    //Select Cari Pelanggan
    let optionTipeHandphone = [
        {
            label: "Cari Tipe Handphone",
            options: isFullTipeHPByBrand || []
        },
    ];

    let [selectedTipeHandphone, setselectedTipeHandphone] = useState(null);

    async function handleFeedCariTipeHP() {
        setisFullTipeHPByBrand(null);

        await FeedFunctionTipeHPByBrand();
    }

    async function handleCariTipeHP(selectedTipeHandphone) {
        setselectedTipeHandphone(selectedTipeHandphone);

        setisEnableDisablePilihFisikPerangkat(false);
        setisEnableDisablePilihFungsiPerangkat(false);
        setisEnableDisablePilihIndikasiServicesPerangkat(false);
        setisEnableDisablePilihInformasiCasingPerangkat(false);
        setisEnableDisablePilihStatusIMEIPerangkat(false);
        setisEnableDisablePilihInformasiChargerPerangkat(false);
        setisEnableDisableInputRAMPerangkat(false);
        setisEnableDisableInputMemInternalPerangkat(false);
        setisEnableDisableInputIMEI1Perangkat(false);
        setisEnableDisableInputIMEI2Perangkat(false);
        setisEnableDisablePilihJenisKatasandiPerangkat(false);
        setisEnableDisableInputCatatanPerangkat(false);
        setisEnableDisableInputCatatanPerangkat(false);

        FeedFunctionSpecHandphone(selectedTipeHandphone.value);
        FeedFunctionNilaiTaksiranHP(selectedTipeHandphone.value);

        INPUT_HargaTaksiran = 0;
        INPUT_HargaTaksiran_Manual = 0;
        INPUT_HargaTaksiran_ManualFORMAT = 0;
        INPUT_TotalPersentaseTaksiran = 0;

        INPUT_NominalGadai = 0;
        INPUT_NominalGadaiFormat = '';
        INPUT_NominalTebus = 0;
        INPUT_NominalTebusFormat = '';
    }

    async function FeedFunctionDataPelanggan() {

        setisLoadingCariPelanggan(true);

        try {
            isFullDataPelangganRESULT = await fetchDataPelangganLama({ CardType: INPUT_TipeTPengenal });

            setisFullDataPelanggan(isFullDataPelangganRESULT);

            setisLoadingCariPelanggan(false);
        }
        catch (Err) {
            //Nothing
        }
    }

    async function FeedFunctionDataPelangganByUID(isUIDCustomer) {
        try {
            isFullDataPelangganUID_RESULT = await postDataPelangganByUID({ 'CustUID': isUIDCustomer });

            setisFullDataPelangganUID(isFullDataPelangganUID_RESULT);

            INPUT_idPelanggan = isFullDataPelangganUID_RESULT[0].idPelanggan;
            INPUT_NamaLengkap = isFullDataPelangganUID_RESULT[0].NamaLengkap;
            INPUT_NamaPanggilan = isFullDataPelangganUID_RESULT[0].NamaPanggilan;
            INPUT_NIK = isFullDataPelangganUID_RESULT[0].NIKPelanggan;
            INPUT_NomorHP = isFullDataPelangganUID_RESULT[0].NoHP;
            INPUT_Alamat = isFullDataPelangganUID_RESULT[0].Alamat;
            INPUT_FotoKTP = isFullDataPelangganUID_RESULT[0].FotoKTP;

            setuserBlackListStatus(isFullDataPelangganUID_RESULT[0].Blacklist)

            try {
                //apply base url for axios
                let API_URL = '';

                const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                //Using Global Domain Server
                if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                    /*API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
    API_IMAGE_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_IMAGE_PORT_INTERNET;*/
                    API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN_BACKEND;
                }
                else {
                    //127.0.0.1
                    if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                        API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                    }
                    //Local IP 192.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                        API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                    }
                    //Public IP X.X.X.X
                    else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                        API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                    }

                }

                //console.log(window.location.hostname);
                setselectedFile(API_URL + "/img-assets/imgKTPNasabah/" + INPUT_FotoKTP);

                setShowUploadKTP(false);
            }
            catch (Err) {
                //Nothing
                console.log(Err);
            }
        }
        catch (Err) {

        }
        //console.log(isFullDataPelangganUID);
    }

    //Select Cari Pelanggan
    let optionCariDataPelanggan = [
        {
            label: "Pilih Data Nasabah",
            options: isFullDataPelanggan || []
        },
    ];

    let [selectedCariDataPelanggan, setselectedCariDataPelanggan] = useState(null);
    let [isNIKNasabahSelected, setisNIKNasabahSelected] = useState(null);

    async function handleFeedCariDataPelanggan() {

        setisFullDataPelanggan(null);

        await FeedFunctionDataPelanggan();
    }

    async function handleCariDataPelanggan(selectedCariDataPelanggan) {

        setselectedCariDataPelanggan(selectedCariDataPelanggan);

        setisNIKNasabahSelected(selectedCariDataPelanggan.value);

        setisEDNamePelanggan(false);
        setisEDPhoneNumberPelanggan(false);
        setisEDAlamatPelanggan(false);

        await FeedFunctionDataPelangganByUID(selectedCariDataPelanggan.value);
    }

    //Select Tipe / Kategori Pelanggan
    const optionTipePelanggan = [
        {
            label: "Pilih Kategori Nasabah",
            options: [
                { label: "Nasabah Lama", value: "OLDCust" },
                { label: "Nasabah Baru", value: "NEWCust" }
            ]
        },
    ];

    const [selectedTipePelanggan, setselectedTipePelanggan] = useState(null);
    const [selRES_TipePelanggan, setselRES_TipePelanggan] = useState("");

    function handleSelectTipePelanggan(selectedTipePelanggan) {
        setselectedTipePelanggan(selectedTipePelanggan);

        SetTipePelangganValue(selectedTipePelanggan.value);

        //SetTipeTPengenalValue(INPUT_TipeTPengenal);
        //console.log(INPUT_TipeTPengenal);
    }

    function SetTipePelangganValue(TipeCustomer) {
        try {
            if (TipeCustomer == "NEWCust") {
                RES_TipePelanggan = "NEWCust";

                INPUT_idPelanggan = "";
                INPUT_NamaLengkap = "";
                INPUT_NamaPanggilan = "";
                INPUT_NIK = "";
                INPUT_NomorHP = "";
                INPUT_Alamat = "";
                INPUT_FotoKTP = "";

                /*isFullDataPelangganUID_RESULT = null;
                isFullDataPelangganRESULT = null;

                setisNIKNasabahSelected(null);*/

                setselectedCariDataPelanggan(null);

                //setselectedTipePelanggan(null);
                setuserBlackListStatus(false);

                setisFullDataPelanggan(null);

                setisEnableDisableCariPelanggan(true);

                setShowUploadKTP(true);

                setisEDFullNamePelanggan(false);
                setisEDNamePelanggan(false);
                setisEDNIKPelanggan(false);
                setisEDPhoneNumberPelanggan(false);
                setisEDFotoKTPPelanggan(false);
                setisEDAlamatPelanggan(false);

                setisFullDataPelangganUID(null);

                //setisFullDataPelanggan(null);
            }
            else if (TipeCustomer == "OLDCust") {

                INPUT_idPelanggan = "";
                INPUT_NamaLengkap = "";
                INPUT_NamaPanggilan = "";
                INPUT_NIK = "";
                INPUT_NomorHP = "";
                INPUT_Alamat = "";
                INPUT_FotoKTP = "";

                RES_TipePelanggan = "OLDCust";
                setisEnableDisableCariPelanggan(false);

                setShowUploadKTP(false);

                setisEDFullNamePelanggan(true);
                setisEDNamePelanggan(true);
                setisEDNIKPelanggan(true);
                setisEDPhoneNumberPelanggan(true);
                setisEDFotoKTPPelanggan(true);
                setisEDAlamatPelanggan(true);

                FeedFunctionDataPelanggan();
            }
            else {
                RES_TipePelanggan = "";
                setisEnableDisableCariPelanggan(true);

                setShowUploadKTP(true);

                setisEDFullNamePelanggan(false);
                setisEDNamePelanggan(false);
                setisEDNIKPelanggan(false);
                setisEDPhoneNumberPelanggan(false);
                setisEDFotoKTPPelanggan(false);
                setisEDAlamatPelanggan(false);

                setisFullDataPelangganUID(null);

                setuserBlackListStatus(false);
            }
        }
        catch (Err) {
            console.log(Err);
        }

        INPUT_KategoriPelanggan = RES_TipePelanggan;
        isSetINPUT_TipePerlanggan(INPUT_KategoriPelanggan);
    }

    const optionTipeTPengenal = [
        {
            label: "Pilih Kategori Nasabah",
            options: [
                { label: "Kartu Tanda Pengenal (KTP)", value: "KTP" },
                { label: "Surat Izin Mengemudi (SIM)", value: "SIM" }
            ]
        },
    ];

    const [selectedTipeTPengenal, setselectedTipeTPengenal] = useState(null);

    function handleSelectTipeTandaPengenal(selectedTipeTPengenal) {
        setselectedTipeTPengenal(selectedTipeTPengenal);

        SetTipeTPengenalValue(selectedTipeTPengenal.value);
        
        INPUT_idPelanggan = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";

        setselectedTipePelanggan(null);

        setselectedCariDataPelanggan(null);

        setisFullDataPelanggan(null);

        setisEnableDisableCariPelanggan(true);

        setisFullDataPelangganUID(null);

        setisEDFullNamePelanggan(true);
        setisEDNamePelanggan(true);
        setisEDNIKPelanggan(true);
        setisEDPhoneNumberPelanggan(true);
        setisEDFotoKTPPelanggan(true);
        setisEDAlamatPelanggan(true);
    }

    function SetTipeTPengenalValue(TipeTandaPengenal) {
        try {
            if (TipeTandaPengenal == "SIM") {
                RES_TipeTPengenal = "SIM";
            }
            else
            {
                RES_TipeTPengenal = "KTP";
            }
        }
        catch (Err) {
            console.log(Err);
        }

        INPUT_TipeTPengenal = RES_TipeTPengenal;
        isSetINPUT_TipeTandaPengenal(INPUT_TipeTPengenal);
    }


    //Select Jenis Katasandi Unit
    const optionJaminanKatasandi = [
        {
            label: "Pilih Jenis Katasandi",
            options: [
                { label: "Tidak Ada", value: "Tidak Ada" },
                { label: "PIN", value: "PIN" },
                { label: "Katasandi", value: "Katasandi" },
                { label: "Pola", value: "Pola" }
            ]
        },
    ];

    const [selectedKatasandi, setselectedKatasandi] = useState(null);
    const [isEnableFieldKatasandi, setisEnableFieldKatasandi] = useState(true);

    function handleSelectKatasandi(selectedKatasandi) {
        setselectedKatasandi(selectedKatasandi);
        //console.log(selectedKatasandi);
        SetJenisKatasandi(selectedKatasandi.value);
    }

    function SetJenisKatasandi(TipeKatasandi) {
        try {
            if (TipeKatasandi == "NotSet") {
                RES_JenisKatasandi = "NotSet";
                setisEnableFieldKatasandi(true);
            }
            else if (TipeKatasandi == "PIN") {
                RES_JenisKatasandi = "PIN";
                setisEnableFieldKatasandi(false);
            }
            else if (TipeKatasandi == "Katasandi") {
                RES_JenisKatasandi = "Katasandi";
                setisEnableFieldKatasandi(false);
            }
            else if (TipeKatasandi == "Pola") {
                RES_JenisKatasandi = "Pola";
                setisEnableFieldKatasandi(false);
            }
            else {
                RES_JenisKatasandi = "";
                setisEnableFieldKatasandi(true);
            }
        }
        catch (Err) {
            console.log(Err);
        }
    }

    const [isFullDataMasterBank, setisFullDataMasterBank] = useState([{}]);
    const [isSelectedSandiBANK, setisSelectedSandiBANK] = useState(null);
    let [selectedCariDataBank, setselectedCariDataBank] = useState(null);
    let [selectedFullDataBank, setselectedFullDataBank] = useState(null);
    let [selectedNamaRekening, setselectedNamaRekening] = useState("");
    let [selectedNoRekening, setselectedNoRekening] = useState("");

    const [isEnableDisableSelectRincianMetode, setisEnableDisableSelectRincianMetode] = useState(true);
    const [isEnableDisableNominalGadai, setisEnableDisableNominalGadai] = useState(true);
    const [isEnableDisableBiayaPerpanjang, setisEnableDisableBiayaPerpanjang] = useState(true);
    const [isEnableDisableNominalTebus, setisEnableDisableNominalTebus] = useState(true);
    const [isEnableDisableCariDataBankEWallet, setisEnableDisableCariDataBankEWallet] = useState(true);
    const [isEnableDisableNamaRek, setisEnableDisableNamaRek] = useState(true);
    const [isEnableDisableNoRek, setisEnableDisableNoRek] = useState(true);
    const [isEnableDisableCatatanTRX, setisEnableDisableCatatanTRX] = useState(true);

    const optionRincianMetode = [
        {
            label: "Metode Pencairan",
            options: [
                { label: "Transfer Bank", value: "TFBank" },
                { label: "E-Wallet", value: "TFWallet" },
                { label: "Tunai", value: "Tunai" }
            ]
        },
    ];

    const [selectedRincianMetode, setselectedRincianMetode] = useState(null);

    function handleSelectRincianMetode(selectedRincianMetode) {
        setselectedRincianMetode(selectedRincianMetode);

        SetRincianMetode(selectedRincianMetode.value);

        setisRekeningExists(false);

        INPUT_RekeningExists = false;
    }

    function handleChangeRekTRXPelanggan(isInputTrueFalse) {

        INPUT_RekeningExists = false;

        setisRekeningExists(false);

        setselectedRincianMetode(null);
        RES_RincianMETODE = "";

        setselectedNamaRekening("");
        setselectedNoRekening("");

        INPUT_NamaRek = "";
        INPUT_NoRek = "";

        setisSelectedSandiBANK(null);
        setisFullDataMasterBank(null);

        setselectedCariDataBank(null);
        setselectedFullDataBank(null);

        if (!isInputTrueFalse) {
            setisEnableDisableSelectRincianMetode(false);
        }
        else {
            setisEnableDisableSelectRincianMetode(true);
            setisEnableDisableNominalGadai(true);
            setisEnableDisableBiayaPerpanjang(true);
            setisEnableDisableCariDataBankEWallet(true);
            setisEnableDisableNamaRek(true);
            setisEnableDisableNoRek(true);
            setisEnableDisableCatatanTRX(true);
        }
    }

    function SetRincianMetode(RincianMetodeTF) {
        try {
            if (RincianMetodeTF == "TFBank") {
                RES_RincianMETODE = "TFBank";
                FeedFunctionMasterDataBank(RES_RincianMETODE);

                setisEnableDisableNominalGadai(false);
                setisEnableDisableBiayaPerpanjang(false);
                setisEnableDisableCariDataBankEWallet(false);
                setisEnableDisableNamaRek(false);
                setisEnableDisableNoRek(false);
                setisEnableDisableCatatanTRX(false);

                handleFormRincianTRX_TipeBank("Bank");
            }
            else if (RincianMetodeTF == "TFWallet") {
                RES_RincianMETODE = "TFWallet";
                FeedFunctionMasterDataBank(RES_RincianMETODE);

                setisEnableDisableNominalGadai(false);
                setisEnableDisableBiayaPerpanjang(false);
                setisEnableDisableCariDataBankEWallet(false);
                setisEnableDisableNamaRek(false);
                setisEnableDisableNoRek(false);
                setisEnableDisableCatatanTRX(false);

                handleFormRincianTRX_TipeBank("eWallet");
            }
            else if (RincianMetodeTF == "Tunai") {
                RES_RincianMETODE = "Tunai";
                FeedFunctionMasterDataBank(RES_RincianMETODE);

                setselectedNamaRekening("Tunai");
                setselectedNoRekening("Tunai");

                setisEnableDisableNominalGadai(false);
                setisEnableDisableBiayaPerpanjang(false);
                setisEnableDisableCariDataBankEWallet(false);
                setisEnableDisableNamaRek(false);
                setisEnableDisableNoRek(false);
                setisEnableDisableCatatanTRX(false);

                handleFormRincianTRX_TipeBank("Tunai");

                INPUT_NamaRek = "Tunai";
                INPUT_NoRek = "Tunai";
            }
            /*else {
                RES_RincianMETODE = "";

                setselectedNamaRekening("");
                setselectedNoRekening("");

                setisEnableDisableNominalGadai(true);
                setisEnableDisableCariDataBankEWallet(true);
                setisEnableDisableNamaRek(true);
                setisEnableDisableNoRek(true);
                setisEnableDisableTGLFrom(true);
                setisEnableDisableTGLTo(true);
                setisEnableDisableCatatanTRX(true);

                INPUT_NamaRek = "";
                INPUT_NoRek = "";

                setisSelectedSandiBANK(null);
                setisFullDataMasterBank(null);

                setselectedCariDataBank(null);
                setselectedFullDataBank(null);
            }*/
        }
        catch (Err) {
            console.log(Err);
        }
    }

    async function FeedFunctionMasterDataBank(isTipeBankORWallet) {

        setisLoadingCariDataBank(true);

        if (isTipeBankORWallet == "TFBank") {
            if (RekTRXExists && isNIKNasabahSelected != null) {
                try {
                    isFullDataMasterBankRESULT = await fetchDataCustListBank({ 'CustUID': isNIKNasabahSelected, 'BType': 'Bank' });

                    setisFullDataMasterBank(isFullDataMasterBankRESULT);

                    setisLoadingCariDataBank(false);
                }
                catch (Err) {
                    //Nothing
                }
            }
            else {
                try {
                    isFullDataMasterBankRESULT = await fetchFullDataBank();

                    setisFullDataMasterBank(isFullDataMasterBankRESULT);

                    setisLoadingCariDataBank(false);
                }
                catch (Err) {
                    //Nothing
                }
            }
        }
        else if (isTipeBankORWallet == "TFWallet") {
            if (RekTRXExists && isNIKNasabahSelected != null) {
                try {
                    isFullDataMasterBankRESULT = await fetchDataCustListBank({ 'CustUID': isNIKNasabahSelected, 'BType': 'eWallet' });

                    setisFullDataMasterBank(isFullDataMasterBankRESULT);

                    setisLoadingCariDataBank(false);
                }
                catch (Err) {
                    //Nothing
                }
            }
            else {
                try {
                    isFullDataMasterBankRESULT = await fetchFullDataeWallet();

                    setisFullDataMasterBank(isFullDataMasterBankRESULT);

                    setisLoadingCariDataBank(false);
                }
                catch (Err) {
                    //Nothing
                }
            }
        }
        else {
            try {
                isFullDataMasterBankRESULT = await fetchFullDataFakeBank();

                setisFullDataMasterBank(isFullDataMasterBankRESULT);

                setisLoadingCariDataBank(false);
            }
            catch (Err) {
                //Nothing
            }
        }
    }
    
   
    //Select Cari Pelanggan
    let optionCariDataBank = [
        {
            label: "Cari Data Bank / eWallet",
            options: isFullDataMasterBank || []
        },
    ];
    
    async function handleFeedCariDataBankEWallet() {
        setisFullDataMasterBank(null);

        FeedFunctionMasterDataBank(RES_RincianMETODE);
    }

    async function handleCariDataBankEWallet(selectedCariDataBank) {
        setselectedCariDataBank(selectedCariDataBank);

        setisSelectedSandiBANK(selectedCariDataBank.value);

        if (RES_RincianMETODE === "Tunai") {
            setselectedNamaRekening("Tunai");
            setselectedNoRekening("Tunai");

            INPUT_NamaRek = "Tunai";
            INPUT_NoRek = "Tunai";
        }
        else {
            setselectedNamaRekening("");
            setselectedNoRekening("");

            INPUT_NamaRek = "";
            INPUT_NoRek = "";
        }
        

        setisRekeningExists(false);

        INPUT_RekeningExists = false;


        if (isFullDataPelangganUID != null) {
            FeedBankPelangganSelected(selectedCariDataBank.value);
        }
    }
    const [isRekeningExists, setisRekeningExists] = useState(false);

    async function FeedBankPelangganSelected(isSelectedSandiBANK) {
        try {
            selectedFullDataBank_RESULT = await fetchPelangganBankSelect({ 'CustUID': isNIKNasabahSelected, 'BankID': isSelectedSandiBANK });

            setselectedFullDataBank(selectedFullDataBank_RESULT);
            
            try {
                if (INPUT_TipeRekening === "RekNew" && selectedFullDataBank_RESULT[0] !== undefined) {
                    setisRekeningExists(true);

                    INPUT_RekeningExists = true;
                }

                if (INPUT_TipeRekening !== "RekNew") {
                    setisRekeningExists(false);

                    INPUT_RekeningExists = false;

                    setselectedNamaRekening(selectedFullDataBank_RESULT[0].BankNamaRek);
                    setselectedNoRekening(selectedFullDataBank_RESULT[0].BankRek);

                    INPUT_NamaRek = selectedFullDataBank_RESULT[0].BankNamaRek;
                    INPUT_NoRek = selectedFullDataBank_RESULT[0].BankRek;
                }
            }
            catch (Err) {
                //Nothing
            }
            
        }
        catch (Err) {
            setselectedNamaRekening("");
            setselectedNoRekening("");
        }
    }
    
    const [selectedFile, setselectedFile] = useState(null);
    const [selectedFileKelengkapan, setselectedFileKelengkapan] = useState(null);

    const [isFilePathCaptureKTP, isSetFilePathCaptureKTP] = useState(false);
    const [isFilePathCaptureKelengkapan, isSetFilePathCaptureKelengkapan] = useState(false);

    function handleChangeFotoKTP(e) {
        setselectedFile(URL.createObjectURL(e.target.files[0]));

        const nameIMG = e.target.files[0].name;
        const lastDotIMG = nameIMG.lastIndexOf('.');

        //const fileNameIMG = nameIMG.substring(0, lastDotIMG);
        const extIMG = nameIMG.substring(lastDotIMG + 1);

        const isFileRenameValue = "imgKTPNasabah-" + INPUT_NIK + "_" + Date.now() + "." + extIMG;
        const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

        INPUT_FotoKTP = isFileRenameValue;

        //console.log(e.target.files[0]);

        setfileFotoKTP(fileRenamed);
    }

     function handleChangeFotoKelengkapan(e) {
        setselectedFileKelengkapan(URL.createObjectURL(e.target.files[0]));

         const nameIMG = e.target.files[0].name;
         const lastDotIMG = nameIMG.lastIndexOf('.');

         //const fileNameIMG = nameIMG.substring(0, lastDotIMG);
         const extIMG = nameIMG.substring(lastDotIMG + 1);

         const isFileRenameValue = "imgTRX-" + INPUT_NIK + "_" + Date.now() + "." + extIMG;
         const fileRenamed = new File(e.target.files, isFileRenameValue, { type: e.target.files[0].type });

         INPUT_FotoKelengkapan = isFileRenameValue;
         setfileFotoKelengkapan(fileRenamed);
    }

    const [FotoKelengkapanFileINPUT, setFotoKelengkapanFileINPUT] = useState([]);

    const getBlobFromFile = (file) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: file.type });
            resolve(blob);
        };
        reader.readAsArrayBuffer(file);
    });

    const handleFileInputFotoKelengkapan = async (e) => {
        const files = Array.from(e.target.files);
        const updateFotoKelengkapanFiles = await Promise.all(
            files.map(async (file) => ({
                name: file.name,
                file: await getBlobFromFile(file),
            })),
        );
        setFotoKelengkapanFileINPUT(updateFotoKelengkapanFiles);
    };

    const onlyNumberRegEXPAAAAAA = "/^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";
    const onlyNumberRegEXP = "^[0-9]*$";

    const VLD_DataPelanggan = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            INPUTDPel_KategoriPelanggan: INPUT_KategoriPelanggan || undefined,
            INPUTDPel_NamaLengkap: INPUT_NamaLengkap.toUpperCase() || undefined,
            INPUTDPel_NamaPanggilan: INPUT_NamaPanggilan.toUpperCase() || undefined,
            INPUTDPel_NIK: INPUT_NIK || undefined,
            INPUTDPel_NoHP: INPUT_NomorHP || "62",
            INPUTDPel_Alamat: INPUT_Alamat.toUpperCase() || undefined,
        },
        validationSchema: Yup.object().shape({
            INPUTDPel_NamaLengkap: Yup.string().when('INPUTDPel_KategoriPelanggan', {
                is: "NEWCust",
                then: Yup.string()
                    .required("Nama Lengkap wajib diisi sesuai nama pada Tanda Pengenal dan tidak boleh kosong!")
                    .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi!')
                    .max(96, 'Maksimal karakter hanya boleh sampai 96 karakter!'),
                otherwise: Yup.string(),
            }),
            INPUTDPel_NamaPanggilan: Yup.string().when('INPUTDPel_KategoriPelanggan', {
                is: "NEWCust",
                then: Yup.string()
                    .required("Nama Panggilan wajib diisi dan tidak boleh kosong!")
                    .matches("^[a-zA-Z. ]*$", 'Spesial karakter & angka dilarang, hanya boleh menggunakan huruf & spasi ataupun titik!')
                    .max(32, 'Maksimal karakter hanya boleh sampai 32 karakter!'),
                otherwise: Yup.string(),
            }),
            INPUTDPel_NIK: Yup.string().when('INPUTDPel_KategoriPelanggan', {
                is: "NEWCust",
                then: Yup.string()
                    .required("Nomor Induk Kependudukan atau Nomor SIM wajib diisi & tidak boleh kosong!")
                    .matches("^[0-9]*$", 'Nomor Induk Kependudukan atau Nomor SIM hanya boleh menggunakan angka!')
                    .min(8, 'Minimal NIK atau Nomor SIM adalah 8 digit!')
                    .max(17, 'Maksimal NIK atau Nomor SIM adalah 8 digit!'),
                otherwise: Yup.string(),
            }),
            INPUTDPel_NoHP: Yup.string().min(10, 'Nonmor Telepon minimal adalah 10 digit angka!').max(15, 'Nonmor Telepon maksimal adalah 15 digit angka!').matches(onlyNumberRegEXP, 'Format No Telepon/WA tidak sesuai, pastikan hanya angka yang anda masukan, Cth: 81234567890').required("Nomor Telepon atau WhatsApp wajib diisi & tidak boleh kosong!"),
            INPUTDPel_Alamat: Yup.string().required("Alamat lengkap wajib diisi dan harus jelas sesuai pada KTP tidak boleh kosong!")
        }),
        onSubmit: (values) => {
            //Nothing
        }
    });

    const [isSpecChipsetSelected, setisSpecChipsetSelected] = useState(null);
    const [isSpecRAMSelected, setisSpecRAMSelected] = useState(null);
    const [isSpecMemInternalSelected, setisSpecMemInternalSelected] = useState(null);

    const VLD_BarangJaminan = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            INPUTJBar_NominalTaksiran: INPUT_HargaTaksiran_ManualFORMAT || '',
            INPUTJBar_TipeHP: INPUT_TipeHP,
            INPUTJBar_TipeHPLainnya: INPUT_TipeHPLainnya.toUpperCase(),
            INPUTJBar_RAM: isSpecRAMSelected || '',
            INPUTJBarChipset: isSpecChipsetSelected || undefined,
            INPUTJBar_MemInternal: isSpecMemInternalSelected || '',
            INPUTJBar_IMEI1: INPUT_IMEI1 || '',
            INPUTJBar_IMEI2: INPUT_IMEI2 || '00000000',
        },
        validationSchema: Yup.object({
            INPUTJBar_TipeHP: Yup.string(),
            INPUTJBar_TipeHPLainnya: Yup.string(),
            INPUTJBar_RAM: Yup.number().min(1, "Kapasitas RAM Minimal adalah 1, tidak boleh kurang.").max(64, "Kapasitas RAM Maksimal adalah 64, tidak boleh lebih.").typeError('Oops! Kapasitas RAM hanya boleh angka, huruf dan karakter dilarang!').required("Bidang RAM wajib diisi dan tidak boleh kosong!"),
            INPUTJBar_MemInternal: Yup.number().min(4, "Kapasitas Penyimpanan Internal Minimal adalah 4, tidak boleh kurang.").max(5120, "Kapasitas RAM Maksimal adalah 5120 (5TB), tidak boleh lebih.").typeError('Oops! Penyimpanan RAM hanya boleh angka, huruf dan karakter dilarang!').required("Bidang Penyimpanan Internal wajib diisi dan tidak boleh kosong!"),
            INPUTJBar_IMEI1: Yup.string()
                //.matches(/[^0-9]/g, 'Format IMEI1 tidak sesuai, pastikan hanya angka yang anda masukan!')
                .required("Informasi IMEI_1 wajib diisi dan tidak boleh kosong!")
                .min(8, "Minimal 8 digit terakhir dari IMEI_1.")
                .max(15, "Maksimal IMEI_1 adalah 15 digit!"),
        }),
        onSubmit: (values) => {
            //updatePassword(values);
            //console.log(values);
        }
    });

    const VLD_RincianTRX = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            INPUTRTrx_NominalGadai: INPUT_NominalGadaiFormat,
            INPUTRTrx_BiayaPerpanjang: INPUT_BiayaPerpanjangFormat,
            INPUTRTrx_NominalTebus: INPUT_NominalTebusFormat,
            INPUTRTrx_NamaRek: INPUT_NamaRek.toUpperCase(),
            INPUTRTrx_NoRek: INPUT_NoRek,
        },
        validationSchema: Yup.object({
            INPUTRTrx_NominalGadai: Yup.string().min(1, "Nominal minimum adalah 1 tidak boleh kurang!").required("Bidang Nominal Gadai wajib diisi dan tidak boleh kosong!"),
            INPUTRTrx_NamaRek: Yup.string().required("Kolom nama penerima atau nama pemilik rekening wajib diisi / tidak boleh kosong!"),
            INPUTRTrx_NoRek: Yup.string().matches("^[a-zA-Z0-9]*$", 'Format Nomor Rekening tidak sesuai, pastikan hanya angka yang anda masukan!').required('Kolom nomor rekening penerima wajib diisi / tidak boleh kosong!'),
        }),
        onSubmit: (values) => {
            //updatePassword(values);
            //console.log(values);
        }
    });

    const [chargerOriginal, setchargerOriginal] = useState(false);
    const [chargerOEM, setchargerOEM] = useState(false);
    const [chargerNothing, setchargerNothing] = useState(false);

    const [jaminanRAM2, setjaminanRAM2] = useState(false);
    const [jaminanRAM3, setjaminanRAM3] = useState(false);
    const [jaminanRAM4, setjaminanRAM4] = useState(false);
    const [jaminanRAM6, setjaminanRAM6] = useState(false);
    const [jaminanRAM8, setjaminanRAM8] = useState(false);
    const [jaminanRAM12, setjaminanRAM12] = useState(false);

    const [jaminanINT16, setjaminanINT16] = useState(false);
    const [jaminanINT32, setjaminanINT32] = useState(false);
    const [jaminanINT64, setjaminanINT64] = useState(false);
    const [jaminanINT128, setjaminanINT128] = useState(false);
    const [jaminanINT256, setjaminanINT256] = useState(false);

    const [RekTRXExists, setRekTRXExists] = useState(false);
    const [RekTRXNew, setRekTRXNew] = useState(false);

    const [DPel_KategoriOLDState, setDPel_KategoriOLDState] = useState(false);
    const [DPel_KategoriNewState, setDPel_KategoriNewState] = useState(false);

    //Form Data Pelanggan
    function handleFormDataPelanggan_NamaLengkap(InputValue) {
        INPUT_NamaLengkap = InputValue;
    }

    function handleFormDataPelanggan_NamaPanggilan(InputValue) {
        INPUT_NamaPanggilan = InputValue.toUpperCase();
    }

    const [isNIKExists, setisNIKExists] = useState(null);
    async function handleFormDataPelanggan_NIK(InputValue) {
        INPUT_NIK = InputValue;

        if (InputValue.length > 8) {
            await CheckNIKisNotExists(InputValue);
        }
    }

    async function CheckNIKisNotExists(valNIKNya) {
        try {
            let isCheckFromAPI = await cekNIK(valNIKNya);

            if (isCheckFromAPI !== null) {
                setisNIKExists(isCheckFromAPI);

                isShowModalNIKExists();

                isStatusNIKAvailable = true;
            }
            else {
                setisNIKExists(null);

                isStatusNIKAvailable = false;
            }
        }
        catch (Err) {
            isStatusNIKAvailable = true;

            setisNIKExists("Error");
        }
    }

    const [isNoHPExists, setisNoHPExists] = useState(null);

    async function handleFormDataPelanggan_NoHP(InputValue) {
        INPUT_NomorHP = InputValue;

        /*if (InputValue.length > 8) {
            await CheckNoHPisNotExists(InputValue);
        }*/
    }

    async function CheckNoHPisNotExists(valNoHP) {
        try {
            let isCheckFromAPI = await cekNoHP(valNoHP);
            
            if (isCheckFromAPI !== null) {
                setisNoHPExists(isCheckFromAPI);

                isShowModalHPExists();

                isStatusNoHPAvailable = true;
            }
            else {
                setisNoHPExists(null);

                isStatusNoHPAvailable = false;
            }
        }
        catch (Err) {
            isStatusNoHPAvailable = true;

            setisNoHPExists("Error");
        }
    }

    function handleFormDataPelanggan_Alamat(InputValue) {
        INPUT_Alamat = InputValue;
    }

    //Form Barang Jaminan
    function handleFormJaminan_KondisiFisik(InputValue) {
        INPUT_KondisiFisik = InputValue;
    }

    function handleFormJaminan_FungsiPerangkat(InputValue) {
        INPUT_FungsiPerangkat = InputValue;
    }

    function handleFormJaminan_IndikasiServices(InputValue) {
        INPUT_IndikasiServices = InputValue;
    }

    function handleFormJaminan_StatusIMEI(InputValue) {
        INPUT_StatusIMEI = InputValue;
    }

    function handleFormJaminan_MerkHP(InputValue) {
        INPUT_MerkHP = InputValue;

        if (InputValue === "LAINNYA") {
            setINPUTTipeHPLainnya(true);

            setisFullTipeHPSpec(null);

            /*setisSpecRAMSelected(null);
            setisSpecMemInternalSelected(null);*/

            setisSpecChipsetSelected(null);
            setisSpecRAMSelected(null);
            setisSpecMemInternalSelected(null);

            INPUT_RAM = '';
            INPUT_MemInternal = '';

            INPUT_TipeHP = '';

            INPUT_HargaTaksiran = 0;
            INPUT_TotalPersentaseTaksiran = 0;

            isResultFullNilaiTaksiranHP = null;

            setisEnableDisableCariTipeHandphone(true);

            setisEnableDisablePilihFisikPerangkat(true);
            setisEnableDisablePilihFungsiPerangkat(true);
            setisEnableDisablePilihIndikasiServicesPerangkat(true);
            setisEnableDisablePilihInformasiCasingPerangkat(true);
            setisEnableDisablePilihStatusIMEIPerangkat(true);
            setisEnableDisablePilihInformasiChargerPerangkat(true);
            setisEnableDisableInputRAMPerangkat(true);
            setisEnableDisableInputMemInternalPerangkat(true);
            setisEnableDisableInputIMEI1Perangkat(true);
            setisEnableDisableInputIMEI2Perangkat(true);
            setisEnableDisablePilihJenisKatasandiPerangkat(true);
            setisEnableDisableInputCatatanPerangkat(true);

        } else {
            setINPUTTipeHPLainnya(false);

            INPUT_TipeHPLainnya = '';

            INPUT_HargaTaksiran = 0;
            INPUT_TotalPersentaseTaksiran = 0;

            isResultFullNilaiTaksiranHP = null;

            setisEnableDisableCariTipeHandphone(false);
        }
    }

    function handleFormJaminan_TipeHP(InputValue) {
        INPUT_TipeHP = InputValue;
    }

    const [isTaksiranBukanAngka, setisTaksiranBukanAngka] = useState(false);

    function handleFormJaminan_NilaiTaksirManual(InputValue) {
        try {
            INPUT_HargaTaksiran_Manual = Number((convertToAngka(InputValue.toString())).toFixed(0));

            INPUT_HargaTaksiran_ManualFORMAT = convertToRupiah(convertToAngka(InputValue.toString()));
        }
        catch (Err) {
            INPUT_HargaTaksiran_Manual = 0;

            INPUT_HargaTaksiran_ManualFORMAT = 0;
        }

        let isInputNumberValue = InputValue;

        try {
            isInputNumberValue = Number(InputValue.replace(/[^0-9]/g, ""))
        }
        catch (Err) {
            //isInputNumberValue = InputValue;
        }

        if (isInputNumberValue > 0) {

            setisEnableDisablePilihFisikPerangkat(false);
            setisEnableDisablePilihFungsiPerangkat(false);
            setisEnableDisablePilihIndikasiServicesPerangkat(false);
            setisEnableDisablePilihInformasiCasingPerangkat(false);
            setisEnableDisablePilihStatusIMEIPerangkat(false);
            setisEnableDisablePilihInformasiChargerPerangkat(false);
            setisEnableDisableInputRAMPerangkat(false);
            setisEnableDisableInputMemInternalPerangkat(false);
            setisEnableDisableInputIMEI1Perangkat(false);
            setisEnableDisableInputIMEI2Perangkat(false);
            setisEnableDisablePilihJenisKatasandiPerangkat(false);
            setisEnableDisableInputCatatanPerangkat(false);

            setisTaksiranBukanAngka(false);
        }
        else {

            INPUT_HargaTaksiran = 0;
            INPUT_HargaTaksiran_Manual = 0;

            setisEnableDisablePilihFisikPerangkat(true);
            setisEnableDisablePilihFungsiPerangkat(true);
            setisEnableDisablePilihIndikasiServicesPerangkat(true);
            setisEnableDisablePilihInformasiCasingPerangkat(true);
            setisEnableDisablePilihStatusIMEIPerangkat(true);
            setisEnableDisablePilihInformasiChargerPerangkat(true);
            setisEnableDisableInputRAMPerangkat(true);
            setisEnableDisableInputMemInternalPerangkat(true);
            setisEnableDisableInputIMEI1Perangkat(true);
            setisEnableDisableInputIMEI2Perangkat(true);
            setisEnableDisablePilihJenisKatasandiPerangkat(true);
            setisEnableDisableInputCatatanPerangkat(true);

            setisTaksiranBukanAngka(true);
        }
    }

    function handleFormJaminan_TipeHPLainnya(InputValue) {
        INPUT_TipeHPLainnya = InputValue;
        INPUT_TipeHPCODE = "Unknown";

        if (InputValue !== '') {
            setisEnableDisablePilihFisikPerangkat(false);
            setisEnableDisablePilihFungsiPerangkat(false);
            setisEnableDisablePilihIndikasiServicesPerangkat(false);
            setisEnableDisablePilihInformasiCasingPerangkat(false);
            setisEnableDisablePilihStatusIMEIPerangkat(false);
            setisEnableDisablePilihInformasiChargerPerangkat(false);
            setisEnableDisableInputRAMPerangkat(false);
            setisEnableDisableInputMemInternalPerangkat(false);
            setisEnableDisableInputIMEI1Perangkat(false);
            setisEnableDisableInputIMEI2Perangkat(false);
            setisEnableDisablePilihJenisKatasandiPerangkat(false);
            setisEnableDisableInputCatatanPerangkat(false);

            setisEnableDisableInputNilaiTaksirMaksimal(false);
        }
        else {
            setisEnableDisablePilihFisikPerangkat(true);
            setisEnableDisablePilihFungsiPerangkat(true);
            setisEnableDisablePilihIndikasiServicesPerangkat(true);
            setisEnableDisablePilihInformasiCasingPerangkat(true);
            setisEnableDisablePilihStatusIMEIPerangkat(true);
            setisEnableDisablePilihInformasiChargerPerangkat(true);
            setisEnableDisableInputRAMPerangkat(true);
            setisEnableDisableInputMemInternalPerangkat(true);
            setisEnableDisableInputIMEI1Perangkat(true);
            setisEnableDisableInputIMEI2Perangkat(true);
            setisEnableDisablePilihJenisKatasandiPerangkat(true);
            setisEnableDisableInputCatatanPerangkat(true);

            setisEnableDisableInputNilaiTaksirMaksimal(true);
        }
    }

    function handleFormJaminan_TipeChargerAdaptor(InputValue) {
        INPUT_Charger = InputValue;
    }

    function handleFormJaminan_InformasiCasing(InputValue) {
        INPUT_InfoCasing = InputValue;
    }

    function handleFormJaminan_RAM(InputValue) {
        INPUT_RAM = InputValue;
    }

    function handleFormJaminan_MemInternal(InputValue) {
        INPUT_MemInternal = InputValue;
    }

    function handleFormJaminan_IMEI1(InputValue) {
        INPUT_IMEI1 = InputValue;
    }

    function handleFormJaminan_IMEI2(InputValue) {
        if (!isNaN(InputValue)) {
            INPUT_IMEI2 = InputValue;
        }
        else {
            INPUT_IMEI2 = '';
        }
    }

    function handleFormJaminan_JenisKatasandi(InputValue) {
        INPUT_JenisKatasandiHP = InputValue;
    }

    function handleFormJaminan_KatasandiHP(InputValue) {
        INPUT_KatasandiHP = InputValue;
    }

    function handleFormJaminan_FotoKelengkapan(InputValue) {
        INPUT_FotoKelengkapan = InputValue;
    }

    function handleFormJaminan_CatatanJaminan(InputValue) {
        INPUT_CatatanBarang = InputValue.toUpperCase();
    }

    //Form Rincian Transaksi
    function handleFormRincianTRX_TipeRekCustomer(InputValue) {
        /*if (InputValue == "RekNew") {
            INPUT_TipeRekening = "Rekening Baru";
        }
        else if (InputValue == "RekExists") {
            INPUT_TipeRekening = "Rekening Terdaftar";
        }
        else {
            INPUT_TipeRekening = InputValue;
        }*/
        INPUT_TipeRekening = InputValue;
    }

    function handleFormRincianTRX_MetodePencairan(InputValue) {
        INPUT_MetodePencairan = InputValue;
    }

    function handleFormRincianTRX_MetodePencairanVALUE(InputValue) {
        INPUT_MetodePencairanVALUE = InputValue;
    }

    const [isLimitHargaTaksiran, setisLimitHargaTaksiran] = useState(false);

    function handleFormRincianTRX_NominalGadai(InputValue) {
        let InputValueFormat = InputValue;

        if (convertToAngka(InputValue.toString()) > convertToAngka(isHargaTaksiran.toString())) {
            InputValueFormat = convertToRupiah(isHargaTaksiran.toString());
            INPUT_NominalGadai = convertToAngka(isHargaTaksiran.toString());

            setisLimitHargaTaksiran(true);
        }
        else {
            InputValueFormat = convertToRupiah(convertToAngka(InputValue.toString()));
            INPUT_NominalGadai = convertToAngka(InputValue.toString());

            setisLimitHargaTaksiran(false);
        }

        /*console.log(convertToAngka(InputValue.toString()));
        console.log("Taksiran : " + isHargaTaksiran);*/

        INPUT_NominalTebus = convertToAngka(((INPUT_NominalGadai + (INPUT_NominalGadai * (INPUT_PercentJasaTitip / 100))).toFixed(0)).toString());
        INPUT_NominalTebusFormat = convertToRupiah(((INPUT_NominalGadai + (INPUT_NominalGadai * (INPUT_PercentJasaTitip / 100))).toFixed(0)).toString());

        INPUT_NominalGadaiFormat = InputValueFormat;
    }

    function handleFormRincianTRX_BiayaPerpanjang(InputValue) {
        let InputValueFormat = InputValue;

        InputValueFormat = convertToRupiah(convertToAngka(InputValue.toString()));
        INPUT_BiayaPerpanjang = convertToAngka(InputValue.toString());

        INPUT_BiayaPerpanjangFormat = InputValueFormat;
    }

    function handleFormRincianTRX_NominalTebus(InputValue) {
        let InputValueFormat = InputValue;

        InputValueFormat = convertToRupiah(convertToAngka(InputValue.toString()));
        INPUT_NominalTebus = convertToAngka((InputValue.toFixed(0)).toString());
        INPUT_NominalTebusFormat = convertToRupiah(((InputValue + (InputValue * (INPUT_PercentJasaTitip / 100))).toFixed(0)).toString());
    }

    const [isHargaTaksiran, setisHargaTaksiran] = useState(0);
    function convertToRupiah(angka) {
        let isResult = 0;

        try {
            var rupiah = '';
            var angkarev = angka.toString().split('').reverse().join('');
            for (var i = 0; i < angkarev.length; i++) if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + '.';
            //return 'Rp. ' + rupiah.split('', rupiah.length - 1).reverse().join('');

            isResult = rupiah.split('', rupiah.length - 1).reverse().join('');

            if (isResult === 'NaN') {
                isResult = 0;
            }
        }
        catch (Err) {
            //Nothing

            return isResult;
        }
        

        return isResult;
    }
    /**
     * Usage example:
     * alert(convertToRupiah(10000000)); -> "Rp. 10.000.000"
     */

    function convertToAngka(rupiah) {
        let isResult = 0;

        try {
            isResult = parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);

            if (isResult === 'NaN') {
                isResult = 0;
            }
        }
        catch (Err) {
            //Nothing

            return isResult;
        }
       
        return isResult;
    }
    
    function handleFormRincianTRX_NamaBank(InputValue) {
        INPUT_NamaBank = InputValue;
    }

    function handleFormRincianTRX_SandiBank(InputValue) {
        INPUT_SandiBank = InputValue;
    }

    function handleFormRincianTRX_TipeBank(InputValue) {
        INPUT_TipeBank = InputValue;
    }

    function handleFormRincianTRX_NamaRek(InputValue) {
        INPUT_NamaRek = InputValue;
    }

    function handleFormRincianTRX_NoRek(InputValue) {
        INPUT_NoRek = InputValue;
    }

    function handleFormRincianTRX_FromTanggal(InputValue) {
        INPUT_FromTanggal = InputValue;
    }

    function handleFormRincianTRX_ToTanggal(InputValue) {
        INPUT_ToTanggal = InputValue;
    }

    function handleFormRincianTRX_CatatanTransaksi(InputValue) {
        INPUT_CatatanTransaksi = InputValue.toUpperCase();
    }

    const FILE_SIZE = 3145728; //3MB in bytes
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/tiff",
        "image/svg",
        "image/bmp",
        "image/png",
    ];

    //const setFieldValue = useFormikContext();

    const [fileFotoKTP, setfileFotoKTP] = useState([]);
    const [fileFotoKelengkapan, setfileFotoKelengkapan] = useState([]);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            if (isDataCabangRESULT === null) {
                await FeedInformasiDataCabang(DecodeUserData.user_cabang);
            }
        }
        catch (Err) {
            //Nothing
        }
    }

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");
    
    const [trxGadaiSuccess, settrxGadaiSuccess] = useState(false);
    const [trxUploadFotoKTPSuccess, settrxUploadFotoKTPSuccess] = useState(false);
    const [trxUploadFotoKelengkapanSuccess, settrxUploadFotoKelengkapanSuccess] = useState(false);
    const [EnableDisableBTNCreateContract, setEnableDisableBTNCreateContract] = useState(false);
    const [EnableDisableBTNTakeFotoWebCam, setEnableDisableBTNTakeFotoWebCam] = useState(false);
    const [updateLoading, setupdateLoading] = useState(false);
    const [isNomorKontrak, setisNomorKontrak] = useState("");

    const [isLoadingTextSubmit, setisLoadingTextSubmit] = useState("Sedang memproses data. . .");

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "1500",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success') {
            toastr.success(isMessage, isTitle);
            setTimeout(async function () {
                return window.location.href = '/apps/pawnshop/details/handphone/' + isResultTransaksiGadai.NoKontrak;
            }, 1500);
        }
        else if (isType === 'info') {
            toastr.info(isMessage, isTitle);
        }
        else if (isType === 'warning') {
            toastr.warning(isMessage, isTitle);
        }
        else {
            toastr.error(isMessage, isTitle);
            setTimeout(async function () {
                return location.reload();
            }, 1500)
        }
    }

    async function FeedFullTransaksiGadai(isJSONValue) {

        setEnableDisableBTNCreateContract(true);
        setupdateLoading(true);

        setEnableDisableBTNTakeFotoWebCam(true);

        let ErrorStatusTransaksi = false;
        let ErrorStatusUploadKTP = false;
        let ErrorStatusUploadFoto = false;

        try {
            try {
                await postUploadFotoKelengkapan({ "imgFile": fileFotoKelengkapan });

                settrxUploadFotoKelengkapanSuccess(true);

                ErrorStatusUploadFoto = false;

            }
            catch (Err) {
                settrxUploadFotoKelengkapanSuccess(false);

                ErrorStatusUploadFoto = true;
            }

            try {
                await postUploadFotoKTPNasabah({ "imgFile": fileFotoKTP });

                settrxUploadFotoKTPSuccess(true);

                ErrorStatusUploadKTP = false;
            }
            catch (Err) {
                settrxUploadFotoKTPSuccess(false);

                ErrorStatusUploadKTP = true;
            }

            isResultTransaksiGadai = await postNewTransaksiGadaiBarang({ "isFieldTRXGadai": isJSONValue });

            setisNomorKontrak(isResultTransaksiGadai.NoKontrak);

            settrxGadaiSuccess(true);

            ErrorStatusTransaksi = false;
            
            setTimeout(async function () {

                setisLoadingTextSubmit("Proses Selesai!");

                setEnableDisableBTNCreateContract(true);
                setupdateLoading(false);

                showToastNotification('Success!', 'Kontrak Baru atas Nama ' + JSONInputFull.DP_NamaLengkap + ' berhasil dibuat!', 'success');
            }, 500);
        }
        catch (Err) {
            settrxGadaiSuccess(false);

            ErrorStatusTransaksi = true;

            setTimeout(async () => {
                {
                    showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah! silahkan ulangi transaksi.', 'danger');
                }
            }, 500);
        }
        
        /*if (!ErrorStatusTransaksi && !ErrorStatusUploadFoto && !ErrorStatusUploadKTP) {
            *//*setTimeout(async function () {

                setisLoadingTextSubmit("Proses Selesai!");

                setEnableDisableBTNCreateContract(true);
                setupdateLoading(false);
            }, 2000);*//*

            *//*setTimeout(async function () {
                return window.location.href = '/apps/transaction-detail/handphone/' + isResultTransaksiGadai.NoKontrak;
            }, 5000);*//*
        }*/
    }

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const [Step1IsPassed, setStep1IsPassed] = useState(false);
    const [Step2IsPassed, setStep2IsPassed] = useState(false);
    const [Step3IsPassed, setStep3IsPassed] = useState(false);
    const [ReviewStepIsPassed, setReviewStepIsPassed] = useState(false);
    const [INPUTTipeHPLainnya, setINPUTTipeHPLainnya] = useState(false);

    const [userBlackListStatus, setuserBlackListStatus] = useState(false);

    const dispatch = useDispatch();

    const Validation_ReviewForm = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Review_JenisTandaPengenal: JSONInputFull.JenisTandaPengenal || undefined,
            Review_CompanyCode: process.env.REACT_APP_COMPANY_CODE,
            Review_KodeCabang: opKodeCabang || undefined,
            Review_UUID_Operator: opUUID || undefined,
            Review_KategoriPelanggan: JSONInputFull.KategoriPelanggan || undefined,
            Review_idPelanggan: JSONInputFull.DP_idPelanggan || undefined,
            Review_NamaLengkap: JSONInputFull.DP_NamaLengkap || undefined,
            Review_NamaPanggilan: JSONInputFull.DP_NamaPanggilan || undefined,
            Review_NIK: JSONInputFull.DP_NIK_KTP || undefined,
            Review_NoHP: JSONInputFull.DP_NomorHP || undefined,
            Review_Alamat: JSONInputFull.DP_Alamat || undefined,
            Review_JenisBarangJaminan: JSONInputFull.BJAM_JenisBarang || "SmartPhone",
            Review_KondisiFisik: JSONInputFull.BJAM_KondisiFisik || undefined,
            Review_FungsiPerangkat: JSONInputFull.BJAM_FungsiPerangkat || undefined,
            Review_IndikasiServices: JSONInputFull.BJAM_IndikasiServices || undefined,
            Review_StatusIMEI: JSONInputFull.BJAM_StatusIMEI || undefined,
            Review_MerkHP: JSONInputFull.BJAM_MerkHP || undefined,
            Review_TipeHP: JSONInputFull.BJAM_TipeHP || JSONInputFull.BJAM_TipeHPLainnya ||undefined,
            Review_TipeHPCODE: JSONInputFull.BJAM_TipeHPCODE || undefined,
            Review_TipeHPLainnya: JSONInputFull.BJAM_TipeHPLainnya || undefined,
            Review_InfoCharger: JSONInputFull.BJAM_TipeCharger || undefined,
            Review_InfoCasing: JSONInputFull.BJAM_isINFOCasing || undefined,
            Review_RAM: JSONInputFull.BJAM_RAM || undefined,
            Review_MemInternal: JSONInputFull.BJAM_MemInternal || undefined,
            Review_IMEI1: JSONInputFull.BJAM_IMEI1 || undefined,
            Review_IMEI2: JSONInputFull.BJAM_IMEI2 || undefined,
            Review_JenisKatasandi: JSONInputFull.BJAM_JenisKatasandiHP || undefined,
            Review_KatasandiHP: JSONInputFull.BJAM_KatasandiHP || "-",
            Review_CatatanBarangJaminan: JSONInputFull.BJAM_CatatanBarang || "Tidak ada catatan barang jaminan.",
            Review_NilaiTaksiranAwal: JSONInputFull.BJAM_RTRX_TaksiranAwal || undefined,
            Review_NilaiTaksiranAkhir: JSONInputFull.BJAM_RTRX_TaksiranAkhir || undefined,
            Review_PersentaseTaksiran: JSONInputFull.BJAM_RTRX_TotalPersentaseTaksiran || undefined,
            Review_TipeRekNasabah: JSONInputFull.RTRX_TipeRekening || undefined,
            Review_MetodePencairan: JSONInputFull.RTRX_MetodePencairan || undefined,
            Review_NominalGadai: JSONInputFull.RTRX_NominalGadai || undefined,
            Review_NamaBank: JSONInputFull.RTRX_NamaBank || undefined,
            Review_NamaRek: JSONInputFull.RTRX_NamaRek || undefined,
            Review_NoRek: JSONInputFull.RTRX_NoRek || undefined,
            Review_TanggalFrom: JSONInputFull.RTRX_FromTanggalGadai || moment().format("YYYY-MM-DD HH:mm:ss"),
            Review_TanggalTo: JSONInputFull.RTRX_ToTanggalGadai || moment(new Date().fp_incr(31)).format("YYYY-MM-DD 23:59:00"),
            Review_CatatanTransaksiGadai: JSONInputFull.RTRX_CatatanTransaksi || "Tidak ada catatan transaksi.",
            Review_SandiBank: JSONInputFull.RTRX_SandiBank || undefined,
            Review_TipeBank: JSONInputFull.RTRX_TipeBank || undefined,
            Review_TipeRekening: INPUT_TipeRekening || undefined,
            Review_FotoKTP: fileFotoKTP || undefined,
            Review_FotoKelengkapan: fileFotoKelengkapan || undefined,
            //Review_FotoKelengkapan: FotoKelengkapanFileINPUT || undefined,
            Review_INPUTFotoKTP: INPUT_FotoKTP || undefined,
            Review_INPUTFotoKelengkapan: INPUT_FotoKelengkapan || undefined,
            Review_PersentaseJasaTitip: JSONInputFull.RTRX_PersentaseJasaTitip || undefined,
            Review_BiayaPerpanjang: JSONInputFull.RTRX_BiayaPerpanjang || undefined,
            Review_NominalTebus: JSONInputFull.RTRX_NominalTebus || undefined,
        },
        validationSchema: Yup.object({
            /*Review_NamaLengkap: Yup.string().required("Nama Lengkap wajib diisi sesuai nama pada KTP dan tidak boleh kosong!"),
            Review_NamaPanggilan: Yup.string().required("Nama Panggilan wajib diisi & tidak boleh kosong!"),
            Review_NIK: Yup.string().matches(onlyNumberRegEXP, 'Format NIK tidak sesuai, pastikan hanya angka yang anda masukan!').required("Kolom Nomor Induk Kependudukan wajib diisi & tidak boleh kosong!"),
            Review_NoHP: Yup.string().matches(onlyNumberRegEXP, 'Format No HP/WA tidak sesuai, pastikan hanya angka yang anda masukan, angka dimulai dari 62XXXXXXXXXXXXX!').required("Kolom Nomor Handphone wajib diisi & tidak boleh kosong!"),
            Review_Alamat: Yup.string().required("Alamat lengkap wajib diisi dan harus jelas sesuai pada KTP tidak boleh kosong!"),
            Review_MerkHP: Yup.string().required("Merk HP wajib diisi dan tidak boleh kosong!"),
            Review_InfoCharger: Yup.string().required("Bidang informasi charger wajib diisi dan tidak boleh kosong!"),
            Review_RAM: Yup.string().required("Bidang RAM wajib diisi dan tidak boleh kosong!"),
            Review_RAM: Yup.string().required("Bidang Memory Internal wajib diisi dan tidak boleh kosong!"),
            Review_IMEI1: Yup.string().required("Bidang IMEI_1 wajib diisi dan tidak boleh kosong!"),
            Review_IMEI2: Yup.string().required("Bidang IMEI_2 wajib diisi dan tidak boleh kosong!"),
            Review_JenisKatasandi: Yup.string().required("Bidang Jenis Katasandi wajib diisi dan tidak boleh kosong!"),
            Review_TipeRekNasabah: Yup.string().required("Bidang Tipe Rekening wajib diisi dan tidak boleh kosong!"),
            Review_MetodePencairan: Yup.string().required("Bidang Metode Pencairan wajib diisi dan tidak boleh kosong!"),
            Review_NominalGadai: Yup.string().matches(onlyNumberRegEXP, 'Format Nominal Gadai tidak sesuai, pastikan hanya angka yang anda masukan!').required("Kolom Nominal Gadai wajib diisi & tidak boleh kosong!"),
            Review_NamaBank: Yup.string().required("Bidang Nama Bank wajib diisi dan tidak boleh kosong!"),
            Review_NamaRek: Yup.string().required("Bidang Nama Pemilik Rekening wajib diisi dan tidak boleh kosong!"),
            Review_NoRek: Yup.string().required("Bidang Nomor Rekening wajib diisi dan tidak boleh kosong!"),
            Review_TanggalFrom: Yup.string().required("Bidang Tanggal Awal Gadai wajib diisi dan tidak boleh kosong!"),
            Review_TanggalTo: Yup.string().required("Bidang Tanggal Awal Gadai wajib diisi dan tidak boleh kosong!"),
            Review_FotoKTP: Yup.mixed().when('Review_KategoriPelanggan', {
                is: "NEWCust",
                then: Yup.mixed()
                    .required("Foto KTP wajib diisi dan tidak boleh kosong!")
                    .test(
                        "fileSize",
                        "Ukuran gambar terlalu besar, maksimal 3MB.",
                        value => value && value.size <= FILE_SIZE

                    )
                    .test(
                        "fileFormat",
                        "Format gambar tidak didukung!",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
                otherwise: Yup.mixed(),
            }),
            Review_FotoKelengkapan: Yup.mixed()
                .required("Foto Kelengkapan Barang Jaminan wajib diisi dan tidak boleh kosong!")
                .test(
                    "fileSize",
                    "Ukuran gambar terlalu besar, maksimal 3MB.",
                    value => value && value.size <= FILE_SIZE
                )
                .test(
                    "fileFormat",
                    "Format gambar tidak didukung!",
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                ),*/
        }),
        onSubmit: (values) => {

            //console.log(values);

            FeedFullTransaksiGadai(values);
        }
    });

    async function FeedInformasiDataCabang(isKodeCabang) {
        try {
            isDataCabangRESULT = await fetchDataCabang({ 'isKodeCabang': isKodeCabang });

            if (isDataCabangRESULT !== null) {
                let InputValueFormat = convertToRupiah(convertToAngka(isDataCabangRESULT.CabangBiayaPerpanjangDef.toString()));

                INPUT_PercentJasaTitip = isDataCabangRESULT.CabangBiayaPercent;
                INPUT_BiayaPerpanjang = isDataCabangRESULT.CabangBiayaPerpanjangDef;
                INPUT_BiayaPerpanjangFormat = InputValueFormat;
            }
        }
        catch (Err) {
            //Nothing
        }
    }

    useEffect(() => {
        isDataCabangRESULT = null;
        isFullMerkHPRESULT = [{}];
        isFullTipeHPRESULT = [{}];
        isFullDataPelangganRESULT = [{}];
        isFullDataMasterBankRESULT = [{}];
        selectedFullDataBank_RESULT = [{}];
        isResultTransaksiGadai = [{}];
        isResultFullPhoneSpec = [{}];
        isResultFullNilaiTaksiranHP = null;
        isFullDataPelangganUID_RESULT = null;

        RES_TipeTPengenal = "";
        RES_TipePelanggan = "";
        RES_JenisKatasandi = "";
        RES_RincianMETODE = "";
        //isLoadingCariPelanggan = false;
        //isLoadingCariMerkHP = false;
        //isLoadingCariDataBank = false;

        JSONInputFull = {};
        INPUT_OP_UUID = "";
        INPUT_OP_KODE_CABANG = "";

        //Form Data Pelanggan - Transaksi Gadai
        INPUT_TipeTPengenal = "";
        INPUT_KategoriPelanggan = "";
        INPUT_idPelanggan = "";
        INPUT_NamaLengkap = "";
        INPUT_NamaPanggilan = "";
        INPUT_NIK = "";
        INPUT_NomorHP = "";
        INPUT_Alamat = "";
        INPUT_FotoKTP = "";
        INPUT_LevelPelanggan = "";

        //Form Barang Jaminan - Transaksi Gadai
        INPUT_JenisBarang = "SmartPhone";
        INPUT_KondisiFisik = "";
        INPUT_FungsiPerangkat = "";
        INPUT_IndikasiServices = "";
        INPUT_StatusIMEI = "";
        INPUT_Charger = "";
        INPUT_InfoCasing = "";
        INPUT_MerkHP = "";
        INPUT_TipeHP = "";
        INPUT_TipeHPCODE = null;
        INPUT_TipeHPLainnya = "";
        INPUT_RAM = "";
        INPUT_MemInternal = "";
        INPUT_IMEI1 = "";
        INPUT_IMEI2 = "00000000";
        INPUT_JenisKatasandiHP = "";
        INPUT_KatasandiHP = "";
        INPUT_FotoKelengkapan = "";
        INPUT_CatatanBarang = "";

        //Form Rincian Transaksi
        INPUT_TipeRekening = "";
        INPUT_TipeRekeningNAMA = "";
        INPUT_MetodePencairan = "";
        INPUT_MetodePencairanVALUE = "";
        INPUT_NominalGadai = "";
        INPUT_NominalGadaiFormat = "";
        INPUT_HargaTaksiran = 0;
        INPUT_HargaTaksiran_Manual = 0;
        INPUT_HargaTaksiran_ManualFORMAT = 0;
        INPUT_TotalPersentaseTaksiran = 0;
        INPUT_NamaBank = "";
        INPUT_SandiBank = "";
        INPUT_TipeBank = "";
        INPUT_NamaRek = "";
        INPUT_NoRek = "";
        INPUT_FromTanggal = "";
        INPUT_ToTanggal = "";
        INPUT_CatatanTransaksi = "";
        INPUT_PercentJasaTitip = 0;
        INPUT_BiayaPerpanjang = 0;
        INPUT_BiayaPerpanjangFormat = 0;
        INPUT_NominalTebus = 0;
        INPUT_NominalTebusFormat = 0;
        INPUT_RekeningExists = false;

        isStatusNIKAvailable = false;
        isStatusNoHPAvailable = false;

        setisPagePreloader(true);

        setTimeout(async () => {
            setisPagePreloader(false);
        }, 500);

        //FeedFunctionDataPelanggan();
        FeedFunctionMerkHP();
        autoUpdateProfile();
        
        setInterval(async () => {
            {
                if (INPUT_TipeRekening == "RekNew") {
                    INPUT_TipeRekeningNAMA = "Tambah Rekening Baru";
                } else if (INPUT_TipeRekening == "RekExists") {
                    INPUT_TipeRekeningNAMA = "Rekening Terdaftar";
                } else {
                    INPUT_TipeRekeningNAMA = "";
                }

                /*INPUT_OP_UUID = opUUID;
                INPUT_OP_KODE_CABANG = opKodeCabang;*/
                
                JSONInputFull =
                {
                    'JenisTandaPengenal': INPUT_TipeTPengenal,
                    'KategoriPelanggan': INPUT_KategoriPelanggan,
                    'DP_idPelanggan': INPUT_idPelanggan,
                    'DP_NamaLengkap': INPUT_NamaLengkap.replace(/[^a-zA-Z ]/g, "").toUpperCase(),
                    'DP_NamaPanggilan': INPUT_NamaPanggilan.replace(/[^a-zA-Z ]/g, "").toUpperCase(),
                    'DP_NIK_KTP': INPUT_NIK.replace(/[^0-9]/g, ""),
                    'DP_NomorHP': INPUT_NomorHP.replace(/[^0-9]/g, ""),
                    'DP_Alamat': INPUT_Alamat.replace(/[^a-zA-Z0-9_. ]/g, "").toUpperCase(),
                    'DP_FotoKTP': INPUT_FotoKTP,
                    'BJAM_JenisBarang': INPUT_JenisBarang,
                    'BJAM_KondisiFisik': INPUT_KondisiFisik,
                    'BJAM_FungsiPerangkat': INPUT_FungsiPerangkat,
                    'BJAM_IndikasiServices': INPUT_IndikasiServices,
                    'BJAM_StatusIMEI': INPUT_StatusIMEI,
                    'BJAM_TipeCharger': INPUT_Charger.replace(/[^a-zA-Z ]/g, ""),
                    'BJAM_isINFOCasing': INPUT_InfoCasing.replace(/[^a-zA-Z ]/g, ""),
                    'BJAM_MerkHP': INPUT_MerkHP,
                    'BJAM_TipeHP': INPUT_TipeHP,
                    'BJAM_TipeHPCODE': INPUT_TipeHPCODE,
                    'BJAM_TipeHPLainnya': INPUT_TipeHPLainnya.replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
                    'BJAM_RAM': INPUT_RAM,
                    'BJAM_MemInternal': INPUT_MemInternal,
                    'BJAM_IMEI1': INPUT_IMEI1.replace(/[^0-9]/g, ""),
                    'BJAM_IMEI2': INPUT_IMEI2.replace(/[^0-9]/g, ""),
                    'BJAM_JenisKatasandiHP': INPUT_JenisKatasandiHP.replace(/[^a-zA-Z ]/g, ""),
                    'BJAM_KatasandiHP': INPUT_KatasandiHP,
                    'BJAM_FotoKelengkapan': INPUT_FotoKelengkapan,
                    'BJAM_CatatanBarang': INPUT_CatatanBarang.replace(/[^a-zA-Z0-9_. ]/g, "").toUpperCase(),
                    'BJAM_RTRX_TotalPersentaseTaksiran': Number(INPUT_TotalPersentaseTaksiran*100).toFixed(0),
                    'BJAM_RTRX_TaksiranAwal': INPUT_HargaTaksiran_Manual,
                    'BJAM_RTRX_TaksiranAkhir': INPUT_HargaTaksiran,
                    'RTRX_TipeRekening': INPUT_TipeRekeningNAMA,
                    'RTRX_MetodePencairan': INPUT_MetodePencairan,
                    'RTRX_NominalGadai': INPUT_NominalGadai,
                    'RTRX_NamaBank': INPUT_NamaBank,
                    'RTRX_SandiBank': INPUT_SandiBank,
                    'RTRX_TipeBank': INPUT_TipeBank,
                    'RTRX_NamaRek': INPUT_NamaRek.toUpperCase(),
                    'RTRX_NoRek': INPUT_NoRek,
                    'RTRX_FromTanggalGadai': INPUT_FromTanggal,
                    'RTRX_ToTanggalGadai': INPUT_ToTanggal,
                    'RTRX_CatatanTransaksi': INPUT_CatatanTransaksi.toUpperCase(),
                    'RTRX_PersentaseJasaTitip': INPUT_PercentJasaTitip,
                    'RTRX_BiayaPerpanjang': INPUT_BiayaPerpanjang,
                    'RTRX_NominalTebus': INPUT_NominalTebus
                };

                /**/
                if (INPUT_TipeTPengenal === 'KTP' || INPUT_TipeTPengenal === 'SIM') {
                    if (INPUT_KategoriPelanggan === "OLDCust" || INPUT_KategoriPelanggan === "NEWCust") {
                        if (INPUT_NamaLengkap !== '' && INPUT_NamaPanggilan !== '' && INPUT_NIK !== '' && INPUT_Alamat !== '') {
                            if (!Step1IsPassed) {
                                if (INPUT_NomorHP !== "62" || INPUT_NomorHP !== "08") {
                                    if (!isStatusNoHPAvailable && !isStatusNIKAvailable) {
                                        setStep1IsPassed(true);
                                    }
                                }
                            }
                            else {
                                setStep1IsPassed(false);
                            }
                        }
                        else {
                            setStep1IsPassed(false);
                        }
                    }
                    else {
                        setStep1IsPassed(false);
                    }
                } else {
                    setStep1IsPassed(false);
                }

                //console.log(INPUT_TipeTPengenal);

                /**/

                if (INPUT_JenisBarang != '' && INPUT_MerkHP !== '' && INPUT_KondisiFisik !== '' && INPUT_FungsiPerangkat !== '' && INPUT_IndikasiServices !== '' && INPUT_StatusIMEI !== ''
                    && INPUT_InfoCasing !== '' && INPUT_Charger !== '' && INPUT_RAM >= 1 && INPUT_MemInternal >= 4 && INPUT_IMEI1.length >= 8) {
                    if (INPUT_TipeHP !== '' || INPUT_TipeHPLainnya !== '') {
                        if (INPUT_HargaTaksiran >= 50000 || INPUT_HargaTaksiran_Manual >= 50000) {
                            if (INPUT_JenisKatasandiHP === 'Tidak Ada') {
                                if (INPUT_KatasandiHP === '') {
                                    if (!Step2IsPassed) {
                                        setStep2IsPassed(true);
                                    }
                                    else {
                                        setStep2IsPassed(false);
                                    }
                                }
                                else {
                                    setStep2IsPassed(false);
                                }
                            }
                            else {
                                if (INPUT_KatasandiHP !== '') {
                                    if (!Step2IsPassed) {
                                        setStep2IsPassed(true);
                                    }
                                    else {
                                        setStep2IsPassed(false);
                                    }
                                }
                                else {
                                    setStep2IsPassed(false);
                                }
                            }
                        }
                        else {
                            setStep2IsPassed(false);
                        }
                    }
                    else {
                        setStep2IsPassed(false);
                    }
                } else {
                    setStep2IsPassed(false);
                }

                if (INPUT_TipeRekening !== "" && INPUT_MetodePencairan !== "" && INPUT_NominalGadai >= 20000 && INPUT_NamaBank !== ""
                    && INPUT_SandiBank !== "" && INPUT_TipeBank !== "" && INPUT_NamaRek !== "" && INPUT_NoRek !== "" && INPUT_FromTanggal !== ""
                    && INPUT_ToTanggal !== "" && INPUT_BiayaPerpanjang >= 20000 && INPUT_NominalTebus >= 20000 && !INPUT_RekeningExists) {
                    if (!Step3IsPassed) {
                        setStep3IsPassed(true);
                    }
                    else {
                        setStep3IsPassed(false);
                    }
                } else {
                    setStep3IsPassed(false);
                }

                if (JSONInputFull.DP_FotoKTP != "" && JSONInputFull.BJAM_FotoKelengkapan != "") {
                    if (!ReviewStepIsPassed) {
                        setReviewStepIsPassed(true);
                    }
                    else {
                        setReviewStepIsPassed(false);
                    }
                } else {
                    setReviewStepIsPassed(false);
                }

                /*if (JSONInputFull.KategoriPelanggan == "OLDCust") {
                    if (INPUT_FotoKTP != "") {
                        try {
                            //apply base url for axios
                            let API_URL = '';

                            const API_PORT_ROOT = process.env.REACT_APP_API_PORT_ROOT;
                            const API_PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
                            const API_PORT_INTERNET = process.env.REACT_APP_API_PORT_INTERNET;

                            //Using Global Domain Server
                            if (window.location.hostname == process.env.REACT_APP_GLOBAL_DOMAIN) {
                                API_URL = "//" + process.env.REACT_APP_GLOBAL_DOMAIN + ":" + API_PORT_INTERNET;
                            }
                            else {
                                //127.0.0.1
                                if (window.location.hostname == process.env.REACT_APP_IP_ROOT || window.location.hostname == "localhost") {
                                    API_URL = "//" + process.env.REACT_APP_IP_ROOT + ":" + API_PORT_ROOT;
                                }
                                //Local IP 192.X.X.X
                                else if (window.location.hostname == process.env.REACT_APP_IP_LOCAL) {
                                    API_URL = "//" + process.env.REACT_APP_IP_LOCAL + ":" + API_PORT_LOCAL;
                                }
                                //Public IP X.X.X.X
                                else if (window.location.hostname == process.env.REACT_APP_IP_INTERNET) {
                                    API_URL = "//" + process.env.REACT_APP_IP_INTERNET + ":" + API_PORT_INTERNET;
                                }

                            }

                            //console.log(window.location.hostname);
                            setselectedFile("http://" + API_URL + "/img-assets/imgKTPNasabah/" + INPUT_FotoKTP);

                            setShowUploadKTP(false);
                        }
                        catch (Err) {
                            //Nothing
                            console.log(Err);
                        }
                    }
                }*/

                /*if (isFullNilaiTaksiranHP != null) {
                    console.log(isResultFullNilaiTaksiranHP);

                    INPUT_HargaTaksiran = isResultFullNilaiTaksiranHP
                }*/

                if (INPUT_TipeTPengenal === "KTP" || INPUT_TipeTPengenal === "SIM") {
                //if (isINPUT_TipeTandaPengenal === "KTP" || isINPUT_TipeTandaPengenal === "SIM") {
                    setisEnableDisableKategoriPelanggan(false);
                }
                else {
                    setisEnableDisableKategoriPelanggan(true);
                }

                autoChangedValueHargaTaksiran();
                
            }
        }, 100);

        setInterval(async () => {
            {
                /*await handleAdapterCharger();
                await handleJaminanRAM();
                await handleJaminanMemInternal();
                await handleJaminanRekTRX();*/

                //console.log(opKodeCabang);
                
                autoUpdateProfile();
            }
        }, 1000);

        setInterval(async () => {
            {
                /*console.log("Taksiran DB : " + INPUT_HargaTaksiran);
                console.log("Taksiran Manual : " + INPUT_HargaTaksiran_Manual);*/
                //console.log(VLD_DataPelanggan.values.INPUTDPel_NamaLengkap);
                //console.log(JSONInputFull);

                //FeedInformasiDataCabang(opKodeCabang);
            }
        }, 2000);

        //console.log(isFullDataPelanggan);


    }, []);

    const [AdaCasing, setAdaCasing] = useState(false);
    const [TidakAdaCasing, setTidakAdaCasing] = useState(false);

    let summaryReports = [
        {
            title: "Total Kontrak",
            iconClass: "fas fa-file-signature",
            bgColor: "primary",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].TotalKontrak : "-",
            value_color: "text-success",
        },
        {
            title: "Kontrak Selesai",
            iconClass: "fas fa-file-signature",
            bgColor: "success",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakSelesai : "-",
            value_color: "text-success",
        },
        {
            title: "Kontrak Berjalan / Jatuh Tempo",
            iconClass: "fas fa-file-signature",
            bgColor: "info",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakBerjalan + " / " + isFullDataPelangganUID[0].TotalJatuhTempo : "- / -",
            value_color: "text-info",
        },
        {
            title: "Kontrak Ditolak",
            iconClass: "fas fa-file-signature",
            bgColor: "black",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].TotalKontrakReject : "-",
            value_color: "text-black",
        },
        {
            title: "Kontrak Gagal",
            iconClass: "fas fa-file-signature",
            bgColor: "danger",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].KontrakGagal : "-",
            value_color: "text-danger",
        },
        {
            title: "Jumlah Keterlambatan",
            iconClass: "fas fa-file-signature",
            bgColor: "warning",
            textColor: "text-white",
            description: INPUT_KategoriPelanggan != "" ? "Jumlah" : "NaN",
            value: isFullDataPelangganUID != null ? isFullDataPelangganUID[0].JumlahKeterlambatan : "-",
            value_color: "text-warning",
        },
    ];

    const [isModalHPExists, setisModalHPExists] = useState(false);
    function isShowModalHPExists() {
        setisModalHPExists(!isModalHPExists);
        removeBodyCss();
    }

    const [isModalNIKExists, setisModalNIKExists] = useState(false);
    function isShowModalNIKExists() {
        setisModalNIKExists(!isModalNIKExists);
        removeBodyCss();
    }

    const [isModalFotoKTPWebCam, setisModalFotoKTPWebCam] = useState(false);

    function isShowModalFotoKTPWebCam() {
        setisModalFotoKTPWebCam(!isModalFotoKTPWebCam);
        removeBodyCss();
    }

    const [isModalFotoKelengkapanWebCam, setisModalFotoKelengkapanWebCam] = useState(false);
    function isShowModalFotoKelengkapanWebCam() {
        setisModalFotoKelengkapanWebCam(!isModalFotoKelengkapanWebCam);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleKeyPressFotoKelengkapan = event => {
        //console.log('Foto Kelengkapan : ', event.keyCode);
        if (isModalFotoKelengkapanWebCam) {
            if (event.keyCode === 32) {
                captureFotoKelengkapanFromCAM();

                setisModalFotoKelengkapanWebCam(false);
            }
        }
    };

    const handleKeyPressFotoKTP = event => {
        //console.log('Foto KTP : ', event.keyCode);
        if (isModalFotoKTPWebCam) {
            if (event.keyCode === 32) {
                captureFotoKTPFromCAM();

                setisModalFotoKTPWebCam(false);
            }
        }
    };

    const handleKeyPressTakeCapture = event => {
        if (isModalFotoKTPWebCam && !isModalFotoKelengkapanWebCam) {
            if (event.keyCode === 32) {
                captureFotoKTPFromCAM();

                setisModalFotoKTPWebCam(false);
            }
        }
        else if (!isModalFotoKTPWebCam && isModalFotoKelengkapanWebCam) {
            if (event.keyCode === 32) {
                captureFotoKelengkapanFromCAM();

                setisModalFotoKelengkapanWebCam(false);
            }
        }
    };

    //meta title
    document.title = "Pegadaian Handphone | Transaksi Baru - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
          <div className="page-content" tabIndex={0} onKeyDown={handleKeyPressTakeCapture}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Pegadaian Handphone - Transaksi Baru")}
                  />

                  {/*IS MODAL FOR Take Foto KTP*/}
                  <Modal
                      isOpen={isModalFotoKTPWebCam}
                      toggle={() => {
                          isShowModalFotoKTPWebCam();
                      }}
                      backdrop={'static'}
                      id="modalFotoKTPWebCam"
                      className={'modal-lg modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-info" id="modalFotoKTPWebCamLabel"><i className="fas fa-camera me-2"></i>AMBIL FOTO TANDA PENGENAL</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalFotoKTPWebCam(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="text-center">Tekan tombol <span className="fw-bold text-primary">SPASI</span> pada <i>Keyboard</i> / Papan Ketik Anda untuk mengambil Foto dengan cepat.</p>
                          <Webcam
                              className="img-fluid mx-auto d-block rounded"
                              forceScreenshotSourceSize={false}
                              mirrored={false}
                              screenshotFormat={'image/jpeg'}
                              screenshotQuality={1}
                              width={640}
                              height={480}
                              ref={webcamRefCaptureKTP}
                              facingmode="user"
                          />
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-info" onClick={() => {
                              captureFotoKTPFromCAM();

                              setisModalFotoKTPWebCam(false);
                          }}><i className="mdi mdi-camera-enhance me-2"></i> Ambil Foto</button>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR Take Foto Kelengkapan*/}
                  <Modal
                      isOpen={isModalFotoKelengkapanWebCam}
                      toggle={() => {
                          isShowModalFotoKelengkapanWebCam();
                      }}
                      backdrop={'static'}
                      id="modalFotoKelengkapanWebCam"
                      className={'modal-lg modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-success" id="modalFotoKelengkapanWebCamLabel"><i className="fas fa-camera me-2"></i>AMBIL FOTO KELENGKAPAN</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalFotoKelengkapanWebCam(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="text-center">Tekan tombol <span className="fw-bold text-primary">SPASI</span> pada <i>Keyboard</i> / Papan Ketik Anda untuk mengambil Foto dengan cepat.</p>
                          <Webcam
                              className="img-fluid mx-auto d-block rounded"
                              forceScreenshotSourceSize={false}
                              mirrored={false}
                              screenshotFormat={'image/jpeg'}
                              screenshotQuality={1}
                              width={640}
                              height={480}
                              ref={webcamRefCaptureKelengkapan}
                              facingmode="user"
                          />
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-success" onClick={() => {
                              captureFotoKelengkapanFromCAM();

                              setisModalFotoKelengkapanWebCam(false);
                          }}><i className="mdi mdi-camera-enhance me-2"></i> Ambil Foto</button>
                      </div>
                  </Modal>

                  
                  {/*IS MODAL FOR ERROR HP EXISTS*/}
                  <Modal
                      isOpen={isModalHPExists}
                      toggle={() => {
                          isShowModalHPExists();
                      }}
                      backdrop={'static'}
                      id="modalHPExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalHPExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NOMOR TELEPON SUDAH DIGUNAKAN</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalHPExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">No. Telepon : <b>{INPUT_NomorHP}</b></p>
                          <p className="font-size-13 text-justify">Nomor Telepon / WhatsApp tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNoHPExists !== null && isNoHPExists !== "Error" ? isNoHPExists.NamaLengkap : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalHPExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {/*IS MODAL FOR ERROR NIK EXISTS*/}
                  <Modal
                      isOpen={isModalNIKExists}
                      toggle={() => {
                          isShowModalNIKExists();
                      }}
                      backdrop={'static'}
                      id="modalNIKExists"
                      className={'modal-dialog-centered'}
                  >
                      <div className="modal-header bg-white bg-opacity-25 ">
                          <h5 className="modal-title text-danger" id="modalNIKExistskLabel"><i className=" mdi mdi-information-outline me-2"></i>NASABAH SUDAH TERDAFTAR</h5>
                          <button type="button" className="btn-close"
                              onClick={() => {
                                  setisModalNIKExists(false);
                              }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <p className="pb-1">Nomor Induk : <b>{INPUT_NIK}</b></p>
                          <p className="font-size-13 text-justify">Nomor Induk tersebut sudah digunakan oleh nasabah lain yang bernama : <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.NamaLengkap : "Unknown"}</b>, dengan jenis tanda pengenal <b>{isNIKExists !== null && isNIKExists !== "Error" ? isNIKExists.JenisTandaPengenal : "Unknown"}</b>.</p>
                          <br />

                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-danger" onClick={() => {
                              setisModalNIKExists(false);
                          }}>Tutup</button>
                      </div>
                  </Modal>

                  {
                      isPagePreloader
                          ?
                          <Row>
                              <Col lg={12} className={"mt-5 pb-5 text-center"}>
                                  <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                              </Col>
                          </Row>
                          :
                          <Row>
                              <Col lg="12">
                                  <Card>
                                      <CardBody>
                                          <CardTitle className="text-center">
                                              <h3 className="card-title mb-4">FORMULIR TRANSAKSI PEGADAIAN HANDPHONE</h3>
                                          </CardTitle>
                                          <hr className="mb-4 mt-2" style={{ borderTop: "1px solid #DDE6F4" }} />
                                          <div className="wizard clearfix">
                                              <div className="steps clearfix">
                                                  <ul>
                                                      <NavItem
                                                          className={classnames({ current: activeTab === 1 })}
                                                      >
                                                          <NavLink
                                                              className={classnames({ current: activeTab === 1 })}
                                                              onClick={() => {
                                                                  {
                                                                      Step1IsPassed && !userBlackListStatus
                                                                          ?
                                                                          setactiveTab(1)
                                                                          :
                                                                          null
                                                                  }
                                                              }}
                                                              disabled={!(passedSteps || []).includes(1)}
                                                          >
                                                              <span className="number">1.</span> Data Pelanggan
                                                          </NavLink>
                                                      </NavItem>
                                                      <NavItem
                                                          className={classnames({ current: activeTab === 2 })}
                                                      >
                                                          <NavLink
                                                              className={classnames({ active: activeTab === 2 })}
                                                              onClick={() => {
                                                                  {
                                                                      Step1IsPassed && Step2IsPassed && !userBlackListStatus
                                                                          ?
                                                                          setactiveTab(2)
                                                                          :
                                                                          null
                                                                  }
                                                              }}
                                                              disabled={!(passedSteps || []).includes(2)}
                                                          >
                                                              <span className="number">2.</span> Barang Jaminan
                                                          </NavLink>
                                                      </NavItem>
                                                      <NavItem
                                                          className={classnames({ current: activeTab === 3 })}
                                                      >
                                                          <NavLink
                                                              className={classnames({ active: activeTab === 3 })}
                                                              onClick={() => {
                                                                  {
                                                                      Step1IsPassed && Step2IsPassed && Step3IsPassed && !userBlackListStatus
                                                                          ?
                                                                          setactiveTab(3)
                                                                          :
                                                                          null
                                                                  }
                                                              }}
                                                              disabled={!(passedSteps || []).includes(3)}
                                                          >
                                                              <span className="number">3.</span> Rincian Transaksi
                                                          </NavLink>
                                                      </NavItem>
                                                      <NavItem
                                                          className={classnames({ current: activeTab === 4 })}
                                                      >
                                                          <NavLink
                                                              className={classnames({ active: activeTab === 4 })}
                                                              onClick={() => {
                                                                  {
                                                                      Step1IsPassed && Step2IsPassed && Step3IsPassed && !userBlackListStatus
                                                                          ?
                                                                          setactiveTab(4)
                                                                          :
                                                                          null
                                                                  }
                                                              }}
                                                              disabled={!(passedSteps || []).includes(4)}
                                                          >
                                                              {/*<NavLink
                                                      className={classnames({ active: activeTab === 4 })}
                                                      onClick={() => {
                                                          setactiveTab(4)
                                                      }}
                                                  >*/}
                                                              <span className="number">4.</span> Tinjauan Transaksi
                                                          </NavLink>
                                                      </NavItem>
                                                  </ul>
                                              </div>
                                              <div className="content clearfix">
                                                  <TabContent activeTab={activeTab} className="body">
                                                      <TabPane tabId={1}>
                                                          <Form>
                                                              <Row>
                                                                  <Col xl={12}>
                                                                      <div >
                                                                          <Alert className="alert-info" role="alert">
                                                                              <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                                              <p>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</p>
                                                                          </Alert>
                                                                      </div>
                                                                  </Col>
                                                                  {
                                                                      isFullDataPelangganUID != null ? isFullDataPelangganUID[0].Blacklist
                                                                          ?
                                                                          <Col xl={12}>
                                                                              <div >
                                                                                  <Alert className="alert-danger" role="alert">
                                                                                      <h5 className="alert-heading"><i className="fas fa-exclamation-triangle me-2"></i> RED NOTICE!</h5>
                                                                                      <p>Nasabah tersebut terdeteksi oleh sistem masuk dalam status <b className="text-danger">BLACKLIST</b> mohon untuk tidak melanjutkan proses transaksi.
                                                                                          <br />
                                                                                          <br />
                                                                                          <b className="text-danger">#REASON : </b>
                                                                                          <br />
                                                                                          {
                                                                                              isFullDataPelangganUID != null
                                                                                                  ?
                                                                                                  '"' + isFullDataPelangganUID[0].Blacklist_Reason + '"'
                                                                                                  :
                                                                                                  null
                                                                                          }
                                                                                      </p>
                                                                                  </Alert>
                                                                              </div>
                                                                          </Col>
                                                                          :
                                                                          null
                                                                          :
                                                                          null
                                                                  }
                                                                  {
                                                                      isINPUT_TipePelanggan == "OLDCust"
                                                                          ?
                                                                          <Col xl="12">
                                                                              <Row>
                                                                                  {/* Reports Render */}
                                                                                  {summaryReports.map((report, key) => (
                                                                                      <Col md="4" key={"_col_" + key}>
                                                                                          <Card className="mini-stats-wid">
                                                                                              <CardBody>
                                                                                                  <div className="d-flex">
                                                                                                      <div className="flex-grow-1">
                                                                                                          {
                                                                                                              report.description == "NaN"
                                                                                                                  ?
                                                                                                                  <p className=
                                                                                                                      {
                                                                                                                          report.description == "NaN"
                                                                                                                              ? "text-black fw-medium placeholder-glow"
                                                                                                                              : "text-black fw-medium"
                                                                                                                      }
                                                                                                                  >
                                                                                                                      {
                                                                                                                          report.description == "NaN"
                                                                                                                              ? <span className="text-black fw-medium placeholder col-8">report.title</span>
                                                                                                                              : report.title
                                                                                                                      }
                                                                                                                  </p>
                                                                                                                  :
                                                                                                                  <p className="text-black fw-medium">
                                                                                                                      {report.title}
                                                                                                                  </p>
                                                                                                          }

                                                                                                          {
                                                                                                              report.description == "NaN"
                                                                                                                  ?
                                                                                                                  <span className=
                                                                                                                      {
                                                                                                                          report.description == "NaN"
                                                                                                                              ? "mb-0 placeholder-glow"
                                                                                                                              : "mb-0"
                                                                                                                      }
                                                                                                                  >
                                                                                                                      {
                                                                                                                          report.description == "NaN"
                                                                                                                              ? <span className="mb-0 placeholder col-6">report.description</span>
                                                                                                                              : report.description
                                                                                                                      }
                                                                                                                  </span>
                                                                                                                  :
                                                                                                                  <>
                                                                                                                      <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                                                                                  </>
                                                                                                          }

                                                                                                      </div>
                                                                                                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                                                                          <span className={
                                                                                                              "avatar-title bg-" +
                                                                                                              report.bgColor +
                                                                                                              " " +
                                                                                                              report.textColor
                                                                                                          }
                                                                                                          >
                                                                                                              <i
                                                                                                                  className={
                                                                                                                      " " + report.iconClass + " font-size-20"
                                                                                                                  }
                                                                                                              ></i>
                                                                                                          </span>
                                                                                                      </div>

                                                                                                  </div>
                                                                                              </CardBody>
                                                                                          </Card>
                                                                                      </Col>
                                                                                  ))}
                                                                              </Row>
                                                                          </Col>
                                                                          :
                                                                          ""
                                                                  }
                                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                              </Row>

                                                              <Row>
                                                                  <Col lg='12' className="">
                                                                      <Row>
                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" htmlFor="Pelanggan_Kategori" for="Pelanggan_Kategori">
                                                                                      <b><span className="text-danger">*</span></b>Tanda Pengenal
                                                                                  </Label>
                                                                                  <Select
                                                                                      id="Pelanggan_Kategori"
                                                                                      name="Pelanggan_Kategori"
                                                                                      placeholder="Pilih Jenis Tanda Pengenal"
                                                                                      value={selectedTipeTPengenal}
                                                                                      onChange={(TipeTandaPengenalDipilih) => {
                                                                                          handleSelectTipeTandaPengenal(TipeTandaPengenalDipilih);

                                                                                          //setselectedTipeTPengenal(INPUT_TipeTPengenal);
                                                                                      }}
                                                                                      options={optionTipeTPengenal}
                                                                                      className="select2-selection"
                                                                                  />
                                                                              </div>
                                                                          </Col>
                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="Pelanggan_Kategori">
                                                                                      <b><span className="text-danger">*</span></b>Kategori Nasabah
                                                                                  </Label>
                                                                                  <Select
                                                                                      id="Pelanggan_Kategori"
                                                                                      name="Pelanggan_Kategori"
                                                                                      placeholder="Pilih Kategori Nasabah"
                                                                                      value={selectedTipePelanggan}
                                                                                      onChange={(TipePelangganDipilih) => {
                                                                                          handleSelectTipePelanggan(TipePelangganDipilih);
                                                                                      }}
                                                                                      options={optionTipePelanggan}
                                                                                      isDisabled={selectedTipeTPengenal != null ? isEnableDisableKategoriPelanggan : true}
                                                                                      className="select2-selection"
                                                                                  />
                                                                              </div>
                                                                          </Col>
                                                                      </Row>

                                                                      <Row>
                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="Pelanggan_CariData">
                                                                                      Cari Data Nasabah
                                                                                  </Label>
                                                                                  <Select
                                                                                      id="Pelanggan_CariData"
                                                                                      name="Pelanggan_CariData"
                                                                                      placeholder="Masukan NIK / Nomor SIM / Nama Nasabah. . ."
                                                                                      value={selectedCariDataPelanggan}
                                                                                      onMenuOpen={(e) => {
                                                                                          handleFeedCariDataPelanggan();
                                                                                      }}
                                                                                      onChange={(selectedCariDataPelanggan) => {
                                                                                          handleCariDataPelanggan(selectedCariDataPelanggan);
                                                                                      }}
                                                                                      options={optionCariDataPelanggan}
                                                                                      className="select2-selection"
                                                                                      isLoading={isLoadingCariPelanggan}
                                                                                      isDisabled={isEnableDisableCariPelanggan}
                                                                                  />
                                                                              </div>
                                                                          </Col>

                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="INPUTDPel_NamaLengkap">
                                                                                      <b><span className="text-danger">*</span></b>Nama Lengkap Nasabah
                                                                                  </Label>
                                                                                  <Input
                                                                                      name="INPUTDPel_NamaLengkap"
                                                                                      id="INPUTDPel_NamaLengkap"
                                                                                      className="form-control"
                                                                                      type="text"
                                                                                      maxLength={64}
                                                                                      placeholder="Nama Lengkap Sesuai di Tanda Pengenal . . ."
                                                                                      onChange={(evt) => {
                                                                                          handleFormDataPelanggan_NamaLengkap(evt.target.value);

                                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                                      }}
                                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                                      value={VLD_DataPelanggan.values.INPUTDPel_NamaLengkap || ""}
                                                                                      autoComplete={"off"}
                                                                                      disabled={isEDFullNamePelanggan}
                                                                                      invalid={
                                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap && !isEDFullNamePelanggan ? true : false
                                                                                      }
                                                                                  />
                                                                                  {
                                                                                      VLD_DataPelanggan.touched.INPUTDPel_NamaLengkap && VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap ? (
                                                                                          <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaLengkap}</FormFeedback>
                                                                                      ) : null
                                                                                  }

                                                                              </div>
                                                                          </Col>
                                                                      </Row>

                                                                      <Row>
                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="INPUTDPel_NamaPanggilan">
                                                                                      <b><span className="text-danger">*</span></b>Nama Panggilan
                                                                                  </Label>
                                                                                  <Input
                                                                                      name="INPUTDPel_NamaPanggilan"
                                                                                      id="INPUTDPel_NamaPanggilan"
                                                                                      className="form-control"
                                                                                      type="text"
                                                                                      maxLength={24}
                                                                                      placeholder="Masukan Nama Panggilan . . ."
                                                                                      onChange={(evt) => {
                                                                                          handleFormDataPelanggan_NamaPanggilan(evt.target.value);

                                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                                      }}
                                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                                      value={INPUT_NamaPanggilan.toUpperCase() || ""}
                                                                                      autoComplete={"off"}
                                                                                      disabled={isEDNamePelanggan}
                                                                                      invalid={
                                                                                          VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan && !isEDNamePelanggan ? true : false
                                                                                      }
                                                                                  />
                                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NamaPanggilan && VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan ? (
                                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NamaPanggilan}</FormFeedback>
                                                                                  ) : null}
                                                                              </div>
                                                                          </Col>

                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="INPUTDPel_NIK">
                                                                                      <b><span className="text-danger">*</span></b>Nomor Induk Kependudukan / Nomor SIM
                                                                                  </Label>
                                                                                  <Input
                                                                                      name="INPUTDPel_NIK"
                                                                                      id="INPUTDPel_NIK"
                                                                                      className="form-control"
                                                                                      type="text"
                                                                                      maxLength={17}
                                                                                      placeholder="Nomor Induk Kependudukan / Nomor Kartu SIM . . ."
                                                                                      onChange={(evt) => {
                                                                                          if (!isNaN(evt.target.value)) {
                                                                                              handleFormDataPelanggan_NIK(evt.target.value);
                                                                                          }
                                                                                          else {
                                                                                              handleFormDataPelanggan_NIK(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                          }

                                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                                      }}
                                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                                      value={INPUT_NIK || ""}
                                                                                      autoComplete={"off"}
                                                                                      disabled={isEDNIKPelanggan}
                                                                                      invalid={
                                                                                          VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK && !isEDNIKPelanggan ? true : false
                                                                                      }
                                                                                  />
                                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NIK && VLD_DataPelanggan.errors.INPUTDPel_NIK ? (
                                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NIK}</FormFeedback>
                                                                                  ) : null}
                                                                              </div>
                                                                          </Col>
                                                                      </Row>

                                                                      <Row>
                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="INPUTDPel_NoHP">
                                                                                      <b><span className="text-danger">*</span></b>Telepon / WhatsApp
                                                                                  </Label>
                                                                                  <Input
                                                                                      name="INPUTDPel_NoHP"
                                                                                      id="INPUTDPel_NoHP"
                                                                                      className="form-control"
                                                                                      type="text"
                                                                                      placeholder="Tanpa diawali 0 / 62 (Cth : 81234567890)"
                                                                                      maxLength={17}
                                                                                      onChange={(evt) => {
                                                                                          if (!isNaN(evt.target.value)) {
                                                                                              handleFormDataPelanggan_NoHP(evt.target.value);
                                                                                          }
                                                                                          else {
                                                                                              handleFormDataPelanggan_NoHP(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                          }

                                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                                      }}
                                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                                      value={INPUT_NomorHP || "62"}
                                                                                      autoComplete={"off"}
                                                                                      disabled={isEDPhoneNumberPelanggan}
                                                                                      invalid={
                                                                                          VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP && !isEDPhoneNumberPelanggan ? true : false
                                                                                      }
                                                                                  />
                                                                                  {VLD_DataPelanggan.touched.INPUTDPel_NoHP && VLD_DataPelanggan.errors.INPUTDPel_NoHP ? (
                                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_NoHP}</FormFeedback>
                                                                                  ) : null}
                                                                              </div>
                                                                          </Col>

                                                                          <Col lg="6">
                                                                              <div className="mb-3">
                                                                                  <Label className="col-form-label" for="INPUTDPel_Alamat">
                                                                                      <b><span className="text-danger">*</span></b>Alamat Lengkap
                                                                                  </Label>
                                                                                  <textarea
                                                                                      id="INPUTDPel_Alamat"
                                                                                      name="INPUTDPel_Alamat"
                                                                                      className="form-control"
                                                                                      rows="3"
                                                                                      placeholder="Masukan Alamat Lengkap sesuai Tanda Pengenal . . ."
                                                                                      disabled={isEDAlamatPelanggan}
                                                                                      autoComplete="off"
                                                                                      maxLength={255}
                                                                                      value={VLD_DataPelanggan.values.INPUTDPel_Alamat || ""}
                                                                                      onChange={(evt) => {
                                                                                          handleFormDataPelanggan_Alamat(evt.target.value);

                                                                                          VLD_DataPelanggan.handleChange(evt);
                                                                                      }}
                                                                                      onBlur={VLD_DataPelanggan.handleBlur}
                                                                                  />
                                                                                  {VLD_DataPelanggan.touched.INPUTDPel_Alamat && VLD_DataPelanggan.errors.INPUTDPel_Alamat ? (
                                                                                      <FormFeedback type="invalid">{VLD_DataPelanggan.errors.INPUTDPel_Alamat}</FormFeedback>
                                                                                  ) : null}
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                  </Col>
                                                              </Row>
                                                          </Form>
                                                      </TabPane>

                                                      <TabPane tabId={2}>
                                                          <div>
                                                              <Form>
                                                                  <Row>
                                                                      <Col xl={12}>
                                                                          <div >
                                                                              <Alert className="alert-info" role="alert">
                                                                                  <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                                                  <ul>
                                                                                      <li>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</li>
                                                                                      <li>Khusus bidang atau kolom <b className="text-info">Nilai Maksimal Taksiran Barang</b> WAJIB diisi apabila data taksiran untuk tipe barang yang akan diproses belum ada didalam database sistem.</li>
                                                                                  </ul>
                                                                              </Alert>
                                                                          </div>
                                                                      </Col>
                                                                      {
                                                                          isTaksiranBukanAngka
                                                                              ?
                                                                              <Col xl={12}>
                                                                                  <div >
                                                                                      <Alert className="alert-warning" role="alert">
                                                                                          <h5 className="alert-heading text-danger"><i className="fas fa-exclamation-triangle me-2"></i> OOPS! HUMAN ERROR!</h5>
                                                                                          <p>## Format <b className="text-danger">Nilai Maksimal Taksiran Barang</b> yang anda masukkan salah! mohon hanya masukan <b className="text-danger">ANGKA</b> saja yang anda masukan. <br />~Terimakasih!</p>
                                                                                      </Alert>
                                                                                  </div>
                                                                              </Col>
                                                                              :
                                                                              null
                                                                      }
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label className="col-form-label" for="INPUTJBar_JenisBarang">
                                                                                  <b><span className="text-danger">*</span></b>Jenis Barang
                                                                              </Label>
                                                                              <Input
                                                                                  type="text"
                                                                                  className="form-control"
                                                                                  id="INPUTJBar_JenisBarang"
                                                                                  name="INPUTJBar_JenisBarang"
                                                                                  placeholder="Pilih Jenis Barang"
                                                                                  value="SmartPhone"
                                                                                  disabled
                                                                                  autoComplete="off"
                                                                              />
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label className="col-form-label" for="INPUTJBar_MerkHP">
                                                                                  <b><span className="text-danger">*</span></b>Merk Handphone
                                                                              </Label>
                                                                              <Select
                                                                                  id="INPUTJBar_MerkHP"
                                                                                  name="INPUTJBar_MerkHP"
                                                                                  placeholder="Cari Merk Handphone"
                                                                                  value={selectedMerkHandphone}
                                                                                  onMenuOpen={(e) => {
                                                                                      handleFeedCariMerkHP();
                                                                                  }}
                                                                                  onChange={(selectedMerkHandphone) => {
                                                                                      handleCariMerkHP(selectedMerkHandphone);
                                                                                      handleFormJaminan_MerkHP(selectedMerkHandphone.value);
                                                                                  }}
                                                                                  options={optionMerkHandphone}
                                                                                  className="select2-selection"
                                                                                  isLoading={isLoadingCariMerkHP}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_TipeHPLainnya">
                                                                                  <b><span className="text-danger">*</span></b>Tipe Handphone
                                                                                  {
                                                                                      !INPUTTipeHPLainnya && isFullTipeHPSpec != null
                                                                                          ?
                                                                                          <> | <a className="font-size-12" href={isFullTipeHPSpec.Link} target="_blank" rel="noreferrer">Spesifikasi Lengkap</a></>
                                                                                          :
                                                                                          null
                                                                                  }
                                                                              </Label>
                                                                              {
                                                                                  INPUTTipeHPLainnya
                                                                                      ?
                                                                                      <><Input
                                                                                          name="INPUTJBar_TipeHPLainnya"
                                                                                          id="INPUTJBar_TipeHPLainnya"
                                                                                          className="form-control"
                                                                                          type="text"
                                                                                          placeholder="Masukan Merk & Tipe Handphone (Cth. Xiaomi Poco F3)"
                                                                                          onChange={(evt) => {
                                                                                              handleFormJaminan_TipeHPLainnya(evt.target.value);

                                                                                              VLD_BarangJaminan.handleChange(evt);
                                                                                          }}
                                                                                          onBlur={VLD_BarangJaminan.handleBlur}
                                                                                          value={VLD_BarangJaminan.values.INPUTJBar_TipeHPLainnya || ""}
                                                                                          autoComplete={"off"}
                                                                                          invalid={
                                                                                              VLD_BarangJaminan.touched.INPUTJBar_TipeHPLainnya && VLD_BarangJaminan.errors.INPUTJBar_TipeHPLainnya ? true : false
                                                                                          }
                                                                                      />
                                                                                          {VLD_BarangJaminan.touched.INPUTJBar_TipeHPLainnya && VLD_BarangJaminan.errors.INPUTJBar_TipeHPLainnya ? (
                                                                                              <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_TipeHPLainnya}</FormFeedback>
                                                                                          ) : null}</>
                                                                                      :
                                                                                      <Select
                                                                                          id="INPUTJBar_TipeHP"
                                                                                          name="INPUTJBar_TipeHP"
                                                                                          placeholder="Cari Tipe Handphone"
                                                                                          value={selectedTipeHandphone}
                                                                                          onMenuOpen={() => {
                                                                                              handleFeedCariTipeHP();
                                                                                          }}
                                                                                          onChange={(selectedTipeHandphone) => {
                                                                                              handleCariTipeHP(selectedTipeHandphone);
                                                                                              //handleFormJaminan_MerkHP(selectedMerkHandphone.value);
                                                                                          }}
                                                                                          options={optionTipeHandphone}
                                                                                          className="select2-selection"
                                                                                          isDisabled={isEnableDisableCariTipeHandphone}
                                                                                          isLoading={isLoadingCariTipeHP}
                                                                                      />
                                                                              }
                                                                          </div>
                                                                          <hr style={{ borderTop: "0px solid #DDE6F4" }} />
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_NominalTaksiran">
                                                                                  <b><span className="text-danger">*</span></b>Nilai Awal Maksimal Taksiran Barang (Rp)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTJBar_NominalTaksiran"
                                                                                  id="INPUTJBar_NominalTaksiran"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Masukan Nilai Maksimal Taksiran Barang (Min. 50.000). . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormJaminan_NilaiTaksirManual(evt.target.value);

                                                                                      VLD_BarangJaminan.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={INPUT_HargaTaksiran_ManualFORMAT || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBar_NominalTaksiran && VLD_BarangJaminan.errors.INPUTJBar_NominalTaksiran ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableInputNilaiTaksirMaksimal}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBar_NominalTaksiran && VLD_BarangJaminan.errors.INPUTJBar_NominalTaksiran ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_NominalTaksiran}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoFisik">
                                                                                  <b><span className="text-danger">*</span></b>Informasi Fisik Perangkat
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoFisikMulus"
                                                                                              name="INPUTJBar_InfoFisikMulus"
                                                                                              checked={FisikMulus}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_KondisiFisik("Mulus")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_KondisiFisik("")
                                                                                                  }
                                                                                                  setFisikMulus(!FisikMulus)
                                                                                                  setFisikTidakMulus(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihFisikPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_InfoFisikMulus"
                                                                                          >
                                                                                              Mulus
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoFisikNotMulus"
                                                                                              name="INPUTJBar_InfoFisikNotMulus"
                                                                                              checked={FisikTidakMulus}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_KondisiFisik("Tidak Mulus")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_KondisiFisik("")
                                                                                                  }
                                                                                                  setFisikMulus(false)
                                                                                                  setFisikTidakMulus(!FisikTidakMulus)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihFisikPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_InfoFisikNotMulus"
                                                                                          >
                                                                                              Tidak Mulus
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoFungsi">
                                                                                  <b><span className="text-danger">*</span></b>Informasi Fungsi Perangkat
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoFungsiNormal"
                                                                                              name="INPUTJBar_InfoFungsiNormal"
                                                                                              checked={FungsiNormal}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_FungsiPerangkat("Normal")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_FungsiPerangkat("")
                                                                                                  }
                                                                                                  setFungsiNormal(!FungsiNormal)
                                                                                                  setFungsiTidakNormal(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihFungsiPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_InfoFungsiNormal"
                                                                                          >
                                                                                              Normal
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoFungsiNotNormal"
                                                                                              name="INPUTJBar_InfoFungsiNotNormal"
                                                                                              checked={FungsiTidakNormal}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_FungsiPerangkat("Tidak Normal")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_FungsiPerangkat("")
                                                                                                  }
                                                                                                  setFungsiNormal(false)
                                                                                                  setFungsiTidakNormal(!FungsiTidakNormal)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihFungsiPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_InfoFungsiNotNormal"
                                                                                          >
                                                                                              Tidak Normal
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoServices">
                                                                                  <b><span className="text-danger">*</span></b>Indikasi Barang Pernah Services
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoPernahServices"
                                                                                              name="INPUTJBar_InfoPernahServices"
                                                                                              checked={IndikasiTidakPernahServices}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_IndikasiServices("Belum Pernah Services")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_IndikasiServices("")
                                                                                                  }
                                                                                                  setIndikasiTidakPernahServices(!IndikasiTidakPernahServices)
                                                                                                  setIndikasiPernahServices(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihIndikasiServicesPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_InfoPernahServices"
                                                                                          >
                                                                                              Blm. Pernah Services
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoTPernahServices"
                                                                                              name="INPUTJBar_InfoTPernahServices"
                                                                                              checked={IndikasiPernahServices}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_IndikasiServices("Pernah Services")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_IndikasiServices("")
                                                                                                  }
                                                                                                  setIndikasiTidakPernahServices(false)
                                                                                                  setIndikasiPernahServices(!IndikasiPernahServices)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihIndikasiServicesPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_InfoTPernahServices"
                                                                                          >
                                                                                              Sdh. Pernah Services
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoIMEI">
                                                                                  <b><span className="text-danger">*</span></b>Informasi Status IMEI Perangkat | <a className="font-size-12" href={process.env.REACT_APP_DOMAIN_IMEI} target="_blank" rel="noreferrer"> Cek Disini </a>
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoIMEIResmi"
                                                                                              name="INPUTJBar_InfoIMEIResmi"
                                                                                              checked={IMEITerdaftar}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_StatusIMEI("Terdaftar")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_StatusIMEI("")
                                                                                                  }
                                                                                                  setIMEITerdaftar(!IMEITerdaftar)
                                                                                                  setIMEITidakTerdaftar(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihStatusIMEIPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_InfoIMEIResmi"
                                                                                          >
                                                                                              Terdaftar
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_InfoIMEIBlocked"
                                                                                              name="INPUTJBar_InfoIMEIBlocked"
                                                                                              checked={IMEITidakTerdaftar}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_StatusIMEI("Tidak Terdaftar")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_StatusIMEI("")
                                                                                                  }
                                                                                                  setIMEITerdaftar(false)
                                                                                                  setIMEITidakTerdaftar(!IMEITidakTerdaftar)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihStatusIMEIPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_InfoIMEIBlocked"
                                                                                          >
                                                                                              Tidak Terdaftar
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoCasing">
                                                                                  <b><span className="text-danger">*</span></b>Informasi <i>Case</i> Eksternal
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_CasingADA"
                                                                                              name="INPUTJBar_CasingADA"
                                                                                              checked={AdaCasing}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_InformasiCasing("Ada")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_InformasiCasing("")
                                                                                                  }

                                                                                                  setAdaCasing(!AdaCasing)
                                                                                                  setTidakAdaCasing(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihInformasiCasingPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_CasingADA"
                                                                                          >
                                                                                              Ada
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_CasingNull"
                                                                                              name="INPUTJBar_CasingNull"
                                                                                              checked={TidakAdaCasing}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_InformasiCasing("Tidak Ada")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_InformasiCasing("")
                                                                                                  }

                                                                                                  setAdaCasing(false)
                                                                                                  setTidakAdaCasing(!TidakAdaCasing)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihInformasiCasingPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_CasingNull"
                                                                                          >
                                                                                              Tidak Ada
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_InfoCharger">
                                                                                  <b><span className="text-danger">*</span></b>Informasi <i>Charger</i>
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="4">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_ChargerORI"
                                                                                              name="INPUTJBar_ChargerORI"
                                                                                              checked={chargerOriginal}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("Original")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("")
                                                                                                  }
                                                                                                  setchargerOriginal(!chargerOriginal)
                                                                                                  setchargerOEM(false)
                                                                                                  setchargerNothing(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihInformasiChargerPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-success"
                                                                                              htmlFor="INPUTJBar_ChargerORI"
                                                                                          >
                                                                                              Original
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="4">
                                                                                      <div className="form-check form-checkbox form-check-info">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_ChargerOEM"
                                                                                              name="INPUTJBar_ChargerOEM"
                                                                                              checked={chargerOEM}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("OEM")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("")
                                                                                                  }
                                                                                                  setchargerOriginal(false)
                                                                                                  setchargerOEM(!chargerOEM)
                                                                                                  setchargerNothing(false)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihInformasiChargerPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-info"
                                                                                              htmlFor="INPUTJBar_ChargerOEM"
                                                                                          >
                                                                                              OEM
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="4">
                                                                                      <div className="form-check form-checkbox form-check-danger">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTJBar_ChargerNull"
                                                                                              name="INPUTJBar_ChargerNull"
                                                                                              checked={chargerNothing}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("Tidak Ada")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormJaminan_TipeChargerAdaptor("")
                                                                                                  }
                                                                                                  setchargerOriginal(false)
                                                                                                  setchargerOEM(false)
                                                                                                  setchargerNothing(!chargerNothing)
                                                                                              }}
                                                                                              disabled={isEnableDisablePilihInformasiChargerPerangkat}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label text-danger"
                                                                                              htmlFor="INPUTJBar_ChargerNull"
                                                                                          >
                                                                                              Tidak Ada
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBarChipset">
                                                                                  Informasi <i>Chipset</i>
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTJBarChipset"
                                                                                  id="INPUTJBarChipset"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Informasi Chipset Handphone. . ."
                                                                                  onChange={VLD_BarangJaminan.handleChange}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={VLD_BarangJaminan.values.INPUTJBarChipset || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBarChipset && VLD_BarangJaminan.errors.INPUTJBarChipset ? true : false
                                                                                  }
                                                                                  disabled={true}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBarChipset && VLD_BarangJaminan.errors.INPUTJBarChipset ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBarChipset}</FormFeedback>
                                                                              ) : null}

                                                                          </div>
                                                                      </Col>
                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_RAM">
                                                                                  <b><span className="text-danger">*</span></b><i>Random Access Memory</i> - RAM (GB)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTJBar_RAM"
                                                                                  id="INPUTJBar_RAM"
                                                                                  className="form-control"
                                                                                  type="number"
                                                                                  placeholder="Masukkan Kapasitas RAM Handphone. . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormJaminan_RAM(evt.target.value);

                                                                                      setisSpecRAMSelected(evt.target.value);

                                                                                      VLD_BarangJaminan.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={VLD_BarangJaminan.values.INPUTJBar_RAM || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBar_RAM && VLD_BarangJaminan.errors.INPUTJBar_RAM ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableInputRAMPerangkat}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBar_RAM && VLD_BarangJaminan.errors.INPUTJBar_RAM ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_RAM}</FormFeedback>
                                                                              ) : null}

                                                                          </div>
                                                                      </Col>
                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_MemInternal">
                                                                                  <b><span className="text-danger">*</span></b>Penyimpanan Internal - ROM (GB)  | <Link className="font-size-12" to={"#"} onClick={() => {
                                                                                      tog_center();
                                                                                  }}>Informasi Kapasitas</Link>
                                                                              </Label>
                                                                              <Modal
                                                                                  size="lg"
                                                                                  isOpen={modal_center}
                                                                                  toggle={() => {
                                                                                      tog_center();
                                                                                  }}
                                                                                  centered
                                                                              >
                                                                                  <div className="modal-header">
                                                                                      <h5 className="modal-title mt-0">INFORMASI PETUNJUK</h5>
                                                                                      <button
                                                                                          type="button"
                                                                                          onClick={() => {
                                                                                              setmodal_center(false);
                                                                                          }}
                                                                                          className="close"
                                                                                          data-dismiss="modal"
                                                                                          aria-label="Close"
                                                                                      >
                                                                                          <span aria-hidden="true">&times;</span>
                                                                                      </button>
                                                                                  </div>
                                                                                  <div className="modal-body">
                                                                                      <p>
                                                                                          <b className="mb-2">#INFORMASI PETUNJUK PENGISIAN PENYIMPANAN INTERNAL</b>
                                                                                      </p>
                                                                                      <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                                                                      <p>Penyimpanan Internal dalam satuan <b>GigaByte (GB)</b></p>
                                                                                      <ul style={{ listStyle: 'circle' }}>
                                                                                          <li>Internal <b>4GB</b> = 4</li>
                                                                                          <li>Internal <b>8GB</b> = 8</li>
                                                                                          <li>Internal <b>16GB</b> = 16</li>
                                                                                          <li>Internal <b>32GB</b> = 32</li>
                                                                                          <li>Internal <b>64GB</b> = 64</li>
                                                                                          <li>Internal <b>128GB</b> = 128</li>
                                                                                          <li>Internal <b>256GB</b> = 256</li>
                                                                                          <li>Internal <b>512GB</b> = 512</li>
                                                                                          <li>Internal <b>640GB</b> = 640</li>
                                                                                      </ul>

                                                                                      <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                                                                      <p>Penyimpanan Internal dalam satuan <b>TeraByte (TB)</b></p>
                                                                                      <p>
                                                                                          # Per-Satu (1) TB adalah 1024<br />
                                                                                          # Rumus : 1024 x KapasitasInternal (Cth: 1024x2 = 2TB)
                                                                                      </p>

                                                                                      <ul style={{ listStyle: 'circle' }}>
                                                                                          <li>Internal <b>1TB</b> = 1024</li>
                                                                                          <li>Internal <b>2TB</b> = 2048</li>
                                                                                          <li>Internal <b>3TB</b> = 3072</li>
                                                                                          <li>Internal <b>4TB</b> = 4096</li>
                                                                                          <li>Internal <b>5TB</b> = 5120</li>
                                                                                      </ul>
                                                                                      <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                                  </div>
                                                                              </Modal>
                                                                              <Input
                                                                                  name="INPUTJBar_MemInternal"
                                                                                  id="INPUTJBar_MemInternal"
                                                                                  className="form-control"
                                                                                  type="number"
                                                                                  placeholder="Masukkan Kapasitas Memory Internal Handphone. . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormJaminan_MemInternal(evt.target.value);

                                                                                      setisSpecMemInternalSelected(evt.target.value);

                                                                                      VLD_BarangJaminan.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={VLD_BarangJaminan.values.INPUTJBar_MemInternal || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBar_MemInternal && VLD_BarangJaminan.errors.INPUTJBar_MemInternal ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableInputMemInternalPerangkat}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBar_MemInternal && VLD_BarangJaminan.errors.INPUTJBar_MemInternal ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_MemInternal}</FormFeedback>
                                                                              ) : null}

                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_IMEI1">
                                                                                  <b><span className="text-danger">*</span></b><i>International Mobile Equipment Identity</i> (IMEI_1)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTJBar_IMEI1"
                                                                                  id="INPUTJBar_IMEI1"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Masukan IMEI_1 Handphone, minimal 8 digit terakhir. . ."
                                                                                  maxLength={15}
                                                                                  onChange={(evt) => {

                                                                                      if (!isNaN(evt.target.value)) {
                                                                                          handleFormJaminan_IMEI1(evt.target.value);
                                                                                      }
                                                                                      else {
                                                                                          handleFormJaminan_IMEI1(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                      }

                                                                                      VLD_BarangJaminan.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={INPUT_IMEI1 || ''}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBar_IMEI1 && VLD_BarangJaminan.errors.INPUTJBar_IMEI1 ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableInputIMEI1Perangkat}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBar_IMEI1 && VLD_BarangJaminan.errors.INPUTJBar_IMEI1 ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_IMEI1}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_IMEI2">
                                                                                  <i>International Mobile Equipment Identity</i> (IMEI_2)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTJBar_IMEI2"
                                                                                  id="INPUTJBar_IMEI2"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  maxLength={15}
                                                                                  placeholder="Masukan IMEI2 Handphone. . ."
                                                                                  onChange={(evt) => {
                                                                                      if (!isNaN(evt.target.value)) {
                                                                                          handleFormJaminan_IMEI2(evt.target.value);
                                                                                      }
                                                                                      else {
                                                                                          handleFormJaminan_IMEI2(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                      }

                                                                                      VLD_BarangJaminan.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_BarangJaminan.handleBlur}
                                                                                  value={INPUT_IMEI2 || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_BarangJaminan.touched.INPUTJBar_IMEI2 && VLD_BarangJaminan.errors.INPUTJBar_IMEI2 ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableInputIMEI2Perangkat}
                                                                              />
                                                                              {VLD_BarangJaminan.touched.INPUTJBar_IMEI2 && VLD_BarangJaminan.errors.INPUTJBar_IMEI2 ? (
                                                                                  <FormFeedback type="invalid">{VLD_BarangJaminan.errors.INPUTJBar_IMEI2}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_JenisKatasandi">
                                                                                  <b><span className="text-danger">*</span></b>Jenis Katasandi Unit Jaminan
                                                                              </Label>
                                                                              <Select
                                                                                  id="INPUTJBar_JenisKatasandi"
                                                                                  name="INPUTJBar_JenisKatasandi"
                                                                                  placeholder="Pilih Jenis Katasandi"
                                                                                  value={selectedKatasandi}
                                                                                  onChange={(selectedKatasandi) => {
                                                                                      handleSelectKatasandi(selectedKatasandi);

                                                                                      handleFormJaminan_JenisKatasandi(selectedKatasandi.value);
                                                                                  }}
                                                                                  options={optionJaminanKatasandi}
                                                                                  className="select2-selection"
                                                                                  isDisabled={isEnableDisablePilihJenisKatasandiPerangkat}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_KatasandiHP">
                                                                                  {
                                                                                      INPUT_JenisKatasandiHP === 'Katasandi' || INPUT_JenisKatasandiHP === 'PIN' || INPUT_JenisKatasandiHP === 'Pola'
                                                                                          ?
                                                                                          <b><span className="text-danger">*</span></b>
                                                                                          :
                                                                                          null
                                                                                  }
                                                                                  PIN / Katasandi Unit Jaminan | <Link className="font-size-12" to={"#"} onClick={() => {
                                                                                      ToggleModalPengisianKatasandi();
                                                                                  }}>Petunjuk Pengisian</Link>
                                                                              </Label>
                                                                              <Modal
                                                                                  size="lg"
                                                                                  isOpen={ModalPengisianKatasandi}
                                                                                  toggle={() => {
                                                                                      ToggleModalPengisianKatasandi();
                                                                                  }}
                                                                                  centered
                                                                              >
                                                                                  <div className="modal-header">
                                                                                      <h5 className="modal-title mt-0">INFORMASI PETUNJUK</h5>
                                                                                      <button
                                                                                          type="button"
                                                                                          onClick={() => {
                                                                                              setModalPengisianKatasandi(false);
                                                                                          }}
                                                                                          className="close"
                                                                                          data-dismiss="modal"
                                                                                          aria-label="Close"
                                                                                      >
                                                                                          <span aria-hidden="true">&times;</span>
                                                                                      </button>
                                                                                  </div>
                                                                                  <div className="modal-body">
                                                                                      <p>
                                                                                          <b className="mb-2">#INFORMASI PETUNJUK PENGISIAN KATASANDI</b>
                                                                                      </p>
                                                                                      <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                                                                                      <ul style={{ listStyle: 'outside' }}>
                                                                                          <li>Pilihan Jenis Keamanan <b>Tidak Ada :</b> Secara otomatis diisi kosong oleh sistem.</li>
                                                                                          <li>Pilihan Jenis Keamanan <b>PIN :</b> Umumnya hanya berisikan ANGKA</li>
                                                                                          <li>Pilihan Jenis Keamanan <b>Katasandi :</b> Umumnya hanya berisikan kombinasi antara lain HURUF,ANGKA, dan KARAKTER SPESIAL.</li>
                                                                                          <li>Pilihan Jenis Keamanan <b>Pola :</b> (Lihat Gambar)
                                                                                              <img
                                                                                                  src={PatternLockIMG}
                                                                                                  className="img-fluid rounded"
                                                                                                  style={{ marginLeft: '24px' }}
                                                                                                  width={120}
                                                                                                  height={'auto'}
                                                                                              />
                                                                                          </li>
                                                                                      </ul>

                                                                                      <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                                  </div>
                                                                              </Modal>

                                                                              <Input
                                                                                  type="text"
                                                                                  className="form-control"
                                                                                  id="INPUTJBar_KatasandiHP"
                                                                                  name="INPUTJBar_KatasandiHP"
                                                                                  placeholder="Masukan PIN / Katasandi / Pola Unit Jaminan . . ."
                                                                                  disabled={isEnableFieldKatasandi}
                                                                                  onChange={(evt) => {
                                                                                      handleFormJaminan_KatasandiHP(evt.target.value);
                                                                                  }}
                                                                                  autoComplete="off"
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col lg="12">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTJBar_CatatanBarang">
                                                                                  Catatan Barang Jaminan
                                                                              </Label>
                                                                              <textarea
                                                                                  id="INPUTJBar_CatatanBarang"
                                                                                  name="INPUTJBar_CatatanBarang"
                                                                                  className="form-control"
                                                                                  rows="4"
                                                                                  maxLength={255}
                                                                                  placeholder="Masukan Catatan barang jaminan . . ."
                                                                                  autoComplete="off"
                                                                                  onChange={(evt) => {
                                                                                      handleFormJaminan_CatatanJaminan(evt.target.value);
                                                                                  }}
                                                                                  disabled={isEnableDisableInputCatatanPerangkat}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                              </Form>
                                                          </div>
                                                      </TabPane>

                                                      <TabPane tabId={3}>
                                                          <div>
                                                              <Form>
                                                                  <Row>
                                                                      <Col xl={12}>
                                                                          <div >
                                                                              <Alert className="alert-info" role="alert">
                                                                                  <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i>  INFORMASI</h5>
                                                                                  <p>Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.</p>
                                                                                  <p><b>Waktu Tempo</b> yang otomatis diisi oleh sistem adalah <b>31 Hari</b>, silahkan lakukan penyesuaian apabila ingin tambahan waktu sesuai kesepakatan.</p>

                                                                                  <p><b className="text-danger">#PENTING</b> <br /><b><span className="text-danger">*</span></b>Nominal Gadai tidak boleh melebihi nilai dari Harga Taksiran.</p>
                                                                              </Alert>
                                                                          </div>
                                                                      </Col>
                                                                      {
                                                                          isLimitHargaTaksiran
                                                                              ?
                                                                              <Col xl={12}>
                                                                                  <div >
                                                                                      <Alert className="alert-danger" role="alert">
                                                                                          <h5 className="alert-heading"><i className="fas fa-exclamation-triangle me-2"></i> OOPS!</h5>
                                                                                          <p><b className="text-danger">#PENTING!</b> <br /><b><span className="text-danger">*</span></b>Nominal Gadai tidak dapat melebihi nilai dari Harga Taksiran Maksimal.</p>
                                                                                      </Alert>
                                                                                  </div>
                                                                              </Col>
                                                                              :
                                                                              null
                                                                      }

                                                                      {
                                                                          isRekeningExists
                                                                              ?
                                                                              <Col xl={12}>
                                                                                  <div >
                                                                                      <Alert className="alert-warning" role="alert">
                                                                                          <h5 className="alert-heading"><i className="fas fa-exclamation-triangle me-2"></i> REKENING SUDAH ADA!</h5>
                                                                                          <p>Rekening yang anda pilih sudah terdatar di sistem, silahkan pilih <b>Rekening Terdaftar</b>. ~Terimakasih :)</p>
                                                                                      </Alert>
                                                                                  </div>
                                                                              </Col>
                                                                              :
                                                                              null
                                                                      }
                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_HargaTaksiran">
                                                                                  Nilai Akhir Maksimal Taksiran Barang
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_HargaTaksiran"
                                                                                  id="INPUTRTrx_HargaTaksiran"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  onChange={(evt) => {

                                                                                  }}
                                                                                  value={CurrencyFormatter.format(isHargaTaksiran)}
                                                                                  autoComplete={"off"}
                                                                                  disabled={true}
                                                                              />
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_PersentaseTotalHargaTaksiran">
                                                                                  Persentase Nilai Penuruan Barang
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_PersentaseTotalHargaTaksiran"
                                                                                  id="INPUTRTrx_PersentaseTotalHargaTaksiran"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  onChange={(evt) => {

                                                                                  }}
                                                                                  value={(INPUT_TotalPersentaseTaksiran * 100).toFixed(0) + "%"}
                                                                                  autoComplete={"off"}
                                                                                  disabled={true}
                                                                              />
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="4">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_PersentaseJasTip">
                                                                                  Persentase Layanan Jasa Titip
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_PersentaseJasTip"
                                                                                  id="INPUTRTrx_PersentaseJasTip"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  onChange={(evt) => {

                                                                                  }}
                                                                                  value={INPUT_PercentJasaTitip.toFixed(0) + "%"}
                                                                                  autoComplete={"off"}
                                                                                  disabled={true}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_TipeRekening">
                                                                                  <b><span className="text-danger">*</span></b>Tipe Rekening Nasabah
                                                                              </Label>
                                                                              <Row>
                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-success">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTRTrx_TipeRekeningExists"
                                                                                              name="INPUTRTrx_TipeRekeningExists"
                                                                                              checked={RekTRXExists}
                                                                                              disabled={INPUT_KategoriPelanggan === "NEWCust" ? true : INPUT_KategoriPelanggan !== "OLDCust" ? true : false}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormRincianTRX_TipeRekCustomer("RekExists")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormRincianTRX_TipeRekCustomer("")
                                                                                                  }

                                                                                                  setRekTRXExists(!RekTRXExists)
                                                                                                  setRekTRXNew(false)

                                                                                                  setselectedNamaRekening("");
                                                                                                  setselectedNoRekening("");

                                                                                                  handleChangeRekTRXPelanggan(RekTRXExists);

                                                                                                  //handleFormRincianTRX_TipeRekCustomer("RekExists");
                                                                                              }}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label"
                                                                                              htmlFor="INPUTRTrx_TipeRekeningExists"
                                                                                          >
                                                                                              Rekening Terdaftar
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>

                                                                                  <Col lg="6">
                                                                                      <div className="form-check form-checkbox form-check-info">
                                                                                          <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              id="INPUTRTrx_TipeRekeningNew"
                                                                                              name="INPUTRTrx_TipeRekeningNew"
                                                                                              checked={RekTRXNew}
                                                                                              disabled={INPUT_KategoriPelanggan !== "" ? false : true}
                                                                                              onChange={(evt) => {
                                                                                                  if (evt.target.checked) {
                                                                                                      handleFormRincianTRX_TipeRekCustomer("RekNew")
                                                                                                  }
                                                                                                  else {
                                                                                                      handleFormRincianTRX_TipeRekCustomer("")
                                                                                                  }

                                                                                                  setRekTRXExists(false)
                                                                                                  setRekTRXNew(!RekTRXNew)

                                                                                                  setselectedNamaRekening("");
                                                                                                  setselectedNoRekening("");

                                                                                                  handleChangeRekTRXPelanggan(RekTRXNew);

                                                                                                  //handleFormRincianTRX_TipeRekCustomer("RekNew");
                                                                                              }}
                                                                                          />
                                                                                          <label
                                                                                              className="form-check-label"
                                                                                              htmlFor="INPUTRTrx_TipeRekeningNew"
                                                                                          >
                                                                                              Tambah Rekening Baru
                                                                                          </label>
                                                                                      </div>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                      </Col>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_MetodePencairan">
                                                                                  <b><span className="text-danger">*</span></b>Metode Pencairan
                                                                              </Label>
                                                                              <Select
                                                                                  placeholder="Pilih Metode Pencairan"
                                                                                  value={selectedRincianMetode}
                                                                                  onChange={(selectedRincianMetode) => {
                                                                                      handleSelectRincianMetode(selectedRincianMetode);

                                                                                      handleFormRincianTRX_MetodePencairan(selectedRincianMetode.label);
                                                                                  }}
                                                                                  options={optionRincianMetode}
                                                                                  className="select2-selection"
                                                                                  isDisabled={isEnableDisableSelectRincianMetode}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_NominalGadai">
                                                                                  <b><span className="text-danger">*</span></b>Nominal Gadai (Rp)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_NominalGadai"
                                                                                  id="INPUTRTrx_NominalGadai"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Masukan nominal gadai . . ."
                                                                                  onChange={(evt) => {
                                                                                      if (!isNaN(evt.target.value)) {
                                                                                          handleFormRincianTRX_NominalGadai(evt.target.value);
                                                                                      }
                                                                                      else {
                                                                                          handleFormRincianTRX_NominalGadai(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                      }

                                                                                      VLD_RincianTRX.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_RincianTRX.handleBlur}
                                                                                  value={INPUT_NominalGadaiFormat || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_RincianTRX.touched.INPUTRTrx_NominalGadai && VLD_RincianTRX.errors.INPUTRTrx_NominalGadai ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableNominalGadai}
                                                                              />
                                                                              {VLD_RincianTRX.touched.INPUTRTrx_NominalGadai && VLD_RincianTRX.errors.INPUTRTrx_NominalGadai ? (
                                                                                  <FormFeedback type="invalid">{VLD_RincianTRX.errors.INPUTRTrx_NominalGadai}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_BankeWallet">
                                                                                  <b><span className="text-danger">*</span></b>Nama Bank / <i>eWallet</i> Nasabah
                                                                              </Label>
                                                                              <Select
                                                                                  id="INPUTRTrx_BankeWallet"
                                                                                  name="INPUTRTrx_BankeWallet"
                                                                                  placeholder="Cari Data Bank / eWallet. . ."
                                                                                  value={selectedCariDataBank}
                                                                                  onMenuOpen={(e) => {
                                                                                      handleFeedCariDataBankEWallet();
                                                                                  }}
                                                                                  onChange={(selectedCariDataBank) => {
                                                                                      handleCariDataBankEWallet(selectedCariDataBank);

                                                                                      handleFormRincianTRX_NamaBank(selectedCariDataBank.label);
                                                                                      handleFormRincianTRX_SandiBank(selectedCariDataBank.value);
                                                                                  }}
                                                                                  options={optionCariDataBank}
                                                                                  className="select2-selection"
                                                                                  isLoading={isLoadingCariDataBank}
                                                                                  isDisabled={isEnableDisableCariDataBankEWallet}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_BiayaPerpanjang">
                                                                                  <b><span className="text-danger">*</span></b>Biaya Perpanjang (Rp)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_BiayaPerpanjang"
                                                                                  id="INPUTRTrx_BiayaPerpanjang"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Masukan biaya perpanjang . . ."
                                                                                  onChange={(evt) => {
                                                                                      if (!isNaN(evt.target.value)) {
                                                                                          handleFormRincianTRX_BiayaPerpanjang(evt.target.value);
                                                                                      }
                                                                                      else {
                                                                                          handleFormRincianTRX_BiayaPerpanjang(evt.target.value.replace(/[^0-9]/g, ""));
                                                                                      }

                                                                                      VLD_RincianTRX.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_RincianTRX.handleBlur}
                                                                                  value={INPUT_BiayaPerpanjangFormat || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_RincianTRX.touched.INPUTRTrx_BiayaPerpanjang && VLD_RincianTRX.errors.INPUTRTrx_BiayaPerpanjang ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableBiayaPerpanjang}
                                                                              />
                                                                              {VLD_RincianTRX.touched.INPUTRTrx_BiayaPerpanjang && VLD_RincianTRX.errors.INPUTRTrx_BiayaPerpanjang ? (
                                                                                  <FormFeedback type="invalid">{VLD_RincianTRX.errors.INPUTRTrx_BiayaPerpanjang}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_NominalTebus">
                                                                                  <b><span className="text-danger">*</span></b>Nominal Tebus (Rp)
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_NominalTebus"
                                                                                  id="INPUTRTrx_NominalTebus"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  placeholder="Estimasi nominal tebus gadai . . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormRincianTRX_NominalTebus(evt.target.value);

                                                                                      VLD_RincianTRX.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_RincianTRX.handleBlur}
                                                                                  value={VLD_RincianTRX.values.INPUTRTrx_NominalTebus || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_RincianTRX.touched.INPUTRTrx_NominalTebus && VLD_RincianTRX.errors.INPUTRTrx_NominalTebus ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableNominalTebus}
                                                                              />
                                                                              {VLD_RincianTRX.touched.INPUTRTrx_NominalTebus && VLD_RincianTRX.errors.INPUTRTrx_NominalTebus ? (
                                                                                  <FormFeedback type="invalid">{VLD_RincianTRX.errors.INPUTRTrx_NominalTebus}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_NamaRek">
                                                                                  <b><span className="text-danger">*</span></b>Nama Pemilik Rekening / Penerima Dana
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_NamaRek"
                                                                                  id="INPUTRTrx_NamaRek"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  maxLength={64}
                                                                                  placeholder="Masukan nama penerima / pemilik rekening . . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormRincianTRX_NamaRek(evt.target.value);

                                                                                      VLD_RincianTRX.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_RincianTRX.handleBlur}
                                                                                  value={VLD_RincianTRX.values.INPUTRTrx_NamaRek || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_RincianTRX.touched.INPUTRTrx_NamaRek && VLD_RincianTRX.errors.INPUTRTrx_NamaRek ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableNamaRek}
                                                                              />
                                                                              {VLD_RincianTRX.touched.INPUTRTrx_NamaRek && VLD_RincianTRX.errors.INPUTRTrx_NamaRek ? (
                                                                                  <FormFeedback type="invalid">{VLD_RincianTRX.errors.INPUTRTrx_NamaRek}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_NoRek">
                                                                                  <b><span className="text-danger">*</span></b>No. Rekening / No. <i>Virtual Account </i> (VA) Nasabah
                                                                              </Label>
                                                                              <Input
                                                                                  name="INPUTRTrx_NoRek"
                                                                                  id="INPUTRTrx_NoRek"
                                                                                  className="form-control"
                                                                                  type="text"
                                                                                  maxLength={32}
                                                                                  placeholder="Masukan nomor rekening / nomor virtual account . . ."
                                                                                  onChange={(evt) => {
                                                                                      handleFormRincianTRX_NoRek(evt.target.value);

                                                                                      VLD_RincianTRX.handleChange(evt);
                                                                                  }}
                                                                                  onBlur={VLD_RincianTRX.handleBlur}
                                                                                  value={VLD_RincianTRX.values.INPUTRTrx_NoRek || ""}
                                                                                  autoComplete={"off"}
                                                                                  invalid={
                                                                                      VLD_RincianTRX.touched.INPUTRTrx_NoRek && VLD_RincianTRX.errors.INPUTRTrx_NoRek ? true : false
                                                                                  }
                                                                                  disabled={isEnableDisableNoRek}
                                                                              />
                                                                              {VLD_RincianTRX.touched.INPUTRTrx_NoRek && VLD_RincianTRX.errors.INPUTRTrx_NoRek ? (
                                                                                  <FormFeedback type="invalid">{VLD_RincianTRX.errors.INPUTRTrx_NoRek}</FormFeedback>
                                                                              ) : null}
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_GadaiTGLFrom">
                                                                                  <b><span className="text-danger">*</span></b>Tanggal & Waktu Transaksi
                                                                              </Label>
                                                                              <InputGroup>
                                                                                  <Flatpickr
                                                                                      className="form-control d-block"
                                                                                      placeholder="Pilih Tanggal Transaksi"
                                                                                      options={{
                                                                                          minDate: "today",
                                                                                          altInput: true,
                                                                                          altFormat: "j F Y, " + moment().format("HH:mm:ss"),
                                                                                          dateFormat: "Y-m-d",
                                                                                          maxDate: "today",
                                                                                          //defaultDate: "today",
                                                                                          locale: Indonesian,
                                                                                          time_24hr: true,
                                                                                          onChange: (selectedDates, dateStr, instance) => handleFormRincianTRX_FromTanggal(dateStr + moment().format(" HH:mm:ss"))
                                                                                      }}
                                                                                  />
                                                                              </InputGroup>
                                                                          </div>
                                                                      </Col>

                                                                      <Col lg="6">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_GadaiTGLTo">
                                                                                  <b><span className="text-danger">*</span></b>Tanggal & Waktu  Jatuh Tempo
                                                                              </Label>
                                                                              <InputGroup>
                                                                                  <Flatpickr
                                                                                      className="form-control d-block"
                                                                                      placeholder="Pilih Tanggal Jatuh Tempo"
                                                                                      options={{
                                                                                          minDate: new Date().fp_incr(31),
                                                                                          altInput: true,
                                                                                          altFormat: "j F Y, 23:59:00",
                                                                                          dateFormat: "Y-m-d",
                                                                                          maxDate: new Date().fp_incr(31),
                                                                                          //defaultDate: new Date().fp_incr(31),
                                                                                          locale: Indonesian,
                                                                                          time_24hr: true,
                                                                                          onChange: (selectedDates, dateStr, instance) => handleFormRincianTRX_ToTanggal(dateStr + " 23:59:00")
                                                                                      }}
                                                                                  />
                                                                              </InputGroup>
                                                                          </div>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="12">
                                                                          <div className="mb-3">
                                                                              <Label for="INPUTRTrx_CatatanTransaksi">
                                                                                  Catatan Transaksi Gadai
                                                                              </Label>
                                                                              <textarea
                                                                                  id="INPUTRTrx_CatatanTransaksi"
                                                                                  name="INPUTRTrx_CatatanTransaksi"
                                                                                  className="form-control"
                                                                                  rows="4"
                                                                                  placeholder="Masukan Catatan transaksi . . ."
                                                                                  autoComplete="off"
                                                                                  disabled={isEnableDisableCatatanTRX}
                                                                                  onChange={(evt) => {
                                                                                      handleFormRincianTRX_CatatanTransaksi(evt.target.value);
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                              </Form>
                                                          </div>
                                                      </TabPane>
                                                      <TabPane tabId={4}>
                                                          <div className=" mb-4">
                                                              <Form encType="multipart/form-data" onSubmit={(e) => {
                                                                  e.preventDefault();
                                                                  Validation_ReviewForm.handleSubmit();
                                                                  return false;
                                                              }}>
                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_KategoriPelanggan"
                                                                      name="Review_KategoriPelanggan"
                                                                      value={INPUT_KategoriPelanggan}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_idPelanggan"
                                                                      name="Review_idPelanggan"
                                                                      value={INPUT_idPelanggan}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_SandiBank"
                                                                      name="Review_SandiBank"
                                                                      value={INPUT_SandiBank}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_TipeBank"
                                                                      name="Review_TipeBank"
                                                                      value={INPUT_TipeBank}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_TipeRekening"
                                                                      name="Review_TipeRekening"
                                                                      value={INPUT_TipeRekening}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_KodeCabang"
                                                                      name="Review_KodeCabang"
                                                                      value={opKodeCabang}
                                                                      autoComplete="off"
                                                                  />

                                                                  <Input
                                                                      type="hidden"
                                                                      className="form-control"
                                                                      id="Review_UUID_Operator"
                                                                      name="Review_UUID_Operator"
                                                                      value={opUUID}
                                                                      autoComplete="off"
                                                                  />
                                                                  <Col xl={12}>
                                                                      <div >
                                                                          <Alert className="alert-info" role="alert">
                                                                              <h5 className="alert-heading"><i className="fas fa-info-circle me-2"></i> INFORMASI</h5>
                                                                              <p># Bidang yang memiliki tanda bintang merah <b><span className="text-danger">*</span></b> adalah bidang yang wajib diisi atau tidak boleh kosong.
                                                                                  <br />
                                                                                  # Bidang yang memiliki tanda bintang merah <b><span className="text-danger">**</span></b> adalah bidang yang wajib diisi pada halaman Tinjauan Transaksi.
                                                                                  <br />
                                                                                  <br />
                                                                                  <b>PENTING!</b>
                                                                                  <br />
                                                                                  Sebelum membuat kontrak, mohon perhatikan ulang bahwa data sudah benar, karena apabila kontrak sudah dibuat, maka data yang dimasukkan tidak dapat diubah kembali. ~Terimakasih.
                                                                              </p>
                                                                          </Alert>
                                                                      </div>
                                                                  </Col>
                                                                  <Row>
                                                                      <Col xl={12}>
                                                                          {/*TINJAUAN DOKUMEN*/}
                                                                          <Card className="rounded">
                                                                              <CardBody>
                                                                                  <CardTitle className="text-center">
                                                                                      <h4>TINJAUAN DOKUMEN</h4>
                                                                                  </CardTitle>
                                                                                  <hr className="pb-4" style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                                  <Row>
                                                                                      <Col md={6}>
                                                                                          <h5 className="text-center pb-2"><b><span className="text-danger">**</span></b><u>Foto Tanda Pengenal</u></h5>
                                                                                          {isFilePathCaptureKTP ? (
                                                                                              <Lightbox
                                                                                                  mainSrc={selectedFile}
                                                                                                  enableZoom={true}
                                                                                                  imageCaption={
                                                                                                      "Foto Tanda Pengenal"
                                                                                                  }
                                                                                                  onCloseRequest={() => {
                                                                                                      isSetFilePathCaptureKTP(false);
                                                                                                  }}
                                                                                              />
                                                                                          )
                                                                                              : null}
                                                                                          <div className="dropzone-previews mt-3" id="file-previews">
                                                                                              <div className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                                                                                                  <img
                                                                                                      src={selectedFile}
                                                                                                      alt=""
                                                                                                      width="320px"
                                                                                                      height="180px"
                                                                                                      id="expandedImg1"
                                                                                                      className="img-fluid mx-auto d-block rounded"
                                                                                                      onClick={() => {
                                                                                                          isSetFilePathCaptureKTP(true);
                                                                                                      }}
                                                                                                      style={{ maxHeight: "180px" }}
                                                                                                  />
                                                                                                  {/*{
                                                                                              INPUT_KategoriPelanggan !== "OLDCust"
                                                                                                  ?
                                                                                                  <div className="text-center pt-2">
                                                                                                      <Button
                                                                                                          className="btn-sm btn-info"
                                                                                                          to="#"
                                                                                                          onClick={() => {
                                                                                                              isShowModalFotoKTPWebCam();
                                                                                                          }}
                                                                                                      >
                                                                                                          Ambil Foto Tanda Pengenal
                                                                                                      </Button>
                                                                                                  </div>
                                                                                                  :
                                                                                                  null
                                                                                          }*/}

                                                                                                  <div className="text-center pt-2">
                                                                                                      <Button
                                                                                                          className="btn-sm btn-info fw-bold"
                                                                                                          to="#"
                                                                                                          disabled={EnableDisableBTNTakeFotoWebCam}
                                                                                                          onClick={() => {
                                                                                                              isShowModalFotoKTPWebCam();
                                                                                                          }}
                                                                                                      >
                                                                                                          {selectedFile !== null ? "Update Foto Tanda Pengenal" : "Ambil Foto Tanda Pengenal"}
                                                                                                      </Button>
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>

                                                                                      </Col>

                                                                                      <Col md={6}>
                                                                                          <h5 className="text-center pb-2"><b><span className="text-danger">**</span></b><u>Foto Kelengkapan Barang</u></h5>
                                                                                          {isFilePathCaptureKelengkapan ? (
                                                                                              <Lightbox
                                                                                                  mainSrc={selectedFileKelengkapan}
                                                                                                  enableZoom={true}
                                                                                                  imageCaption={
                                                                                                      "Foto Kelengkapan Barang"
                                                                                                  }
                                                                                                  onCloseRequest={() => {
                                                                                                      isSetFilePathCaptureKelengkapan(false);
                                                                                                  }}
                                                                                              />
                                                                                          )
                                                                                              : null}
                                                                                          <div className="dropzone-previews mt-3" id="file-previews">
                                                                                              <div className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                                                                                                  <img
                                                                                                      src={selectedFileKelengkapan}
                                                                                                      alt=""
                                                                                                      width="320px"
                                                                                                      height="180px"
                                                                                                      id="expandedImg2"
                                                                                                      className="img-fluid mx-auto d-block rounded"
                                                                                                      onClick={() => {
                                                                                                          isSetFilePathCaptureKelengkapan(true);
                                                                                                      }}
                                                                                                      style={{ maxHeight: "180px" }}
                                                                                                  />

                                                                                                  <div className="text-center pt-2">
                                                                                                      <Button
                                                                                                          className="btn-sm btn-success fw-bold"
                                                                                                          to="#"
                                                                                                          disabled={EnableDisableBTNTakeFotoWebCam}
                                                                                                          onClick={() => {
                                                                                                              isShowModalFotoKelengkapanWebCam();
                                                                                                          }}
                                                                                                      >
                                                                                                          Ambil Foto Kelengkapan
                                                                                                      </Button>
                                                                                                  </div>

                                                                                                  {Validation_ReviewForm.touched.Review_FotoKelengkapan && Validation_ReviewForm.errors.Review_FotoKelengkapan ? (
                                                                                                      <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_FotoKelengkapan}</FormFeedback>
                                                                                                  ) : null}

                                                                                              </div>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>

                                                                      <Col xl={12}>
                                                                          {/*TINJAUAN DATA NASABAH*/}
                                                                          <Card className="rounded">
                                                                              <CardBody>
                                                                                  <CardTitle className="text-center">
                                                                                      <h4>TINJAUAN DATA NASABAH</h4>
                                                                                  </CardTitle>
                                                                                  <hr className="pb-4" style={{ borderTop: "1px solid #DDE6F4" }} />

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating sm mb-3">
                                                                                              <Input
                                                                                                  name="Review_NamaLengkap"
                                                                                                  id="Review_NamaLengkap"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nama Lengkap Sesuai di Tanda Pengenal . . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NamaLengkap || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NamaLengkap && Validation_ReviewForm.errors.Review_NamaLengkap ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {
                                                                                                  Validation_ReviewForm.touched.Review_NamaLengkap && Validation_ReviewForm.errors.Review_NamaLengkap ? (
                                                                                                      <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NamaLengkap}</FormFeedback>
                                                                                                  ) : null
                                                                                              }
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_NamaLengkap" for="Review_NamaLengkap">
                                                                                                  Nama Lengkap Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NamaPanggilan"
                                                                                                  id="Review_NamaPanggilan"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nama Panggilan . . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NamaPanggilan || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NamaPanggilan && Validation_ReviewForm.errors.Review_NamaPanggilan ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NamaPanggilan && Validation_ReviewForm.errors.Review_NamaPanggilan ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NamaPanggilan}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_NamaPanggilan" for="Review_NamaPanggilan">
                                                                                                  Nama Panggilan Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NIK"
                                                                                                  id="Review_NIK"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="number"
                                                                                                  placeholder="Review NIK / Nomor SIM . . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NIK || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NIK && Validation_ReviewForm.errors.Review_NIK ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NIK && Validation_ReviewForm.errors.Review_NIK ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NIK}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_NIK" for="Review_NIK">
                                                                                                  Nomor Induk Kependudukan / No. SIM
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NoHP"
                                                                                                  id="Review_NoHP"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="number"
                                                                                                  placeholder="Review Nomor Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NoHP || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NoHP && Validation_ReviewForm.errors.Review_NoHP ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NoHP && Validation_ReviewForm.errors.Review_NoHP ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NoHP}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_NoHP" for="Review_NoHP">
                                                                                                  Telepon / WhatsApp
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg={12}>
                                                                                          <div className="form-floating mb-3">
                                                                                              <textarea
                                                                                                  id="Review_Alamat"
                                                                                                  name="Review_Alamat"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  rows="3"
                                                                                                  placeholder="Review Alamat Lengkap sesuai Tanda Pengenal . . ."
                                                                                                  autoComplete="off"
                                                                                                  value={Validation_ReviewForm.values.Review_Alamat || ""}
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_Alamat && Validation_ReviewForm.errors.Review_Alamat ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_Alamat}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_Alamat" for="Review_Alamat">
                                                                                                  Alamat Lengkap
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>

                                                                      <Col xl={12}>
                                                                          {/*TINJAUAN DATA BARANG JAMINAN*/}
                                                                          <Card className="rounded">
                                                                              <CardBody>
                                                                                  <CardTitle className="text-center">
                                                                                      <h4>TINJAUAN DATA BARANG JAMINAN</h4>
                                                                                  </CardTitle>
                                                                                  <hr className="pb-4" style={{ borderTop: "1px solid #DDE6F4" }} />
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  type="text"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  id="Review_JenisBarang"
                                                                                                  name="Review_JenisBarang"
                                                                                                  value="SmartPhone"
                                                                                                  disabled
                                                                                                  autoComplete="off"
                                                                                              />
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_JenisBarang" for="Review_JenisBarang">
                                                                                                  Jenis Barang
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_MerkHP"
                                                                                                  id="Review_MerkHP"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Merk Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_MerkHP || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_MerkHP && Validation_ReviewForm.errors.Review_MerkHP ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_MerkHP && Validation_ReviewForm.errors.Review_MerkHP ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_MerkHP}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_MerkHP" for="Review_MerkHP">
                                                                                                  Merk Handphone
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_TipeHP"
                                                                                                  id="Review_TipeHP"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Tipe Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_TipeHP || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_TipeHP && Validation_ReviewForm.errors.Review_TipeHP ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_TipeHP && Validation_ReviewForm.errors.Review_TipeHP ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_TipeHP}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_TipeHP" for="Review_TipeHP">
                                                                                                  Tipe Handphone
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NilaiTaksiranAwal"
                                                                                                  id="Review_NilaiTaksiranAwal"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Taksiran Awal Nilai Maksimal Barang. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NilaiTaksiranAwal || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NilaiTaksiranAwal && Validation_ReviewForm.errors.Review_NilaiTaksiranAwal ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NilaiTaksiranAwal && Validation_ReviewForm.errors.Review_NilaiTaksiranAwal ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NilaiTaksiranAwal}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_NilaiTaksiranAwal" for="Review_NilaiTaksiranAwal">
                                                                                                  Nilai Awal Taksiran Maksimal Barang (Rp)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_KondisiFisik"
                                                                                                  id="Review_KondisiFisik"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Kondisi Fisik Perangkat. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_KondisiFisik || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_KondisiFisik && Validation_ReviewForm.errors.Review_KondisiFisik ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_KondisiFisik && Validation_ReviewForm.errors.Review_KondisiFisik ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_KondisiFisik}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_KondisiFisik" for="Review_KondisiFisik">
                                                                                                  Informasi Fisik Perangkat
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_FungsiPerangkat"
                                                                                                  id="Review_FungsiPerangkat"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Fungsi Perangkat. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_FungsiPerangkat || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_FungsiPerangkat && Validation_ReviewForm.errors.Review_FungsiPerangkat ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_FungsiPerangkat && Validation_ReviewForm.errors.Review_FungsiPerangkat ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_FungsiPerangkat}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_FungsiPerangkat" for="Review_FungsiPerangkat">
                                                                                                  Informasi Fungsi Perangkat
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_IndikasiServices"
                                                                                                  id="Review_IndikasiServices"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Indikasi Services-an. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_IndikasiServices || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_IndikasiServices && Validation_ReviewForm.errors.Review_IndikasiServices ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_IndikasiServices && Validation_ReviewForm.errors.Review_IndikasiServices ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_IndikasiServices}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_IndikasiServices" for="Review_IndikasiServices">
                                                                                                  Indikasi Barang Pernah Services
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_StatusIMEI"
                                                                                                  id="Review_StatusIMEI"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Status IMEI Perangkat. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_StatusIMEI || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_StatusIMEI && Validation_ReviewForm.errors.Review_StatusIMEI ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_StatusIMEI && Validation_ReviewForm.errors.Review_StatusIMEI ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_StatusIMEI}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_StatusIMEI" for="Review_StatusIMEI">
                                                                                                  Informasi Status IMEI Perangkat
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_InfoCasing"
                                                                                                  id="Review_InfoCasing"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Informasi Charger. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_InfoCasing || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_InfoCasing && Validation_ReviewForm.errors.Review_InfoCasing ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_InfoCasing && Validation_ReviewForm.errors.Review_InfoCasing ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_InfoCasing}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_InfoCasing" for="Review_InfoCasing">
                                                                                                  Informasi Casing Tambahan
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_InfoCharger"
                                                                                                  id="Review_InfoCharger"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Informasi Charger. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_InfoCharger || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_InfoCharger && Validation_ReviewForm.errors.Review_InfoCharger ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_InfoCharger && Validation_ReviewForm.errors.Review_InfoCharger ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_InfoCharger}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_InfoCharger" for="Review_InfoCharger">
                                                                                                  Informasi Charger
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_RAM"
                                                                                                  id="Review_RAM"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review RAM Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_RAM || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_RAM && Validation_ReviewForm.errors.Review_RAM ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_RAM && Validation_ReviewForm.errors.Review_RAM ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_RAM}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_RAM" for="Review_RAM">
                                                                                                  <i>Random Access Memory</i> - RAM (GB)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_MemInternal"
                                                                                                  id="Review_MemInternal"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Memory Internal Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_MemInternal || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_MemInternal && Validation_ReviewForm.errors.Review_MemInternal ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_MemInternal && Validation_ReviewForm.errors.Review_MemInternal ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_MemInternal}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_MemInternal" for="Review_MemInternal">
                                                                                                  Penyimpanan Internal (GB)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_IMEI1"
                                                                                                  id="Review_IMEI1"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review IMEI_1 Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_IMEI1 || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_IMEI1 && Validation_ReviewForm.errors.Review_IMEI1 ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_IMEI1 && Validation_ReviewForm.errors.Review_IMEI1 ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_IMEI1}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_IMEI1" for="Review_IMEI1">
                                                                                                  <i>International Mobile Equipment Identity</i> (IMEI_1)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_IMEI2"
                                                                                                  id="Review_IMEI2"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review IMEI_2 Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_IMEI2 || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_IMEI2 && Validation_ReviewForm.errors.Review_IMEI2 ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_IMEI2 && Validation_ReviewForm.errors.Review_IMEI2 ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_IMEI2}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_IMEI2" for="Review_IMEI2">
                                                                                                  <i>International Mobile Equipment Identity</i> (IMEI_2)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_JenisKatasandi"
                                                                                                  id="Review_JenisKatasandi"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Jenis Katasandi Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_JenisKatasandi || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_JenisKatasandi && Validation_ReviewForm.errors.Review_JenisKatasandi ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_JenisKatasandi && Validation_ReviewForm.errors.Review_JenisKatasandi ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_JenisKatasandi}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_JenisKatasandi" for="Review_JenisKatasandi">
                                                                                                  Jenis Katasandi Unit Jaminan
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_KatasandiHP"
                                                                                                  id="Review_KatasandiHP"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Katasandi Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_KatasandiHP || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_KatasandiHP && Validation_ReviewForm.errors.Review_KatasandiHP ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_KatasandiHP && Validation_ReviewForm.errors.Review_KatasandiHP ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_KatasandiHP}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_KatasandiHP" for="Review_KatasandiHP">
                                                                                                  PIN / Katasandi Unit Jaminan
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>

                                                                                      <Col lg="12">
                                                                                          <div className="form-floating mb-3">
                                                                                              <textarea
                                                                                                  id="Review_CatatanBarangJaminan"
                                                                                                  name="Review_CatatanBarangJaminan"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  rows="3"
                                                                                                  placeholder="Review Catatan Barang Jaminan . . ."
                                                                                                  autoComplete="off"
                                                                                                  value={Validation_ReviewForm.values.Review_CatatanBarangJaminan || ""}
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_CatatanBarangJaminan && Validation_ReviewForm.errors.Review_CatatanBarangJaminan ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_CatatanBarangJaminan}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label className="col-form-label form-label" htmlFor="Review_CatatanBarangJaminan" for="Review_CatatanBarangJaminan">
                                                                                                  Catatan Barang Jaminan
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>

                                                                      <Col xl={12}>
                                                                          {/*TINJAUAN DATA TRANSAKSI*/}
                                                                          <Card className="rounded">
                                                                              <CardBody>
                                                                                  <CardTitle className="text-center">
                                                                                      <h4>TINJAUAN DATA TRANSAKSI</h4>
                                                                                  </CardTitle>
                                                                                  <hr className="pb-4" style={{ borderTop: "1px solid #DDE6F4" }} />

                                                                                  <Row>
                                                                                      <Col lg="4">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NilaiTaksiranAkhir"
                                                                                                  id="Review_NilaiTaksiranAkhir"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nilai Akhir Maksimal Taksiran Barang. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={CurrencyFormatter.format(Validation_ReviewForm.values.Review_NilaiTaksiranAkhir)|| ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NilaiTaksiranAkhir && Validation_ReviewForm.errors.Review_NilaiTaksiranAkhir ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NilaiTaksiranAkhir && Validation_ReviewForm.errors.Review_NilaiTaksiranAkhir ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NilaiTaksiranAkhir}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NilaiTaksiranAkhir" for="Review_NilaiTaksiranAkhir">
                                                                                                  Nilai Akhir Maksimal Taksiran Barang
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="4">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_PersentaseTaksiran"
                                                                                                  id="Review_PersentaseTaksiran"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Persentase Nilai Penuruan Barang. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_PersentaseTaksiran || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_PersentaseTaksiran && Validation_ReviewForm.errors.Review_PersentaseTaksiran ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_PersentaseTaksiran && Validation_ReviewForm.errors.Review_PersentaseTaksiran ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_PersentaseTaksiran}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_PersentaseTaksiran" for="Review_PersentaseTaksiran">
                                                                                                  Persentase Nilai Penuruan Barang (%)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="4">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_PersentaseJasaTitip"
                                                                                                  id="Review_PersentaseJasaTitip"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Persentase Jasa Titip. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_PersentaseJasaTitip || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_PersentaseJasaTitip && Validation_ReviewForm.errors.Review_PersentaseJasaTitip ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_PersentaseJasaTitip && Validation_ReviewForm.errors.Review_PersentaseJasaTitip ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_PersentaseJasaTitip}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_PersentaseJasaTitip" for="Review_PersentaseJasaTitip">
                                                                                                  Persentase Layanan Jasa Titip (%)
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_TipeRekNasabah"
                                                                                                  id="Review_TipeRekNasabah"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Tipe Rekening Nasabah. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_TipeRekNasabah || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_TipeRekNasabah && Validation_ReviewForm.errors.Review_TipeRekNasabah ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_TipeRekNasabah && Validation_ReviewForm.errors.Review_TipeRekNasabah ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_TipeRekNasabah}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_TipeRekNasabah" for="Review_TipeRekNasabah">
                                                                                                  Tipe Rekening Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_MetodePencairan"
                                                                                                  id="Review_MetodePencairan"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Metode Pencairan. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_MetodePencairan || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_MetodePencairan && Validation_ReviewForm.errors.Review_MetodePencairan ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_MetodePencairan && Validation_ReviewForm.errors.Review_MetodePencairan ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_MetodePencairan}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_MetodePencairan" for="Review_MetodePencairan">
                                                                                                  Metode Pencairan
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NominalGadai"
                                                                                                  id="Review_NominalGadai"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nominal Gadai. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={CurrencyFormatter.format(Validation_ReviewForm.values.Review_NominalGadai) || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NominalGadai && Validation_ReviewForm.errors.Review_NominalGadai ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NominalGadai && Validation_ReviewForm.errors.Review_NominalGadai ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NominalGadai}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NominalGadai" for="Review_NominalGadai">
                                                                                                  Nominal Gadai
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NamaBank"
                                                                                                  id="Review_NamaBank"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nama Bank Yang Digunakan Nasabah. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NamaBank || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NamaBank && Validation_ReviewForm.errors.Review_NamaBank ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NamaBank && Validation_ReviewForm.errors.Review_NamaBank ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NamaBank}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NamaBank" for="Review_NamaBank">
                                                                                                  Nama Bank / <i>eWallet</i> Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_BiayaPerpanjang"
                                                                                                  id="Review_BiayaPerpanjang"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Biaya Perpanjang. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={CurrencyFormatter.format(Validation_ReviewForm.values.Review_BiayaPerpanjang) || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_BiayaPerpanjang && Validation_ReviewForm.errors.Review_BiayaPerpanjang ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_BiayaPerpanjang && Validation_ReviewForm.errors.Review_BiayaPerpanjang ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_BiayaPerpanjang}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_BiayaPerpanjang" for="Review_BiayaPerpanjang">
                                                                                                  Biaya Perpanjang
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NominalTebus"
                                                                                                  id="Review_NominalTebus"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nominal Tebus Handphone. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={CurrencyFormatter.format(Validation_ReviewForm.values.Review_NominalTebus) || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NominalTebus && Validation_ReviewForm.errors.Review_NominalTebus ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NominalTebus && Validation_ReviewForm.errors.Review_NominalTebus ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NominalTebus}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NominalTebus" for="Review_NominalTebus">
                                                                                                  Nominal Tebus
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NamaRek"
                                                                                                  id="Review_NamaRek"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Nama Rekening Nasabah. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NamaRek || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NamaRek && Validation_ReviewForm.errors.Review_NamaRek ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NamaRek && Validation_ReviewForm.errors.Review_NamaRek ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NamaRek}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NamaRek" for="Review_NamaRek">
                                                                                                  Nama Pemilik Rekening / Penerima Dana Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_NoRek"
                                                                                                  id="Review_NoRek"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review No. Rekening Nasabah. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_NoRek || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_NoRek && Validation_ReviewForm.errors.Review_NoRek ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_NoRek && Validation_ReviewForm.errors.Review_NoRek ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_NoRek}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_NoRek" for="Review_NoRek">
                                                                                                  No. Rekening / No. <i>Virtual Account </i> (VA) Nasabah
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_TanggalFrom"
                                                                                                  id="Review_TanggalFrom"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Tanggal Awal Gadai. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_TanggalFrom || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_TanggalFrom && Validation_ReviewForm.errors.Review_TanggalFrom ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_TanggalFrom && Validation_ReviewForm.errors.Review_TanggalFrom ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_TanggalFrom}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_TanggalFrom" for="Review_TanggalFrom">
                                                                                                  Tanggal & Waktu Transaksi
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>

                                                                                      <Col lg="6">
                                                                                          <div className="form-floating mb-3">
                                                                                              <Input
                                                                                                  name="Review_TanggalTo"
                                                                                                  id="Review_TanggalTo"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  type="text"
                                                                                                  placeholder="Review Tanggal Jatuh Tempo. . ."
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  value={Validation_ReviewForm.values.Review_TanggalTo || ""}
                                                                                                  autoComplete={"off"}
                                                                                                  invalid={
                                                                                                      Validation_ReviewForm.touched.Review_TanggalTo && Validation_ReviewForm.errors.Review_TanggalTo ? true : false
                                                                                                  }
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_TanggalTo && Validation_ReviewForm.errors.Review_TanggalTo ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_TanggalTo}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_TanggalTo" for="Review_TanggalTo">
                                                                                                  Tanggal & Waktu  Jatuh Tempo
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>

                                                                                  <Row>
                                                                                      <Col lg="12">
                                                                                          <div className="form-floating mb-3">
                                                                                              <textarea
                                                                                                  id="Review_CatatanTransaksiGadai"
                                                                                                  name="Review_CatatanTransaksiGadai"
                                                                                                  className="form-control border-primary bg-primary bg-opacity-10 fw-bold"
                                                                                                  rows="3"
                                                                                                  placeholder="Review Catatan Transaksi Gadai . . ."
                                                                                                  autoComplete="off"
                                                                                                  value={Validation_ReviewForm.values.Review_CatatanTransaksiGadai || ""}
                                                                                                  onChange={Validation_ReviewForm.handleChange}
                                                                                                  onBlur={Validation_ReviewForm.handleBlur}
                                                                                                  disabled={true}
                                                                                              />
                                                                                              {Validation_ReviewForm.touched.Review_CatatanTransaksiGadai && Validation_ReviewForm.errors.Review_CatatanTransaksiGadai ? (
                                                                                                  <FormFeedback type="invalid">{Validation_ReviewForm.errors.Review_CatatanTransaksiGadai}</FormFeedback>
                                                                                              ) : null}
                                                                                              <Label htmlFor="Review_CatatanTransaksiGadai" for="Review_CatatanTransaksiGadai">
                                                                                                  Catatan Transaksi Gadai
                                                                                              </Label>
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Col>
                                                                  </Row>

                                                                  <Row>
                                                                      <Col lg="12">
                                                                          {
                                                                              trxGadaiSuccess && !updateLoading
                                                                                  ?
                                                                                  <UncontrolledAlert
                                                                                      color="success"
                                                                                      className="alert-dismissible fade show"
                                                                                      role="alert"
                                                                                  >
                                                                                      <i className="mdi mdi-check-all me-2"></i>Nomor Kontrak <b>#{isNomorKontrak}</b> atas nama Nasabah <b>{INPUT_NamaLengkap}</b> berhasil dibuat, selanjutnya mohon menunggu untuk penerbitan Nomor Kontrak hanya dapat dilakukan oleh Administrator. Beberapa saat lagi Anda akan dialihkan ke halaman Detail Kontrak.
                                                                                  </UncontrolledAlert>
                                                                                  :
                                                                                  ""
                                                                          }

                                                                          <div style={{ textAlign: 'center' }}>
                                                                              {/*<button
                                                                          className={"btn btn-success btn-block align-right"}
                                                                      type="submit"
                                                                      >
                                                                          <i className="bx bxs-analyse font-size-16 align-middle me-2"></i>
                                                                      Buat Nomor Kontrak
                                                                      </button>*/}

                                                                              <button
                                                                                  className={
                                                                                      !ReviewStepIsPassed ? "btn btn-success btn-block align-right disabled" : "btn btn-success btn-block align-right"
                                                                                  }
                                                                                  disabled={EnableDisableBTNCreateContract}
                                                                                  type="submit"
                                                                              >
                                                                                  {
                                                                                      updateLoading
                                                                                          ?
                                                                                          <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="light" /> {isLoadingTextSubmit}</>
                                                                                          :
                                                                                          <><i className="bx bxs-analyse font-size-16 align-middle me-2"></i> Buat Nomor Kontrak</>
                                                                                  }
                                                                              </button>
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                              </Form>
                                                          </div>
                                                      </TabPane>
                                                  </TabContent>
                                              </div>
                                              <div className="actions clearfix">

                                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} className="mb-4 mt-2" />

                                                  {
                                                      isFullDataPelangganUID != null ? isFullDataPelangganUID[0].Blacklist
                                                          ?
                                                          null
                                                          :
                                                          <ul>
                                                              <li
                                                                  className={
                                                                      activeTab === 1 ? "previous disabled" : "previous"
                                                                  }
                                                                  style={{
                                                                      display: activeTab === 1 ? "none" : null
                                                                  }}
                                                              >
                                                                  <Link
                                                                      className="btn btn-sm"
                                                                      to="#"
                                                                      onClick={() => {
                                                                          toggleTab(activeTab - 1)
                                                                      }}
                                                                  >
                                                                      Sebelumnya
                                                                  </Link>
                                                              </li>
                                                              <li
                                                                  className={activeTab === 1 && !Step1IsPassed || activeTab === 2 && !Step2IsPassed || activeTab === 3 && !Step3IsPassed || activeTab === 4 && Step1IsPassed
                                                                      && Step2IsPassed && Step3IsPassed
                                                                      ?
                                                                      "next disabled"
                                                                      :
                                                                      "next"}

                                                                  style={{
                                                                      display: activeTab === 4 && Step1IsPassed && Step2IsPassed && Step3IsPassed ? "none" : null
                                                                  }}
                                                              >
                                                                  <Link
                                                                      className="btn btn-sm"
                                                                      to="#"
                                                                      disabled={true}
                                                                      onClick={() => {
                                                                          activeTab === 1 && !Step1IsPassed
                                                                              ?
                                                                              toggleTab(activeTab)
                                                                              :
                                                                              activeTab === 2 && !Step2IsPassed
                                                                                  ?
                                                                                  toggleTab(activeTab)
                                                                                  :
                                                                                  activeTab === 3 && !Step3IsPassed
                                                                                      ?
                                                                                      toggleTab(activeTab)
                                                                                      :
                                                                                      toggleTab(activeTab + 1)
                                                                      }}
                                                                  >
                                                                      Selanjutnya
                                                                  </Link>
                                                              </li>
                                                          </ul>
                                                          :
                                                          <ul>
                                                              <li
                                                                  className={
                                                                      activeTab === 1 ? "previous disabled" : "previous"
                                                                  }
                                                                  style={{
                                                                      display: activeTab === 1 ? "none" : null
                                                                  }}
                                                              >
                                                                  <Link
                                                                      className="btn btn-sm"
                                                                      to="#"
                                                                      onClick={() => {
                                                                          toggleTab(activeTab - 1)
                                                                      }}
                                                                  >
                                                                      Sebelumnya
                                                                  </Link>
                                                              </li>
                                                              <li
                                                                  className={activeTab === 1 && !Step1IsPassed || activeTab === 2 && !Step2IsPassed || activeTab === 3 && !Step3IsPassed || activeTab === 4 && Step1IsPassed
                                                                      && Step2IsPassed && Step3IsPassed
                                                                      ?
                                                                      "next disabled"
                                                                      :
                                                                      "next"}

                                                                  style={{
                                                                      display: activeTab === 4 && Step1IsPassed && Step2IsPassed && Step3IsPassed ? "none" : null
                                                                  }}
                                                              >
                                                                  <Link
                                                                      className="btn btn-sm"
                                                                      to="#"
                                                                      disabled={true}
                                                                      onClick={() => {
                                                                          activeTab === 1 && !Step1IsPassed
                                                                              ?
                                                                              toggleTab(activeTab)
                                                                              :
                                                                              activeTab === 2 && !Step2IsPassed
                                                                                  ?
                                                                                  toggleTab(activeTab)
                                                                                  :
                                                                                  activeTab === 3 && !Step3IsPassed
                                                                                      ?
                                                                                      toggleTab(activeTab)
                                                                                      :
                                                                                      toggleTab(activeTab + 1)
                                                                      }}
                                                                  >
                                                                      Selanjutnya
                                                                  </Link>
                                                              </li>
                                                          </ul>
                                                  }
                                              </div>
                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }

                  

          </Container>
          </div>
    </React.Fragment>
  );
};

NewTRXPawnshopHandphone.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(NewTRXPawnshopHandphone);
