import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    Alert,
    Modal,
    Input,
    Form,
    Collapse,
    Label,
    Badge,
    Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableContainer from '../../../../components/Common/TableContainer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select";
import jwt_decode from "jwt-decode";
import classnames from "classnames";

import {
    fetchTableReportPawnshopPhoneDeleted,
} from "../../../../helpers/backend_helper";

import {
    FormatNoKontrak,
    FormatTipeASBarang,
    FormatNamaCabang,
    FormatNamaOperator,
    FormatTerhapusKontrak,
} from "./isMasterTableCol";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import 'animate.css';

let MasterDataTable = [{}];
let isUUIDOperator = null;
let isValopKodeCabang = null;
let MasterSummaryReport = null;

const ManagementReportPawnshopDeleted = props => {
    moment.tz.setDefault('Asia/Jakarta');

    function showToastNotification(isTitle, isMessage, isType) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1500",
            "timeOut": "3000",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "slideDown",
            "hideMethod": "slideUp"
        }

        //info | error | success | warning
        if (isType === 'success')
            toastr.success(isMessage, isTitle);
        else if (isType === 'info')
            toastr.info(isMessage, isTitle);
        else if (isType === 'warning')
            toastr.warning(isMessage, isTitle);
        else
            toastr.error(isMessage, isTitle);
    }

    const [isShowInformasi, setisShowInformasi] = useState(false);

    const handleClickisShowInformasi = () => {
        setisShowInformasi(!isShowInformasi);
    };

    const dispatch = useDispatch();
    
    const [isDataLoading, setisDataLoading] = useState(false);

    async function FeedFunctionDataForTableValidasiTransaksi(WhatsFiltered) {

        setisDataValidsasiTransaksi(null);
        setisDataLoading(true);

        setTimeout(async () => {
            try {
                MasterDataTable = await fetchTableReportPawnshopPhoneDeleted({ LimitData: 1000, MethodSort: 'ASC', FilterDate: WhatsFiltered, isUIDAccount: isUUIDOperator, KodeCabang: isValopKodeCabang });
                //console.log()
                if (MasterDataTable[0] != undefined) {
                    setisDataValidsasiTransaksi(MasterDataTable);
                }
                else {
                    MasterDataTable = null;
                    setisDataValidsasiTransaksi(null);
                }

                showToastNotification('Success!', 'Berhasil menampilkan data yang ingin anda lihat!', 'success');

                setisDataLoading(false);
            }
            catch (Err) {
                setisDataLoading(false);
                showToastNotification('Oops!', 'Sepertinya ada sesuatu yang salah!', 'error');

            }
        }, 500);
    }

    const [isDataValidsasiTransaksi, setisDataValidsasiTransaksi] = useState(null);

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }
    }

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    useEffect(() => {
        autoUpdateProfile();

        FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

        /*setInterval(async () => {
            {
                await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");
            }
        }, 10000); //Refresh data setiap 10s*/

        setInterval(async () => {
            {
                await autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s


    }, [dispatch]);

    const isColumnsData = useMemo(
        () => [
            {
                Header: "No. Kontrak",
                accessor: "PGD_NoKontrak",
                filterable: true,
                disableFilters: true,
                selector: 'no_kontrak',
                sortable: true,
                Cell: cellProps => {
                    return <FormatNoKontrak {...cellProps} />;
                    //return datanya.name;
                },
            },
            {
                Header: "NIK / SIM",
                //accessor: "updatedAt", //pake ini untuk live
                accessor: "isCustomer.NIKPelanggan",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Nasabah",
                accessor: "isCustomer.NamaLengkap",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "Jaminan",
                accessor: "PGDJaminan_Tipe",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatTipeASBarang {...cellProps} />;
                },
            },
            {
                Header: "St. Kontrak",
                accessor: "PGD_DeleteStatus",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    /*return ((
                        cellProps.cell.row.allCells[0].value
                            ?
                            <Badge className="bg-danger font-size-11">Kontrak Gagal</Badge>
                            :
                            <FormatPelunasan {...cellProps} />
                    ))*/

                    return <FormatTerhapusKontrak {...cellProps} />;
                },
            },
            /*{
                Header: "St. Barang",
                accessor: "PGD_StatusBarang",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatStatusBarang {...cellProps} />;
                },
            },*/
            {
                Header: "Cabang",
                accessor: "isCabang.CabangName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaCabang {...cellProps} />;
                },
            },
            {
                Header: "Operator",
                accessor: "isOperator.fullName",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <FormatNamaOperator {...cellProps} />;
                },
            },
            {
                Header: "Aksi",
                disableFilters: true,
                accessor: "dbID",
                Cell: cellProps => {
                    return (
                        cellProps.value
                            ?
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                    <Link
                                        to={"/apps/pawnshop/details/handphone/" + cellProps.cell.row.allCells[0].value}
                                        className="btn btn-sm btn-info"
                                        onClick={() => {

                                        }}
                                    >
                                        <i className="mdi mdi-eye-outline" id="detailtooltip" />
                                        <UncontrolledTooltip placement="top" target="detailtooltip">
                                            Detil Kontrak
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                            :
                            cellProps.value == null
                                ?
                                "-"
                                :
                                <span className="placeholder-glow">
                                    <Link
                                        to="#"
                                        className="text-body fw-bold text-center placeholder col-8">NaN
                                    </Link>
                                </span>
                    );
                },
            },
        ],
        []
    );
    
    //meta title
    document.title = "Laporan Data Pegadaian Terhapus - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Laporan Data Pegadaian Terhapus")}
                  />

                  {/*<Col xl="12">
                      <Row>
                          */}{/* Reports Render */}{/*
                          {summaryReports.map((report, key) => (
                              <Col md="4" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-black fw-medium placeholder-glow"
                                                                      : "text-black fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-black fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>*/}

                  {
                      isDataLoading
                          ?
                          <Col lg={12} className={"mt-5 pb-5 text-center"}>
                              <Spinner className="" style={{ height: "48px", width: "48px" }} color="primary" />
                          </Col>
                          :
                          <Row>
                              <Col lg="12">
                                  <Card>
                                      <CardBody>
                                          <Row>
                                              {/*ACCORDION INFORMASI*/}
                                              <Col xl={12} className={"pb-4"}>
                                                  <div
                                                      className="accordion"
                                                      id="showInformasiTab"
                                                  >
                                                      <div className="accordion-item ">
                                                          <h2 className={classnames(
                                                              "accordion-header",
                                                              /*{ "bg-light": !isShowInformasi },*/

                                                          )} id="showInformasiTabHeading">
                                                              <button
                                                                  className={classnames(
                                                                      "accordion-button",
                                                                      "fw-medium",
                                                                      { collapsed: !isShowInformasi },
                                                                      { "text-white": !isShowInformasi },
                                                                      { "text-white": isShowInformasi },
                                                                      /*{ "text-danger bg-light": isShowInformasi },*/
                                                                  )}
                                                                  type="button"
                                                                  onClick={handleClickisShowInformasi}
                                                                  style={{ cursor: "pointer", backgroundColor: '#101218' }}
                                                              >
                                                                  <i className="text-muted fw-bold mdi mdi-information-outline me-2"></i> {!isShowInformasi ? "TAMPILKAN PETUNJUK INFORMASI" : "SEMBUNYIKAN PETUNJUK INFORMASI"}
                                                              </button>
                                                          </h2>

                                                          <Collapse
                                                              isOpen={isShowInformasi}
                                                              className="accordion-collapse"
                                                          >
                                                              <div className="accordion-body">
                                                                  <div className="text-black">
                                                                      <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                                      <br />
                                                                      <p>#Penjelesan Fungsi Tombol <b>Aksi</b> : </p>
                                                                      <ul style={{ listStyle: 'none' }}>
                                                                          <li className="pb-3">
                                                                              <Link
                                                                                  to="#"
                                                                                  className="btn btn-sm btn-info font-size-14"
                                                                                  onClick={() => {
                                                                                      return false;
                                                                                  }}
                                                                              >
                                                                                  <i className="mdi mdi-eye-outline" id="detailtooltipINFO" />
                                                                                  <UncontrolledTooltip placement="top" target="detailtooltipINFO">
                                                                                      Detil Kontrak
                                                                                  </UncontrolledTooltip>
                                                                              </Link> Tombol Detil Kontrak
                                                                              <ul style={{ listStyle: 'inside' }}>
                                                                                  <li><b>#FUNGSI</b> : Berfungsi untuk melihat detil kontak atau melihat isi data kontrak secara keseluruhan.</li>
                                                                              </ul>
                                                                          </li>
                                                                      </ul>
                                                                      <p>~Terimakasih ^.^</p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                  </div>
                                                  {/*<div >
                                              <Alert className="alert-info" role="alert">
                                                  <h5 className="alert-heading text-primary pb-2"><i className="mdi mdi-information-outline me-2"></i> INFORMASI</h5>
                                                  <p>Pada halaman <b>Validasi Transaksi</b> ini, berguna untuk menampilkan data transaksi gadai, khusus yang belum di <b><i>Validasi</i></b> oleh Admin. Silahkan gunakan fitur Filter data ataupun fitur pencarian pada kolom yang dibawah ini.</p>
                                                  <p>~Terimakasih ^.^</p>
                                              </Alert>
                                          </div>*/}
                                              </Col>

                                              <Col lg={12} className="">
                                                  {/*<Col sm={3}>
                                              <Select
                                                  id="INPUTFilterDateTRX"
                                                  name="INPUTFilterDateTRX"
                                                  placeholder="Filter Waktu Transaksi"
                                                  value={selectedFilterDateData}
                                                  onChange={(selectedFilterDateData) => {
                                                      handleSelectedFilterDateData(selectedFilterDateData);
                                                  }}
                                                  options={optionFilterDateData}
                                                  className="select2-selection"
                                                  isDisabled={null}
                                              />
                                          </Col>*/}
                                                  <div className="d-flex flex-wrap gap-2 text-center me-2 float-end">
                                                      <button
                                                          type="button"
                                                          className="btn btn-soft-primary  btn-label me-2 fw-bold"
                                                          onClick={async (evt) => {
                                                              setisDataLoading(true);
                                                              await FeedFunctionDataForTableValidasiTransaksi("isTRXAll");

                                                          }}
                                                      >
                                                          <i className="bx bx-sync label-icon"></i>Perbaharui Data
                                                      </button>
                                                  </div>
                                              </Col>
                                              {
                                                  isDataValidsasiTransaksi != null && isDataValidsasiTransaksi.length > 0
                                                      ?
                                                      <Col lg={12} className={"mt-1"}>
                                                          <TableContainer
                                                              className={"table table-hover rounded"}
                                                              columns={isColumnsData}
                                                              data={isDataValidsasiTransaksi || [{}]}
                                                              isGlobalFilter={true}
                                                              isAddOptions={false}
                                                              isPlaceholderData={"Cari data pegadaian. . ."}
                                                              customPageSize={50}
                                                              isSorted={"desc"}
                                                              isLoading={true}
                                                          />
                                                      </Col>
                                                      :
                                                      <Col lg={12} className="mt-5 pb-5 text-center">
                                                          <CardTitle className="pb-1">------ TIDAK ADA DATA ------</CardTitle>
                                                      </Col>
                                              }
                                          </Row>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
                  }
                  
          </Container>
          </div>
    </React.Fragment>
  );
};

ManagementReportPawnshopDeleted.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ManagementReportPawnshopDeleted);
