import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Table,
    Label,
    Input,
    Modal,
    Form,
    UncontrolledTooltip,
    Spinner,
} from "reactstrap";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link, useParams } from "react-router-dom";

//Import Image
import approved from "../../../../assets/images/approved.png";
import lunas from "../../../../assets/images/lunas.png";
import rejected from "../../../../assets/images/rejected.png";
import failed from "../../../../assets/images/failed.png";

import {
    fetchDetailContract,
} from "../../../../helpers/backend_helper";
import moment from 'moment';
import 'moment/locale/id'  // without this line it didn't work
//import puppeteer from 'puppeteer';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { saveAs } from 'file-saver';
var FileSaver = require('file-saver');
var download = require('downloadjs');

let isMasterDataContract = null;
//let isContractNumber = null;

const ImageContract = props => {

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aToken, setAToken] = useState("");
    const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    const [isNomorKontrak, setisNomorKontrak] = useState(null);
    const paramsID = useParams();

    const dispatch = useDispatch();

    const [isFullDataContract, setisFullDataContract] = useState(null);
    const [isContractNumber, setisContractNumber] = useState(null);
    const [isStateLoadingDownloadIMG, setisStateLoadingDownloadIMG] = useState(false);


    async function DownloadIMGInvoice(imgName) {
        /*htmlToImage.toCanvas(document.getElementById('imgPrintContract'))
            .then(function (canvas) {
                *//*canvas.width = 1024;
        canvas.height = 640;
        document.body.appendChild(canvas);*//*

        window.saveAs(canvas, imgName + '.png');
    });*/

        /*var node = document.getElementById('imgPrintContract');
        htmlToImage.toPng(node, { width: 1024, height: 480 })
            .then(function (dataUrl) {

                var img = new Image();
                img.src = dataUrl;
                //document.body.appendChild(img);

                window.saveAs(dataUrl, imgName + '.png');
                //console.log(dataUrl)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });*/

        /*htmlToImage.toPng(document.getElementById('imgPrintContract'), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = imgName + '.jpeg';
                link.href = dataUrl;
                link.click();
            });*/
        setisStateLoadingDownloadIMG(true);

        await htmlToImage.toJpeg(document.getElementById('imgPrintContract'), { width: 1024, height: 520, quality: 0.8, cacheBust: true, backgroundColor: '#ffffff' })
            .then(async function (dataUrl) {
                //await download(dataUrl, imgName + "_" + Date.now() + '.jpeg');
                await download(dataUrl, imgName + '.jpeg');
                //console.log(dataUrl);
                setisStateLoadingDownloadIMG(false);
            });


        /*htmlToImage.toBlob(document.getElementById('imgPrintContract'))
            .then(function (blob) {
                if (window.saveAs) {
                    window.saveAs(blob, imgName + '.png');
                } else {
                    FileSaver.saveAs(blob, imgName + '.png');
                }
            });*/
    }

    async function FeedFunctionFullDataContract(isValNoKontrak) {

        isMasterDataContract = null;

        if (isValNoKontrak != null) {
            try {
                isMasterDataContract = await fetchDetailContract({ ContractNumber: isValNoKontrak });

                if (isMasterDataContract === null) {
                    return window.location.href = '/apps/dashboard';
                }
                else {
                    setisFullDataContract(isMasterDataContract);
                }

            }
            catch (Err) {
                return window.location.href = '/apps/dashboard';
            }
        }
    }

    useEffect(() => {
        FeedFunctionFullDataContract(paramsID.ContractNumber);

        setisNomorKontrak(paramsID.ContractNumber);

        autoUpdateProfile();

        setTimeout(async () => {
            {
                setisContractNumber(paramsID.ContractNumber);
            }
        }, 100); //Refresh data setiap 10s

        setInterval(async () => {
            {
                autoUpdateProfile();
            }
        }, 1000); //Refresh data setiap 10s

    }, [dispatch]);

    /*const queryGETParameters = new URLSearchParams(window.location.search)
    const type = queryGETParameters.get("type")*/

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            //isUUIDOperator = DecodeUserData.uuid;
        }
        catch (Err) {
            //Nothing
        }
    }

    //meta title
    document.title = "INV #" + paramsID.ContractNumber + " - " + process.env.REACT_APP_NAME;

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid id="imgPrintContract" style={{ /*width: '1024px', height: '500px', overflow: 'hidden'*/ }}>
                    {isFullDataContract != null ? isFullDataContract.PGD_Lunas
                        ?
                        <img src={lunas} alt="stempel-lunas" className="" height="296px" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', opacity: 0.5 }} />
                        :
                        isFullDataContract.PGD_isRejected
                            ?
                            <img src={rejected} alt="stempel-rejected" className="" height="296px" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', opacity: 0.5 }} />
                            :
                            isFullDataContract.PGD_KontrakFailed
                                ?
                                <img src={failed} alt="stempel-failed" className="" height="296px" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', opacity: 0.5 }} />
                                :
                                <img src={approved} alt="stempel-approved" className="" height="296px" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', /*marginTop: '15%', marginLeft: '35%',*/ opacity: 0.5 }} />
                        :
                        null
                    }

                    <Row className="font-size-11 text-black">
                        <Col lg={12} className="p-2 text-black">
                            <Card className="bg-transparent m-0" style={{ boxShadow: 'none' }}>
                                <CardBody className="">
                                    <div className="invoice-title">
                                        <h4 className="float-end fw-bold font-size-12">
                                            <span className="fw-bold">#{isContractNumber || "NaN"}</span>
                                        </h4>
                                        <div className="p-0 m-0">
                                            <h5 className="p-0 m-0 fw-bold">ARKA STORE {isFullDataContract != null ? " " + isFullDataContract.isCabang.CabangName : null}</h5>
                                        </div>
                                        <div className="">
                                            <span>{isFullDataContract != null ? isFullDataContract.isCabang.CabangAlamat + ", " + isFullDataContract.isCabang.CabangArea + " (" + isFullDataContract.isCabang.CabangNoHP + ")" : "Please wait..."}</span>
                                        </div>
                                    </div>
                                    <hr className="p-0 m-0 mt-2 mb-2" style={{ borderTop: "1px solid #4F4F4F" }} />

                                    <Row>
                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    <span>Nama Pelanggan</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span>{isFullDataContract != null ? isFullDataContract.isCustomer.NamaLengkap : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    {isFullDataContract != null
                                                        ?
                                                        isFullDataContract.PGD_Lunas
                                                            ?
                                                            <span>Tgl. Pelunasan</span>
                                                            :
                                                            <span>Tgl. Transaksi</span>
                                                        :
                                                        <><span>Tgl. Transaksi</span> <span className="d-print-none"> Terakhir</span></>
                                                    }
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span className="fw-bold font-size-12">{isFullDataContract != null ? isFullDataContract.PGD_Lunas ? moment(isFullDataContract.PGD_TanggalPelunasan).locale("id").tz('Asia/Jakarta').format('D MMMM YYYY') : moment(isFullDataContract.PGD_TanggalTerakhirTRX || isFullDataContract.PGDRincian_WaktuTRX).locale("id").tz('Asia/Jakarta').format('D MMMM YYYY') : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    <span>No. Telepon</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span>{isFullDataContract != null ? isFullDataContract.isCustomer.NoHP : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    <span>Tgl. Jt. Tempo</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span className="fw-bold font-size-12">{isFullDataContract != null ? isFullDataContract.PGD_Lunas || isFullDataContract.PGD_KontrakFailed ? "-" : moment(isFullDataContract.PGDRincian_JTempoTRX).locale("id").tz('Asia/Jakarta').format('D MMMM YYYY') : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    <span>Cabang</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span>{isFullDataContract != null ? isFullDataContract.isCabang.CabangName : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={6} className={"m-0"}>
                                            <Row>
                                                <Col sm={5}>
                                                    <span>Operator</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <span>:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <span>{isFullDataContract != null ? isFullDataContract.isOperator.fullName : "Please wait..."}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr className="p-0 m-0 mt-2 mb-2" style={{ borderTop: "1px solid #4F4F4F" }} />


                                    <Row>
                                        <Col md={12}>
                                            <div className="p-0 mb-2">
                                                <span className="font-size-14 fw-bold"><u>RINCIAN TRANSAKSI</u></span>
                                            </div>
                                            <div className="table-responsive">
                                                <Table className="table-nowrap table-bordered text-black">
                                                    <thead>
                                                        <tr style={{ borderColor: '#4F4F4F' }}>
                                                            <th className="font-size-11 p-1 text-center font-size-12">Nama Perangkat</th>
                                                            <th className="font-size-11 p-1 text-center font-size-12">R A M</th>
                                                            <th className="font-size-11 p-1 text-center font-size-12">R O M</th>
                                                            <th className="font-size-11 p-1 text-center font-size-12">IMEI</th>
                                                            <th className="font-size-11 p-1 text-center font-size-12">Charger</th>
                                                            <th className="font-size-11 p-1 text-center font-size-12">Case Eks.</th>
                                                            <th className="font-size-11 p-1 text-end font-size-12">Harga</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ borderColor: '#4F4F4F' }}>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGDJaminan_Tipe : "Please wait..."}</td>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGDJaminan_RAM + " GB" : "Please wait..."}</td>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGDJaminan_Internal + " GB" : "Please wait..."}</td>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGDJaminan_IMEI1 : "Please wait..."}</td>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCharger : "Please wait..."}</td>
                                                            <td className="font-size-11 p-1 text-center">{isFullDataContract != null ? isFullDataContract.PGD_KelengkapanCasing : "Please wait..."}</td>
                                                            <td className="font-size-11 text-end p-1">{isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal.toFixed(0)) : "Please wait..."}</td>
                                                        </tr>

                                                        <tr style={{ borderColor: 'transparent' }} className="p-0">
                                                            <td className="col-sm-3" style={{ position: 'absolute', borderColor: 'transparent' }}>
                                                                <Row>
                                                                    <Col sm="3" className="font-size-11">
                                                                        <address>
                                                                            <span>Keterangan :</span>
                                                                            <ul className="font-size-12 text-justify" style={{ listStyle: 'outside' }}>
                                                                                <li className="font-size-11">
                                                                                    Tempo 31 Hari, tidak terima alasan apapun <br /> mengenai keterlambatan pembayaran.
                                                                                </li>
                                                                                <li className="font-size-11">
                                                                                    Gadai itu ada aturannya, jangan pura-pura lupa, <br /> tidak tau, tidak baca , dll.
                                                                                </li>
                                                                                <li className="font-size-11">
                                                                                    Harap dipahami sistemnya, dan lakukan kewajiban <br /> bayar tepat waktu.
                                                                                </li>
                                                                                <li className="font-size-11">
                                                                                    Jangan salahkan siapapun jika ada konsekuensi <br /> lelang / jual jaminan akibat kelalaian pembayaran<br /> yang tidak sesuai aturan.
                                                                                </li>
                                                                                <li className="font-size-11">
                                                                                    Menandatangani nota gadai, berarti Anda <br />menyetujui segala aturan yang ada.
                                                                                </li>
                                                                            </ul>
                                                                        </address>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderColor: 'transparent' }} className="p-0">
                                                            <td colSpan="6" className="font-size-11 text-end p-1">
                                                                <span className="fw-bold">Sub Total : </span>
                                                                <br />
                                                                <span className="fw-bold">Biaya Jasa Titip : </span>

                                                            </td>
                                                            <td className="font-size-11 text-end p-1">
                                                                {isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGDRincian_Nominal.toFixed(0)) : "Please wait..."}
                                                                <br />
                                                                {isFullDataContract != null ? CurrencyFormatter.format((isFullDataContract.PGDRincian_Nominal) * (isFullDataContract.PGD_PercentBiaya / 100)) : "Rp. -"}
                                                                <hr className="mt-1" style={{ borderTop: "1px solid #4F4F4F" }} />

                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderColor: 'transparent' }} className="p-0">
                                                            <td colSpan="6" className="font-size-11 text-end p-1">
                                                                <span className="fw-bold">Total Harga : </span>
                                                            </td>
                                                            <td className="font-size-12 text-end p-1 fw-bold">
                                                                {isFullDataContract != null ? CurrencyFormatter.format(isFullDataContract.PGD_NominalTebus) : "Rp. -"}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>

                                    {/*<hr className="mt-3" style={{ borderTop: "1px solid #DDE6F4" }} />*/}

                                    <Row>
                                        <Col sm="6" className="">

                                        </Col>
                                        <Col sm="3" className="mt-3 text-sm-center">
                                            <div style={{ paddingBottom: '30px' }}>
                                                <span className="font-size-11">Pelanggan,</span>
                                            </div>
                                            <br />
                                            <div>
                                                <span className="font-size-11">( {isFullDataContract != null ? isFullDataContract.isCustomer.NamaLengkap : "NaN"} )</span>
                                            </div>
                                        </Col>
                                        <Col sm="3" className="mt-3 text-sm-center">
                                            <div style={{ paddingBottom: '30px' }}>
                                                <span className="font-size-11">Hormat Kami,</span>
                                            </div>
                                            <br />
                                            <div>
                                                <span className="font-size-11">( {isFullDataContract != null ? isFullDataContract.isOperator.fullName : "NaN"} )</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Row className={"pb-4 d-print-none"}>
                    <Col lg={12}>
                        <div className="d-print-none mt-5" id="printInvoice">
                            <div className="float-end">
                                <Link
                                    to="#"
                                    onClick={printInvoice}
                                    className="btn btn-success  me-2"
                                >
                                    <i className="fa fa-print me-2" /> Cetak Invoice
                                </Link>
                            </div>
                        </div>
                        <div className="d-print-none mt-5" id="downloadGambar">
                            <div className="float-end">
                                <Button
                                    type="button"
                                    className="btn btn-soft-primary me-2"
                                    onClick={async (evt) => {
                                        await DownloadIMGInvoice(isFullDataContract != null ? "INV_" + isFullDataContract.isCustomer.NamaLengkap + "_" + paramsID.ContractNumber : "INV_" + paramsID.ContractNumber);
                                    }}
                                    disabled={isStateLoadingDownloadIMG ? true : false}
                                >
                                    {
                                        isStateLoadingDownloadIMG
                                            ?
                                            <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0 me-2" color="light" /> Mengunduh Gambar . . .</>
                                            :
                                            <><i className="fas fa-cloud-download-alt me-2" /> Unduh Gambar</>
                                    }

                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

ImageContract.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ImageContract);
