import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import jwt_decode from "jwt-decode";
import axios from "axios";

// users
import userProfilePict from "../../../assets/images/users/default.png";
import userProfilePictTroll from "../../../assets/images/users/default_troll.png";

import { fetchGetAuthStatus, fetchRefreshToken } from "helpers/backend_helper.js";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [fullName, setfullName] = useState("");
    const [avatar, setAvatar] = useState("");
  const [aToken, setAToken] = useState("");
  const [rToken, setRToken] = useState("");
    const [userName, setuserName] = useState("");
    const [role, setrole] = useState(null);

    const [isAuth, setisAuth] = useState("");

    const refreshToken = async () => {

        //localStorage.removeItem("authUser");

        try {
            const response = await fetchRefreshToken();

            localStorage.setItem("authToken", JSON.stringify(response.accessToken));

            setAToken(response.accessToken);

            const aDecoded = jwt_decode(response.accessToken);

            setfirstName(aDecoded.firstName);
            setlastName(aDecoded.lastName);
            setfullName(aDecoded.fullName);
            setuserName(aDecoded.username);
            setAvatar(aDecoded.avatar);
            setrole(aDecoded.role);
        }
        catch (Err) {
            //console.log("Err-refreshToken : " + Err);
        }
    }

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setAvatar(DecodeUserData.avatar);
            setrole(DecodeUserData.role);
        }
        catch (Err) {
        }
    }

    const imagePath = (path) => {
        return `../../../assets/${path}`
    }

    useEffect(() => {
        refreshToken();

        setInterval(async () => {
            {
                /*let responseStatus = await fetchGetAuthStatus();
                setisAuth(responseStatus);*/

                //console.log(responseStatus);

                refreshToken();
            }
        }, 1800000); //Refresh token setiap 15s

        setInterval(() => {
            {
                autoUpdateProfile();
            }
        }, 1000); //Refresh token setiap 15s

  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
                      className="rounded-circle header-profile-user"
                      src={userName == "system" ? userProfilePictTroll : userProfilePict}
            alt="Header Avatar"
          />
                  <span className="d-none d-xl-inline-block ms-2 me-1">{fullName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
                  {/*<DropdownItem tag="a" href="/apps/account/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profil Saya")}{" "}
          </DropdownItem>*/}
                  <DropdownItem tag="a" href="/apps/account/settings">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Pengaturan")}
                  </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/auth/sign-out" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Keluar")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
