import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
const StatisticsPurchasing = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch]);
    /*const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch]);*/

    //meta title
    document.title = "Statistik Pembelian " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Statistik Pembelian")}
                  />


          </Container>
          </div>
    </React.Fragment>
  );
};

StatisticsPurchasing.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(StatisticsPurchasing);
