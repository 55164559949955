import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormFeedback,
    Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Indonesian } from "flatpickr/dist/l10n/id.js"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
    TXA_STATISTIK_fetchNominalCashflowStatistik,
    TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER,
} from "../../../helpers/backend_helper";
import jwt_decode from "jwt-decode";
import moment from "moment";

let isUUIDOperator = null;
let isValopKodeCabang = null;

const StatisticsPawnshop = props => {
    const [opUUID, setopUUID] = useState("");
    const [opKodeCabang, setopKodeCabang] = useState("");

    const autoUpdateProfile = async () => {
        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setopUUID(DecodeUserData.uuid);
            setopKodeCabang(DecodeUserData.user_cabang);

            isUUIDOperator = DecodeUserData.uuid;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            //Nothing
        }

    }

    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch]);

    const [isResultCountingArusKAS, setisResultCountingArusKAS] = useState(null);

    async function FeedFunctionArusKASKeseluruhan(isUIDAccount) {

        setTimeout(async () => {
            try {

                let isResultDataCashFlow = await TXA_STATISTIK_fetchNominalCashflowStatistik({ isUIDAccount: isUIDAccount });
                setisResultCountingArusKAS(isResultDataCashFlow);
            }
            catch (Err) {

            }
        }, 200);
    }

    async function FeedFunctionArusKASFilterCoy(isUIDAccount, isDateFrom, isDateTo) {

        setisResultCountingArusKAS(null);

        setTimeout(async () => {
            try {

                let isResultDataCashFlow = await TXA_STATISTIK_fetchNominalCashflowStatistik_FILTER({ isUIDAccount: isUIDAccount, isDateFrom: isDateFrom, isDateTo: isDateTo });
                setisResultCountingArusKAS(isResultDataCashFlow);
            }
            catch (Err) {

            }
        }, 200);
    }

    useEffect(() => {
        autoUpdateProfile();

        setInterval(async () => {
            autoUpdateProfile();
        }, 500);

        setTimeout(async () => {
            try {

                await FeedFunctionArusKASKeseluruhan(isUUIDOperator);
            }
            catch (Err) {

            }
        }, 200);
    }, []);

    const CurrencyFormatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    let summaryReportsNominal = [
        {
            title: "Arus KAS Kontrak Aktif - Keseluruhan",
            iconClass: "far fa-check-circle",
            bgColor: "primary",
            textColor: "text-white",
            description: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalBerjalan.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalBerjalan.Total) : "Rp. -" : "NaN",
            value: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalBerjalan.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalBerjalan.Total) : "Rp. -" : "NaN",
            value_color: "text-primary",
        },
        {
            title: "Arus KAS Kontrak Lunas - Keseluruhan",
            iconClass: "far fa-check-circle",
            bgColor: "info",
            textColor: "text-white",
            description: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value_color: "text-info",
        },
        {
            title: "Estimasi Keuntungan Perpanjang",
            iconClass: "far fa-check-circle",
            bgColor: "black",
            textColor: "text-white",
            description: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value_color: "text-black",
        },
        {
            title: "Estimasi Keuntungan Pelunasan",
            iconClass: "far fa-check-circle",
            bgColor: "black",
            textColor: "text-white",
            description: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalLunas.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalLunas.Total) : "Rp. -" : "NaN",
            value_color: "text-black",
        },
    ];

    let summaryReportsEstProfit = [
        {
            title: "Estimasi Keuntungan Global",
            iconClass: "bx bxs-rocket",
            bgColor: "success",
            textColor: "text-white",
            description: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalPerpanjang.Total !== null || isResultCountingArusKAS.TotalNominalPelunasan.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalPerpanjang.Total + (isResultCountingArusKAS.TotalNominalTebusGadai.Total - isResultCountingArusKAS.TotalNominalLunas.Total)) : "Rp. -" : "NaN",
            value: isResultCountingArusKAS !== null ? isResultCountingArusKAS.TotalNominalPerpanjang.Total !== null || isResultCountingArusKAS.TotalNominalPelunasan.Total !== null ? CurrencyFormatter.format(isResultCountingArusKAS.TotalNominalPerpanjang.Total + (isResultCountingArusKAS.TotalNominalTebusGadai.Total - isResultCountingArusKAS.TotalNominalLunas.Total)) : "Rp. -" : "NaN",
            value_color: "text-success",
        },
    ];

    let summaryReports = [
        {
            title: "Total Kontrak Aktif",
            iconClass: "far fa-check-circle",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-primary",
        },
        {
            title: "Total Kontrak Pending",
            iconClass: "bx bxs-hourglass-top",
            bgColor: "warning",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-warning",
        },
        {
            title: "Total Kontrak Berjalan",
            iconClass: "far fa-check-circle",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-success",
        },
        {
            title: "Total Kontrak Ja.Po",
            iconClass: "mdi mdi-file-document-outline",
            bgColor: "warning",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-warning",
        },
        {
            title: "Total Kontrak Lunas",
            iconClass: "fas fa-money-check-alt",
            bgColor: "success",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-success",
        },
        {
            title: "Total Kontrak Gagal",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-danger",
        },
        {
            title: "Total Kontrak Ditolak",
            iconClass: "far fa-times-circle",
            bgColor: "danger",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-danger",
        },
        {
            title: "Total Keseluruhan Profit",
            iconClass: "bx bxs-rocket",
            bgColor: "primary",
            textColor: "text-white",
            description: "-",
            value: 0,
            value_color: "text-primary",
        },
    ];

    const [FilterManualTglFromVAL, setFilterManualTglFromVAL] = useState(null);
    const [FilterManualTglToVAL, setFilterManualTglToVAL] = useState(null);

    const VLDFilterManual = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            FilterManualTglFrom: FilterManualTglFromVAL || undefined,
            FilterManualTglTo: FilterManualTglToVAL || undefined,
            isUIDAccount: isUUIDOperator || undefined,
        },
        validationSchema: Yup.object({
            FilterManualTglFrom: Yup.date().required("Tanggal DARI wajib diisi tidak boleh kosong!"),
            FilterManualTglTo: Yup.date().required("Tanggal SAMPAI wajib diisi tidak boleh kosong!"),
        }),
        onSubmit: async (values) => {
            await FeedFunctionArusKASFilterCoy(values.isUIDAccount, values.FilterManualTglFrom, values.FilterManualTglTo);
        }
    });

    //meta title
    document.title = "Statistik Pegadaian " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Statistik Pegadaian")}
                  />

                  {/*<Row>
                      <Col xl={12}>
                          <Card>
                              <CardBody className="bg-light">
                                  <CardTitle>
                                      Ringkasan Data Kontrak
                                  </CardTitle>
                                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />
                                  
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>*/}

                  <Col lg={12} className="">
                          <Card>
                              <CardBody>
                              <Row>
                                  <Col md={3}>
                                      <div className="mb-2 mt-2">
                                          <h4 className="card-title col-form-label">Filter Data Berdasarkan Tanggal ~ </h4>
                                      </div>
                                  </Col>
                                  <Col md={9}>
                                      <Form onSubmit={(e) => {
                                          e.preventDefault();
                                          VLDFilterManual.handleSubmit();
                                          return false;
                                      }}>
                                          <Row>
                                              <Col sm={5}>
                                                  <div className="mb-2 mt-2">
                                                      <Flatpickr
                                                          name="FilterManualTglFrom"
                                                          id="FilterManualTglFrom"
                                                          className={`form-control ${VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? "is-invalid" : ""
                                                              }`}
                                                          placeholder="Pilih Tanggal Dari"
                                                          options={{
                                                              altInput: true,
                                                              altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                              dateFormat: "Y-m-d",
                                                              locale: Indonesian,
                                                              disableMobile: "true",
                                                              defaultDate: null,
                                                              time_24hr: true,
                                                              maxDate: 'today',
                                                              onChange: (selectedDates, dateStr, instance) => setFilterManualTglFromVAL(dateStr + " 00:00" /*+ moment().format(" 00:00:00")*/),
                                                          }}
                                                          value={VLDFilterManual.values.FilterManualTglFrom || ""}
                                                      />
                                                      {
                                                          VLDFilterManual.touched.FilterManualTglFrom && VLDFilterManual.errors.FilterManualTglFrom ? (
                                                              <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglFrom}</FormFeedback>
                                                          ) : null
                                                      }
                                                  </div>
                                              </Col>
                                              <Col sm={5}>
                                                  <div className="mb-2 mt-2">
                                                      <Flatpickr
                                                          name="FilterManualTglTo"
                                                          id="FilterManualTglTo"
                                                          className={`form-control ${VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? "is-invalid" : ""
                                                              }`}
                                                          placeholder="Pilih Tanggal Sampai"
                                                          options={{
                                                              altInput: true,
                                                              altFormat: "j F Y",// + moment().format("HH:mm:ss"),
                                                              dateFormat: "Y-m-d",
                                                              locale: Indonesian,
                                                              time_24hr: true,
                                                              disableMobile: "true",
                                                              defaultDate: null,
                                                              minDate: FilterManualTglFromVAL,
                                                              maxDate: 'today',
                                                              onChange: (selectedDates, dateStr, instance) => setFilterManualTglToVAL(dateStr + " 23:59"),
                                                          }}
                                                          value={VLDFilterManual.values.FilterManualTglTo || ""}
                                                      />
                                                      {
                                                          VLDFilterManual.touched.FilterManualTglTo && VLDFilterManual.errors.FilterManualTglTo ? (
                                                              <FormFeedback type="invalid">{VLDFilterManual.errors.FilterManualTglTo}</FormFeedback>
                                                          ) : null
                                                      }
                                                  </div>
                                              </Col>
                                              <Col sm={2}>
                                                  <div className="mb-2 mt-2">
                                                      <Button color="btn btn-square btn-primary w-md me-2" disabled={false} type="submit"
                                                          style={({ width: "100%", cursor: "pointer", fontSize: 12 })}
                                                      >
                                                          <i className="mdi mdi-filter" style={{ marginRight: '5px' }}></i> Filter Data
                                                      </Button>
                                                  </div>
                                              </Col>
                                          </Row>
                                      </Form>
                                  </Col>
                              </Row>
                              </CardBody>
                          </Card>
                  </Col>

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReportsNominal.map((report, key) => (
                              <Col md="6" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-info fw-medium placeholder-glow"
                                                                      : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReportsEstProfit.map((report, key) => (
                              <Col md="12" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-info fw-medium placeholder-glow"
                                                                      : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

                  <hr style={{ borderTop: "1px solid #DDE6F4" }} />

                  <Col xl="12">
                      <Row>
                          {/* Reports Render */}
                          {summaryReports.map((report, key) => (
                              <Col md="3" key={"_col_" + key}>
                                  <Card className="mini-stats-wid">
                                      <CardBody>
                                          <div className="d-flex">
                                              <div className="flex-grow-1">
                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <p className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "text-info fw-medium placeholder-glow"
                                                                      : "text-info fw-medium"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="text-info fw-medium placeholder col-8">report.title</span>
                                                                      : report.title
                                                              }
                                                          </p>
                                                          :
                                                          <p className="text-black fw-medium">
                                                              {report.title}
                                                          </p>
                                                  }

                                                  {
                                                      report.description == "NaN"
                                                          ?
                                                          <span className=
                                                              {
                                                                  report.description == "NaN"
                                                                      ? "mb-0 placeholder-glow text-info"
                                                                      : "mb-0"
                                                              }
                                                          >
                                                              {
                                                                  report.description == "NaN"
                                                                      ? <span className="mb-0 placeholder col-6 text-info">report.description</span>
                                                                      : report.description
                                                              }
                                                          </span>
                                                          :
                                                          <>
                                                              <h5 className={"mb-0 fw-bold " + report.value_color}>{report.value}</h5>
                                                          </>
                                                  }

                                              </div>
                                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                  <span className={
                                                      "avatar-title bg-" +
                                                      report.bgColor +
                                                      " " +
                                                      report.textColor
                                                  }
                                                  >
                                                      <i
                                                          className={
                                                              " " + report.iconClass + " font-size-20"
                                                          }
                                                      ></i>
                                                  </span>
                                              </div>

                                          </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          ))}
                      </Row>
                  </Col>

          </Container>
          </div>
    </React.Fragment>
  );
};

StatisticsPawnshop.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(StatisticsPawnshop);
