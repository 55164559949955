import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Badge,
    Spinner,
} from "reactstrap";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

import {
    TXA_POST_WHATSAPP_SESSION_DETAIL,
} from "../../helpers/backend_helper";

//i18n
import { withTranslation } from "react-i18next";

import jwt_decode from "jwt-decode";

let roleVAR = null;
let isValopKodeCabang = null;
let isAuthDetailData = null;

const SidebarContent = props => {
    const [uuid, setuuid] = useState(null);
    const [role, setrole] = useState(null);

    const [isWhatsAppConnectionStatus, setisWhatsAppConnectionStatus] = useState(null);
    const [isWhatsAppCheckLoading, setisWhatsAppCheckLoading] = useState(false);

    async function FetchSessionDetailWhatsApp() {

        setisWhatsAppConnectionStatus(null);
        setisWhatsAppCheckLoading(true);

        setTimeout(async () => {
            try {
                isAuthDetailData = await TXA_POST_WHATSAPP_SESSION_DETAIL({ SessionIdentity: isValopKodeCabang });

                if (isAuthDetailData.data.authState !== undefined) {
                    if (isAuthDetailData.data.authState.creds.me) {
                        setisWhatsAppConnectionStatus(true);
                    }
                    else {
                        setisWhatsAppConnectionStatus(false);
                    }
                }
                else {
                    setisWhatsAppConnectionStatus(false);
                }

                setisWhatsAppCheckLoading(false);
            }
            catch (Err) {
                //console.log(Err)
                setisWhatsAppConnectionStatus(false);
                setisWhatsAppCheckLoading(false);
            }
        }, 1000);
        
    }

    const eachUserData = async () => {

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);

            roleVAR = DecodeUserData.role;
            isValopKodeCabang = DecodeUserData.user_cabang;
        }
        catch (Err) {
            console.log(Err);
        }
    }

    const ref = useRef();
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        eachUserData();
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    useEffect(() => {
        //roleVAR = null;

        setInterval(async () => {
            {
                await eachUserData();
            }
        }, 500);

        setTimeout(async () => {
            {
                await FetchSessionDetailWhatsApp();
            }
        }, 1000);

        /*setInterval(async () => {
            {
                await FetchSessionDetailWhatsApp();
            }
        }, 5000);*/
    }, []);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (

        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <span onClick={async (evt) => {
                            await FetchSessionDetailWhatsApp();
                        }} style={{ cursor: 'pointer' }}>
                            <li className="menu-title text-dark badge badge-sm badge-soft-primary mb-2"> {isWhatsAppCheckLoading ? "Please wait. . ." : "Check Server WhatsApp"} </li>
                        </span>

                        <li className="menu-title text-dark">Akses Cepat</li>
                        <li>
                            <Link to="/apps/dashboard">
                                <i className="fas fa-home" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Halaman Utama")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/apps/wa-gateway">
                                <i className="fab fa-whatsapp" style={{ fontSize: "16px" }}></i>
                                <span className="float-end">
                                    <Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color={
                                        isWhatsAppConnectionStatus !== null ? isWhatsAppConnectionStatus ? "success" : "danger" : "warning"
                                    } />
                                </span>
                                <span>{props.t("WhatsApp Gateway")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/apps/customer-management/#" className="has-arrow">
                                <i className="fas fa-address-card" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Nasabah")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/customer-management/customer-list-data">{props.t("Daftar Nasabah")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/customer-management/customer-register">{props.t("Registrasi Nasabah")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/users-management/#" className="has-arrow">
                                <i className="fas fa-users" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Pengguna Sistem")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/users-management/user-list-data">{props.t("Data Pengguna")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/users-management/user-register">{props.t("Registrasi Data Baru")}</Link>
                                </li>
                            </ul>
                        </li>
                        <hr style={{ borderTop: "1px solid rgb(195 208 246)", opacity: '0.2', margin: 'auto', maxWidth: "75%", marginLeft: '5px', marginTop: "10px", marginBottom: "10px" }} />

                        <li className="menu-title text-dark">Sistem Pegadaian</li>
                        <li>
                            <Link to="/apps/pawnshop/transaction/#" className="has-arrow">
                                <i className="fas fa-gem" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Transaksi Pegadaian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/transaction/handphone/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/transaction/handphone/new-contract">{props.t("Transaksi Baru")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/transaction/handphone/new-extend">{props.t("Transaksi Perpanjang")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/transaction/handphone/new-repayment">{props.t("Transaksi Pelunasan")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/pawnshop/validation/#" className="has-arrow">
                                <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Validasi Pegadaian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/validation/handphone/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/validation/handphone/contract">{props.t("Validasi Kontrak")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/validation/handphone/extend">{props.t("Validasi Perpanjang")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/pawnshop/validation/handphone/repayment">{props.t("Validasi Pelunasan")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="">
                            <Link to="/apps/pawnshop/report-management/#" className="has-arrow">
                                <i className="fas fa-database" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Pegadaian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/report-management/handphone/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/report-management/handphone/report-data">{props.t("Laporan Data Pegadaian")}</Link>
                                        </li>
                                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                                            <Link to="/apps/pawnshop/report-management/handphone/report-extend">{props.t("Laporan Data Perpanjang")}</Link>
                                        </li>
                                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                                            <Link to="/apps/pawnshop/report-management/handphone/report-repayment">{props.t("Laporan Data Pelunasan")}</Link>
                                        </li>
                                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                                            <Link to="/apps/pawnshop/report-management/handphone/report-deleted">{props.t("Laporan Data Terhapus")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/pawnshop/statistics/#" className="has-arrow">
                                <i className="fas fa-chart-line" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Statistik Pegadaian")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/statistics/handphone/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/statistics/handphone/analytics">{props.t("Analisa Statistik")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/pawnshop/others/#" className="has-arrow">
                                <i className="fas fa-layer-group" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Lainnya")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/pawnshop/others/handphone/#" className="has-arrow">
                                        <span>{props.t("Pegadaian Handphone")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/pawnshop/others/handphone/estimating-data" className="">
                                                <span>{props.t("Data Taksiran Handphone")}</span>
                                            </Link>
                                            <Link to="/apps/pawnshop/others/handphone/physical-checks" className="">
                                                <span>{props.t("Pengecekan Fisik")}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <hr style={{ borderTop: "1px solid rgb(195 208 246)", opacity: '0.2', margin: 'auto', maxWidth: "75%", marginLeft: '5px', marginTop: "10px", marginBottom: "10px" }} />

                        {/*<li className="menu-title text-dark">Sistem Penjualan</li>
                        <li>
                            <Link to="/apps/selling/transaction/#" className="has-arrow">
                                <i className="fas fa-cash-register" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Transaksi Penjualan")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/selling/transaction/outlet#" className="has-arrow">
                                        <span>{props.t("Transaksi Toko")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/selling/transaction/outlet/handphone">{props.t("Penjualan Handphone")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/apps/selling/transaction/outlet/accessories">{props.t("Penjualan Aksesoris")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/apps/selling/transaction/auction/#" className="has-arrow">
                                        <span>{props.t("Transaksi Lelang")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/selling/transaction/auction/handphone">{props.t("Pelelangan Handphone")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/selling/validation/#" className="has-arrow">
                                <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Validasi Transaksi")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/selling/validation/outlet-transaction" className="">
                                        <span>{props.t("Transaksi Produk Toko")}</span>
                                    </Link>
                                    <Link to="/apps/selling/validation/auction-transaction" className="">
                                        <span>{props.t("Transaksi Produk Lelang")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="">
                            <Link to="/apps/selling/report-management/#" className="has-arrow">
                                <i className="fas fa-database" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Penjualan")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/selling/report-management/store-product" className="">
                                        <span>{props.t("Laporan Produk Toko")}</span>
                                    </Link>
                                    <Link to="/apps/selling/report-management/auction-product" className="">
                                        <span>{props.t("Laporan Produk Lelang")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className={roleVAR === "Administrator" || roleVAR === "Superuser" ? "" : "d-none"}>
                            <Link to="/apps/selling/statistics/#" className="has-arrow">
                                <i className="fas fa-chart-bar" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Statistik Penjualan")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/selling/statistics/outlet/analytics#" className="has-arrow">
                                        <span>{props.t("Produk Toko")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/selling/statistics/outlet/analytics">{props.t("Grafik Statistik")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/apps/report-management/outlet/#" className="has-arrow">
                                        <span>{props.t("Produk Lelang")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/apps/auction/statistics/outlet/analytics">{props.t("Grafik Statistik")}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <hr style={{ borderTop: "1px solid rgb(195 208 246)", opacity: '0.2', margin: 'auto', maxWidth: "75%", marginLeft: '5px', marginTop: "10px", marginBottom: "10px" }} />

                        <li className="menu-title text-dark">Manajemen Produk</li>
                        <li>
                            <Link to="/apps/product-management/outlet#" className="has-arrow">
                                <i className="fas fa-boxes" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Produk Toko")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/apps/product-management/outlet/handphone-data">{props.t("Produk Handphone")}</Link>
                                </li>
                                <li>
                                    <Link to="/apps/product-management/accessories/acesories-data">{props.t("Produk Aksesoris")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/apps/product-management/auction" >
                                <i className="fas fa-gavel" style={{ fontSize: "16px" }}></i>
                                <span>{props.t("Data Produk Lelang")}</span>
                            </Link>
                        </li>
                        <hr style={{ borderTop: "1px solid rgb(195 208 246)", opacity: '0.2', margin: 'auto', maxWidth: "75%", marginLeft: '5px', marginTop: "10px", marginBottom: "10px" }} />*/}

                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
    
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
