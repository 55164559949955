import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import {
    postAuthSignIn,
    deleteAuthSignOut,
} from "../../../helpers/backend_helper";
import { Navigate } from "react-router-dom"

function* loginUser({ payload: { user, history } }) {
  try {
      const response = yield call(postAuthSignIn, {
          email: user.email,
          password: user.password,
      });

      //console.log("SAGA : " + response);

      localStorage.setItem("authToken", JSON.stringify(response.accessToken));
      localStorage.setItem("authRToken", JSON.stringify(response.refreshToken));

      if (response.accessToken != null) {
          yield put(loginSuccess(response, true));

          //yield call(fetchRefreshToken);

          setTimeout(function () {
              /*window.location.href =*/ history('/apps/dashboard');
          }, 2000);
      }
      else {
          localStorage.removeItem("authToken");
          localStorage.removeItem("authRToken");
      }

  } catch (error) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("authRToken");
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
    try {
        const response = yield call(deleteAuthSignOut);

        //console.log(response);

        if (response == "OK") {
            localStorage.removeItem("authToken");
            localStorage.removeItem("authRToken");

            yield put(logoutUserSuccess(response));

            setTimeout(function () {
                    /*window.location.href =*/ history('/auth/sign-in');
            }, 1000);
        }
  } catch (error) {
        yield put(apiError(error));

        localStorage.removeItem("authToken");
        localStorage.removeItem("authRToken");

        yield put(logoutUserSuccess(response));

        setTimeout(function () {
            /*window.location.href =*/ history('/auth/sign-in');
        }, 1000);
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
