import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Input,
    Label,
    Alert,
    FormFeedback,
    Spinner
} from "reactstrap";
import classnames from "classnames";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//i18n
import { withTranslation } from "react-i18next";

// users
import userProfilePict from "../../../assets/images/users/default.png";
import userProfilePictTroll from "../../../assets/images/users/default_troll.png";

import jwt_decode from "jwt-decode";

import * as moment from "moment";
import 'moment-timezone';
import Moment from "moment";

import {
    updateProfileData,
    fetchRefreshToken,
    putUpdatePassword,
} from "../../../helpers/backend_helper";

const UserSettings = props => {
    const [activeTab, setactiveTab] = useState("1");

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [card_id, setcard_id] = useState("");
    const [fullName, setfullName] = useState("");
    const [userName, setuserName] = useState("");
    const [uuid, setuuid] = useState("");
    const [role, setrole] = useState("");
    const [email, setemail] = useState("");
    const [last_login_at, setlast_login_at] = useState("");
    const [api_key, setapi_key] = useState("");
    const [avatar, setAvatar] = useState("");

    const [BTNUpdate, setBTNUpdate] = useState(false);

    const [updateLoading, setupdateLoading] = useState(false);
    const [updateSuccess, setupdateSuccess] = useState(false);
    const [updateError, setupdateError] = useState(false);
    const [ErrorMSG, setErrorMSG] = useState("");

    const [BTNUpdatePass, setBTNUpdatePass] = useState(false);

    const [updateLoadingPass, setupdateLoadingPass] = useState(false);
    const [updateSuccessPass, setupdateSuccessPass] = useState(false);
    const [updateErrorPass, setupdateErrorPass] = useState(false);
    const [ErrorMSGPass, setErrorMSGPass] = useState("");

    const eachUserData = async () => {

        try {
            const UserData = localStorage.getItem("authToken");
            const DecodeUserData = jwt_decode(UserData);

            setfirstName(DecodeUserData.firstName);
            setlastName(DecodeUserData.lastName);
            setcard_id(DecodeUserData.card_id);
            setfullName(DecodeUserData.fullName);
            setuserName(DecodeUserData.username);
            setuuid(DecodeUserData.uuid);
            setrole(DecodeUserData.role);
            setemail(DecodeUserData.email);
            setlast_login_at(DecodeUserData.last_login_at);
            setapi_key(DecodeUserData.api_key);

            setAvatar(DecodeUserData.avatar);
        }
        catch (Err) {
            //
        }
    }

    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            fName: firstName,
            lName: lastName,
            uuid: uuid,
        },
        validationSchema: Yup.object({
            fName: Yup.string().required("Nama Depan wajib diisi / tidak boleh kosong.").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Tidak dapat menggunakan spesial karakter!'),
            lName: Yup.string().required("Nama Belakang wajib diisi / tidak boleh kosong.").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Tidak dapat menggunakan spesial karakter!'),
        }),
        onSubmit: async (values) => {
            updateProfile(values);
        }
    });

    async function updateProfile(values) {

        setBTNUpdate(true);
        setupdateLoading(true);
        setupdateSuccess(false);
        setupdateError(false);
        setErrorMSG("");

        setTimeout(async() => {
            {
                try {
                    const result = await updateProfileData(values);

                    if (result.message == "success") {
                        setupdateSuccess(true);
                    }
                }
                catch (Err) {
                    setErrorMSG(Err);

                    setupdateError(true);
                }

                try {
                    let response = await fetchRefreshToken();

                    localStorage.setItem("authToken", JSON.stringify(response.accessToken));
                }
                catch (Err) {
                    Err;
                }

                eachUserData();

                setupdateLoading(false);
                setBTNUpdate(false);
            }
        }, 2000); //Refresh token setiap 15s
    }

    const validationPass = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            UserUUID: uuid,
            oldPass: '',
            newPass: '',
            newPassConfirm: '',
        },
        validationSchema: Yup.object({
            oldPass: Yup.string().required("Masukkan password lama atau password sebelumnya, kolom tidak boleh kosong.").min(6, 'Password minimal 6 karakter.'),
            newPass: Yup.string().required("Masukkan password password baru, kolom tidak boleh kosong.").min(8, 'Password minimal 8 karakter.'),
            newPass: Yup.string()
                .oneOf([Yup.ref('newPassConfirm'), null], 'Kofirmasi Password dan Password baru tidak sama.').min(8, 'Password minimal 8 karakter.'),
            /*newPass: Yup.mixed().test('match', 'Kolom Passwords lama tidak boleh kosong.', function (password) {
                return oldPass === ""
            }).required('Masukkan password password baru, kolom tidak boleh kosong.'),*/
            /*newPass: Yup.ref('oldPass') != "",*/
            newPassConfirm: Yup.string().required("Mohon konfirmasi atau masukkan ulang password baru anda, kolom tidak boleh kosong.").min(8, 'Password minimal 8 karakter.'),
            newPassConfirm: Yup.string()
                .oneOf([Yup.ref('newPass'), null], 'Kofirmasi Password dan Password baru tidak sama.')
        }),
        onSubmit: (values) => {
            updatePassword(values);
        }
    });

    async function updatePassword(values) {

        setBTNUpdatePass(true);
        setupdateLoadingPass(true);
        setupdateSuccessPass(false);
        setupdateErrorPass(false);
        setErrorMSGPass("");

        setTimeout(async () => {
            {
                try {
                    const result = await putUpdatePassword(values);

                    if (result.message == "success") {
                        setupdateSuccessPass(true);

                        validationPass.values.oldPass = '';
                        validationPass.values.newPass = '';
                        validationPass.values.newPassConfirm = '';
                    }
                }
                catch (Err) {
                    setErrorMSGPass(Err);

                    setupdateErrorPass(true);
                }

                try {
                    let response = await fetchRefreshToken();

                    localStorage.setItem("authToken", JSON.stringify(response.accessToken));
                }
                catch (Err) {
                    Err;
                }

                eachUserData();

                setupdateLoadingPass(false);
                setBTNUpdatePass(false);
            }
        }, 2000); //Refresh token setiap 15s
    }

    useEffect(() => {
        eachUserData();

        setTimeout(() => {
            {
                eachUserData();
            }
        }, 3000); //Refresh token setiap 15s

        /*setTimeout(() => {
            {
                eachUserData();
            }
        }, 3000); //Refresh token setiap 15s*/

    }, [props.success]);

    const handleClickShowPassword = () => {
        validation.setFieldValue('showPassword', !validation.values.showPassword);
    };

  //meta title
    document.title = "Pengaturan Akun - " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={process.env.REACT_APP_NAME + " " + process.env.REACT_APP_TXA_SITE}
            breadcrumbItem={props.t("Pengaturan Akun")}
                  />

                  <div className="checkout-tabs">
                      <Row>
                          <Col lg="2">
                              <Nav className="flex-column" pills>
                                  <NavItem>
                                      <NavLink
                                          className={classnames({ active: activeTab === "1" })}
                                          onClick={() => {
                                              setactiveTab("1");
                                              setupdateSuccess(false);
                                              setupdateError(false);
                                              setErrorMSG("");
                                          }}
                                      >
                                          <i className="d-block check-nav-icon mt-4 mb-2"><img
                                              src={userName == "system" ? userProfilePictTroll : userProfilePict}
                                              alt=""
                                              className="avatar-lg rounded img-thumbnail"
                                          /></i>
                                          <p className="font-weight-bold mb-4">Profile</p>
                                      </NavLink>
                                  </NavItem>
                                  {/*<NavItem>
                                      <NavLink
                                          className={classnames({ active: activeTab === "2" })}
                                          onClick={() => {
                                              setactiveTab("2");
                                              setupdateSuccess(false);
                                              setupdateError(false);
                                              setErrorMSG("");
                                          }}
                                      >
                                          <i className="fas fa-user-cog d-block check-nav-icon mt-4 mb-2" />
                                          <p className="font-weight-bold mb-4">Pengaturan</p>
                                      </NavLink>
                                  </NavItem>*/}
                                  <NavItem>
                                      <NavLink
                                          className={classnames({ active: activeTab === "3" })}
                                          onClick={() => {
                                              setactiveTab("3");
                                              setupdateSuccess(false);
                                              setupdateError(false);
                                              setErrorMSG("");
                                          }}
                                      >
                                          <i className="bx bx-lock d-block check-nav-icon mt-4 mb-2" />
                                          <p className="font-weight-bold mb-4">Ubah Password</p>
                                      </NavLink>
                                  </NavItem>
                              </Nav>
                          </Col>
                          <Col lg="10">
                              <Card>
                                  <CardBody>
                                      <TabContent activeTab={activeTab}>
                                          <TabPane tabId="1">
                                              <CardTitle className="mb-5">
                                                  INFORMASI AKUN PENGGUNA
                                              </CardTitle>
                                              <div className="faq-box d-flex mb-4">

                                                  <Col md={12}>
                                                      <div className="table-responsive">
                                                          <table className="table table-wrap mb-0 table">
                                                              <tbody>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Nama Depan</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {firstName}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Nama Belakang</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {lastName}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Nama Lengkap</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {fullName}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Alamat Email</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {email}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Username</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {userName}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Role</th>
                                                                      <td width="5%">:</td>
                                                                      <td>
                                                                          {
                                                                              role == 'Superuser'
                                                                                  ?
                                                                                  <b className="font-size-12 badge badge-soft-danger">{role}</b>
                                                                                  :
                                                                                  role == 'Administrator'
                                                                                      ?
                                                                                      <b className="font-size-12 badge badge-soft-primary">{role}</b>
                                                                                      :
                                                                                      role == 'Operator'
                                                                                          ?
                                                                                          <b className="font-size-12 badge badge-soft-warning">{role}</b>
                                                                                          :
                                                                                          <b className="font-size-12 badge badge-soft-info">{role}</b>
                                                                          }
                                                                          
                                                                          </td>
                                                                  </tr>
                                                                  {/*<tr>
                                                                      <th scope="row" width="30%">UUID</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {uuid != null ? "*********************************************" : "-"}
                                                                      </b></td>
                                                                  </tr>*/}
                                                                  {/*<tr>
                                                                      <th scope="row" width="30%">Card ID</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {card_id != null ? "***********************" : "-"}
                                                                      </b></td>
                                                                  </tr>*/}
                                                                  <tr>
                                                                      <th scope="row" width="30%">API Key</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {api_key != null ? "**********************************" : "-"}
                                                                      </b></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th scope="row" width="30%">Terakhir Login</th>
                                                                      <td width="5%">:</td>
                                                                      <td><b>
                                                                          {Moment.tz(Moment(last_login_at), "Asia/Jakarta").format('DD MMMM YYYY , HH:mm:ss')}
                                                                      </b></td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </Col>

                                              </div>
                                          </TabPane>
                                          <TabPane tabId="2">
                                              <CardTitle className="mb-5">PENGATURAN AKUN</CardTitle>

                                              <div className="faq-box d-flex mb-4">
                                                  <Col md={12}>
                                                  <Form
                                                      className="form-horizontal"
                                                      onSubmit={(e) => {
                                                          e.preventDefault();
                                                          validation.handleSubmit();
                                                          return false;
                                                      }}
                                                      >

                                                          {updateSuccess ? <Alert color="success">< i className="fas fa-info"></i> successfully updated your profile!</Alert> : null}

                                                          {updateError ? <Alert color="danger">< i className="fas fa-exclamation-triangle"></i> {ErrorMSG}</Alert> : null}
                                                      
                                                          <Col md={12}>
                                                              <div className="mb-3">
                                                                  <Row>
                                                                      <Col md={2}>
                                                                          <Label className="col-form-label">Nama Depan</Label>
                                                                      </Col>
                                                                      <Col md={10}>
                                                                          <Input
                                                                              name="uuid"
                                                                              className="form-control"
                                                                              type="hidden"
                                                                              value={uuid}
                                                                          />
                                                                          <Input
                                                                              name="fName"
                                                                              className="form-control"
                                                                              type="text"
                                                                              placeholder="Nama Depan Pengguna"
                                                                              onChange={validation.handleChange}
                                                                              onBlur={validation.handleBlur}
                                                                              value={validation.values.fName || ""}
                                                                              invalid={
                                                                                  validation.touched.fName && validation.errors.fName ? true : false
                                                                              }
                                                                              autoComplete={"off"}
                                                                          />
                                                                          {validation.touched.fName && validation.errors.fName ? (
                                                                              <FormFeedback type="invalid">{validation.errors.fName}</FormFeedback>
                                                                          ) : null}
                                                                      </Col>
                                                                  </Row>
                                                          </div>
                                                          </Col>

                                                          <Col md={12}>
                                                          <div className="mb-3">
                                                                  <Row>
                                                                      <Col md={2}>
                                                                          <Label className="col-form-label">Nama Belakang</Label>
                                                                      </Col>
                                                                      <Col md={10}>
                                                                          <Input
                                                                              name="lName"
                                                                              className="form-control"
                                                                              type="text"
                                                                              placeholder="Nama Belakang Pengguna"
                                                                              onChange={validation.handleChange}
                                                                              onBlur={validation.handleBlur}
                                                                              value={validation.values.lName || ""}
                                                                              autoComplete={"off"}
                                                                              invalid={
                                                                                  validation.touched.lName && validation.errors.lName ? true : false
                                                                              }
                                                                          />
                                                                          {validation.touched.lName && validation.errors.lName ? (
                                                                              <FormFeedback type="invalid">{validation.errors.lName}</FormFeedback>
                                                                          ) : null}
                                                                      </Col>
                                                                  </Row>
                                                          </div>
                                                          </Col>
                                                      
                                                      <Col md={12}>
                                                       <div className="mt-3 d-grid text-md-end">
                                                                  <Col md={12}>
                                                          <button
                                                              className="btn btn-primary btn-block" disabled={BTNUpdate}
                                                              type="submit"
                                                                      >
                                                                          {
                                                                              updateLoading
                                                                                  ?
                                                                                  <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="info" /> Please wait . . .</>
                                                                                  :
                                                                                  <><i className="far fa-edit"></i> Perbarui Profil</>
                                                                          }
                                                                      </button>
                                                                  </Col>
                                                          </div>
                                                  </Col>
                                                  </Form>

                                                  </Col>
                                              </div>
                                          </TabPane>
                                          <TabPane tabId="3">
                                              <CardTitle className="mb-5">PERUBAHAN PASSWORD</CardTitle>

                                              <div className="faq-box d-flex mb-4">
                                                  <Col md={12}>
                                                      <Form
                                                          className="form-horizontal"
                                                          onSubmit={(e) => {
                                                              e.preventDefault();
                                                              validationPass.handleSubmit();
                                                              return false;
                                                          }}
                                                      >
                                                          {updateSuccessPass ? <Alert color="success">< i className="fas fa-info"></i> successfully updated your password!</Alert> : null}

                                                          {updateErrorPass ? <Alert color="danger">< i className="fas fa-exclamation-triangle"></i> {ErrorMSGPass}</Alert> : null}

                                                          <Col md={12}>
                                                              <div className="mb-3">
                                                                  <Row>
                                                                      <Col md={2}>
                                                                          <Label className="col-form-label">Password Lama *</Label>
                                                                      </Col>
                                                                      <Col md={9}>
                                                                          <Input
                                                                              name="oldPass"
                                                                              className="form-control"
                                                                              type={validation.values.showPassword ? 'text' : 'password'}
                                                                              placeholder="**********************"
                                                                              onChange={validationPass.handleChange}
                                                                              onBlur={validationPass.handleBlur}
                                                                              value={validationPass.values.oldPass || ""}
                                                                              invalid={
                                                                                  validationPass.touched.oldPass && validationPass.errors.oldPass ? true : false
                                                                              }
                                                                              autoComplete={"off"}
                                                                          />
                                                                          {validationPass.touched.oldPass && validationPass.errors.oldPass ? (
                                                                              <FormFeedback type="invalid">{validationPass.errors.oldPass}</FormFeedback>
                                                                          ) : null}
                                                                      </Col>
                                                                      <Col md={1}>
                                                                          <div
                                                                              onClick={() => {
                                                                                  handleClickShowPassword();
                                                                              }}
                                                                              className="btn btn-light"
                                                                          >
                                                                              {validation.values.showPassword ? (
                                                                                  <span>
                                                                                      <i className="bx bxs-show" />
                                                                                  </span>
                                                                              ) : (
                                                                                  <span>
                                                                                      <i className="bx bx-hide" />
                                                                                  </span>
                                                                              )}
                                                                          </div>
                                                                      </Col>
                                                                  </Row>
                                                              </div>
                                                          </Col>

                                                          <Col md={12}>
                                                              <div className="mb-3">
                                                                  <Row>
                                                                      <Col md={2}>
                                                                          <Label className="col-form-label">Password Baru *</Label>
                                                                      </Col>
                                                                      <Col md={10}>
                                                                          <Input
                                                                              name="newPass"
                                                                              className="form-control"
                                                                              type={validation.values.showPassword ? 'text' : 'password'}
                                                                              placeholder="**********************"
                                                                              onChange={validationPass.handleChange}
                                                                              onBlur={validationPass.handleBlur}
                                                                              value={validationPass.values.newPass || ""}
                                                                              invalid={
                                                                                  validationPass.touched.newPass && validationPass.errors.newPass ? true : false
                                                                              }
                                                                              autoComplete={"off"}
                                                                          />
                                                                          {validationPass.touched.newPass && validationPass.errors.newPass ? (
                                                                              <FormFeedback type="invalid">{validationPass.errors.newPass}</FormFeedback>
                                                                          ) : null}
                                                                      </Col>
                                                                  </Row>
                                                              </div>
                                                          </Col>

                                                          <Col md={12}>
                                                              <div className="mb-3">
                                                                  <Row>
                                                                      <Col md={2}>
                                                                          <Label className="col-form-label">Konfirmasi Password Baru *</Label>
                                                                      </Col>
                                                                      <Col md={10}>
                                                                          <Input
                                                                              name="newPassConfirm"
                                                                              className="form-control"
                                                                              type={validation.values.showPassword ? 'text' : 'password'}
                                                                              placeholder="**********************"
                                                                              onChange={validationPass.handleChange}
                                                                              onBlur={validationPass.handleBlur}
                                                                              value={validationPass.values.newPassConfirm || ""}
                                                                              invalid={
                                                                                  validationPass.touched.newPassConfirm && validationPass.errors.newPassConfirm ? true : false
                                                                              }
                                                                              autoComplete={"off"}
                                                                          />
                                                                          {validationPass.touched.newPassConfirm && validationPass.errors.newPassConfirm ? (
                                                                              <FormFeedback type="invalid">{validationPass.errors.newPassConfirm}</FormFeedback>
                                                                          ) : null}
                                                                      </Col>
                                                                  </Row>
                                                              </div>
                                                          </Col>

                                                          <Col md={12}>
                                                              <div className="mt-3 d-grid text-md-end">
                                                                  <Col md={12}>
                                                                      <button
                                                                          className="btn btn-primary btn-block" disabled={BTNUpdatePass}
                                                                          type="submit"
                                                                      >
                                                                          {
                                                                              updateLoadingPass
                                                                                  ?
                                                                                  <><Spinner type="grow" style={{ height: '10px', width: '10px' }} className="ms-0" color="info" /> Please wait . . .</>
                                                                                  :
                                                                                  <><i className="far fa-edit"></i> Ubah Password</>
                                                                          }
                                                                      </button>
                                                                  </Col>
                                                              </div>
                                                          </Col>
                                                      </Form>

                                                  </Col>
                                              </div>
                                          </TabPane>
                                      </TabContent>
                                  </CardBody>
                              </Card>
                          </Col>
                      </Row>
                  </div>

          </Container>
          </div>
    </React.Fragment>
  );
};


export default withTranslation()(UserSettings);
