import React from "react";

import { Navigate } from "react-router-dom";
import { getLoggedInUser, fetchGetAuthStatus, isUserAuthenticated } from "helpers/backend_helper.js";

const Authmiddleware = (props) => {
    /*if (isUserAuthenticated()) {
        return (
            <Navigate to={{ pathname: "/auth/sign-in", state: { from: props.location } }} />
        );
    }*/

    if (!localStorage.getItem("authToken") && !localStorage.getItem("authRToken")) {
        if (isUserAuthenticated()) {
            return window.location.href = '/auth/sign-in';
        }
    }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
